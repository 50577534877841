import React from 'react';
import {ReactComponent as Database} from '../../../../Assets/images/icons/database.svg';

const DataNormalization = () => {
    return (
        <div className='mt-60'>
            <div className='aug-speech-models__icon d-flex align-items-center justify-content-center'>
                <Database/>
            </div>
            <h3 className='mb-3 aug-speech-models__heading'>Clinical data normalization</h3>
            <p className='mb-0'>In-built text formatting preferences customized for clinical documentation, including the capitalization of words, numeric representations, and specialized formatting for medical terms.</p>
        </div>
    );
};

export default DataNormalization;