import React from 'react';
import CloudBased from './CloudBased';
import OnPremise from './OnPremise';
import './VersatileDeployment.scss';
import VirtualDesktop from './VirtualDesktop';
import BaseComponent from '../../../../Common/BaseComponent';
import VirtualDesktopME from './VirtualDesktopME';
import VirtualDesktopIN from './VirtualDesktopIN';

export default class VersatileDeployment extends BaseComponent {

    render() {
        // const { regionData } = this.state;
        var regionData = this.props.userRegionData;
        if (this.props.isGeoLoading) {
            return;
        }
   
        return (
        <section className='aug-versatile-deployment aug-our-customer'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='offset-lg-2 offset-md-1 col-md-11 col-lg-8'>
                    <h2 className='aug-title mb-60'>Versatile Deployment</h2>
                    </div>
                </div>
                <div className='row'>
                    <div className='offset-lg-2 offset-md-1 col-12 col-md-10 col-lg-10'>
                        <div className='row'>
                            <div className='col-12 col-md-12 col-lg-4'>
                                <CloudBased/>
                            </div>
                            <div className='col-12 col-md-12 col-lg-4'>
                                <OnPremise/>
                            </div>
                            <div className='col-12 col-md-12 col-lg-4'>
                                {
                                    regionData === "ME" ? <VirtualDesktopME/> :
                                    regionData === "IN" ? <VirtualDesktopIN/> :
                                    <VirtualDesktop/>
                                }  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        );
    };
};