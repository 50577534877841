import React from 'react';
import './CareerBanner.scss'


const CareerBanner = () => {
    return (
       <section className='aug-career-banner'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12 offset-md-1 col-md-10 offset-lg-2 col-lg-8'>
                        <h1>Careers at Augnito</h1>
                        <p className='mb-0'>Help revolutionize the future of clinical documentation & workflows, with the industry’s most attentive & meticulous Voice AI solutions innovator.</p>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default CareerBanner;