import React from 'react';

const RevenueCycle = () => {
    return (
        <div className='mt-60'>
            <h3 className='mb-3 aug-choose-us__heading'>Improve revenue cycle management</h3>
            <p>With more detailed and complete patient narratives, reduce claim rejections and improve reimbursements for more than 90% of your patients.</p>
        </div>
    );
};

export default RevenueCycle;