import React from 'react';

const OurPartnerLogoME = () => {
    return (
        <ul className='list-unstyled mb-0 d-flex flex-wrap'>
            <li>
                <img src={require('../../../Assets/images/PartnersLogo/ME/millennium-hospital.webp')} alt="millennium hospital logo" className="img-fluid bg-white p-10"/>
            </li>
            <li>
                <img src={require('../../../Assets/images/PartnersLogo/ME/prime-hospital.webp')} alt="prime hospital logo" className="img-fluid bg-white p-10"/>
            </li>
            <li>
                <img src={require('../../../Assets/images/PartnersLogo/ME/fujifilm.webp')} alt="fujifilm logo" className="img-fluid bg-white p-10"/>
            </li>
            <li>
                <img src={require('../../../Assets/images/PartnersLogo/ME/paxera-health.webp')} alt="paxera health logo" className="img-fluid bg-white p-10"/>
            </li>
            <li>
                <img src={require('../../../Assets/images/PartnersLogo/ME/international-modern-hospital.webp')} alt="international modern hospital logo" className="img-fluid bg-white p-10"/>
            </li>
            <li>
                <img src={require('../../../Assets/images/PartnersLogo/ME/aster.webp')} alt="aster logo" className="img-fluid bg-white p-10"/>
            </li>
            <li>
                <img src={require('../../../Assets/images/PartnersLogo/ME/nhs.webp')} alt="nhs logo" className="img-fluid bg-white p-10"/>
            </li>
            <li>
                <img src={require('../../../Assets/images/PartnersLogo/ME/kims-hospital.webp')}  alt="kims hospital logo" className="img-fluid bg-white p-10"/>
            </li>
            <li>
                <img src={require('../../../Assets/images/PartnersLogo/ME/al-futtaim.webp')} alt="al-futtaim logo" className="img-fluid bg-white p-10"/>
            </li>
            <li>
                <img src={require('../../../Assets/images/PartnersLogo/ME/attieh.webp')} alt="attieh logo" className="img-fluid bg-white p-10"/>
            </li>
        </ul>
    );
};

export default OurPartnerLogoME;