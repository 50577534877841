import React from 'react';
import {ReactComponent as Dollar} from '../../../../Assets/images/icons/dollar-sign.svg';

const TranscriptionCost = () => {
    return (
        <div className='mt-60'>
            <div className='aug-choose-us__icon d-flex align-items-center justify-content-center'>
                <Dollar/>
            </div>
            <h3 className='mb-3 aug-choose-us__heading'>Reduce transcription cost</h3>
            <p>Replace costly, inconvenient transcription services with an ‘AI Typist’ that offers real time quality with faster turnaround time.</p>
        </div>
    );
};

export default TranscriptionCost;