import React from 'react';
import './VoiceTryNow.scss';
import { RedirectToSignUpVoiceServices } from '../../../../Common/Utils';

const VoiceTryNow = () => {
    return (
        <section className='aug-voive-trynow container-fluid mb-60'>
            <div className='row'>
                <div className='col-12 col-md-11 col-lg-10'>
                    <div className='aug-get-started ml-n40'>
                        <h2 className='mb-0'>
                        Voice-power your clinical suite in less than 48 hours with Augnito Voice Services!
                        </h2>
                        <div className='mt-40'>
                            <button type="button" className="btn btn-white" onClick={RedirectToSignUpVoiceServices}>Try Voice Services Now</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default VoiceTryNow;