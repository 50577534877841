import React from 'react';
import './LeadershipTeam.scss';
import {ReactComponent as Linkedin} from '../../../Assets/images/icons/linkedin.svg';

const LeadershipTeam = () => {
    return (
        <section className='aug-leadership-team mt-60'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='offset-md-1 offset-lg-2 col-md-11 col-lg-9'>
                        <h2 className="aug-title mb-60">Leadership team</h2>
                        <div className='row'>
                            <div className='col-6 col-sm-6 col-md-6 col-lg-4'>
                                <div className='mb-60'>
                                    <div className='mb-4 pb-md-2'>
                                        <img src={require('../../../Assets/images/AboutUs/leadership-team/rustom-lawyer.webp')} height={190} width={190} alt="Rustom Lawyer" className="img-fluid"/>
                                    </div>
                                    <h3 className='aug-leadership-team-name'>Rustom Lawyer</h3>
                                    <p className='mb-2 pb-1'>Co-founder & CEO</p>
                                    <a href='https://www.linkedin.com/in/rustom-lawyer/' target="_blank" rel="noreferrer">
                                        <Linkedin/>
                                    </a>
                                </div>
                            </div>
                            <div className='col-6 col-sm-6 col-md-6 col-lg-4'>
                                <div className='mb-60'>
                                    <div className='mb-4 pb-md-2'>
                                        <img src={require('../../../Assets/images/AboutUs/leadership-team/shiraz-austin.webp')} height={190} width={190} alt="Shiraz Austin" className="img-fluid"/>
                                    </div>
                                    <h3 className='aug-leadership-team-name'>Shiraz Austin</h3>
                                    <p className='mb-2 pb-1'>Co-founder</p>
                                    <a href='https://www.linkedin.com/in/shiraz-austin-89760511/' target="_blank" rel="noreferrer">
                                        <Linkedin/>
                                    </a>
                                </div>
                            </div>
                            <div className='col-6 col-sm-6 col-md-6 col-lg-4'>
                                <div className='mb-60'>
                                    <div className='mb-4 pb-md-2'>
                                        <img src={require('../../../Assets/images/AboutUs/leadership-team/mohamed-iyaz.webp')} height={190} width={190} alt="Mohamed Iyaz" className="img-fluid"/>
                                    </div>
                                    <h3 className='aug-leadership-team-name'>Mohamed Iyaz</h3>
                                    <p className='mb-2 pb-1'>Chief Operating Officer</p>
                                    <a href='https://www.linkedin.com/in/mohamed-iyaz-1798964/' target="_blank" rel="noreferrer">
                                        <Linkedin/>
                                    </a>
                                </div>
                            </div>
                            <div className='col-6 col-sm-6 col-md-6 col-lg-4'>
                                <div className='mb-60'>
                                    <div className='mb-4 pb-md-2'>
                                        <img src={require('../../../Assets/images/AboutUs/leadership-team/spriha-biswas.webp')} height={190} width={190} alt="Spriha Biswas" className="img-fluid"/>
                                    </div>
                                    <h3 className='aug-leadership-team-name'>Spriha Biswas</h3>
                                    <p className='mb-2 pb-1'>CPO & Head of Marketing</p>
                                    <a href='https://www.linkedin.com/in/spriha-biswas/' target="_blank" rel="noreferrer">
                                        <Linkedin/>
                                    </a>
                                </div>
                            </div>
                            <div className='col-6 col-sm-6 col-md-6 col-lg-4'>
                                <div className='mb-60'>
                                    <div className='mb-4 pb-md-2'>
                                        <img src={require('../../../Assets/images/AboutUs/leadership-team/piyush-arya.webp')} height={190} width={190} alt="Piyush Arya" className="img-fluid"/>
                                    </div>
                                    <h3 className='aug-leadership-team-name'>Piyush Arya</h3>
                                    <p className='mb-2 pb-1'>Chief Growth Officer</p>
                                    <a href='https://www.linkedin.com/in/piyusharya/' target="_blank" rel="noreferrer">
                                        <Linkedin/>
                                    </a>
                                </div>
                            </div>
                            <div className='col-6 col-sm-6 col-md-6 col-lg-4'>
                                <div className='mb-60'>
                                    <div className='mb-4 pb-md-2'>
                                        <img src={require('../../../Assets/images/AboutUs/leadership-team/jonathan-whitmore.webp')} height={190} width={190} alt="Jonathan Whitmore" className="img-fluid"/>
                                    </div>
                                    <h3 className='aug-leadership-team-name'>Jonathan Whitmore</h3>
                                    <p className='mb-2 pb-1'>Director of Global Expansion</p>
                                    <a href='https://www.linkedin.com/in/jonathanwhitmore/' target="_blank" rel="noreferrer">
                                        <Linkedin/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default LeadershipTeam;