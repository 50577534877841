import React from 'react';

const AmbientUSP = () => {
    return (
        <section className='aug-about-usp aug-spectra-USP'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='offset-md-1 col-md-11 col-lg-10'>
                        <div className='row gx-40'>
                            <div className='col-6 col-md-3'>
                                <div className='mb-60'>
                                    <div className='mb-3 aug-spectra-USP__digit'>
                                    3+
                                    </div>
                                    <div className='aug-spectra-USP__text'>
                                    hours saved per day on average
                                    </div>
                                </div>
                            </div>
                            <div className='offset-md-1 col-6 col-md-3'>
                                <div className='mb-60'>
                                    <div className='mb-3 aug-spectra-USP__digit'>
                                    80%
                                    </div>
                                    <div className='aug-spectra-USP__text'>
                                    average reduction in documentation time
                                    </div>
                                </div>
                            </div>
                            <div className='offset-md-1 col-6 col-md-3'>
                                <div className='mb-60'>
                                    <div className='mb-3 aug-spectra-USP__digit'>
                                    30
                                    </div>
                                    <div className='aug-spectra-USP__text'>
                                    seconds or less to create clinically structured documents
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AmbientUSP;