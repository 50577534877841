import React from 'react';
import './UnmatchedCompatibility.scss';

const UnmatchedCompatibilityIN = () => {
    return (
       <section className='aug-unmatched-compatibility mb-60'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='offset-lg-2 offset-md-1 col-md-11 col-lg-8'>
                        <h2 className='aug-title mb-60'>Unmatched compatibility</h2>
                    </div>
                </div>
                <div className='row'>
                    <div className='offset-lg-2 offset-md-1 col-12 col-md-11 col-lg-10'>
                        <div className='row gx-40'>
                            <div className='col-12 col-md-6'>
                                <div className='mb-60'>
                                    <h3 className='mb-3 aug-unmatched-compatibility__header'>Editors</h3>
                                    <p className='mb-0'>Spectra comes with in-built text editors—the Smart Editor & Micro Editor. It can also be used to directly dictate into all popular editors such as Notepad, Microsoft Word, CK Editor & more.</p>
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className='mb-60'>
                                    <h3 className='mb-3 aug-unmatched-compatibility__header'>Platforms & OS</h3>
                                    <p className='mb-0'>Spectra is available as a Windows desktop application, web application, browser extension (Google Chrome & Microsoft Edge) and mobile application (iOS & Android).</p>
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className='mb-60'>
                                    <h3 className='mb-3 aug-unmatched-compatibility__header'>EMR applications</h3>
                                    <p className='mb-0'>Spectra has straight out-of-the-box compatibility with homegrown clinical systems such as Akhil Systems, Plus91, Next Gen, and more, as well as popular international systems like eClinicalWorks, Epic, Cerner, Athena, and more.  </p>
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className='mb-60'>
                                    <h3 className='mb-3 aug-unmatched-compatibility__header'>Microphones</h3>
                                    <p className='mb-0'>Spectra is compatible with a wide range of microphones, including Sennheiser & Philips. It also comes with the free-to-download Augnito Mic app (iOS & Android).</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default UnmatchedCompatibilityIN;