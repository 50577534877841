import React from 'react';
import {ReactComponent as Plus} from '../../../../Assets/images/icons/plus.svg';

const RevenueCycle = () => {
    return (
        <div className='mt-60'>
            <div className='aug-choose-us__icon d-flex align-items-center justify-content-center'>
                <Plus/>
            </div>
            <h3 className='mb-3 aug-choose-us__heading'>Improve revenue cycle management</h3>
            <p>With more detailed and complete patient 
narratives, reduce claim rejections and improve reimbursements for more than 90% of your patients.</p>
        </div>
    );
};

export default RevenueCycle;