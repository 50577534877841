import React from 'react';
import {ReactComponent as Chart} from '../../../../Assets/images/icons/chat.svg';

const CompatibleAccents = () => {
    return (
        <div className='mt-60'>
            <div className='aug-speech-models__icon d-flex align-items-center justify-content-center'>
                <Chart/>
            </div>
            <h3 className='mb-3 aug-speech-models__heading'>Compatible accents</h3>
            <p>Spectra comprehends all global accents, without any voice training requirements.</p>
        </div>
    );
};

export default CompatibleAccents;