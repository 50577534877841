import React from 'react';
import DocumentationImg from '../../../../Assets/images/Ambient/overview/Ambient-Intelligence/Meet-Ambient-Feature.webp';
import { NavigateToInternalPage } from '../../../../Common/Utils';

const ConvertDocumentation = () => {

    return (
        <div className='row gx-0 mt-60 flex-column-reverse flex-lg-row'>
            <div className='offset-md-1 col-12 col-lg-8 col-md-10 pr-40'>
                <h3 className='aug-ambient-intelligence__heading mb-3'>Convert Conversations into Structured Medical Documentation</h3>
                <p className='mb-0'>Proprietary Multi-lingual Medical Speech Recognition and Generative AI technology that enables providers to interact with patients without the burden of manual data capture and input. It also enables easy transfer of this data into any EHR system via simple voice commands.  
                </p>
                <button type='button' className='btn btn-primary mt-40' onClick={NavigateToInternalPage("/schedule-demo-ambient")}>Book demo</button>
            </div>
            <div className='offset-lg-0 offset-md-1 col-12 col-md-10 col-lg-2'>
                <div className='aug-ambient-intelligence-documentation-img aug-ambient-convert-img'>
                    <img src={DocumentationImg} alt="Augnito Ambient Web Browser Extension interface displayed" className='w-100 img-fluid'/>
                </div>
            </div>
        </div>
    );
};

export default ConvertDocumentation;


