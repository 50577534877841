import React from 'react';
import './AboutUSP.scss'

const AboutUSP = () => {
    return (
        <section className='aug-about-usp aug-spectra-USP'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='offset-md-1 offset-lg-2 col-md-11 col-lg-8'>
                        <div className='row gx-40'>
                            <div className='col-6 col-md-4'>
                                <div className='mb-60'>
                                    <div className='mb-3 aug-spectra-USP__digit'>
                                    9+
                                    </div>
                                    <div className='aug-spectra-USP__text'>
                                    Years of proprietary Speech Recognition
                                    </div>
                                </div>
                            </div>
                            <div className='col-6 col-md-4'>
                                <div className='mb-60'>
                                    <div className='mb-3 aug-spectra-USP__digit'>
                                    23K+
                                    </div>
                                    <div className='aug-spectra-USP__text'>
                                    Rules of medical transcription quality control coded
                                    </div>
                                </div>
                            </div>
                            <div className='col-6 col-md-4'>
                                <div className='mb-60'>
                                    <div className='mb-3 aug-spectra-USP__digit'>
                                    99%
                                    </div>
                                    <div className='aug-spectra-USP__text'>
                                    Out-of-the-box accuracy
                                    </div>
                                </div>
                            </div>
                            <div className='col-6 col-md-4'>
                                <div className='mb-60'>
                                    <div className='mb-3 aug-spectra-USP__digit'>
                                    55+
                                    </div>
                                    <div className='aug-spectra-USP__text'>
                                    Medical specialities supported
                                    </div>
                                </div>
                            </div>
                            <div className='col-6 col-md-4'>
                                <div className='mb-60'>
                                    <div className='mb-3 aug-spectra-USP__digit'>
                                    236M+
                                    </div>
                                    <div className='aug-spectra-USP__text'>
                                    Trained lines of medical data
                                    </div>
                                </div>
                            </div>
                            <div className='col-6 col-md-4'>
                                <div className='mb-60'>
                                    <div className='mb-3 aug-spectra-USP__digit'>
                                    9+
                                    </div>
                                    <div className='aug-spectra-USP__text'>
                                    Years of proprietary Natural Language Understanding
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutUSP;