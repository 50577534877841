import React from 'react';
import './BookDemo.scss';
import { NavigateToInternalPage } from '../../../../Common/Utils';

const BookDemoME = () => {
   
    return (
       <section className='mb-60 aug-voice-book-demo'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-lg-11'>
                        <div className='aug-book-demo ml-n40 d-lg-flex justify-content-lg-between align-items-lg-center mb-60'>
                            <div>
                                Get access to the entire language of medicine, with clinical data normalisation & support for 50+ specialties
                            </div>
                            <div className='aug-book-demo-btn'>
                                <button type='button' className='btn btn-white' onClick={NavigateToInternalPage("/schedule-demo")}>
                                    Book demo
                                </button>
                            </div>
                        </div>        
                    </div>
                </div>
            </div>
       </section>
    );
};

export default BookDemoME;