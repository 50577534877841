import React from 'react';
import ClinicImg from '../../../../Assets/images/Spectra/OurCustomer/ME/clinics.webp';

const SmallClinicsME = () => {
    return (
        <div className='aug-card'>
            <img src={ClinicImg} alt="Physician watching radiology screen on desktop" className="w-100 img-fluid"/>
            <div className='aug-card-body p-4'>
                <h3 className='mb-3 aug-card-header'>Small clinics</h3>
                <p className='mb-0'>Spectra is extremely convenient to procure and use for small clinics. Our cloud based solution enables installations and upgrades that require less time and offer you the latest features without having to pay for version upgrades.</p>
            </div>
        </div>
    );
};

export default SmallClinicsME;