import React from 'react';
import Provider from './Provider';
import Patient from './Patient';
import OperationAdministrator from './OperationAdministrator';
import Healthcare from './Healthcare';

const TabContent = () => {
    return (
        <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade show active" id="provider" role="tabpanel" aria-labelledby="provider-tab">
                <Provider/>
            </div>
            <div className="tab-pane fade" id="patient" role="tabpanel" aria-labelledby="patient-tab">
                <Patient/>
            </div>
            <div className="tab-pane fade" id="operation-administrator" role="tabpanel" aria-labelledby="operation-administrator-tab">
                <OperationAdministrator/>
            </div>
            <div className="tab-pane fade" id="Healthcare-IT" role="tabpanel" aria-labelledby="Healthcare-IT-tab">
                <Healthcare/>
            </div>
        </div> 
    );
};

export default TabContent;