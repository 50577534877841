import React from 'react';
import './SubNavbar.scss'
import { NavLink } from "react-router-dom";
import {ReactComponent as ArrowDown} from '../../../Assets/images/icons/arrow-right.svg';
import { RedirectToSignUp, ScrollTop } from '../../../Common/Utils';

const SubNavbar = () => {

    return(
        <nav className="aug-subnavbar aug-subnavbar-full-width">
            <div className="container-fluid d-flex align-items-center">
                <div className='d-none d-md-block'>
                    <NavLink to="/spectra" onClick={ScrollTop} className="nav-link dropdown-toggle">Spectra
                    </NavLink>
                </div>
                <div className="nav-item dropdown d-md-none">
                    <NavLink to="/spectra" className="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">Spectra <ArrowDown height={18} width={18} /> 
                    </NavLink>
                    {/* mobile dropdown */}
                    <ul className="dropdown-menu aug-dropdown-menu-full-width" id="pills-tab_mobile" role="tablist">
                        <li>
                            <NavLink to="/spectra" onClick={ScrollTop} className="nav-link">
                                Overview 
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/spectra/features" onClick={ScrollTop} className="nav-link">
                                Features 
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/spectra/pricing" onClick={ScrollTop} className="nav-link">
                                Plans & Pricing
                            </NavLink>
                        </li>
                    </ul>
                </div>
                
                {/* desktop tab  */}
                <ul className="navbar-nav flex-row ms-auto">
                    <li className="nav-item d-none d-md-block">
                        <NavLink to="/spectra" onClick={ScrollTop} className="nav-link" end>
                            Overview 
                        </NavLink>
                    </li>
                    {/* Reference Document https://reactrouter.com/en/main/components/nav-link */}
                    <li className="nav-item d-none d-md-block">
                        <NavLink to="/spectra/features" onClick={ScrollTop} className="nav-link" end>
                            Features 
                        </NavLink>
                    </li>
                    <li className="nav-item d-none d-md-block">
                        <NavLink to="/spectra/pricing" onClick={ScrollTop} className="nav-link" end>
                            Plans & Pricing
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <button className="btn btn-primary" onClick={RedirectToSignUp}>
                            Try for free
                        </button>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default SubNavbar;