import React from 'react';
import './FeaturesCompare.scss';
import { ReactComponent as Tick } from '../../../../Assets/images/icons/tick.svg';
import { NavLink } from "react-router-dom";
import { FormatCurrencyCode } from '../../../../Common/Utils';
import { SetSignupURL } from '../../../../Common/Utils';

const IsFeaturesFeaturePresent = (featuresId, planFeatures) => {
    if (planFeatures !== null && planFeatures !== undefined && planFeatures.length > 0) {
        var planFilter = planFeatures.filter(f => f.Key === featuresId);
        if (planFilter !== null && planFilter !== undefined && planFilter.length > 0) {
            var filteredData = planFilter[0].Value;
            if (filteredData !== undefined && filteredData !== null) {
                if (filteredData.FeatureValue === "true" || filteredData.FeatureValue === true) {
                    return (<Tick />);
                }
                else if (filteredData.FeatureValue === "false" || filteredData.FeatureValue === false) {
                    return (<></>);
                }
                else {
                    return (<>{filteredData.FeatureValue}</>);
                }
            }
        }
    }

    return (<></>);
}

const FeaturesCompare = (props) => {
    if (props.productPlanDetails === undefined || props.productPlanDetails === "" || props.productPlanDetails.Status !== 0) {
        return false;
    }

    var FeatureCompare = props.productPlanDetails.Data.Clouds.FeatureCompare;
    var Plans = props.productPlanDetails.Data.Clouds.Plans;
    var CurrencyCode = props.productPlanDetails.Data.CurrencyCode;

    var isFeaturesAvailable = FeatureCompare !== null && FeatureCompare !== undefined;
    var IsPlatformFeaturesAvailable = isFeaturesAvailable && FeatureCompare.Platform !== null && FeatureCompare.Platform !== undefined;
    var IsProductFeaturesAvailable = isFeaturesAvailable && FeatureCompare.Product !== null && FeatureCompare.Product !== undefined;
    var IsSupportFeaturesAvailable = isFeaturesAvailable && FeatureCompare.Support !== null && FeatureCompare.Support !== undefined;
    
    var planLengthClassName = "";
    switch (Plans.length) {        
        case 1:
            planLengthClassName = "aug-features-compare-one";
            break;
        case 2:
            planLengthClassName = "aug-features-compare-two";
            break;
        case 3:
            planLengthClassName = "aug-features-compare-three";
            break;
        default:
            planLengthClassName = "";
      }

    return (
        <section className='aug-features-compare mb-60'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12 offset-lg-1 col-lg-10'>
                        <div className={planLengthClassName}>
                            <h2 className="aug-title mb-60 mt-60">Compare features</h2>
                            <div className='aug-features-compare-box aug-features-compare-box-top aug-platform-availability aug-features-compare-list-sticky'>
                                <ul className='list-unstyled mb-0 aug-features-compare-list'>
                                    <li></li>
                                    {
                                        Plans.map((item, index, data) => {
                                            return (
                                                item.IsMostPopular ?
                                                    <li key={index}>
                                                        <div className='aug-subscription-plan-popular text-center'>
                                                            Most popular
                                                        </div>
                                                    </li> :
                                                    <li key={index}></li>
                                            )
                                        })
                                    }

                                </ul>
                            </div>
                            <div className='aug-features-compare-box'>

                                <div className='aug-plan-features-name aug-plan-compare-sticky'>
                                    <div>
                                        <ul className='list-unstyled mb-0 aug-features-compare-header'>
                                            <li></li>
                                            {
                                                Plans.map((item, index, data) => {
                                                    var monthlyPlan = undefined;
                                                    if (item.Prices !== null && item.Prices !== undefined) {
                                                        monthlyPlan = item.Prices.filter(f => f.Key === props.selectedBillingCycle);
                                                        if (monthlyPlan !== undefined && monthlyPlan.length > 0) {
                                                            var selectedMonthlyPlan = monthlyPlan[0].Value;
                                                            return (
                                                                <li key={index}>
                                                                    <h3>{item.Header}</h3>
                                                                    <p>{FormatCurrencyCode(CurrencyCode)}{selectedMonthlyPlan.PerMonthFinalAmount}/m</p>
                                                                    <div className='d-md-grid'>
                                                                        {item.IsCustomPrice ?
                                                                            (<NavLink to="/contact-sales" className='btn btn-primary'>
                                                                                Contact sales
                                                                            </NavLink>) :
                                                                            (<a href={SetSignupURL(props.productPublicId, selectedMonthlyPlan.PlanId, selectedMonthlyPlan.PlanPriceId)} className='btn btn-primary'>
                                                                                Start free trial
                                                                            </a>)
                                                                        }
                                                                    </div>
                                                                </li>
                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                <li key={index}>
                                                                    <h3>{item.Header}</h3>
                                                                    <p>Custom</p>
                                                                    <div className='d-md-grid'>
                                                                        <NavLink to="/contact-sales" className='btn btn-primary'>
                                                                            Contact sales
                                                                    </NavLink>
                                                                    </div>
                                                                </li>
                                                            )
                                                        }
                                                    }
                                                    else {
                                                        return (
                                                            <li key={index}>
                                                                <h3>{item.Header}</h3>
                                                                <p>Custom</p>
                                                                <div className='d-md-grid'>
                                                                    <NavLink to="/contact-sales" className='btn btn-primary'>
                                                                        Contact sales
                                                                    </NavLink>
                                                                </div>
                                                            </li>
                                                        )
                                                    }
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>

                                <div className='d-md-none aug-features-name-list-sticky'>
                                    <ul className='list-unstyled aug-features-name-list'>
                                        {
                                            Plans.map((item, index, data) => {
                                                return (<li key={index}>{item.Header}</li>)
                                            })
                                        }
                                    </ul>
                                </div>

                                {
                                    IsPlatformFeaturesAvailable ?
                                        <div className='aug-platform-availability'>
                                            <div className='aug-features-compare-item'>
                                                Platform availability
                                            </div>
                                            <div>
                                                {
                                                    FeatureCompare.Platform.map((item, index, data) => {
                                                        return (
                                                            <ul key={index} className='list-unstyled mb-0 aug-features-compare-list'>
                                                                <li>{item.FeatureName}</li>
                                                                {
                                                                    Plans.map((planItem, planIndex, data) => {
                                                                        return (
                                                                            <li key={planIndex}>
                                                                                {IsFeaturesFeaturePresent(item.FeatureId, planItem.PlanFeatureInfo)}
                                                                            </li>
                                                                        )
                                                                    })
                                                                }

                                                            </ul>
                                                        )
                                                    })
                                                }

                                            </div>
                                        </div>
                                        : <></>
                                }

                                {
                                    IsProductFeaturesAvailable ?
                                        <div className='aug-platform-availability'>
                                            <div className='aug-features-compare-item'>
                                                Product features
                                            </div>
                                            <div>
                                                {
                                                    FeatureCompare.Product.map((item, index, data) => {
                                                        return (
                                                            <ul key={index} className='list-unstyled mb-0 aug-features-compare-list'>
                                                                <li>{item.FeatureName}</li>
                                                                {
                                                                    Plans.map((planItem, planIndex, data) => {
                                                                        return (
                                                                            <li key={planIndex}>
                                                                                {IsFeaturesFeaturePresent(item.FeatureId, planItem.PlanFeatureInfo)}
                                                                            </li>
                                                                        )
                                                                    })
                                                                }

                                                            </ul>
                                                        )
                                                    })
                                                }

                                            </div>
                                        </div>
                                        : <></>
                                }

                                {
                                    IsSupportFeaturesAvailable ?
                                        <div className='aug-platform-availability'>
                                            <div className='aug-features-compare-item'>
                                                Training & Support
                                            </div>
                                            <div>
                                                {
                                                    FeatureCompare.Support.map((item, index, data) => {
                                                        return (
                                                            <ul key={index} className='list-unstyled mb-0 aug-features-compare-list'>
                                                                <li>{item.FeatureName}</li>
                                                                {
                                                                    Plans.map((planItem, planIndex, data) => {
                                                                        return (
                                                                            <li key={planIndex}>
                                                                                {IsFeaturesFeaturePresent(item.FeatureId, planItem.PlanFeatureInfo)}
                                                                            </li>
                                                                        )
                                                                    })
                                                                }

                                                            </ul>
                                                        )
                                                    })
                                                }

                                            </div>
                                        </div>
                                        : <></>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FeaturesCompare;