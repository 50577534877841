import React from 'react';
import Hero from '../../../Assets/images/BookDemo/banner.webp';
import AmbientHubSport from './AmbientHubSpot';
import './AmbientBookDemoBanner.scss';

const AmbientBookDemoBanner = () => {
    return (
        <section className='aug-ambient-banner aug-contact-sales aug-banner-features'>
            <div className='aug-banner-features-img position-relative'>
                <img src={Hero} alt="banner" className='img-fluid'/>
                <div className='aug-banner-features-content position-absolute'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="offset-lg-2 offset-md-1 col-md-10 col-lg-10">
                                <h1 className="mb-3 text-primary aug-bookdemo-banner-title">Schedule a demo with Augnito Ambient</h1>
                                <p className='text-primary'>Fill out the form and one of our representatives will be in touch with you shortly.</p>
                            </div>
                            <div className="offset-lg-2 offset-md-1 col-md-10 col-lg-8">
                                <AmbientHubSport/>
                            </div>
                        </div>
                    </div>
                </div>
           </div>
        </section>
    );
};

export default AmbientBookDemoBanner;