import React from 'react';

const CustomerTextIN = () => {
    return (
       <div className='aug-customer-text'>
           <h3>Dr Sunil HV</h3>
           <p>Consultant, Nuclear Medicine, Narayana Health, Mazumdar Shaw Medical Center</p>
           <p className='mb-0'>
            <span className='aug-quote'>“</span>
            <span>It has definitely improved the speed of typing reports and reduced the time that it takes to complete and maintain a complex and accurate report.</span>
            </p>
       </div>
    );
};

export default CustomerTextIN;