import React from 'react';
import './FeaturesTrySpectra.scss';
import { RedirectToSignUp } from '../../../../Common/Utils';

const FeaturesTrySpectra = () => {
    return (
        <section className='aug-features-try-spectra container-fluid mb-60 mt-60'>
            <div className='row'>
                <div className='col-12 col-lg-10'>
                    <div className='aug-get-started ml-n40'>
                        <h2 className='mb-0'>
                            An AI-powered voice solution to facilitate high-quality healthcare transcription
                        </h2>
                        <div className='mt-40'>
                            <button type="button" className="btn btn-white" onClick={RedirectToSignUp}>Try Spectra for free</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FeaturesTrySpectra;