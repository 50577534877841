import React from 'react';
import {ReactComponent as Note} from '../../../../Assets/images/icons/note.svg';

const SoapTemplates = () => {
    return (
        <div className='mt-60'>
            <div className='aug-speech-models__icon d-flex align-items-center justify-content-center'>
                <Note/>
            </div>
            <h3 className='mb-3 aug-speech-models__heading'>Customizable SOAP Note templates</h3>
            <p className='mb-0'>Configurable SOAP (Subjective, Objective, Assessment, Plan) note templates that cater to multiple medical specialties and provider preferences. </p>
        </div>
    );
};

export default SoapTemplates;