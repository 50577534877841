import React from 'react';
import { NavLink } from 'react-router-dom';
import './PageCard.scss';

const PageCard = () => {
    return (
       <section className='aug-page-card'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12 offset-md-1 col-md-10 offset-lg-2 col-lg-9'>
                        <div className='row gx-16'>
                            <div className='col-md-12 col-lg-4 mb-4'>
                                <div className='p-4 aug-page-card__box'>
                                    <NavLink to="/legal/terms-of-use">
                                        <h3 className='mb-3'>Terms of Use</h3>
                                        <p className='mb-0'>Your rights and responsibilities as an Augnito subscriber. For hassle-free understanding and compliance.</p>
                                    </NavLink>
                                </div>
                            </div>
                            <div className='col-md-12 col-lg-4 mb-4'>
                                <div className='p-4 aug-page-card__box'>
                                    <NavLink to="/legal/augnito-privacy-policy">
                                        <h3 className='mb-3'>Privacy Policy</h3>
                                        <p className='mb-0'>Our policy on what information we collect at Augnito, how we use it and what choices you have.</p>
                                    </NavLink>
                                </div>
                            </div>
                            <div className='col-md-12 col-lg-4 mb-4'>
                                <div className='p-4 aug-page-card__box'>
                                    <NavLink to="/legal/augnito-cookie-policy">
                                        <h3 className='mb-3'>Cookie Policy</h3>
                                        <p className='mb-0'>A list of all the cookies and trackers implemented to improve or customize our products and your experience.</p>
                                    </NavLink>
                                </div>
                            </div>
                            <div className='col-md-12 col-lg-4 mb-4'>
                                <div className='p-4 aug-page-card__box'>
                                    <NavLink to="/legal/subscription-terms">
                                        <h3 className='mb-3'>Subscription Terms & Conditions</h3>
                                        <p className='mb-0'>The parameters of your subscription. Payment terms, duration, limitations of liability, and more.</p>
                                    </NavLink>
                                </div>
                            </div>
                            <div className='col-md-12 col-lg-4 mb-4'>
                                <div className='p-4 aug-page-card__box'>
                                    <NavLink to="/legal/refund-cancellation-policy">
                                        <h3 className='mb-3'>Refund & Cancellation Policy</h3>
                                        <p className='mb-0'>Guidelines for termination and renewal of your subscription. Criteria and regulations for rebates.</p>
                                    </NavLink>
                                </div>
                            </div>
                            <div className='col-md-12 col-lg-4 mb-4'>
                                <div className='p-4 aug-page-card__box'>
                                    <NavLink to="/legal/api-sdk-agreement">
                                        <h3 className='mb-3'>API & SDK License Agreement</h3>
                                        <p className='mb-0'>API & SDK license agreement. Outlining the terms of each party’s rights and usage of the software.</p>
                                    </NavLink>
                                </div>
                            </div>
                            <div className='col-md-12 col-lg-4 mb-4'>
                                <div className='p-4 aug-page-card__box'>
                                    <NavLink to="/legal/augnito-data-breach-policy">
                                        <h3 className='mb-3'>Data Breach Policy</h3>
                                        <p className='mb-0'>Details of cybersecurity and data privacy frameworks and response mechanisms.</p>
                                    </NavLink>
                                </div>
                            </div>
                            <div className='col-md-12 col-lg-4 mb-4'>
                                <div className='p-4 aug-page-card__box'>
                                    <NavLink to="/legal/augnito-data-retention-policy">
                                        <h3 className='mb-3'>Data Retention Policy</h3>
                                        <p className='mb-0'>Why and how we store your data, the duration it is kept for, as well as data disposal procedures.</p>
                                    </NavLink>
                                </div>
                            </div>
                            <div className='col-md-12 col-lg-4 mb-4'>
                                <div className='p-4 aug-page-card__box'>
                                    <NavLink to="/legal/referral-policy">
                                        <h3 className='mb-3'>Referral Policy</h3>
                                        <p className='mb-0'>Benefits and parameters of the customer referral policy and implementation of referral codes.</p>
                                    </NavLink>
                                </div>
                            </div>
                            <div className='col-md-12 col-lg-4 mb-4'>
                                <div className='p-4 aug-page-card__box'>
                                    <NavLink to="/legal/augnito-data-processing-terms">
                                        <h3 className='mb-3'>Data Processing Terms</h3>
                                        <p className='mb-0'>Our policy on how we process your data including personal information and the rights you have to safeguard your data</p>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default PageCard;