import React from 'react';
import './PositiveStake.scss';
import PositiveStakeTabME from './PositiveStakeTabME';
import PositiveStakeTab from './PositiveStakeTab';
import BaseComponent from '../../../Common/BaseComponent';
import TabContent from './TabContent';
import TabContentME from './TabContentME';
import PositiveStakeTabIN from './PositiveStakeTabIN';
import TabContentIN from './TabContentIN';

export default class PositiveStake extends BaseComponent {
    render() {
        // const { regionData } = this.state;
        var regionData = this.props.userRegionData;
        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <section className='aug-positive-stake'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='offset-lg-1 offset-md-1 col-md-11 col-lg-9'>
                            <h2 className='aug-title mb-60'>Benefits across-the-board</h2>
                        </div>
                        <div className='offset-lg-1 offset-md-1 col-md-11 col-lg-9'>
                            {
                                regionData === "IN" ? <PositiveStakeTabIN/> :
                                regionData === "ME" ? <PositiveStakeTabME/> :
                                <PositiveStakeTab/>
                            }
                        </div>
                    </div>
                </div>
                <div className='aug-tab-content'>
                    <div className='container-fluid'>
                        {
                            regionData === "IN" ? <TabContentIN/> :
                            regionData === "ME" ? <TabContentME/> :
                            <TabContent/>
                        }
                    </div>       
                </div> 
            </section>
        );
    };
};