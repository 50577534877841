import React from 'react';
import CustomerImg from '../../../../Assets/images/Ambient/overview/superior-service/Customer-Service-Ambient.webp';

const CustomerSupport = () => {
    return (
        <div className='aug-card'>
            <img src={CustomerImg} alt="Customer service executive" className="w-100 aug-superior-service-img img-fluid"/>
            <div className='aug-card-body p-4'>
                <h3 className='mb-3 aug-card-header'>Unparalleled customer support & service </h3>
                <p className='mb-0'>Dedicated Customer Success Managers support with on demand, free of cost 1:1 training. 24/7 technical support via email, phone, and chat. </p>
            </div>
        </div>
    );
};

export default CustomerSupport;