import React from 'react';
import './HyperPersonalization.scss';
import KeyboardShortcutsIN from './KeyboardShortcutsIN';
import CustomVocabularyIN from './CustomVocabularyIN';
import SpecializedFormattingIN from './SpecializedFormattingIN';
import NoiseSuppressionIN from './NoiseSuppressionIN';

const HyperPersonalizationIN = () => {
    return (
        <section className='aug-hyper-personalization aug-positive-stake'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='offset-lg-2 offset-md-1 col-md-11 col-lg-9'>
                        <h2 className='aug-title mb-60'>Hyper personalization </h2>
                    </div>
                    <div className='offset-lg-2 offset-md-1 col-md-11 col-lg-10'>
                        <ul className="aug-nav-tab nav nav-pills" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link px-0 active" id="KeyboardShortcuts-tab" data-bs-toggle="pill" data-bs-target="#KeyboardShortcuts" type="button" role="tab" aria-controls="KeyboardShortcuts" aria-selected="true">Keyboard shortcuts</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link px-0" id="CustomVocabulary-tab" data-bs-toggle="pill" data-bs-target="#CustomVocabulary" type="button" role="tab" aria-controls="CustomVocabulary" aria-selected="false">Custom vocabulary</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link px-0" id="SpecializedFormatting-tab" data-bs-toggle="pill" data-bs-target="#SpecializedFormatting" type="button" role="tab" aria-controls="SpecializedFormatting" aria-selected="false">Specialized formatting</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link px-0" id="NoiseSuppression-tab" data-bs-toggle="pill" data-bs-target="#NoiseSuppression" type="button" role="tab" aria-controls="NoiseSuppression" aria-selected="false">Adjustable noise suppression</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='aug-tab-content'>
                <div className='container-fluid'>
                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="KeyboardShortcuts" role="tabpanel" aria-labelledby="KeyboardShortcuts-tab">
                            <KeyboardShortcutsIN/>
                        </div>
                        <div className="tab-pane fade" id="CustomVocabulary" role="tabpanel" aria-labelledby="CustomVocabulary-tab">
                            <CustomVocabularyIN/>
                        </div>
                        <div className="tab-pane fade" id="SpecializedFormatting" role="tabpanel" aria-labelledby="SpecializedFormatting-tab">
                            <SpecializedFormattingIN/>
                        </div>
                        <div className="tab-pane fade" id="NoiseSuppression" role="tabpanel" aria-labelledby="NoiseSuppression-tab">
                            <NoiseSuppressionIN/>
                        </div>
                    </div>   
                </div>       
            </div> 
        </section>
    );
};

export default HyperPersonalizationIN;