import React from 'react';
import './StandoutFeatures.scss';
import Img from '../../../../Assets/images/features/standoutfeatures/IN/Custom_Vocabulary.webp';
import { NavigateToInternalPage } from '../../../../Common/Utils';

const CustomVocabularyIN = () => {
    return (
        <div className='aug-standout-features mt-60'>
            <div className='row'>
                <div className='col-12'>
                    <div className='aug-standout-features-img p-0'>
                        <img src={Img} alt="Augnito Spectra Medical Speech Recognition Software : Vocabulary Word Addition" className='w-100 img-fluid ' />
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-12'>
                    <div className='mt-4 pt-1'>
                        <h3 className='mb-3 aug-standout-features__header'>Personalised reporting with custom vocabulary</h3>
                        <p className='mb-0'>Optimise Spectra for better dictation output by filling in details like your preferred spellings and pronunciations. Add new words, drug names, proper nouns, alternative text formatting and more.</p>
                    </div>
                    <div className='mt-40'>
                        <button type="button" className="btn btn-outline-primary" onClick={NavigateToInternalPage("/schedule-demo")}>Book Demo</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomVocabularyIN;