import React from 'react';
import Slider from "react-slick";
import ArrowRight from '../../../Assets/images/icons/chevron-right-white.svg';
import ArrowLeft from '../../../Assets/images/icons/chevron-left-white.svg';
import { RedirectToSignUp, NavigateToInternalPage } from '../../../Common/Utils';

function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        style={{ 
            backgroundImage: `url(${ArrowRight})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
         }}
        onClick={onClick}
      />
    );
  }
  
function SamplePrevArrow(props) {
const { className, onClick } = props;
return (
    <div
    className={className}
    style={{
        backgroundImage: `url(${ArrowLeft})`, 
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    }}
    onClick={onClick}
    />
);
}

const SpectraME = () => {

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows:true,
        className:'aug-spectra-slider',
        nextArrow: <SamplePrevArrow />,
        prevArrow: <SampleNextArrow />,
    };

    return (
       <section className='aug-spectra mb-60'>
            <div className='row gx-0'>
                <div className='offset-lg-1 offset-md-1 col-12 col-md-10 col-lg-5'>
                    <Slider {...settings}>
                        <div className='aug-spectra-items aug-spectra-height'>
                            <img src={require('../../../Assets/images/home/WhatWeOffer/ME/spectra-1.webp')} alt="Augnito spectra opened in desktop, web, and mobile" className='img-fluid' />
                        </div>
                        <div className='aug-spectra-items aug-spectra-height'>
                            <div className='aug-spectra-items__name'>Spectra Desktop</div>
                            <img src={require('../../../Assets/images/home/WhatWeOffer/ME/spectra-2.webp')} alt="Augnito spectra opened in desktop app" className='img-fluid' />   
                        </div>
                        <div className='aug-spectra-items aug-spectra-height'>
                            <div className='aug-spectra-items__name'>Spectra Web</div>
                            <img src={require('../../../Assets/images/home/WhatWeOffer/ME/spectra-3.webp')} alt="Augnito spectra opened in web app" className='img-fluid' />
                        </div>
                        <div className='aug-spectra-items aug-spectra-height'>
                            <div className='aug-spectra-items__name'>Spectra Mobile</div>
                            <img src={require('../../../Assets/images/home/WhatWeOffer/ME/spectra-4.webp')} alt="Augnito spectra opened in mobile app" className='img-fluid' />
                        </div>
                    </Slider>
                </div>
                <div className='col-12 offset-lg-0 offset-md-1 col-md-10 col-lg-4 pl-40 pl-md-0 mt-md-32'>
                    <h2 className='aug-what-we-offer__heading'>Spectra</h2>
                    <h3 className='mb-3 aug-our-expertise__subheading'>Clinical speech recognition software for physicians</h3>
                    <p className='mb-0'>A fast and easy speech-to-text solution that captures patient notes in seconds, increases productivity and improves patient care.</p>
                    <div className='mt-40'>
                        <button type="button" className="btn btn-primary" onClick={RedirectToSignUp}>Try for free</button>
                        <button type="button" className="btn btn-outline-primary" onClick={ NavigateToInternalPage("/spectra") }>Discover more</button>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default SpectraME;