import React from 'react';

const AutoDebited = () => {
    return (
        <div className="accordion-item">
            <h3 className="accordion-header" id="autoDebited">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseAutoDebited" aria-expanded="false" aria-controls="collapseAutoDebited">
                    Will I be auto-debited at the end of my free trial?
                </button>
            </h3>
            <div id="collapseAutoDebited" className="accordion-collapse collapse" aria-labelledby="autoDebited" data-bs-parent="#faq">
                <div className="accordion-body">
                    No, an auto-debit will not take place. As we do not request credit card details at the beginning of the trial, you will be prompted to select your preferred plan & billing cycle once the trial period expires. Payments will take place accordingly.
                </div>
            </div>
        </div>
    );
};

export default AutoDebited;