import React from 'react';
import './PricingTrusted.scss'
import TrustedLogo from './TrustedLogo';
import BaseComponent from '../../../../Common/BaseComponent';
import TrustedLogoME from './TrustedLogoME';
import TrustedLogoIN from './TrustedLogoIN';

export default class PricingTrusted extends BaseComponent {

    render() {
        // const { regionData } = this.state;
        var regionData = this.props.userRegionData;
        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <section className='aug-pricing-trusted'>
            <div className='container-fluid'>
                    <div className='row'>
                        <div className='offset-lg-1 col-12 col-lg-10'>
                            <h2 className='aug-title mb-60 mt-60'>Trusted by</h2>
                            {
                                regionData === "ME" ? <TrustedLogoME/> :
                                regionData === "IN" ? <TrustedLogoIN/> :
                                <TrustedLogo/>
                            }
                        </div>
                    </div>
                </div>
            </section>
        );
    };
};