import React from 'react';
import Img from '../../../../Assets/images/features/standoutfeatures/Increased_Productivity.webp';
import { RedirectToSignUpVoiceServices } from '../../../../Common/Utils';

const IncreasedProductivity = () => {
    return (
        <div className='aug-standout-features'>
            <div className='row'>
                <div className='col-12'>
                    <div className='aug-standout-features-img'>
                        <img src={Img} alt="Augnito Medical Voice Services settings: Macros window for word editing open" className='w-100 img-fluid ' />
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-8 col-12'>
                    <div className='mt-4 pt-1'>
                        <h3 className='mb-3 aug-standout-features__header'>Increased productivity with macros</h3>
                        <p className='mb-0'>Voice Services enables users to save frequently repeating sentences or paragraphs as simple phrases to reduce the time spent on dictation even further.</p>
                    </div>
                    <div className='mt-40'>
                        <button type="button" className="btn btn-outline-primary" onClick={RedirectToSignUpVoiceServices}>Try now</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IncreasedProductivity;