import React from 'react';
import IndividualImg from '../../../../Assets/images/features/versatiledeployment/virtual_desktop.webp';

const VirtualDesktopME = () => {
    return (
        <div className='aug-card'>
            <img src={IndividualImg} alt="On-premise data warehouse" className="w-100 img-fluid"/>
            <div className='aug-card-body p-4'>
                <h3 className='mb-3 aug-card-header'>Citrix or virtual desktops</h3>
                <p className='mb-0'>Spectra can also be deployed on virtualised desktops and server-based, centralised systems for hassle-free updates and maintenance.</p>
            </div>
        </div>
    );
};

export default VirtualDesktopME;