import React from 'react';
import ImperativesImg from '../../../Assets/images/AboutUs/TheVoice/imperatives-healthcare.webp';

const ImperativesHealthcare = () => {
    return (
        <div className='aug-card mb-0'>
            <img src={ImperativesImg} alt="Doctor using Augnitos' voice recognition software to generate reports on Laptop using Augnitos' mobile mic" className="w-100 img-fluid"/>
            <div className='aug-card-body p-4'>
                <h3 className='mb-3 aug-card-header'>How Voice Recognition Supports the 3 Big Imperatives of Healthcare</h3>
                <p className='mb-0'>Medical voice recognition is driving positive, measurable outcomes for healthcare providers and organizations with better patient outcomes, less provider burnout, and increased revenue</p>
            </div>
        </div>
    );
};

export default ImperativesHealthcare;