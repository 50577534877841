import React from 'react';
import {NavLink} from "react-router-dom";
import './SubscriptionPlan.scss';
import SubscriptionPlanName from './SubscriptionPlanName';
import SubscriptionPlanPopular from './SubscriptionPlanPopular';
import { RedirectToSignUp } from '../../../../Common/Utils';
import BaseComponent from '../../../../Common/BaseComponent';
import Processing from '../../../Shared/Processing/Processing'

export default class SubscriptionPlan extends BaseComponent {

    constructor(props) {
        super(props);
        this.ReloadPlans = this.ReloadPlans.bind(this);
    }

    ReloadPlans(){       
        this.LoadPlaPriceInformation(this.props.innerProductListId);
    }

    IsValidPlanData() {
        if (this.props.productPlanDetails === undefined || this.props.productPlanDetails === ""
            || this.props.productPlanDetails.Status !== 0 ||
            this.props.productPlanDetails.Data.Clouds === null) {
            return false;
        }
        else {
            return true;
        }
    }

    IsValidPlanDataFromState(){
        if(this.state.priceData !== undefined && this.state.priceData !== "" && this.state.priceData.Status === 0 
        && this.state.priceData.Data.Clouds !== null){            
            return true;
        }
        else{
            return false;
        }
    }

    render() {
        if (this.props.isPriceLoading) {
            return (
                <section className='aug-subscription-plan'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='offset-lg-2 offset-md-1 col-md-11 col-lg-8 aug-processing-left'>
                                <h3 className='aug-title mb-60'>Affordable, flexible subscription plans</h3>
                                <Processing />
                            </div>
                        </div>
                    </div>
                </section>
            );
        }
        if (!this.IsValidPlanData() && !this.IsValidPlanDataFromState()) {
            return(
                <section className='aug-subscription-plan'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='offset-lg-2 offset-md-1 col-md-11 col-lg-8'>
                                <h3 className='aug-title mb-60'>Affordable, flexible subscription plans</h3>
                                <h4 className='aug-subscription-plan-load'>Oops! Failed to load latest pricing.</h4>
                                <div className="mt-40">
                                <button type='button' className='btn btn-primary' onClick={this.ReloadPlans}>Try again</button>
                            </div>
                            </div>
                        </div>
                    </div>
                </section>
            );
        }

        var PlanPriceDeatils = this.props.productPlanDetails;
        if(!this.IsValidPlanData()){
            PlanPriceDeatils = this.state.priceData;
        }
        var CloudsData = PlanPriceDeatils.Data.Clouds;
        var CurrencyCode = PlanPriceDeatils.Data.CurrencyCode;
        var billingCycle = CloudsData.BillingCycle;
        var plans = CloudsData.Plans;
        var selectedBillingCycle = billingCycle[billingCycle.length - 1];

        var SetClassName = 'offset-lg-2 col-12 col-md-12 col-lg-10';
        if(plans.length < 4){
            SetClassName = 'offset-lg-2 offset-md-1 col-12 col-md-11 col-lg-10'
        }

        var columnWidth = 'col-md-3';
        if(plans.length === 3){
            columnWidth = 'col-md-4';
        }
        
        return (
            <section className='aug-subscription-plan'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='offset-lg-2 offset-md-1 col-md-11 col-lg-8'>
                            <h2 className='aug-title'>Affordable, flexible subscription plans</h2>
                            <div className='mt-40 mb-60'>
                                <NavLink className='btn btn-primary me-3 mb-3 mb-sm-0' onClick={RedirectToSignUp}>
                                    Try for free
                                </NavLink>
                                <NavLink to="/spectra/pricing" className='btn btn-outline-primary mb-3 mb-sm-0'>
                                    View plans and pricing
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className={SetClassName}>
                            <div className='row gx-6'>
                                {
                                    plans.map((item, index, data) => {
                                        return (
                                            <div className={`col-12 ${columnWidth} col-lg-3`} key={index}>
                                                {item.IsMostPopular ? <SubscriptionPlanPopular /> : ""}
                                                <SubscriptionPlanName planData={item} selectedBillingCycle={selectedBillingCycle} currencyCode={CurrencyCode} productPublicId={this.props.innerProductListId}/>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
