import React from 'react';
import AmbientMeta from './Meta/AmbientMeta';
import SubNavbarAmbient from '../../Shared/SubNavbarAmbient/SubNavbarAmbient';
import AmbientBanner from './AmbientBanner/AmbientBanner';
import StandoutFeatures from './StandoutFeatures/StandoutFeatures';
import BookDemo from './BookDemo/BookDemo';
import ChooseUs from './ChooseUs/ChooseUs';
import SuperiorService from './SuperiorService/SuperiorService';
import AmbientUSP from './AmbientUSP/AmbientUSP';
import TryAmbient from './TryAmbient/TryAmbient';
import UnrivalledExperience from './UnrivalledExperience/UnrivalledExperience';
import AmbientIntelligence from './AmbientIntelligence/AmbientIntelligence';


const AmbientOverview = () => {
    
    return (
        <main>
            <SubNavbarAmbient/>
            <AmbientMeta/>
            <AmbientBanner/>
            <AmbientIntelligence/>
            <UnrivalledExperience/>
            <AmbientUSP/>
            <StandoutFeatures/>
            <BookDemo/>
            <ChooseUs/>  
            <SuperiorService/> 
            <TryAmbient/>            
        </main>
    );
};

export default AmbientOverview;