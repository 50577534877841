import React from 'react';
import {ReactComponent as Chat} from '../../../../Assets/images/icons/chat.svg';

const AccentsSpellings = () => {
    return (
        <div className='mt-60'>
            <div className='aug-speech-models__icon d-flex align-items-center justify-content-center'>
                <Chat/>
            </div>
            <h3 className='mb-3 aug-speech-models__heading'>Specialties, accents & spellings</h3>
            <p className='mb-0'>Access to the entire language of medicine, covering specialties such as Family Medicine, Oncology, Cardiology, Surgery, Radiology & lots more. Supports all global accents, without any voice training requirements, with UK and US spellings.</p>
        </div>
    );
};

export default AccentsSpellings;