import React from 'react';

const AttentiveResponse = () => {
    return (
        <div className='row gx-40 flex-column-reverse flex-md-row'>
            <div className='offset-lg-2 offset-md-1 col-md-6 col-lg-5'>
                <div>
                    <h3 className='mb-4 aug-tab-content__subheading'>Differentiated customer service</h3>
                    <p className='mb-0'>We offer a distinct user experience, with a human touch. Our dedicated Customer Success professionals lend assistance at any stage of your relationship with us—to help you make the most of our technologies, at no added cost. We understand the significance of building strong associations—and will provide analytics, testing and reports on request.</p>
                </div>
            </div>
            <div className='col-md-5 col-lg-4'>
                <img src={require('../../../Assets/images/AboutUs/OurEthos/attentive-response.webp')} alt="Customer service executive speaking to a physician online" className='rounded-10 img-fluid mb-4 mb-md-0 pb-2 pb-md-0'/>
            </div>
        </div>
    );
};

export default AttentiveResponse;