import React from 'react';
import {ReactComponent as Chart} from '../../../../Assets/images/icons/keyboard.svg';

const SpellingPreferences = () => {
    return (
        <div className='mt-60'>
            <div className='aug-speech-models__icon d-flex align-items-center justify-content-center'>
                <Chart/>
            </div>
            <h3 className='mb-3 aug-speech-models__heading'>Spelling preferences</h3>
            <p>Spectra can be set to produce reports in both UK and US spellings, as per your requirements.</p>
        </div>
    );
};

export default SpellingPreferences;