import React from 'react';
import MediaImg from '../../../Assets/images/ContactUs/media-contact/contact-puzzle.webp';
import AmanImg from '../../../Assets/images/ContactUs/media-contact/aman-mehta.webp';
import {ReactComponent as Message} from '../../../Assets/images/icons/message.svg';
import './MediaContact.scss'

const MediaContact = () => {
    return (
        <section className='aug-media-contact'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='offset-md-1 offset-lg-2 col-12 col-md-6 col-lg-4'>
                        <h2 className='aug-title mb-0'>Media contact</h2>
                    </div>
                </div>
                <div className='row gx-40 mt-60'>
                    <div className='col-12 col-md-12 col-lg-9'>
                        <div className='ml-n40 aug-media-contact--img'>
                            <img src={MediaImg} alt="Media icons of mail, call, and customer support on a table" className='w-100 rounded-rtb-10 img-fluid'/>
                        </div>
                    </div>
                    <div className='col-12 offset-md-1 offset-lg-0 col-md-11 col-lg-3'>
                        <div className='row gx-16'>
                            <div className='col-12 col-md-6 col-lg-12'>
                                <div className='aug-media-contact-box mb-lg-3 mt-60 mt-lg-0'>
                                    <div className='mb-3'>
                                        <img src={AmanImg} alt="Aman Mehta" height={60} width={60}/>
                                    </div>
                                    <h3 className='mb-3'>Aman Mehta</h3>
                                    <a href="mailto:aman.mehta@augnito.ai" className='d-block'>aman.mehta@augnito.ai</a>
                                    <a href="tel:+91 9769526266" className='d-block'>+91 9769526266</a>
                                </div>
                            </div>
                            <div className='col-12 col-md-6 col-lg-12'>
                                <div className='aug-media-contact-box  mt-60 mt-lg-0'>
                                    <div className='mb-3 aug-media-contact-icon'>
                                        <Message/>
                                    </div>
                                    <h3 className='mb-3'>Write to us</h3>
                                    <a href="mailto:marketing@augnito.ai" className='d-block mb-2'>marketing@augnito.ai</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </section>
    );
};

export default MediaContact;