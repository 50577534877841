import React from 'react';
import './PageNotFound.scss';
import {ReactComponent as Erorr} from '../../Assets/images/icons/error.svg';
import {Link} from "react-router-dom";
import BaseComponent from '../../Common/BaseComponent';
import PageNotFoundMeta from './Meta/PageNotFoundMeta';

export default class PageNotFound extends BaseComponent {
    render() {

        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <main className="aug-error-page">
                <PageNotFoundMeta />
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-12 offset-md-1 col-md-11 offset-lg-2 col-lg-10'>
                            <div className='mb-60'>
                                <Erorr height={60} width={60} />
                                <h1 className='aug-error-page__heading mt-1'>Oops, this is unexpected.</h1>
                                <p className='mb-0 aug-arror-code'>Error code - 404</p>
                            </div>

                            <div className='aug-helpfull-link'>
                                <p>Here are some helpful links to continue browsing while we resolve the issue.</p>
                                <ul className='list-unstyled mb-0'>
                                    <li>
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li>
                                        <Link to="/about-us">About us</Link>
                                    </li>
                                    <li>
                                        <Link to="/spectra">Augnito Spectra</Link>
                                    </li>
                                    <li>
                                        <Link to="/voice-services">Augnito Voice Services</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}