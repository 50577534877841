import React from 'react';
import AccessFeatures from './AccessFeatures';
import AutoDebited from './AutoDebited';
import './FAQ.scss';
import PaymentMethods from './PaymentMethods';
import SwitchPlan from './SwitchPlan';

const FAQ = () => {
    return (
       <section className='aug-faq'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='offset-lg-1 col-12 col-lg-10'>
                        <h2 className='aug-title mb-60 mt-60'>Frequently asked questions</h2>
                        <div className="accordion aug-accordion" id="faq">
                            <div className='mb-4'>
                                <AutoDebited/>
                            </div>
                            <div className='mb-4'>
                                <AccessFeatures/>
                            </div>
                            <div className='mb-4'>
                                <PaymentMethods/>
                            </div>
                            <div className='mb-4'>
                                <SwitchPlan/> 
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default FAQ;