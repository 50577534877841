import React from 'react';
import DocumentationVideo from '../../../../Assets/images/Ambient/overview/Ambient-Intelligence/Ambient-Benefits-Feature.gif';
// import { useEffect, useRef } from "react";

const TangibleBenefits = () => {

    // const videoRef = useRef(null);
    // useEffect(() => {
    //     let options = {threshold: 0.9};
    //     let handlePlay = (entries, observer) => {
    //         entries.forEach((entry) => {
    //             videoRef.current.pause();
    //         });
    //     };
    //     let observer = new IntersectionObserver(handlePlay, options);
    //     observer.observe(videoRef.current);
    // });

    // var videoElement = document.getElementById("videoAmbient");
    // document.addEventListener("visibilitychange", function() {
    //     if(document.visibilityState === "hidden")
    //     {
    //         videoElement.pause();
    //     }     
    // });

    return (
        <div className='row gx-0 mt-60'>
            <div className='offset-md-1 col-12 col-lg-6 col-md-10'>
                <div className='aug-ambient-intelligence-documentation-img'>
                    <img src={DocumentationVideo} alt="Physician using Ambient to record patient conversations" className='w-100 rounded-10 img-fluid'/>
                </div>
                {/* <div className="ratio ratio-16x9">
                    <video ref={videoRef} width="100%" id='videoAmbient' className='aug-watch-video' controls>
                        <source src={DocumentationVideo} type="video/webm" />
                        Your browser does not support the video tag.
                    </video>
                </div> */}
            </div>
            <div className='offset-lg-0 offset-md-1 col-12 col-lg-4 col-md-10 pl-40'>
                <h3 className='aug-ambient-intelligence__heading mb-3'>Tangible benefits from day one </h3>
                <ul className="list-unstyled mb-0 list-disc ps-lg-3">
                    <li className="mb-2">Real-time medical transcription and data-capture</li>
                    <li className="mb-2">Reduced administrative burden</li>
                    <li className="mb-2">Increased provider satisfaction and efficiency</li>
                    <li className="mb-2">Improved turnaround time</li>
                    <li>Enhanced quality of patient care and experience</li>
                </ul>
            </div>
        </div>
    );
};

export default TangibleBenefits;