import React from 'react';
import {ReactComponent as Chart} from '../../../../Assets/images/icons/range.svg';

const RangeSpecialtiesIN = () => {
    return (
        <div className='mt-60'>
            <div className='aug-speech-models__icon d-flex align-items-center justify-content-center'>
                <Chart/>
            </div>
            <h3 className='mb-3 aug-speech-models__heading'>Range of specialties</h3>
            <p>Spectra supports the entire language of medicine, covering specialties such as General Medicine, Oncology, Cardiology, General Surgery, Radiology & lots more.</p>
        </div>
    );
};

export default RangeSpecialtiesIN;