import React from 'react';
import ContactImg from '../../../Assets/images/ContactUs/HelpYou/IN/contact-sales.webp';
import { NavLink } from 'react-router-dom';

const ContactSalesIN = () => {

    return (
        <div className='aug-card'>
            <img src={ContactImg} alt="Sales representative talking virtually to a doctor using his laptop " className="w-100 img-fluid"/>
            <div className='aug-card-body p-4'>
                <h3 className='mb-3 aug-card-header'>Contact sales</h3>
                <p className='mb-0'>For any additional information about our products and services.</p>
                <div className='mt-40'>
                    <NavLink to="/contact-sales" className='btn btn-outline-primary'>Contact sales</NavLink>
                </div>
            </div>
        </div>
    );
};

export default ContactSalesIN;