import React from 'react';
import HumanTouch from './HumanTouch';
import './CustomerService.scss';
import HumanTouchME from './HumanTouchME';
import BaseComponent from '../../../../Common/BaseComponent';
import HumanTouchIN from './HumanTouchIN';

export default class CustomerService extends BaseComponent {

    render() {
        // const { regionData } = this.state;
        var regionData = this.props.userRegionData;
        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <section className='aug-customer-service aug-voice-feature-customer-service mb-60'>
            <div className='container-fluid'>
                    <div className='row'>
                        <div className='offset-md-1 offset-lg-2 col-12 col-md-6 col-lg-4'>
                            <h2 className='aug-title mb-0'>Premier customer service</h2>
                        </div>
                    </div>
                    {
                        regionData === "ME" ? <HumanTouchME/> :
                        regionData === "IN" ? <HumanTouchIN/> :
                        <HumanTouch/> 
                    }        
            </div> 
            </section>
        );
    };
};