import { Component } from 'react';
import * as Constants from './Constants'
import * as CommonUtil from './Utils'
import axios from "axios";
import Cookies from 'js-cookie';

// const delay = ms => new Promise(
//     resolve => setTimeout(resolve, ms)
// );

export default class BaseComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true, isGeoRequestSuccess: true,
            regionData: undefined, inIndex: false,
            setInIndex: undefined, priceData: undefined,
            isPriceLoading: false
        };
    }

    componentDidMount() {

        if (this.props.isGeoLoading === undefined) {

            const UserRegion = Cookies.get('UserRegion');

            if (UserRegion === null || UserRegion === undefined) {
                this.setState({ isGeoRequestSuccess: true });
                axios.post(Constants.FETCH_REGION_INFO, { "Request": { "IsProductInfoRequired": true } }).then(response => {

                    const contentRegion = CommonUtil.ParseRegionData(response);
                    Cookies.set('UserRegion', contentRegion, { expires: 7, secure: true, sameSite: 'strict' });

                    const countryName = CommonUtil.ParseCountryName(response);
                    Cookies.set('UserCountry', countryName, { expires: 7, secure: true, sameSite: 'strict' });

                    const priceRegion = CommonUtil.ParsePriceRegionData(response);
                    Cookies.set('PriceRegion', priceRegion, { expires: 7, secure: true, sameSite: 'strict' });

                    const productTrialRegion = CommonUtil.ParseTrialPeriodRegionData(response);
                    Cookies.set('TrialRegionData', productTrialRegion, { expires: 7, secure: true, sameSite: 'strict' });

                    this.setState({ regionData: contentRegion });
                    this.setState({ isLoading: false });


                }).catch((error) => {
                    console.log("Failed", error);
                    this.setState({ isGeoRequestSuccess: false });
                });
            }
            else {
                this.setState({ regionData: UserRegion, isGeoRequestSuccess: true });
                this.setState({ isLoading: false });

            }
        }
        else if (this.props.isGeoLoading === false) {
            const UserRegion = Cookies.get('UserRegion');
            this.setState({ regionData: UserRegion, isGeoRequestSuccess: true });
            this.setState({ isLoading: false });
        }

        if (this.props.productListId !== undefined && this.props.productListId !== "") {
            this.LoadPlaPriceInformation(this.props.productListId);
        }
    }
    
    async LoadPlaPriceInformation(ProductPublicListingId) {
        this.setState({ isPriceLoading: true });
        // await delay(10000);
        axios.post(Constants.PRODUCT_PLAN_DETAILS, { "Request": { "ProductPublicListingId": ProductPublicListingId, "CountryDomain": Cookies.get('PriceRegion') } }).then(response => {
            this.setState({ isPriceLoading: false });
            this.setState({ priceData: response.data });
        }).catch((error) => {
            this.setState({ isPriceLoading: false });
        });
    }
}