import React from 'react';
import './ThankYou.scss';
import CustomerSay from '../Home/CustomerSay/CustomerSay';
import CustomerSayME from '../Home/CustomerSay/CustomerSayME';
import CustomerSayIN from '../Home/CustomerSay/CustomerSayIN';
import Trusted from './Trusted';
import BaseComponent from '../../Common/BaseComponent'
import ThankYouMeta from './Meta/ThankYouMeta';
import ExpressInterest from './ThankYouBanner/ExpressInterest';
import ExpressInterestME from './ThankYouBanner/ExpressInterestME';
import ExpressInterestIN from './ThankYouBanner/ExpressInterestIN';

export default class ThankYou extends BaseComponent {
    render() {
        // const { regionData } = this.state;
        var regionData = this.props.userRegionData;
        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <main className="aug-thankyou-page mt-60">
                <ThankYouMeta />
                {
                    regionData === "ME" ? <ExpressInterestME/> :
                    regionData === "IN" ? <ExpressInterestIN/> :
                    <ExpressInterest/>
                }
                {
                    regionData === "ME" ? <CustomerSayME/> :
                    regionData === "IN" ? <CustomerSayIN/> :
                    <CustomerSay />
                }         
                <div className='container-fluid'>
                    <div className="row mb-60">
                        <div className="offset-lg-1 offset-md-1 col-12 col-md-11 col-lg-9">
                            <Trusted userRegionData={regionData}/>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}