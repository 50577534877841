import React from 'react';
import IndividualImg from '../../../../Assets/images/Ambient/overview/superior-service/Secure-Data-Centre-Ambient.webp';

const ImplementDeploy = () => {
    return (
        <div className='aug-card'>
            <img src={IndividualImg} alt="On-premise data warehouse" className="w-100 aug-superior-service-img img-fluid"/>
            <div className='aug-card-body p-4'>
                <h3 className='mb-3 aug-card-header'>Easy to implement & deploy</h3>
                <p className='mb-0'>Designed for flexible deployments, Ambient is available on secure cloud-based servers. Installation and upgrades require less than 10 minutes. It is available as a Chrome & Edge browser extension and on mobile (coming soon). </p>
            </div>
        </div>
    );
};

export default ImplementDeploy;