import * as Constants from './Constants';
import {useNavigate} from 'react-router-dom';
import Cookies from 'js-cookie';

export function GetTrialDaysByProduct(productName) {
    const trialRegion = Cookies.get('TrialRegionData');
    var trailDays = 7;
    if (trialRegion !== null && trialRegion !== undefined && trialRegion !== "undefined" && trialRegion !== "") {
        var data = JSON.parse(trialRegion);
        if (data !== undefined && data !== null && data.length > 0) {
            var filteredData = data.filter(f => f.PublictListingId === productName);
            if (filteredData.length > 0) {
                trailDays = filteredData[0].MinTrialPeriodDays;
            }
        }
    }
    return trailDays;
}

export function GetCountryName() {
    return Cookies.get('UserCountry');
}

export function ParseRegionData(response) {
    if (response !== null && response.data !== null &&
        response.data !== undefined) {

        const responseData = response.data;
        if (responseData.Status === 0 &&
            responseData.Data !== null &&
            responseData.Data !== undefined) {

            const data = responseData.Data;

            if (data.continent_region_code !== null && data.continent_region_code !== undefined) {
                return data.continent_region_code;
            }
            else if (data.country_code !== null && data.country_code !== undefined) {
                return data.country_code;
            }
        }
    }
    return Constants.DEFAULT_REGION;
}

export function ParseCountryName(response) {
    if (response !== null && response.data !== null &&
        response.data !== undefined) {

        const responseData = response.data;
        if (responseData.Status === 0 &&
            responseData.Data !== null &&
            responseData.Data !== undefined) {

            const data = responseData.Data;

            if (data.country_name !== null && data.country_name !== undefined) {
                return data.country_name;
            }
            else if (data.continent_name !== null && data.continent_name !== undefined) {
                return data.continent_name;
            }
        }
    }
    return Constants.DEFAULT_COUNTRY;
}

export function ParsePriceRegionData(response) {
    if (response !== null && response.data !== null &&
        response.data !== undefined) {

        const responseData = response.data;
        if (responseData.Status === 0 &&
            responseData.Data !== null &&
            responseData.Data !== undefined) {

            const data = responseData.Data;

            if (data.country_code !== null && data.country_code !== undefined) {
                return data.country_code;
            }
        }
    }
    return undefined;
}

export function ParseTrialPeriodRegionData(response) {
    if (response !== null && response.data !== null &&
        response.data !== undefined) {

        const responseData = response.data;
        if (responseData.Status === 0 &&
            responseData.Data !== null &&
            responseData.Data !== undefined) {

            const data = responseData.Data;

            if (data.ProductRegionInfo !== null && data.ProductRegionInfo !== undefined && data.ProductRegionInfo.length > 0) {
                return JSON.stringify(data.ProductRegionInfo);
            }
        }
    }
    return undefined;
}

export function FormatCurrencyCode(val) {
    if (val === undefined) {
        return (<></>);
    }

    var uniCode = '';
    var matches = val.match(/\d+/g);

    if (matches != null && matches.length > 0) {
        matches.forEach(parsedValue => {
            uniCode += String.fromCodePoint(parsedValue);
        });
    }
    else {
        var parsedValue = parseInt(val.replace(/&#x|;/g, ''), 16);
        if (isNaN(parsedValue)) {
            parsedValue = parseInt(val.replace(/&#|;/g, ''));
        }
        uniCode = String.fromCodePoint(parsedValue);
    }

    return (<>{uniCode}</>);
}

export function ScrollTop() {
    document.documentElement.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth", // Optional if you want to skip the scrolling animation
    });

    if(document.getElementById('NavBtnClose') != null){
        var IsMobileMenuOpen = document.getElementById('NavBtnClose').getAttribute('aria-expanded');
        if(IsMobileMenuOpen !== null && IsMobileMenuOpen === 'true'){
            document.getElementById('NavBtnClose').click();
        }
    }

    if(document.getElementById('pills-tab_mobile') != null){
        if(document.getElementById('pills-tab_mobile').classList.contains('show')){
            document.getElementById('pills-tab_mobile').classList.remove('show')
        }
    }
}

// export function NavigatetoSpectraOverView() {
//     if(document.getElementById("pills-overview-tab") != null){
//     document.getElementById("pills-overview-tab_mobile").click();
//     document.getElementById("pills-overview-tab").click();
//     } 
//     ScrollTop();
// }

// export function NavigatetoSpectraFeatures() {
//     if(document.getElementById("pills-features-tab") != null){
//     document.getElementById("pills-features-tab_mobile").click();
//     document.getElementById("pills-features-tab").click();
//     }
//     ScrollTop();
// }

// export function NavigatetoSpectraPlanPrice() {
//     if(document.getElementById("pills-plansPricing-tab")!=null){
//     document.getElementById("pills-plansPricing-tab_mobile").click();
//     document.getElementById("pills-plansPricing-tab").click();
//     }
//     ScrollTop();
// }

// export function NavigatetoVoiceServiceOverview() {
//     if(document.getElementById("pills-overview-voice-tab")!=null){
//     document.getElementById("pills-overview-voice-tab_mobile").click();
//     document.getElementById("pills-overview-voice-tab").click();
//     }    
//     ScrollTop();
// }

// export function NavigatetoVoiceServiceFeatures() {
//     if(document.getElementById("pills-features-voice-tab")!=null){
//     document.getElementById("pills-features-voice-tab_mobile").click();
//     document.getElementById("pills-features-voice-tab").click();
//     }
//     ScrollTop();
// }

export function RedirectToSignUp(){
    var signupUrl = Constants.USERCONSOLE_DOMAIN + "user/signup/augnito-spectra";
    window.location = signupUrl;
}

export function RedirectToSignUpVoiceServices(){
    var signupUrl = Constants.USERCONSOLE_DOMAIN + "user/signup/augnito-voice-services";
    window.location = signupUrl;
}

export function ReloadPage() {
    window.location.reload();
}

export function NavigateToInternalPage(PageURL){
    const navigate = useNavigate();
    const NavigatePage = () => {
        navigate(PageURL);
      };
      return NavigatePage;
}

export function SetSignupURL(ProductPublicId, PlanId, PlanPriceId){
    return Constants.USERCONSOLE_DOMAIN + "user/signup/" + ProductPublicId + "?pId=" + PlanId + "&bId=" + PlanPriceId;
}