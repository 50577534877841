import React from 'react';
import IndividualImg from '../../../../Assets/images/Spectra/OurCustomer/IN/individual.webp';

const RadiologistsTeleradiologistsIN = () => {
    return (
        <div className='aug-card'>
            <img src={IndividualImg} alt="Doctor speaking on headphone mic on her desktop" className="w-100 img-fluid"/>
            <div className='aug-card-body p-4'>
                <h3 className='mb-3 aug-card-header'>Radiologists & teleradiologists</h3>
                <p className='mb-0'>Spectra enables faster turnaround times for radiology reports within minutes of subscribing. Multi-tasking is easy with focused anchoring that allows a user to click and scroll through multiple screens, including PACS, while being able to dictate and capture impressions and findings.  </p>
            </div>
        </div>
    );
};

export default RadiologistsTeleradiologistsIN;