import React from 'react';
import QueriesImg from '../../../Assets/images/ContactUs/HelpYou/IN/general-queries.webp';


const GeneralQueriesIN = () => {   

    return (
        <div className='aug-card'>
            <img src={QueriesImg} alt="Doctor wearing a white coat and a stethoscope around his neck working on his laptop" className="w-100 img-fluid"/>
            <div className='aug-card-body p-4'>
                <h3 className='mb-3 aug-card-header'>General queries</h3>
                <p className='mb-0'>For anything else, contact us at  
                    <a href="mailto:support@augnito.ai"> support@augnito.ai</a>
                </p>
            </div>
        </div>
    );
};

export default GeneralQueriesIN;