import React from 'react';
import { RedirectToSignUp } from '../../../Common/Utils';

const BookDemoSpectra = () => {
   
    return (
       <section className='aug-voice-book-demo mt-60'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-lg-11'>
                        <div className='aug-book-demo ml-n40 d-lg-flex justify-content-lg-between align-items-lg-center mb-60'>
                            <div>
                                Robust medical vocabularies and 99% 
                                out-of-the-box accuracy for 50+ specialties
                            </div>
                            <div className='aug-book-demo-btn'>
                                <button type='button' className='btn btn-white' onClick={RedirectToSignUp}>
                                Try Spectra for free
                                </button>
                            </div>
                        </div>        
                    </div>
                </div>
            </div>
       </section>
    );
};

export default BookDemoSpectra;