import React from 'react';

const ProviderSatisfaction = () => {
    return (
        <div className='mt-60'>
            <h3 className='mb-3 aug-choose-us__heading'>Increase provider satisfaction</h3>
            <p className='mb-0'>Replace costly, inconvenient transcription services with an ‘AI Typist’ that offers providers real time quality with faster turnaround time. Reduce administrative burden, physician burnout and stress from time spent doing paperwork.</p>
        </div>
    );
};

export default ProviderSatisfaction;