import React from 'react';
import Slider from "react-slick";
import ArrowRight from '../../../Assets/images/icons/chevron-right-white.svg';
import ArrowLeft from '../../../Assets/images/icons/chevron-left-white.svg';
import { NavigateToInternalPage } from '../../../Common/Utils';

function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        style={{ 
            backgroundImage: `url(${ArrowRight})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
         }}
        onClick={onClick}
      />
    );
  }
  
function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
        <div
        className={className}
        style={{
            backgroundImage: `url(${ArrowLeft})`, 
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        }}
        onClick={onClick}
        />
    );
}

const SpectraME = () => {

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows:true,
        nextArrow: <SamplePrevArrow />,
        prevArrow: <SampleNextArrow />,
        className:'aug-spectra-slider',
    };

    return (
       <section className='aug-spectra'>
            <div className='row gx-0'>
                <div className='col-12 offset-md-1 offset-lg-0 col-md-11 col-lg-8'>
                    <Slider {...settings}>
                        <div className='aug-spectra-items'>
                            <img src={require('../../../Assets/images/home/WhatWeOffer/ME/spectra-1.webp')} alt="Augnito spectra opened in desktop, web, and mobile" className='img-fluid' />
                        </div>
                        <div className='aug-spectra-items'>
                            <div className='aug-spectra-items__name'>Spectra Desktop</div>
                            <img src={require('../../../Assets/images/home/WhatWeOffer/ME/spectra-2.webp')} alt="Augnito spectra opened in desktop app" className='img-fluid' />   
                        </div>
                        <div className='aug-spectra-items'>
                            <div className='aug-spectra-items__name'>Spectra Web</div>
                            <img src={require('../../../Assets/images/home/WhatWeOffer/ME/spectra-3.webp')} alt="Augnito spectra opened in web app" className='img-fluid' />
                        </div>
                        <div className='aug-spectra-items'>
                            <div className='aug-spectra-items__name'>Spectra Mobile</div>
                            <img src={require('../../../Assets/images/home/WhatWeOffer/ME/spectra-4.webp')} alt="Augnito spectra opened in mobile app" className='img-fluid' />
                        </div>
                    </Slider>
                </div>
                <div className='col-12 col-md-11 col-lg-4 pl-40 ms-md-4 ms-lg-0'>
                    <h3 className='aug-what-we-offer__heading'>Spectra</h3>
                    <h4 className='mb-3 aug-our-expertise__subheading'>Clinical speech recognition software for providers.</h4>
                    <ul className='list-unstyled mb-0 list-disc ms-3'>
                        <li>
                            Compatible with popular text editors such as Notepad, Microsoft Word, CK Editor.
                        </li>
                        <li>
                            Available as a Windows desktop application, web application, browser extension (Google Chrome & Microsoft Edge) and mobile application (iOS & Android).
                        </li>
                        <li>
                            Out-of-the-box compatibility with clinical systems such as eClinicalWorks, Epic, Cerner, and Athena.
                        </li>
                    </ul>
                    <div className='mt-40'>
                        <button type="button" className="btn btn-primary" onClick={NavigateToInternalPage("/spectra")}>Discover Spectra</button>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default SpectraME;