import React from 'react';
import './PricingBanner.scss'


const PricingBannerIN = () => {
    return (
       <section className='aug-pricing-banner'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12 offset-md-1 col-md-10'>
                        <div className='text-center'>
                        <h1>Get started with the right pricing plan</h1>
                        <p className='mb-0'>Augnito Spectra equips doctors with the perfect tools for higher efficiency.</p>
                        </div>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default PricingBannerIN;