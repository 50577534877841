import React from 'react';
import CustomSDk from '../../../../Assets/images/VoiceServices/overview/standout-features/custom-sdk.webp';

const CustomSDK = () => {
    return (
        <div className='row gx-0 mt-60 align-items-lg-center flex-column-reverse flex-md-row'>
            <div className='col-12 col-md-5 col-lg-6'>
                <div className='ml-n40 aug-voice-overview-standout-img'>
                    <img src={CustomSDk} alt="Augnito Medical Voice Recognition: Desktop screen showing SDK voice services" className='w-100 rounded-rtb-10 aug-voice-features-standout-img img-fluid'/>
                </div>
            </div>
            <div className='col-12 col-md-7 col-lg-4 pl-40'>
                <h3 className='aug-features-glance__heading mb-3'>Custom SDKs for diverse editors</h3>
                <p className='mb-0'>
                Built-in libraries for comprehensive voice-based editing on popular editors such as Microsoft Word, CKEditor, Plate.js, and more.
                </p>
            </div>
        </div>
    );
};

export default CustomSDK;