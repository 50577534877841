import React from 'react';
import './WhatOffer.scss';

const WhatOfferME = () => {
    return (
       <section className='aug-what-offer aug-what-offer-me'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='offset-lg-2 offset-md-1 col-md-11 col-lg-8'>
                        <h2 className='aug-title mb-60'>What we offer</h2>
                    </div>
                </div>
                <div className='row'>
                    <div className='offset-lg-2 offset-md-1 col-12 col-md-11 col-lg-10'>
                        <div className='row gx-40'>
                            <div className='col-12 col-md-6'>
                                <div className='mb-60'>
                                    <h3 className='mb-3 aug-what-offer__header'>HIPAA compliant and secure</h3>
                                    <p className='mb-0'>Spectra is HIPAA, GDPR, SOC 2, and ISO 27001 compliant with strict information security policies.</p>
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className='mb-60'>
                                    <h3 className='mb-3 aug-what-offer__header'>Compatible with all clinical applications</h3>
                                    <p className='mb-0'>Direct dictation into any HIS/EHR/EMR/PACS/RIS without the need for native integration. Compatible with popular systems like Kranium, Fujifilm, eClinicalWorks, Athena, Epic, Cerner, and more.</p>
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className='mb-60'>
                                    <h3 className='mb-3 aug-what-offer__header'>Easy to implement and deploy</h3>
                                    <p className='mb-0'>Designed for flexible deployments, Spectra is available on secure cloud-based and on-premise servers. It is Citrix and Remote/Virtual desktop ready. Installation and upgrades require less than 10 minutes.</p>
                                </div>
                            </div>                            
                            <div className='col-12 col-md-6'>
                                <div className='mb-60'>
                                    <h3 className='mb-3 aug-what-offer__header'>Unparalleled customer support and service</h3>
                                    <p className='mb-0'>Dedicated Customer Success managers support with on demand, free of cost 1:1 training. 24/7 technical support via email, phone and chat.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default WhatOfferME;