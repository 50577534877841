import React from 'react';
import OfficesImg from '../../../Assets/images/ContactUs/our-offices/our-offices.webp';
import Bengaluru from './Bengaluru';
import USA from './USA';
import London from './London';
import Mumbai from './Mumbai';
import './OurOffices.scss'

const OurOffices = () => {
    return (
        <section className='aug-our-offices'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='offset-md-1 offset-lg-2 col-12 col-md-6 col-lg-10'>
                        <h2 className='aug-title mb-60'>Our offices around the world</h2>
                    </div>
                </div>
                <div className='row'>
                    <div className='offset-lg-2 col-12 col-lg-10'>
                        <img src={OfficesImg} alt="World map showing locations of Augnito offices around the globe" className='w-100 img-fluid mb-60 mb-md-0'/>
                    </div>
                </div>
                <div className='row'>
                    <div className="offset-lg-2 offset-md-1 col-12 col-md-11 col-lg-8">
                        <div className="row gx-40">
                            <div className="col-12 col-md-6">
                                <Mumbai/>
                            </div>
                            <div className="col-12 col-md-6">
                                <Bengaluru/>
                            </div>
                            <div className="col-12 col-md-6">
                               <London/>
                            </div>
                            <div className="col-12 col-md-6">
                                <USA/>
                            </div>
                        </div>                
                    </div>        
                </div>

            </div> 
        </section>
    );
};

export default OurOffices;