import React from 'react';
import Img from '../../../Assets/images/home/WhatWeOffer/voice-services.webp';
import { NavigateToInternalPage } from '../../../Common/Utils';

const VoiceServicesIN = () => {
    return (
       <section className='aug-voice-services'>
            <div className='row gx-0 flex-column-reverse flex-md-row'>
                <div className='offset-lg-2 offset-md-1 col-12 col-md-6 col-lg-6 pr-40'>
                    <h3 className='aug-what-we-offer__heading'>Voice Services</h3>
                    <h4 className='mb-3 aug-our-expertise__subheading'>Speech Recognition APIs to enable voice in healthcare applications. </h4>
                    <ul className='list-unstyled mb-0 list-disc ms-3'>
                        <li>
                        Intuitive voice commands for your preferred EHR/EMR/PACS/RIS software.
                        </li>
                        <li>
                        Built for clinical data normalisation, offering in-built text formatting preferences. 
                        </li>
                        <li>
                        Easy integration with C#, JavaScript, Flutter, React & more.  
                        </li>
                    </ul>
                    <div className='mt-40'>
                        <button type="button" className="btn btn-primary" onClick={NavigateToInternalPage("/voice-services")}>Discover Voice Services</button>
                    </div>
                </div>
                <div className='col-12 col-md-5 col-lg-4'>
                    <div className='aug-what-we-offer__img'>
                        <img src={Img} alt="Augnito voice services loading on a laptop screen" className="img-fluid rounded-10 w-100"/>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default VoiceServicesIN;