import React from 'react';

const Innovation = () => {
    return (
       <div className='mb-60'>
            <h3 className='mb-3'>Innovation</h3>
            <p className='mb-0'>We understand the importance of structure, data, and intelligence. But we also recognize that true disruption comes from deep research and building advanced technologies.</p>
       </div>
    );
};

export default Innovation;