import React from 'react';

const LegalBanner = () => {
    return (
       <section className='aug-career-banner'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12 offset-md-1 col-md-10 offset-lg-2 col-lg-8'>
                        <h1>Legal</h1>
                        <p className='mb-0'>Here are some important things to know about using Augnito.</p>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default LegalBanner;