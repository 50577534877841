import React from 'react';
import './TrustedBy.scss';
import SpectraTrustedByIN from './SpectraTrustedByIN';

const TrustedByIN = () => {

    return (
        <section className='aug-trusted-by aug-trusted-by-me'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='offset-md-1 offset-lg-2 col-md-11 col-lg-10'>
                        <h2 className="aug-title mb-60">Trusted by</h2>
                        <SpectraTrustedByIN/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TrustedByIN;