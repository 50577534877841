import React from 'react';
import MediaPressKit from '../../../Assets/pressKit/Augnito-Presskit.zip';

const DownloadPress = () => {
    return (
        <section className='mb-60 aug-features-demo'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-lg-11'>
                        <div className='aug-book-demo ml-n40 d-lg-flex justify-content-lg-between align-items-lg-center mb-60'>
                            <div>
                                Access Augnito’s brand logos, product shots and company photos
                            </div>
                            <div className='aug-book-demo-btn'>
                                <a href={MediaPressKit} target='_blank' rel='noreferrer' className='btn btn-white'>
                                    Download press kit
                                </a>
                            </div>
                        </div>        
                    </div>
                </div>
            </div>
       </section>
    );
};

export default DownloadPress;