import React from 'react';
import { NavLink } from 'react-router-dom';
import DemoImg from '../../../Assets/images/ContactUs/HelpYou/ME/book-demo.webp';

const BookDemoME = () => {
    return (
        <div className='aug-card'>
            <img src={DemoImg} alt="Doctor getting a demo of Augnito virtually on his desktop from a Augnito representative" className="w-100 img-fluid"/>
            <div className='aug-card-body p-4'>
                <h3 className='mb-3 aug-card-header'>Book a demo</h3>
                <p className='mb-0'>Interested in how our products work? Watch them in action.</p>
                <div className='mt-40'>
                    <NavLink to="/schedule-demo" className='btn btn-outline-primary'>Book a demo</NavLink>
                </div>
            </div>
        </div>
    );
};

export default BookDemoME;