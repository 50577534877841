import React from 'react';

const PaymentMethods = () => {
    return (
        <div className="accordion-item">
            <h3 className="accordion-header" id="paymentMethods">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePaymentMethods" aria-expanded="false" aria-controls="collapsePaymentMethods">
                What payment methods do you accept?
                </button>
            </h3>
            <div id="collapsePaymentMethods" className="accordion-collapse collapse" aria-labelledby="paymentMethods" data-bs-parent="#faq">
                <div className="accordion-body">
                All major credit cards, PayPal, as well as (in select countries) direct debit. We also support transactions in a wide range of currencies.
                </div>
            </div>
        </div>
    );
};

export default PaymentMethods;