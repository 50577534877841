import React from 'react';

const CareerFlexibilityLeft = () => {
    return (
       <div>
            <h3 className='mb-3'>At Augnito, we implement a hybrid work model</h3>
            <p className='mb-0'>With Covid-19 changing the world significantly, we understand the need for versatile systems that can be adapted to best suit our talent. Whether you prefer to work from home, a co-working space, your favourite café, or garden—we want you to be able to select the environment you feel most productive and comfortable in. So, work remotely, or find a desk at one of our offices—the choice is entirely yours!</p>
       </div>
    );
};

export default CareerFlexibilityLeft;