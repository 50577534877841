import React from 'react';
import CustomerSupport from './CustomerSupport';
import PostIntegration from './PostIntegration';
import TechnicalIntegration from './TechnicalIntegration';
import './SuperiorService.scss';
import BaseComponent from '../../../../Common/BaseComponent';
import TechnicalIntegrationME from './TechnicalIntegrationME';
import CustomerSupportME from './CustomerSupportME';
import TechnicalIntegrationIN from './TechnicalIntegrationIN';
import CustomerSupportIN from './CustomerSupportIN';

export default class SuperiorService extends BaseComponent {

    render() {
        // const { regionData } = this.state;
        var regionData = this.props.userRegionData;
        if (this.props.isGeoLoading) {
            return;
        }
   
        return (
        <section className='aug-superior-service aug-our-customer'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='offset-lg-2 offset-md-1 col-md-11 col-lg-8'>
                    <h2 className='aug-title mb-60'>A superior service</h2>
                    </div>
                </div>
                <div className='row'>
                    <div className='offset-lg-2 offset-md-1 col-12 col-md-10 col-lg-10'>
                        <div className='row'>
                            <div className='col-12 col-md-12 col-lg-4'>
                                {
                                    regionData === "IN" ? <TechnicalIntegrationIN/> :
                                    regionData === "ME" ? <TechnicalIntegrationME/> :
                                    <TechnicalIntegration/>
                                }
                            </div>
                            <div className='col-12 col-md-12 col-lg-4'>
                                <PostIntegration/>
                            </div>
                            <div className='col-12 col-md-12 col-lg-4'>
                                {
                                    regionData === "IN" ? <CustomerSupportIN/> :
                                    regionData === "ME" ? <CustomerSupportME/> :
                                    <CustomerSupport/>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        );
    };
};