import React from 'react';
import IndividualImg from '../../../../Assets/images/Spectra/OurCustomer/individual.webp';

const IndividualProviders = () => {
    return (
        <div className='aug-card'>
            <img src={IndividualImg} alt="Doctor using headphone mic for medical dictation" className="w-100 img-fluid"/>
            <div className='aug-card-body p-4'>
                <h3 className='mb-3 aug-card-header'>Individual providers</h3>
                <p className='mb-0'>Individual providers can get started with Spectra on their own through a simple self checkout process that does not require credit card details or long commitments. Designed for simplicity and ease of use, the product has in-built product guides that enable you to get started within minutes of subscribing. </p>
            </div>
        </div>
    );
};

export default IndividualProviders;