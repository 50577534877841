import React from 'react';
import Img from '../../../../Assets/images/features/standoutfeatures/Custom_Vocabulary.webp';
import { RedirectToSignUpVoiceServices } from '../../../../Common/Utils';

const CustomVocabulary = () => {
    return (
        <div className='aug-standout-features mt-60'>
            <div className='row'>
                <div className='col-12'>
                    <div className='aug-standout-features-img p-0'>
                        <img src={Img} alt="Augnito Medical Voice Services settings: Vocabulary window open" className='w-100 img-fluid ' />
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-8 col-12'>
                    <div className='mt-4 pt-1'>
                        <h3 className='mb-3 aug-standout-features__header'>Real-time training of custom vocabulary</h3>
                        <p className='mb-0'>Users can train the AI to accurately recognize new words, drug names, proper nouns, and more.</p>
                    </div>
                    <div className='mt-40'>
                        <button type="button" className="btn btn-outline-primary" onClick={RedirectToSignUpVoiceServices}>Try now</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomVocabulary;