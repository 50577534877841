import React from 'react';
import {ReactComponent as Clock} from '../../../../Assets/images/icons/clock.svg';

const AutomateTranscriptionIN = () => {
    return (
        <div className='mt-60'>
            <div className='aug-choose-us__icon d-flex align-items-center justify-content-center'>
                <Clock/>
            </div>
            <h3 className='mb-3 aug-choose-us__heading'>Automate transcription processes</h3>
            <p>Replace inefficient manual transcription services with an ‘AI Typist’ that offers real time quality with faster turnaround time. </p>
        </div>
    );
};

export default AutomateTranscriptionIN;