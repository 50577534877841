import React from 'react';

const OperationAdministratorIN = () => {
    return (
        <div className='row gx-40 flex-column-reverse flex-md-row'>
            <div className='offset-lg-1 offset-md-1 col-md-6 col-lg-5'>
                <div>
                    {/* <h3 className='aug-tab-content__heading mb-2'>Operations & Administrators</h3> */}
                    <h3 className='mb-4 aug-tab-content__subheading'>Unlock physician capacity</h3>
                    <p className='mb-0'>Boost top and bottom-line revenue figures and manage OPD footfall with faster turnaround times.</p>
                </div>
            </div>
            <div className='offset-lg-1 col-md-5 col-lg-4'>
                <img src={require('../../../Assets/images/home/PositiveStake/IN/positive-stake-2.webp')} height="auto" width="100%" alt="Radiologist using Augnito mobile mic for transcriptions" className='img-fluid mb-4 mb-md-0 pb-2 pb-md-0'/>
            </div>
        </div>
    );
};

export default OperationAdministratorIN;