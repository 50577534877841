import React from 'react';
import {NavLink} from "react-router-dom";
import Img from '../../../../Assets/images/Spectra/FeaturesGlance/vocabulary.webp';
import { RedirectToSignUp } from '../../../../Common/Utils';

const CustomVocabulary = () => {
    return (
        <div className='row gx-0 mt-60 align-items-lg-center'>
            <div className='offset-md-1 offset-lg-2 col-12 col-md-6 col-lg-4 pr-40'>
                <h3 className='aug-features-glance__heading mb-3'>Custom vocabulary</h3>
                <p className='mb-0'>
                    Add new words such as proper nouns to your personal vocabulary. Spectra learns and recognizes them in real time.
                </p>
                <div className="mt-40">
                    <NavLink className='btn btn-primary me-3' onClick={RedirectToSignUp}>
                        Try for free   
                    </NavLink>
                </div>
            </div>
            <div className='col-12 col-md-5 col-lg-6'>
                <div className='mr-n40 aug-features-glance__img'>
                    <img src={Img} alt="Augnito Spectra Medical Speech Recognition Software: Vocabulary settings window" className='w-100 img-fluid'/>
                </div>
            </div>
        </div>
    );
};

export default CustomVocabulary;