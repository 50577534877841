import React from 'react';
import {ReactComponent as Share} from '../../../../Assets/images/icons/share.svg';

const EMRTransfer = () => {
    return (
        <div className='mt-60'>
            <div className='aug-speech-models__icon d-flex align-items-center justify-content-center'>
                <Share/>
            </div>
            <h3 className='mb-3 aug-speech-models__heading'>Easy EMR transfer</h3>
            <p className='mb-0'>Hassle-free data transfer to web-based EMRs without the need for complex integration, providing immediate access to patient records within existing workflows. </p>
        </div>
    );
};

export default EMRTransfer;