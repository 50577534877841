import React from 'react';
import OfficeMumbai from '../../../Assets/images/ContactUs/our-offices/mumbai.png';

const Mumbai = () => {
    return (
        <div className='mb-60'>
            <div className='mb-3'>
                <img src={OfficeMumbai} alt="Mumbai" className='img-fluid'/>
            </div>
            <h3 className='mb-3 aug-our-offices__name'>Mumbai, India</h3>
            <p className='mb-3'>Meher House, 1st Floor, #15 Cawasji Patel Road, Fort, Mumbai, 400001.</p>
            <a href="tel:8447745585">+91 8447-74-5585</a>
        </div>
    );
};

export default Mumbai;