import React from 'react';

const Precision = () => {
    return (
       <div className='mb-60'>
            <h3 className='mb-3'>Precision</h3>
            <p className='mb-0'>We undertake any task with thoroughness, accuracy and sharpness, born from experience and ability.</p>
       </div>
    );
};

export default Precision;