import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import IndividualImg from '../../../../Assets/images/features/versatiledeployment/cloud-based.webp';

const CloudBased = () => {

    const [inIndex, setInIndex] = useState();
    let location = useLocation();
    useEffect(() => {
        setInIndex(location.pathname === "/spectra/features");
    }, [location]);

    return (
        <div className='aug-card'>
            <img src={IndividualImg} alt="Abstract cloud deployment with security" className="w-100 img-fluid"/>
            <div className='aug-card-body p-4'>
                <div className={`${inIndex ? 'd-block' : 'd-none'}`}>
                    <h3 className='mb-3 aug-card-header'>Cloud-based</h3>
                    <p className='mb-0'>Spectra is a secure, cloud-based SaaS solution that eliminates any server or hosting complications. You can get started with absolutely no investment in infrastructure. It is locally hosted, region specific, GDPR, HIPAA compliant and ISO 27001 certified.</p>
                </div>

                <div className={`${inIndex ? 'd-none' : 'd-block'}`}>
                    <h3 className='mb-3 aug-card-header'>Cloud-based</h3>
                    <p className='mb-0'>Voice Services is a secure, cloud-based SaaS solution that eliminates any server or hosting complications. Users can get started with absolutely no investment in infrastructure. It is locally hosted, region specific, GDPR, HIPAA compliant and ISO 27001 certified.</p>
                </div>

                
            </div>
        </div>
    );
};

export default CloudBased;