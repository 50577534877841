import React from 'react';
import ImgCommand from '../../../../Assets/images/VoiceServices/features/standout-features/IN/command-control.webp';

const CommandControlIN = () => {
    return (
        <div className='row gx-0 mt-60 align-items-lg-center flex-column-reverse flex-md-row'>
            <div className='col-12 col-md-5 col-lg-6'>
                <div className='ml-n40'>
                    <img src={ImgCommand} alt="Physician navigating on his respective EMR/EHR/PACS/RIS software" className='w-100 aug-voice-features-standout-img rounded-rtb-10 img-fluid'/>
                </div>
            </div>
            <div className='col-12 col-md-6 col-lg-4 pl-40'>
                <h3 className='aug-features-glance__heading mb-3'>Easy navigation with voice command & control</h3>
                <p className='mb-0'>
                Intuitive voice commands to perform actions on your preferred HIS/EMR/PACS/RIS software.
                </p>
            </div>
        </div>
    );
};

export default CommandControlIN;