import React from 'react';
import './OurCustomer.scss';
import IndividualProviders from './IndividualProviders';
import SmallClinics from './SmallClinics';
import LargeHospitals from './LargeHospitals';
import BaseComponent from '../../../../Common/BaseComponent';
import IndividualProvidersME from './IndividualProvidersME';
import SmallClinicsME from './SmallClinicsME';
import LargeHospitalsME from './LargeHospitalsME';
import RadiologistsTeleradiologistsIN from './RadiologistsTeleradiologistsIN';
import SmallClinicsIN from './SmallClinicsIN';
import LargeHospitalsIN from './LargeHospitalsIN';

export default class OurCustomer extends BaseComponent {

    render() {
        // const { regionData } = this.state;
        var regionData = this.props.userRegionData;
        if (this.props.isGeoLoading) {
            return;
        }

        return (
        <section className='aug-our-customer aug-spectra-overview-our-customer'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='offset-lg-2 offset-md-1 col-md-11 col-lg-8'>
                            <h2 className='aug-title mb-60'>Our customers</h2>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='offset-lg-2 offset-md-1 col-12 col-md-10 col-lg-10'>
                            <div className='row'>
                                <div className='col-12 col-md-12 col-lg-4'>
                                    {
                                        regionData === "ME" ? <IndividualProvidersME/> :
                                        regionData === "IN" ? <RadiologistsTeleradiologistsIN/> :
                                        <IndividualProviders/>
                                    }
                                </div>
                                <div className='col-12 col-md-12 col-lg-4'>
                                    {
                                        regionData === "ME" ? <SmallClinicsME/> :
                                        regionData === "IN" ? <SmallClinicsIN/> :
                                        <SmallClinics/>
                                    }
                                </div>
                                <div className='col-12 col-md-12 col-lg-4'>
                                    {
                                        regionData === "ME" ? <LargeHospitalsME/> :
                                        regionData === "IN" ? <LargeHospitalsIN/> :
                                        <LargeHospitals/>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </section>
        );
    };
};