import React from 'react';

const ComprehensiveDocumentation = () => {
    return (
       <div className='mb-60'>
            <h3 className='mb-3'>Comprehensive documentation</h3>
            <p className='mb-0'>Extensive resources for your development team to understand, integrate, and optimize Voice AI integration.</p>
       </div>
    );
};

export default ComprehensiveDocumentation;