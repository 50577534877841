import React from 'react';
import './Sitemap.scss';
import {Link} from "react-router-dom";
import * as Constants from '../../Common/Constants';
import BaseComponent from '../../Common/BaseComponent';
import SitemapMeta from './Meta/SitemapMeta';

export default class Sitemap extends BaseComponent {
    render() {

        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <main className="aug-sitemap-page">
                <SitemapMeta />
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-12 offset-md-1 col-md-11 offset-lg-2 col-lg-10'>
                            <h1 className='aug-sitemap-page__heading'>Sitemap</h1>
                            <div className='row gx-40'>
                                <div className='col-12 col-md-4 col-lg-3'>
                                    <h2 className='aug-sitemap-page__subheading mb-3'>Product</h2>
                                    <ul className='list-unstyled mb-4 pb-2 pb-md-0 mb-md-0'>
                                        <li>
                                            <Link to="/spectra">Augnito Spectra</Link>
                                        </li>
                                        <li>
                                            <Link to="/voice-services">Augnito Voice Services</Link>
                                        </li>
                                        <li className='mb-0'>
                                            <Link to="/ambient">Augnito Ambient</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className='col-12 col-md-4 col-lg-3'>
                                    <h2 className='aug-sitemap-page__subheading mb-3'>Company</h2>
                                    <ul className='list-unstyled mb-4 pb-2 pb-md-0 mb-md-0'>
                                        <li>
                                            <Link to="/about-us">About Us</Link>
                                        </li>
                                        <li>
                                            <Link to="/careers">Careers</Link>
                                        </li>
                                        <li className='mb-0'>
                                            <Link to="/contact-us">Contact us</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className='col-12 col-md-4 col-lg-3'>
                                    <h2 className='aug-sitemap-page__subheading mb-3'>Resources</h2>
                                    <ul className='list-unstyled mb-4 pb-2 pb-md-0 mb-md-0'>
                                        <li>
                                            <a href={Constants.SITE_URL + "resources/blog"} target="_blank" rel="noreferrer" className="nav-link">Blog</a>
                                        </li>
                                        <li>
                                            <a href={Constants.SITE_URL + "resources/case-studies"} target="_blank" rel="noreferrer" className="nav-link">Case study</a>
                                        </li>
                                        <li>
                                            <a href={Constants.SITE_URL + "resources/newsroom"} target="_blank" rel="noreferrer" className="nav-link">Newsroom</a>
                                        </li>
                                        <li>
                                            <a href={Constants.SITE_URL + "resources/faq"} target="_blank" rel="noreferrer" className="nav-link">FAQs</a>
                                        </li>
                                        <li>
                                            <a href={Constants.SITE_URL + "resources/docs"} target="_blank" rel="noreferrer" className="nav-link">Documentation</a>
                                        </li>
                                        <li>
                                            <a href={Constants.SITE_URL + "resources/videos"} target="_blank" rel="noreferrer" className="nav-link">Videos</a>
                                        </li>
                                        <li className='mb-0'>
                                            <a href={Constants.SITE_URL + "resources/events"} target="_blank" rel="noreferrer" className="nav-link">Events</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}