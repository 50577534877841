import React from 'react';
import IndividualImg from '../../../../Assets/images/VoiceServices/overview/superior-service/technical-integration.webp';

const TechnicalIntegration = () => {
    return (
        <div className='aug-card'>
            <img src={IndividualImg} alt="Customer support instructing a doctor with Augnito Medical Voice Recognition on desktop" className="w-100 aug-superior-service-img img-fluid"/>
            <div className='aug-card-body p-4'>
                <h3 className='mb-3 aug-card-header'>Technical integration support</h3>
                <p className='mb-0'>Our team is available to lend any assistance that you may require throughout the onboarding process.</p>
            </div>
        </div>
    );
};

export default TechnicalIntegration;