import React from 'react';
import IndividualImg from '../../../../Assets/images/VoiceServices/overview/superior-service/ME/customer-support.webp';

const CustomerSupportME = () => {
    return (
        <div className='aug-card'>
            <img src={IndividualImg} alt="Doctor and customer support executive having a discussion" className="w-100 aug-superior-service-img img-fluid"/>
            <div className='aug-card-body p-4'>
                <h3 className='mb-3 aug-card-header'>Premier customer support</h3>
                <p className='mb-0'>Our dedicated Customer Success professionals are focused on training and onboarding new users, helping them make the most of our speech recognition technology—at no added cost!</p>
            </div>
        </div>
    );
};

export default CustomerSupportME;