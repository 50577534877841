import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  
    const { pathname } = useLocation();
    useEffect(() => {
        
        // window.scrollTo(0, 0);
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth", // Optional if you want to skip the scrolling animation
        });

        if (pathname === "/spectra" || pathname === "/spectra/features" || pathname === "/spectra/pricing" || pathname === "/voice-services" || pathname === "/voice-services/features" || pathname === "/ambient" ) {
            let header = document.getElementById("augnito-main-header");
            if (header) {
                let classList = header.classList;
                if (!classList.contains("product-sticky")) {
                    classList.add("product-sticky");
                }
            }
        }
        else {
            let header = document.getElementById("augnito-main-header");
            if (header) {
                let classList = header.classList;
                if (classList.contains("product-sticky")) {
                    classList.remove("product-sticky");
                }
            }
        }
        /*window['scrollTo']({ top: 0, behavior: 'smooth' });*/
    }, [pathname]);

    return null;
}