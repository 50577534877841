import React from 'react';
import BaseComponent from '../../Common/BaseComponent';
import AmbientBookDemoMeta from './Meta/AmbientBookDemoMeta';
import AmbientBookDemoBanner from './AmbientBookDemoBanner/AmbientBookDemoBanner';
import SecureCompliant from '../VoiceServices/Overview/SecureCompliant/SecureCompliant';
import TrustedByDemo from '../BookDemo/TrustedByDemo/TrustedByDemo';
import SecureCompliantME from '../VoiceServices/Overview/SecureCompliant/SecureCompliantME';

export default class AmbientBookDemo extends BaseComponent {
    render() {

        var regionData = this.props.userRegionData;
        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <main>
                <AmbientBookDemoMeta/>
                <AmbientBookDemoBanner/>
                <div className='mt-120 pt-1'>
                   <div className='py-60'>
                        <div className='pt-lg-5 mt-5'></div>
                    </div> 
                </div>
                <div className='aug-ambient-book-demo-secure-compliant aug-book-demo-secure-compliant mb-60'>
                    {
                        regionData === "ME" ? <SecureCompliantME/> :
                        <SecureCompliant/>
                    }
                </div>
                <TrustedByDemo userRegionData={regionData} />
            </main>
        );
    }
}