import React from 'react';

const Doctors = () => {
    return (
        <div className='row gx-40 flex-column-reverse flex-md-row'>
            <div className='offset-lg-1 offset-md-1 col-md-6 col-lg-5'>
                {/* <h3 className='aug-tab-content__heading mb-2'>Healthcare IT</h3> */}
                <h3 className='mb-4 aug-tab-content__subheading'>Ease EMR adoption</h3>
                <p className='mb-0'>Streamline clinical workflows and create Electronic Medical Records seamlessly. With Augnito users are more productive, experience less fatigue, and spend more time with patients (less on paperwork). </p>
            </div>
            <div className='offset-lg-1 col-md-5 col-lg-4'>
                <img src={require('../../../Assets/images/home/PositiveStake/IN/positive-stake-3.webp')} height="auto" width="100%" alt="Doctor discussing diagnosis with the patient" className='img-fluid mb-4 mb-md-0 pb-2 pb-md-0'/>
            </div>
        </div>
    );
};

export default Doctors;