import React from 'react';
import { NavigateToInternalPage } from '../../../Common/Utils';
import { useState } from 'react';
import { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import './BookDemo.scss';

const BookDemoIN = (props) => {
   
    const [inIndex, setInIndex] = useState();
    let location = useLocation();
    useEffect(() => {
        setInIndex(location.pathname !== "/");
    }, [location]);
   
    return (
       <section className='container-fluid'>
            <div className='row'>
                <div className='col-lg-11'>
                    <div className={` aug-book-demo ml-n40 d-lg-flex justify-content-lg-between align-items-lg-center ${inIndex ? 'aug-book-demo-bg-change' : ''}`}>
                        <div className='me-lg-4'>
                            <div className={` ${inIndex ? 'd-none' : ''}`}>
                                Augnito is trusted by 10,000+ global doctors from leading healthcare organisations
                            </div>
                            <div className={` ${inIndex ? 'd-block' : ''}`}>
                             {props.children}
                            </div>
                        </div>
                        <div className='aug-book-demo-btn'>
                            <button type='button' className='btn btn-white' onClick={NavigateToInternalPage("/schedule-demo")}>
                                Book demo for Spectra
                            </button>
                        </div>
                    </div>        
                </div>
            </div>
       </section>
    );
};

export default BookDemoIN;