import React from 'react';
import { NavigateToInternalPage } from '../../../../Common/Utils';
import './ContactSales.scss';

const ContactSales = () => {
    return (
        <section className='container-fluid mb-60'>
            <div className='row'>
                <div className='col-12 col-md-11 col-lg-10 mt-60'>
                    <div className='aug-get-started aug-get-voice-feature-get-started ml-n40'>
                        <h2 className='mb-0'>
                        Augnito Voice Services provides a cost-effective solution for healthcare providers of all specialties
                        </h2>
                        <div className='mt-40'>
                            <button type="button" className="btn btn-white" onClick={NavigateToInternalPage("/contact-sales")}>Contact sales</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactSales;