import React from 'react';

const InnovativeAccessible = () => {
    return (
        <div className='row gx-40 flex-column-reverse flex-md-row'>
            <div className='offset-lg-2 offset-md-1 col-md-6 col-lg-5'>
                <div>
                    <h3 className='mb-4 aug-tab-content__subheading'>Intensive research & development</h3>
                    <p className='mb-0'>True disruption comes from deep research and building advanced technologies. Our aim is to transform over two decades of medical domain knowledge and expertize into powerful AI products—and make them easily accessible to every healthcare professional. Therefore, our team is a remarkable mix of experienced computer scientists, tenured researchers, user-focused designers, medical transcribers, quality analysts, doctors, engineers, on-site project managers, and more.</p>
                </div>
            </div>
            <div className='col-md-5 col-lg-4'>
                <img src={require('../../../Assets/images/AboutUs/OurEthos/innovative-accessible.webp')} alt="Two business persons discussing a report on development" className='rounded-10 img-fluid mb-4 mb-md-0 pb-2 pb-md-0'/>
            </div>
        </div>
    );
};

export default InnovativeAccessible;