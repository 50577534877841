import React from 'react';
import './Navbar.scss';
import { NavLink , useLocation} from "react-router-dom";
import { useState } from 'react';
import { useEffect } from 'react';
import {ReactComponent as Logo} from '../../../Assets/images/logo.svg';
import {ReactComponent as ArrowDown} from '../../../Assets/images/icons/chevron-left.svg';
import {ReactComponent as TogglerIcon} from '../../../Assets/images/icons/bars.svg';
import { ReactComponent as TogglerIconClose } from '../../../Assets/images/icons/times.svg';
import * as Constants from '../../../Common/Constants';
import { ScrollTop } from '../../../Common/Utils';

const Navbar = () => {

    const [inIndex, setInIndex] = useState();
    let location = useLocation();
    useEffect(() => {
        setInIndex(location.pathname !== "/");
    }, [location]);

    const [isSticky, setSticky] = useState(false)
    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 50) {
                setSticky(true)
            } else {
                setSticky(false)
            }
        })
    }, [])

    function closePopupMenuInMobile(){
        if(document.getElementById('NavBtnClose') != null){
            var IsMobileMenuOpen = document.getElementById('NavBtnClose').getAttribute('aria-expanded');
            if(IsMobileMenuOpen !== null && IsMobileMenuOpen === 'true'){
                document.getElementById('NavBtnClose').click();
            }
        }
    }
   

    return(
        <nav className={`navbar navbar-expand-md navbar-light ${isSticky ? "aug-navbar-fixed" : "aug-navbar"} ${inIndex ? 'aug-navbar-fixed' : ''}`}>
            <div className="container-fluid">
                <NavLink to="/" onClick={ScrollTop} className="navbar-brand">
                    <Logo/>
                </NavLink>
                <button id='NavBtnClose' className="navbar-toggler border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <TogglerIcon height={18} width={32} className="aug-icon-open" />
                    <TogglerIconClose height={18} width={32} className="aug-icon-close" />
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                <ul className="navbar-nav ms-auto mb-2 mb-md-0">
                    <li className="nav-item dropdown">
                        <NavLink className="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">Product <ArrowDown className='mx-1'/></NavLink>
                        <ul className="dropdown-menu dropdown-menu-white">
                            <li>
                                <NavLink to="/spectra" onClick={ScrollTop} className="nav-link">Augnito Spectra</NavLink>
                            </li>
                            <li>
                                <NavLink to="/voice-services" onClick={ScrollTop} className="nav-link">Augnito Voice Services</NavLink>
                            </li>
                            <li>
                                <NavLink to="/ambient" onClick={ScrollTop} className="nav-link">Augnito Ambient</NavLink>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-item dropdown">
                        <NavLink className="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">Company <ArrowDown className='mx-1'/> </NavLink>
                        <ul className="dropdown-menu dropdown-menu-white">
                            <li>
                                <NavLink to="/about-us" onClick={ScrollTop} className="nav-link">About us</NavLink>
                            </li>
                            <li>
                                <NavLink to="/careers" onClick={ScrollTop} className="nav-link">Careers</NavLink>
                            </li>
                            <li>
                                <NavLink to="/contact-us" onClick={ScrollTop} className="nav-link">Contact us</NavLink>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-item dropdown">
                        <NavLink className="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">Resources <ArrowDown className='mx-1'/> </NavLink>
                        <ul className="dropdown-menu dropdown-menu-white">
                            <li>
                                <a href={Constants.SITE_URL + "resources/blog" } onClick={closePopupMenuInMobile} target="_blank" rel="noreferrer" className="nav-link">Blog</a>
                            </li>

                            <li>
                                <a href={Constants.SITE_URL + "resources/case-studies"} onClick={closePopupMenuInMobile} target="_blank" rel="noreferrer" className="nav-link">Case study</a>
                            </li>
                            <li>
                                <a href={Constants.SITE_URL + "resources/newsroom"} onClick={closePopupMenuInMobile} target="_blank" rel="noreferrer" className="nav-link">Newsroom</a>
                            </li>
                            <li>
                                <a href={Constants.SITE_URL + "resources/faq"} onClick={closePopupMenuInMobile} target="_blank" rel="noreferrer" className="nav-link">FAQs</a>
                            </li>
                            <li>
                                <a href={Constants.SITE_URL + "resources/docs"} onClick={closePopupMenuInMobile} target="_blank" rel="noreferrer" className="nav-link">Documentation</a>
                            </li>
                            <li>
                                <a href={Constants.SITE_URL + "resources/videos"} onClick={closePopupMenuInMobile} target="_blank" rel="noreferrer" className="nav-link">Videos</a>
                            </li>
                            <li>
                                <a href={Constants.SITE_URL + "resources/events"} onClick={closePopupMenuInMobile} target="_blank" rel="noreferrer" className="nav-link">Events</a>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/contact-sales" onClick={ScrollTop} className="nav-link">Contact sales</NavLink>
                    </li>
                    <li className="nav-item">
                        <a href={Constants.USERCONSOLE_DOMAIN} onClick={closePopupMenuInMobile} className="btn btn-outline-white">
                            Login
                        </a>
                    </li>                  
                </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;