import React from 'react';

const StreamlinedSDK = () => {
    return (
       <div className='mb-60'>
            <h3 className='mb-3'>Streamlined SDKs</h3>
            <p className='mb-0'>Easy-to-download and integrate SDKs with sample projects. Compatible with Angular, C#, JavaScript, Flutter, React & more.</p>
       </div>
    );
};

export default StreamlinedSDK;