import React from 'react';

const CreativeIntuitive = () => {
    return (
        <div className='row gx-40 flex-column-reverse flex-md-row'>
            <div className='offset-lg-2 offset-md-1 col-md-6 col-lg-5'>
                <div>
                    <h3 className='mb-4 aug-tab-content__subheading'>Human-centric design principles</h3>
                    <p className='mb-0'>Augnito products are engineered to keep the user’s preferences and experience at the forefront, at every stage. Consistent on-ground work and feedback from providers helps us address explicit and latent pain points in real-time as we upgrade and refine our service. Creative thinking is actively encouraged—we do not believe in rigid frameworks or solutions. We evaluate concepts and experiment early and often.</p>
                </div>
            </div>
            <div className='col-md-5 col-lg-4'>
                <img src={require('../../../Assets/images/AboutUs/OurEthos/creative-intuitive.webp')} alt="Group of doctors discussing while sitting around a table" className='rounded-10 img-fluid mb-4 mb-md-0 pb-2 pb-md-0'/>
            </div>
        </div>
    );
};

export default CreativeIntuitive;