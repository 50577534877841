import React from 'react';
import Img from '../../../Assets/images/home/MadeIndia/made-india.webp';
import './MadeIndia.scss';
import { RedirectToSignUp } from '../../../Common/Utils';

const MadeIndia = () => {
    return (
       <section className='aug-made-india aug-our-expertise'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='offset-lg-1 offset-md-1 col-md-11 col-lg-8'>
                        <h2 className='aug-title mb-60'>Made in India, for the world</h2>
                    </div>
                </div>
                <div className='row gx-40 flex-column-reverse flex-md-row'>
                    <div className='offset-lg-1 offset-md-1 col-12 col-md-7 col-lg-6'>
                        <h3 className='mb-4 aug-our-expertise__heading'>We support & enable an integrated digital health infrastructure</h3>
                        <p>Augnito is a proponent of the Government of India’s Ayushman Bharat Digital Mission. We have successfully enabled EMR adoption in over 300 hospitals across the country and continue to expand our operations. </p>
                        <div className='mt-40'>
                            <button type="button" className="btn btn-primary" onClick={RedirectToSignUp}>Try Spectra for free</button>
                        </div>
                    </div>
                    <div className='offset-lg-1 col-12 col-md-4 col-lg-4'>
                        <div className='aug-our-expertise__img mr-n40'>
                            <img src={Img} alt="Doctor accessing Augnito Spectra on tablet interface, with Indian national flag in the background" className="w-100 rounded-ltb-10 img-fluid"/>
                        </div>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default MadeIndia;