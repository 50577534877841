import React from 'react';
import CostReduction from '../../../../Assets/images/Ambient/overview/unrivalled-experience/Cost-Efficient-Ambient-Feature.webp';
import { NavigateToInternalPage } from '../../../../Common/Utils';

const ReductionEfficiency = () => {
    return (
        <div className='row gx-0 mt-60'>
            <div className='offset-md-1 col-12 col-md-4 col-lg-4'>
                <h3 className='aug-features-glance__heading mb-4'>Cost reduction & operational efficiency </h3>
                <p className='mb-0'>Ambient expedites the claims and reimbursement process by enabling healthcare providers to create comprehensive medical records. This technology offers a cost-effective alternative to traditional medical scribes and manual data entry tasks. It streamlines workflows while maintaining accuracy and precision. </p>
                <div className="mt-40">
                    <button onClick={NavigateToInternalPage("/schedule-demo-ambient")} className='btn btn-outline-primary'>
                        Book demo   
                    </button>
                </div>
            </div>
            <div className='offset-md-1 col-12 col-md-4'>
                <div className='mr-n40 aug-unrivalled-experience-img'>
                    <img src={CostReduction} alt="Physician writing notes on paper, while holding a digital tablet in left hand" className='w-100 img-fluid'/>
                </div>
            </div>
        </div>
    );
};

export default ReductionEfficiency;