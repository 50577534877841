import React from 'react';
import Hero from '../../../Assets/images/AboutUs/banner.webp';
import { RedirectToSignUp } from '../../../Common/Utils';

const AboutBanner = () => {
    return (
        <section className='aug-banner-features'>
            <div className='aug-banner-features-img position-relative'>
                <img src={Hero} alt="banner" className='img-fluid'/>
                <div className='aug-banner-features-content position-absolute d-flex align-items-center'>
                    <div className='container-fluid'>
                        <div className="row">
                            <div className='offset-lg-2 offset-md-1 col-md-10 col-lg-8'>
                                <h1 className='mb-3'>Transforming Healthcare with Attentive Voice AI Solutions</h1>
                                <p>Augnito enables providers to optimize their clinical workflows with state-of-the-art speech powered softwares.</p>
                                <button className='btn btn-white' onClick={RedirectToSignUp}>Try Augnito Spectra</button>
                            </div>
                        </div>
                    </div>
                </div>
           </div>
        </section>
    );
};

export default AboutBanner;