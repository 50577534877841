import React from 'react';
import SimplifiedUser from '../../../../Assets/images/Ambient/overview/unrivalled-experience/Simplified-UX-Ambient-Feature.webp';

const UserExperience = () => {
    return (
        <div className='row gx-0 mt-60 flex-column-reverse flex-md-row'>
            <div className='offset-md-1 col-12 col-md-5 pr-40'>
                <div className='aug-unrivalled-experience-img'>
                    <img src={SimplifiedUser} alt="Physician and staff discussing Ambient notes on desktop screen" className='w-100 aug-voice-features-standout-img img-fluid'/>
                </div>
            </div>
            <div className='col-12 col-md-4'>
                <h3 className='aug-features-glance__heading mb-3'>Simplified user experience </h3>
                <p className='mb-0'>Ambient works seamlessly in various healthcare environments and understands conversation in multiple regional and global languages. With a user-friendly interface, support for multiple medical specialties as well as zero voice profile training requirements—it is truly intuitive to use. Additionally, the AI seamlessly integrates with existing EHR systems.  
                </p>
            </div>
        </div>
    );
};

export default UserExperience;