import React from 'react';
import BaseComponent from '../../../Common/BaseComponent';
import DataProcessingMeta from './DataProcessingMeta';

export default class DataProcessing extends BaseComponent {
    render() {

        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <>
                <DataProcessingMeta/>
                <section className='aug-legal-page mt-60'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-12 offset-md-1 col-md-10 offset-lg-2 col-lg-8'>
                                <div className='aug-legal-page-header'>
                                    <h1 className='aug-legal-page-title mb-3'>Notice On Data Processing</h1>
                                    <p className='mb-0 text-secondary'>Last updated: 15th March, 2024</p>
                                </div>
                                <div className='mb-4'>
                                    <p className='mb-0'>
                                    This notice is issued by Augnito India Pvt Ltd (hereinafter referred to as AIPL) to inform customers and data principals about the processing of their data including personal information in accordance with applicable laws.
                                    </p>
                                </div>
                                <div className='mb-4'>
                                    <p className='mb-0'><strong>1. Purpose of Data Processing: </strong>
                                    AIPL processes customer data, subject to anonymization and applicable laws, for the following purposes:
                                    </p>
                                    <div className='mb-3'>
                                        <p className='mb-0'>(a) Providing services as per contract</p>
                                        <p className='mb-0'>(b) Training its AI to enhance our products.</p>
                                    </div>
                                    <p className='mb-0'>It is important to note that AIPL processes personal information of its customers without anonymization as the same is necessary for maintaining customer accounts and providing services, in compliance with applicable laws.</p>
                                </div>
                                <div className='mb-4'>
                                    <p className='mb-0'><strong>2. Rights of Customers and Data Principals: </strong>
                                    AIPL acknowledges and ensures the rights of its customers and data principals. Should you wish to modify or delete your data or raise any relevant grievances pertaining to personal data, you can contact us at <a rel="noreferrer" target="_blank" href="mailto: legal@augnito.ai"><u>legal@augnito.ai</u></a> .We are committed to addressing your concerns promptly and in accordance with the applicable law.
                                    </p>
                                </div>
                                <div className='mb-4'>
                                    <p className='mb-0'><strong>Contact Information: </strong>
                                    For any inquiries or requests related to data processing, please reach out to us at:
                                    </p>
                                    <div className='mt-1'>
                                        <strong>Email: </strong>
                                        <a rel="noreferrer" target="_blank" href="mailto:legal@augnitoai"> <u>legal@augnito.ai</u> </a> or write to our DPO Aakanksha Yadav at <a rel="noreferrer" target="_blank" href="mailto: aakanksha.yadav@augnito.ai"><u>aakanksha.yadav@augnito.ai</u></a>
                                    </div>
                                </div>
                                <div className='mb-4'>
                                    <p className='mb-0'>Your privacy is important to us, and we are dedicated to maintaining the confidentiality and security of your personal information.
                                    </p>
                                </div>
                                <div className='mb-4'>
                                    <p className='mb-0'>Thank you for your trust in AIPL.
                                    </p>
                                </div>



                                






                                
                               
                               
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}