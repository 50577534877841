import React from 'react';
import ImgStarted from '../../../../Assets/images/VoiceServices/features/get-started.webp';
import { GetTrialDaysByProduct, RedirectToSignUpVoiceServices } from '../../../../Common/Utils';
import * as Constants from '../../../../Common/Constants';


const GetStarted = () => {
    var trailDays = GetTrialDaysByProduct(Constants.VOICE_LISTING_NAME);
    return (
       <section className='aug-features-days position-relative'>
            <div className='aug-features-days__img h-100'>
                <img src={ImgStarted} alt="banner footer" className="h-100 img-fluid"/>
            </div>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='offset-lg-2 offset-md-1 col-12 col-md-10 col-lg-6'>
                        <div className='aug-features-days-box position-absolute'>
                            <h2 className='mb-3'>Get unlimited access <br/> to Voice Services for {trailDays} days</h2>
                            <p>Discover first-hand how Voice Services is engineered to save time & increase productivity.</p>
                            <button type='button' className='btn btn-primary' onClick={RedirectToSignUpVoiceServices}>Get started</button>
                        </div>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default GetStarted;