import React from 'react';

const PositiveStakeTabIN = () => {
    return (
        <ul className="aug-nav-tab nav nav-pills" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
                <button className="nav-link px-0 active" id="Healthcare-IT-tab" data-bs-toggle="pill" data-bs-target="#Healthcare-IT" type="button" role="tab" aria-controls="Healthcare-IT" aria-selected="true">Healthcare IT</button>
            </li>
            <li className="nav-item" role="presentation">
                <button className="nav-link px-0" id="operation-administrator-tab" data-bs-toggle="pill" data-bs-target="#operation-administrator" type="button" role="tab" aria-controls="operation-administrator" aria-selected="false">Operatives & Administrators</button>
            </li>
            <li className="nav-item" role="presentation">
                <button className="nav-link px-0" id="doctors-tab" data-bs-toggle="pill" data-bs-target="#doctors" type="button" role="tab" aria-controls="doctors" aria-selected="false">Doctors</button>
            </li>
            <li className="nav-item" role="presentation">
                <button className="nav-link px-0" id="patient-tab" data-bs-toggle="pill" data-bs-target="#patient" type="button" role="tab" aria-controls="patient" aria-selected="false">Patients</button>
            </li>
            <li className="nav-item" role="presentation">
                <button className="nav-link px-0" id="financiers-tab" data-bs-toggle="pill" data-bs-target="#financiers" type="button" role="tab" aria-controls="financiers" aria-selected="false">Financiers</button>
            </li>
            
        </ul>
    );
};

export default PositiveStakeTabIN;