import React from 'react';
import './Footer.scss';
import FooterLogo from './FooterLogo';
import FooterSPR from './FooterSPR';
import FooterInfo from './FooterInfo';

import BaseComponent from '../../../Common/BaseComponent'

export function AddLibrary(url) {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    script.defer = true;
    script.setAttribute("data-cookieyes", "cookieyes-analytics")
    document.body.appendChild(script);
}

export default class Footer extends BaseComponent {

    render() {

        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <footer className='aug-footer'>
                {AddLibrary("//js.hs-scripts.com/9455047.js")}
                <div className='container-fluid'>
                    <FooterLogo />
                    <FooterInfo />
                    <FooterSPR />
                </div>
            </footer>
        );
    }
}