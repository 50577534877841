import React from 'react';
import './GetStarted.scss';
import { RedirectToSignUp } from '../../../Common/Utils';

const GetStarted = () => {
    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-12 col-lg-11'>
                    <section className='aug-get-started aug-get-started--home-page  ml-n40'>
                        <h2 className='mb-3'>
                            Zero infrastructure investment.<br/> 
                            Zero integrations.<br/> 
                            Zero training.
                        </h2>
                        <p>Designed for simplicity and ease of use, Augnito products deliver tangible productivity gains from day one.</p>
                        <button type="button" className="btn btn-white" onClick={RedirectToSignUp}>Get started with Spectra</button>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default GetStarted;