import React from 'react';
import Img from '../../../Assets/images/home/spectra-tool-ME.webp'
import {ReactComponent as Microphone} from '../../../Assets/images/icons/microphone.svg';
import {ReactComponent as Chart} from '../../../Assets/images/icons/chart-line.svg';
import './SpectraTool.scss';

const SpectraToolME = () => {
    return (
       <section className='aug-spectra-tool aug-home-spectra-tool'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12 col-lg-10'>
                        <div className='aug-spectra-tool__img ml-n40'>
                            <img src={Img} height="auto" width="100%" alt="Physician using Augnito app on desktop" className="img-fluid rounded-rtb-10"/>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='offset-lg-1 offset-md-1 col-md-11 col-lg-8'>
                        <h2 className='aug-spectra-title'>Spectra is a physician's AI assistant</h2>
                    </div>
                    <div className='offset-lg-1 offset-md-1 col-md-11 col-lg-8'>
                        <div className='row gx-40'>
                            <div className='col-12 col-md-6'>
                                <div>
                                    <div className='aug-icon-cricle d-flex align-items-center  justify-content-center'>
                                        <Microphone/>
                                    </div>
                                    <h3 className='aug-spectra-heading mb-4'>Designed for seamless compatibility</h3>
                                    <ul className='list-unstyled mb-0 list-disc'>
                                        <li className='mb-1'>
                                            Direct dictation into any HIS/EMR/PAC/RIS and other clinical systems
                                        </li>
                                        <li className='mb-1'>
                                            Compatible with Windows and macOS
                                        </li>
                                        <li className='mb-1'>
                                            Roaming profile with user’s settings synced and available on any device
                                        </li>
                                        <li>
                                            Citrix & remote/virtual desktop ready
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className='mt-60 mt-md-0'>
                                    <div className='aug-icon-cricle d-flex align-items-center  justify-content-center'>
                                        <Chart/>
                                    </div>
                                    <h3 className='mb-4 aug-spectra-heading'>Designed to double productivity</h3>
                                    <ul className='list-unstyled mb-0 list-disc'>
                                        <li className='mb-1'>
                                            Unlimited templates and macros
                                        </li>
                                        <li className='mb-1'>
                                            Personalised vocabulary and formatting preferences
                                        </li>
                                        <li>
                                            Focused text anchoring to dictate while scrolling multiple windows
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default SpectraToolME;