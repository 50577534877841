import React from 'react';

const SwitchPlan = () => {
    return (
        <div className="accordion-item">
            <h3 className="accordion-header" id="switchPlan">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSwitchPlan" aria-expanded="false" aria-controls="collapseSwitchPlan">
                How do I switch to a different plan?
                </button>
            </h3>
            <div id="collapseSwitchPlan" className="accordion-collapse collapse" aria-labelledby="switchPlan" data-bs-parent="#faq">
                <div className="accordion-body">
                    Switch to a new plan effortlessly by contacting one of our sales executives.
                </div>
            </div>
        </div>
    );
};

export default SwitchPlan;