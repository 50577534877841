import React from 'react';
import {ReactComponent as Key} from '../../../../Assets/images/icons/key.svg';

const DynamicPaymentIN = () => {
    return (
        <div className='mt-60'>
            <div className='aug-speech-models__icon d-flex align-items-center justify-content-center'>
                <Key/>
            </div>
            <h3 className='mb-3 aug-speech-models__heading'>Dynamic payment plans</h3>
            <p className='mb-0'>Customise a plan that best suits your enterprise’s size and requirements, from our dynamic range of subscription models. </p>
        </div>
    );
};

export default DynamicPaymentIN;