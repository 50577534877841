import React from 'react';
import Slider from "react-slick";
import ArrowRight from '../../../Assets/images/icons/arrow-right.svg';
import ArrowLeft from '../../../Assets/images/icons/arrow-left.svg';
import './CustomerSay.scss';

function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        style={{ 
            backgroundImage: `url(${ArrowRight})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
         }}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        style={{
            backgroundImage: `url(${ArrowLeft})`, 
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        }}
        onClick={onClick}
      />
    );
  }

const CustomerSayME = () => {
    const settings = {
        dots: true,
        autoplay:true,
        infinite: true,
        slidesToShow: 3,
        pauseOnHover: true,
        slidesToScroll: 1,
        arrows:true,
        className:'aug-customer-slider',
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 2,
                speed: 1000,
              }
            },
            {
              breakpoint: 575,
              settings: {
                slidesToShow: 1
              }
            }
        ]
    };
    return (
       <section className='aug-customer-say'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='offset-lg-1 offset-md-1 col-md-11 col-lg-6'>
                        <h2 className='aug-title mb-60'>What customers say</h2>
                    </div>
                </div>
                <div className='row'>
                    <div className='offset-lg-1 offset-md-1 col-12 col-md-11 col-lg-9'>
                        <Slider {...settings}>

                            <div className='aug-customer-items'>
                                <img src={require('../../../Assets/images/HeadShot/ME/Ahmed-El-Naji.webp')} alt="Ahmed El-Naji, CEO, ATTIEH Medico" height={96} width={96} className='img-fluid rounded-circle' />
                                <div className='mb-4 mt-3'>
                                    <h3 className='aug-customer-items__name mb-0'>Ahmed El-Naji</h3>
                                    <h4 className='aug-customer-items__designation mb-0'>CEO, ATTIEH Medico</h4>
                                </div>
                                <sub className='aug-customer-items__quote d-flex'>“</sub>
                                <div className='aug-customer-items__text'>
                                "Augnito offers excellent service and quality products that are loved by the medical professionals that use them. They have a proven track record to reduce clinical documentation timelines, help mitigate physician burnout, increase revenue for healthcare organizations, reduce insurance claim rejections, and improve overall patient outcomes." 
                                </div>
                            </div>

                            <div className='aug-customer-items'>
                                <img src={require('../../../Assets/images/HeadShot/ME/Jamil-Ahmed.webp')} alt="Dr Jamil Ahmed" height={96} width={96} className='img-fluid rounded-circle' />
                                <div className='mb-4 mt-3'>
                                    <h3 className='aug-customer-items__name mb-0'>Dr Jamil Ahmed</h3>
                                    <h4 className='aug-customer-items__designation mb-0'>Founder and Managing Director, PRIME Healthcare Group</h4>
                                </div>
                                <sub className='aug-customer-items__quote d-flex'>“</sub>
                                <div className='aug-customer-items__text'>
                                    It is a great honour to have Augnito onboard as our Voice AI partner as we continue to work towards delivering world-class services which positively contributes to the UAE’s vision to become a leading nation in healthcare practices. After reviewing several products, we found the quality of Augnito to be exceptional, plus the engagement experience was smooth for us during the proof of concept stage which strengthened our belief in the product and the ability for it to deliver on all counts post onboarding.
                                </div>
                            </div>

                            <div className='aug-customer-items'>
                                <img src={require('../../../Assets/images/HeadShot/ME/Murali-Mohan.webp')} alt="Dr Murali Mohan" height={96} width={96} className='img-fluid rounded-circle' />
                                <div className='mb-4 mt-3'>
                                    <h3 className='aug-customer-items__name mb-0'> Dr Murali Mohan, MD</h3>
                                    <h4 className='aug-customer-items__designation mb-0'>Pulmonology and Internal Medicine</h4>
                                </div>
                                <sub className='aug-customer-items__quote d-flex'>“</sub>
                                <div className='aug-customer-items__text'>
                                    Augnito has really saved me a lot of time, especially saved me tiresome key-ing in of all the words. It understands medical language very well and adapts to all kinds of accents. I would strongly recommend everyone to use it as it is a real boon to the practicing doctor
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default CustomerSayME;