import React from 'react';
import FooterImg from '../../../../Assets/images/Ambient/overview/Footer-Image-Ambient-Page.webp';
import './TryAmbient.scss';
import { NavigateToInternalPage } from '../../../../Common/Utils';


const TryAmbient = () => {
   
    return (
       <section className='aug-try-ambient aug-features-days position-relative'>
            <div className='aug-features-days__img h-100'>
                <img src={FooterImg} alt="banner footer" className="h-100 img-fluid"/>
            </div>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='offset-lg-1 offset-md-1 col-12 col-md-10 col-lg-6'>
                        <div className='aug-features-days-box position-absolute'>
                            <h2 className='mb-3'>Try Ambient for Free <br/> Get Your Trial Today</h2>
                            <p>No Credit Card required. <br/> No implementation and training fees.</p>
                            <button type='button' className='btn btn-primary' onClick={NavigateToInternalPage("/schedule-demo-ambient")}>Book demo</button>
                        </div>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default TryAmbient;