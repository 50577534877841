import React from 'react';
import Hero from '../../../../Assets/images/VoiceServices/overview/banner.webp';
import { NavLink } from 'react-router-dom';
import { RedirectToSignUpVoiceServices } from '../../../../Common/Utils';
import './OverviewBanner.scss'

const OverviewBanner = () => {
    return (
        <section className='aug-banner-features aug-voice-overview--banner'>
            <div className='aug-banner-features-img position-relative'>
                <img src={Hero} alt="banner" className='img-fluid'/>
                <div className='aug-banner-features-content position-absolute d-flex align-items-center'>
                    <div className='container-fluid'>
                        <div className="row">
                            <div className='offset-lg-2 offset-md-1 col-md-10 col-lg-8'>
                                <h1 className='mb-3'>Enhance clinical productivity with easy-to-integrate APIs & SDKs.</h1>
                                <p>Discover AI-driven Speech Recognition & Natural Language Processing that requires minimal coding to integrate.</p>
                                <button type='button' className='btn btn-white' onClick={RedirectToSignUpVoiceServices}>Try for free</button>
                                <NavLink to='/contact-sales' className='btn btn-outline-white'>Contact sales</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
           </div>
        </section>
    );
};

export default OverviewBanner;