import React from 'react';

const SubscriptionPlanPopular = () => {
    return (
       <div className='aug-subscription-plan-popular text-center'>
           Most popular
       </div>
    );
};

export default SubscriptionPlanPopular;