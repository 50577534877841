import React from 'react';
import Img from '../../../Assets/images/Careers/careerflexibility.webp';

const CareerFlexibilityRight = () => {
    return (
        <div className='mr-n40'>
            <img src={Img} alt="A clean table with a desktop, laptop, and headphones on it, encouraging the work from home culture." className="img-fluid w-100 rounded-ltb-10"/>
        </div>
    );
};

export default CareerFlexibilityRight;