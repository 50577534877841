import React from 'react';

const SpecializedFormatting = () => {
    return (
        <div className='row gx-40 flex-column-reverse flex-md-row'>
            <div className='offset-lg-2 offset-md-1 col-md-6 col-lg-5'>
                <div>
                    <h3 className='mb-4 aug-tab-content__subheading'>Configurable typing preferences</h3>
                    <p className='mb-0'>You can set your own text formatting preferences as per your documentation style, including the capitalization of words, numeric representations and more.</p>
                </div>
            </div>
            <div className='col-md-5 col-lg-4 pb-2 pb-md-0'>
                <img src={require('../../../../Assets/images/features/HyperPersonalization/specialized-formatting.webp')} alt="Augnito Spectra Medical Speech Recognition Software : Formatting Rule Addition Window" className='img-fluid mb-4 mb-md-0'/>
            </div>
        </div>
    );
};

export default SpecializedFormatting;