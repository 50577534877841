import React from 'react';
import BookDemo from './BookDemo';
import ContactSales from './ContactSales';
import GeneralQueries from './GeneralQueries';
import './HelpYou.scss';
import ContactSalesME from './ContactSalesME';
import BaseComponent from '../../../Common/BaseComponent';
import GeneralQueriesME from './GeneralQueriesME';
import BookDemoIN from './BookDemoIN';
import ContactSalesIN from './ContactSalesIN';
import GeneralQueriesIN from './GeneralQueriesIN';
import BookDemoME from './BookDemoME';

export default class HelpYou extends BaseComponent {

    render() {
        // const { regionData } = this.state;
        var regionData = this.props.userRegionData;
        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <section className='aug-help-you aug-our-customer position-relative mb-60'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='offset-md-1 offset-lg-2 col-12 col-md-6 col-lg-4'>
                            <h2 className='aug-title mb-0'>How can we help you?</h2>
                        </div>
                    </div>
                    <div className='row mt-60'>
                        <div className='offset-lg-2 offset-md-1 col-12 col-md-10 col-lg-10'>
                            <div className='row'>
                                <div className='col-12 col-md-12 col-lg-4'>
                                    {
                                        regionData === "ME" ? <BookDemoME/> :
                                        regionData === "IN" ? <BookDemoIN/> :
                                        <BookDemo/>
                                    }
                                    
                                </div>
                                <div className='col-12 col-md-12 col-lg-4'>
                                    {
                                        regionData === "IN" ? <ContactSalesIN/> :
                                        regionData === "ME" ? <ContactSalesME/> :
                                        <ContactSales/>
                                    }
                                </div>
                                <div className='col-12 col-md-12 col-lg-4'>
                                    {
                                        regionData === "IN" ? <GeneralQueriesIN/> :
                                        regionData === "ME" ? <GeneralQueriesME/> :
                                        <GeneralQueries/> 
                                    }                                                           
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='aug-help-you-footer'></div>
            </section>
        );
    };
};