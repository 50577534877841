import React from 'react';
import {ReactComponent as Plus} from '../../../../Assets/images/icons/plus.svg';

const DriveEMRME = () => {
    return (
        <div className='mt-60'>
            <div className='aug-choose-us__icon d-flex align-items-center justify-content-center'>
                <Plus/>
            </div>
            <h3 className='mb-3 aug-choose-us__heading'>Drive EMR adoption</h3>
            <p>Simplify the navigation of and input of data by physicians into complex clinical systems, enabling them to create quality and source and reduce time spent on creating medical records. Get access to more detailed data with less effort.</p>
        </div>
    );
};

export default DriveEMRME;