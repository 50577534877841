import React from 'react';

const SpectraTrustedByME = () => {
    return (
        <ul className='list-unstyled mb-0 d-flex flex-wrap'>
            <li>
                <img src={require('../../../../Assets/images/PartnersLogo/ME/millennium-hospital.webp')} alt="millennium hospital logo" className="img-fluid p-10"/>
            </li>
            <li>
                <img src={require('../../../../Assets/images/PartnersLogo/ME/prime-hospital.webp')} alt="prime hospital logo" className="img-fluid p-10"/>
            </li>
            <li>
                <img src={require('../../../../Assets/images/PartnersLogo/ME/international-modern-hospital.webp')} alt="international modern hospital logo" className="img-fluid p-10"/>
            </li>            
            <li>
                <img src={require('../../../../Assets/images/PartnersLogo/ME/aster.webp')} alt="aster logo" className="img-fluid p-10"/>
            </li>
            <li>
                <img src={require('../../../../Assets/images/PartnersLogo/ME/nhs.webp')} alt="nhs logo" className="img-fluid p-10"/>
            </li>
            <li>
                <img src={require('../../../../Assets/images/PartnersLogo/ME/kims-hospital.webp')} alt="kims hospital logo" className="img-fluid p-10"/>
            </li>
            <li>
                <img src={require('../../../../Assets/images/PartnersLogo/ME/al-futtaim.webp')} alt="al-futtaim logo" className="img-fluid p-10"/>
            </li>
            <li>
                <img src={require('../../../../Assets/images/PartnersLogo/ME/ahalia-hospital.webp')} alt="ahalia hospital logo" className="img-fluid p-10"/>
            </li>
            <li>
                <img src={require('../../../../Assets/images/PartnersLogo/ME/apollo.webp')} alt="apollo logo" className="img-fluid p-10"/>
            </li>
            <li>
                <img src={require('../../../../Assets/images/PartnersLogo/ME/bahrain-specialist-hospital.webp')} alt="bahrain specialist hospital logo" className="img-fluid p-10"/>
            </li>
        </ul>           
    );
};

export default SpectraTrustedByME;