import React from 'react';
import AutoDebits from './AutoDebits';
import DynamicPayment from './DynamicPayment';
import FeatureUpgrades from './FeatureUpgrades';
import TestOut from './TestOut';
import BaseComponent from '../../../../Common/BaseComponent';
import EliminateCardsME from './EliminateCardsME';
import DynamicPaymentME from './DynamicPaymentME';
import FeatureUpgradesME from './FeatureUpgradesME';
import EliminateCardsIN from './EliminateCardsIN';
import DynamicPaymentIN from './DynamicPaymentIN';
import FeatureUpgradesIN from './FeatureUpgradesIN';

export default class TailorMade extends BaseComponent {

    render() {
        // const { regionData } = this.state;
        var regionData = this.props.userRegionData;
        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <section className='aug-superior-service aug-speech-models mt-60'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='offset-md-1 offset-lg-2 col-md-11 col-lg-10'>
                            <h2 className="aug-title">Tailor-made subscriptions</h2>
                        </div>
                        <div className="offset-lg-2 offset-md-1 col-md-11 col-lg-9">
                            <div className="row gx-40">
                                <div className="col-12 col-md-6">
                                    <TestOut/>
                                </div>
                                <div className="col-12 col-md-6">
                                    {
                                        regionData === "IN" ? <EliminateCardsIN/> :
                                        regionData === "ME" ? <EliminateCardsME/> :
                                        <FeatureUpgrades/>
                                    }
                                </div>
                                <div className="col-12 col-md-6">
                                    {
                                        regionData === "IN" ? <DynamicPaymentIN/> :
                                        regionData === "ME" ? <DynamicPaymentME/> :
                                        <DynamicPayment/>
                                    }
                                </div>
                                <div className="col-12 col-md-6">
                                    {
                                        regionData === "IN" ? <FeatureUpgradesIN/> :
                                        regionData === "ME" ? <FeatureUpgradesME/> :
                                        <AutoDebits/>
                                    }
                                </div>
                            </div>                
                        </div>
                    </div>
                </div>
            </section>
        );
    };
};