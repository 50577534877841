import React from 'react';
import {NavLink} from "react-router-dom";
import Img from '../../../../Assets/images/Spectra/FeaturesGlance/IN/voice-command.webp';
import { RedirectToSignUp, NavigateToInternalPage } from '../../../../Common/Utils';

const VoiceCommandsIN = () => {
    return (
        <div className='row gx-0 mt-60 align-items-lg-center'>
            <div className='offset-md-1 offset-lg-2 col-12 col-md-6 col-lg-4 pr-40'>
                <h3 className='aug-features-glance__heading mb-4'>Intuitive voice commands</h3>
                <p className='mb-0'>
                    Transitioning from your old speech recognition software or using voice commands for the first time, Spectra covers all popular variants to minimise your learning curve. 
                </p>
                <div className="mt-40">
                    <NavLink className='btn btn-primary me-3' onClick={RedirectToSignUp}>
                        Try for free   
                    </NavLink>
                    <button onClick={NavigateToInternalPage("/spectra/features")} className='btn btn-outline-primary'>
                        View all features   
                    </button>
                </div>
            </div>
            <div className='col-12 col-md-5 col-lg-6'>
                <div className='mr-n40 aug-features-glance__img'>
                    <img src={Img} alt="Augnito Spectra Medical Speech Recognition Software: Command help window" className='w-100 img-fluid'/>
                </div>
            </div>
        </div>
    );
};

export default VoiceCommandsIN;