import React from 'react';
import Img from '../../../Assets/images/home/makesUnique/ME/customer-support.webp';

const  CustomerSupportME= () => {
    return (
        <div className='row gx-0 mt-60 align-items-lg-center flex-column-reverse flex-md-row'>
            <div className='col-12 col-md-5 col-lg-6'>
                <div className='ml-n40'>
                    <img src={Img} height="auto" width="100%" alt="Physician talking to a customer service executive" className='w-100 rounded-rtb-10 img-fluid'/>
                </div>
            </div>
            <div className='col-12 col-md-6 col-lg-4 pl-40'>
                <h3 className='aug-makes-unique-heading mb-4'>Exceptional customer support & service</h3>
                <ul className='list-unstyled mb-0 list-disc'>
                    <li className='mb-2'>Dedicated Customer Success Managers</li>
                    <li className='mb-2'>24/7 technical support via email, phone and chat</li>
                    <li>On demand free of cost 1:1 training sessions and product demos</li>
                </ul>
            </div>
        </div>
    );
};

export default CustomerSupportME;