import React from 'react';

const CustomerText = () => {
    return (
       <div className='aug-customer-text'>
           <h3>Dr. Murali Mohan</h3>
           <p>MD, Pulmonology and Internal Medicine</p>
           <p className='mb-0'>
            <span className='aug-quote'>“</span>
            <span>Augnito has really saved me a lot of time, especially saved me tiresome key-ing in of all the words. It understands medical language very well and adapts to all kinds of accents. I would strongly recommend everyone to use it as it is a real boon to the practicing physician.</span>
            </p>
       </div>
    );
};

export default CustomerText;