import React from 'react';
import Hero from '../../../../Assets/images/features/banner/banner.webp';
import './BannerFeatures.scss';
import { RedirectToSignUp } from '../../../../Common/Utils';

const BannerFeaturesIN = () => {
    return (
        <section className='aug-banner-features'>
            <div className='aug-banner-features-img position-relative'>
                <img src={Hero} alt="banner" className='img-fluid'/>
                <div className='aug-banner-features-content position-absolute d-flex align-items-center'>
                    <div className='container-fluid'>
                        <div className="row">
                            <div className='offset-lg-2 offset-md-1 col-md-10 col-lg-8'>
                                <h1 className='mb-3'>Efficient documentation with Clinical Speech Recognition AI </h1>
                                <p>Augnito Spectra empowers doctors with a host of tools to speed up their workflows. Discover Macros, Templates, Custom Vocabulary & more. </p>
                                <button type='button' className='btn btn-white' onClick={RedirectToSignUp}>
                                Get started for free
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
           </div>
        </section>
    );
};

export default BannerFeaturesIN;