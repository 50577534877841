import React from 'react';
import Img from '../../../Assets/images/home/makesUnique/IN/speech-recognition.webp';

const SpeechRecognitionIN = () => {
    return (
        <div className='row gx-0 mt-60 align-items-lg-center'>
            <div className='offset-md-1 offset-lg-1 col-12 col-md-6 col-lg-4 pr-40'>
                <h3 className='aug-makes-unique-heading mb-4'>Highly accurate speech recognition  </h3>
                <ul className='list-unstyled mb-0 list-disc'>
                    <li className='mb-2'>99% out-of-the-box accuracy for all Indian accents  </li>
                    <li className='mb-2'>No voice profile training required  </li>
                    <li className='mb-2'>Robust medical vocabularies for 50+ specialties </li>
                    <li>Regularly updated with newly approved drugs and procedures </li>
                </ul>
            </div>
            <div className='offset-lg-1 col-12 col-md-5 col-lg-6'>
                <div className='mr-n40'>
                    <img src={Img} height="auto" width="100%" alt="Physician working on her desktop using speech recognition" className='w-100 rounded-ltb-10 img-fluid'/>
                </div>
            </div>
        </div>
    );
};

export default SpeechRecognitionIN;