import React from 'react';
import './VoiceTryNow.scss';
import { RedirectToSignUpVoiceServices } from '../../../../Common/Utils';

const VoiceTryNowIN = () => {
    return (
        <section className='aug-voive-trynow container-fluid mb-60'>
            <div className='row'>
                <div className='col-12 col-md-11 col-lg-10'>
                    <div className='aug-get-started ml-n40'>
                        <h2 className='mb-0'>
                            Voice-power your clinical software in less than 48 hours!
                        </h2>
                        <div className='mt-40'>
                            <button type="button" className="btn btn-white" onClick={RedirectToSignUpVoiceServices}>Try Voice Services Now</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default VoiceTryNowIN;