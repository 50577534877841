import React from 'react';
import Hero from '../../../../Assets/images/Spectra/Banner/banner.webp';
import './SpectraBanner.scss';
import { RedirectToSignUp } from '../../../../Common/Utils';
import { NavigateToInternalPage } from '../../../../Common/Utils';

const SpectraBanner = () => {
    return (
        <section className='aug-spectra-banner aug-banner'>
            <div className='aug-banner-img position-relative'>
                <img src={Hero} alt="spectra banner" className='img-fluid' />
                <div className='aug-banner-content aug-spectra-banner-content position-absolute d-flex align-items-center'>
                    <div className='container-fluid'>
                        <div className="row">
                            <div className='offset-lg-2 offset-md-1 col-md-10 col-lg-8'>
                                <h1 className='mb-3'>Medical Voice to Text Software designed for doctors</h1>
                                <p>A fast and easy speech-to-text solution that captures patient notes in seconds, increases productivity and improves patient care.</p>
                                <button type='button' className='btn btn-white' onClick={RedirectToSignUp}>
                                    Get started for free
                                </button>
                                <button type='button' className='btn btn-outline-white' onClick={NavigateToInternalPage("/schedule-demo")}>
                                    Request demo
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SpectraBanner;