import React from 'react';
import Img from '../../../Assets/images/home/makesUnique/always-available.webp';

const  AlwaysAvailable= () => {
    return (
        <div className='row gx-0 mt-60 align-items-lg-center flex-column-reverse flex-md-row'>
            <div className='col-12 col-md-5 col-lg-6'>
                <div className='ml-n40'>
                    <img src={Img} height="auto" width="100%" alt="Abstract art showing security" className='w-100 rounded-rtb-10 img-fluid'/>
                </div>
            </div>
            <div className='col-12 col-md-6 col-lg-4 pl-40'>
                <h3 className='aug-makes-unique-heading mb-4'>Secure & always available</h3>
                <ul className='list-unstyled mb-0 list-disc'>
                    <li className='mb-2'>Secure cloud-based and on-premise hosting</li>
                    <li className='mb-2'>99.9% guaranteed uptime</li>
                    <li>HIPAA, GDPR, SOC 2, ISO 27001 compliant</li>
                </ul>
            </div>
        </div>
    );
};

export default AlwaysAvailable;