import React from 'react';
import ImgMobile from '../../../../Assets/images/VoiceServices/overview/standout-features/free-mobile.webp';

const FreeMobile = () => {
    return (
        <div className='row gx-0 mt-60 align-items-lg-center'>
            <div className='offset-md-1 offset-lg-2 col-12 col-md-6 col-lg-4 pr-40'>
                <h3 className='aug-features-glance__heading mb-3'>Free mobile mic app & SDK</h3>
                <p className='mb-0'>
                A free-to-download mobile mic app (iOS & Android) and SDK for seamless dictation. No additional hardware required.  
                </p>
            </div>
            <div className='col-12 col-md-5 col-lg-6'>
                <div className='mr-n40'>
                    <img src={ImgMobile} alt="Mobile mic app open on Augnito Medical Voice Recognition platform" className='w-100 aug-voice-features-standout-img rounded-ltb-10 img-fluid'/>
                </div>
            </div>
        </div>
    );
};

export default FreeMobile;