import React from 'react';

const DoctorSatisfactionIN = () => {
    return (
        <div className='mt-60'>
            <h3 className='mb-3 aug-choose-us__heading'>Increase doctor satisfaction</h3>
            <p className='mb-0'>Replace costly, inconvenient transcription services with an ‘AI Typist’ that offers doctors real time quality with faster turnaround time. The improvement in data capture also aids financial gain via the hospital/clinic and related facilities such as pharmacies and laboratories. </p>
        </div>
    );
};

export default DoctorSatisfactionIN;