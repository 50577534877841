import { Helmet } from "react-helmet";
import * as Constants from '../../../../Common/Constants';

const AmbientMeta = () => {

    return (
        <Helmet>
            <title>Augnito Ambient: Fluent medical documentation using natural conversations</title>
            <link rel="shortcut icon" href={Constants.SITE_URL + "/favicon.ico"} />
            <link rel="icon" href={Constants.SITE_URL + "/favicon.ico"} />
            <link rel="profile" href="https://gmpg.org/xfn/11" />
            <meta name="description" content="Augnito Ambient: Medical Documentation with Real-Time Conversations. Reduce Burnout, Boost Efficiency and Accuracy with NLP." />
            <link rel="canonical" href={Constants.SITE_URL + "ambient"} />
            <meta property="og:url" content={Constants.SITE_URL + "ambient"} />
            <meta property="og:title" content="Augnito Ambient: Fluent medical documentation using natural conversations " />
            <meta property="og:description" content="Augnito Ambient: Medical Documentation with Real-Time Conversations. Reduce Burnout, Boost Efficiency and Accuracy with NLP." />
            <meta property="og:image" content={Constants.SITE_URL + "/logo192.png"} />
            <meta property="og:type" content="website" /> 
            <meta property="og:locale" content="en_US" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:url" content={Constants.SITE_URL + "ambient"}/>
            <meta name="twitter:site" content="@augnito" />
            <meta name="twitter:title" content="Augnito Ambient: Fluent medical documentation using natural conversations" />
            <meta name="twitter:description" content="Augnito Ambient: Medical Documentation with Real-Time Conversations. Reduce Burnout, Boost Efficiency and Accuracy with NLP." />
            <meta name="twitter:creator" content="@augnito" />
            <meta name="twitter:image" content={Constants.SITE_URL + "/logo192.png"} />
            <meta name="copyright" content="Augnito" />
            <meta name="owner" content="Augnito" />
            <link rel="alternate" href={Constants.SITE_URL} hreflang="en-us" />
            <meta name="google-site-verification" content="TivMeqLjfaI6TIRsSF0Kqw0J8UnBPK2PToomuQRwVCs" />
        </Helmet>
    );
};

export default AmbientMeta;
