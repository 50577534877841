import React from 'react';
import Slider from "react-slick";
import ArrowRight from '../../../Assets/images/icons/arrow-right.svg';
import ArrowLeft from '../../../Assets/images/icons/arrow-left.svg';
import './CustomerSay.scss';

function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        style={{ 
            backgroundImage: `url(${ArrowRight})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
         }}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        style={{
            backgroundImage: `url(${ArrowLeft})`, 
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        }}
        onClick={onClick}
      />
    );
  }

const CustomerSayIN = () => {
    const settings = {
        dots: true,
        autoplay:true,
        infinite: true,
        slidesToShow: 3,
        pauseOnHover: true,
        slidesToScroll: 1,
        arrows:true,
        className:'aug-customer-slider',
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 2,
                speed: 1000,
              }
            },
            {
              breakpoint: 575,
              settings: {
                slidesToShow: 1
              }
            }
        ]
    };
    return (
       <section className='aug-customer-say'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='offset-lg-1 offset-md-1 col-md-11 col-lg-6'>
                        <h2 className='aug-title mb-60'>What customers say</h2>
                    </div>
                </div>
                <div className='row'>
                    <div className='offset-lg-1 offset-md-1 col-12 col-md-11 col-lg-9'>
                        <Slider {...settings}>

                            <div className='aug-customer-items'>
                                <img src={require('../../../Assets/images/HeadShot/IN/Uday-Phadke.webp')} alt="Dr Uday Phadke" height={96} width={96} className='img-fluid rounded-circle' />
                                <div className='mb-4 mt-3'>
                                    <h3 className='aug-customer-items__name mb-0'>Dr Uday Phadke</h3>
                                    <h4 className='aug-customer-items__designation mb-0'>Director, Endocrine & Diabetes Services, Sahyadri Group of Hospitals </h4>
                                </div>
                                <sub className='aug-customer-items__quote d-flex'>“</sub>
                                <div className='aug-customer-items__text'>
                                A detailed record of a patient’s medical history, investigations, and progress while on treatment is extremely important in planning and monitoring a patient’s condition. Our busy schedules however preclude us from spending too much time typing these details into the EMR. With Augnito I can dictate into my EMR, and I am happy that even the most complex medical terms are picked up with almost 100% accuracy. I would undoubtedly recommend Augnito to all doctors looking to digitise their medical records.
                                </div>
                            </div>

                            <div className='aug-customer-items'>
                                <img src={require('../../../Assets/images/HeadShot/IN/Anirudh-Kohli.webp')} alt="Dr. Anirudh Kohli" height={96} width={96} className='img-fluid rounded-circle' />
                                <div className='mb-4 mt-3'>
                                    <h3 className='aug-customer-items__name mb-0'>Dr. Anirudh Kohli MD</h3>
                                    <h4 className='aug-customer-items__designation mb-0'>DNB DMRD Chief of imaging and Radiodiagnosis, Breach Candy Hospital</h4>
                                </div>
                                <sub className='aug-customer-items__quote d-flex'>“</sub>
                                <div className='aug-customer-items__text'>
                                    It is making a huge difference, it has improved our turnaround time tremendously, it’s changed my life and it will change every radiologist’s life, believe me!
                                </div>
                            </div>

                            <div className='aug-customer-items'>
                                <img src={require('../../../Assets/images/HeadShot/IN/Mohan-Sunil-Kumar.webp')} alt="Dr Mohan Sunil Kumar" height={96} width={96} className='img-fluid rounded-circle' />
                                <div className='mb-4 mt-3'>
                                    <h3 className='aug-customer-items__name text-nowrap mb-0'>Dr Mohan Sunil Kumar</h3>
                                    <h4 className='aug-customer-items__designation mb-0'>Director and Chief Psychiatrist, Couch- Centre for New Beginning</h4>
                                </div>
                                <sub className='aug-customer-items__quote d-flex'>“</sub>
                                <div className='aug-customer-items__text'>
                                    In a world where documentation matters, Augnito’s versatile and robust platform makes documentation a breeze. Saves time by 60%. Better documentation, better care.
                                </div>
                            </div>

                            <div className='aug-customer-items'>
                                <img src={require('../../../Assets/images/HeadShot/IN/Paresh-Doshi.webp')} alt="Dr Paresh Doshi" height={96} width={96} className='img-fluid rounded-circle' />
                                <div className='mb-4 mt-3'>
                                    <h3 className='aug-customer-items__name mb-0'>Dr Paresh Doshi</h3>
                                    <h4 className='aug-customer-items__designation mb-0'>Director of Neurosurgery, Jaslok Hospital and Research Centre </h4>
                                </div>
                                <sub className='aug-customer-items__quote d-flex'>“</sub>
                                <div className='aug-customer-items__text'>
                                Let me congratulate and compliment you on this excellent medical dictation, AI-based, software. It has been working seamlessly so far. I have been using it for more than two years. Your service has been excellent and you have surpassed all expectations.
                                </div>
                            </div>

                            <div className='aug-customer-items'>
                                <img src={require('../../../Assets/images/HeadShot/IN/Gaurang-Raval.webp')} alt="Dr Gaurang Raval" height={96} width={96} className='img-fluid rounded-circle' />
                                <div className='mb-4 mt-3'>
                                    <h3 className='aug-customer-items__name mb-0'>Dr Gaurang Raval</h3>
                                    <h4 className='aug-customer-items__designation mb-0'>Radiologist, Wockhardt Hospitals </h4>
                                </div>
                                <sub className='aug-customer-items__quote d-flex'>“</sub>
                                <div className='aug-customer-items__text'>
                                Augnito successfully registers my voice to text to 99.9% accuracy. I can vouch for my colleagues in the Radiology department in my hospital and know for a fact that their experiences have been exceptional. Augnito is an industry leader in voice to text software for medical reports, and I fully endorse Augnito for quick, effective, and flawless Radiology reports.
                                </div>
                            </div>
                            
                            <div className='aug-customer-items'>
                                <img src={require('../../../Assets/images/HeadShot/IN/Arjun-Narula.webp')} alt="Dr. Arjun Narula" height={96} width={96} className='img-fluid rounded-circle' />
                                <div className='mb-4 mt-3'>
                                    <h3 className='aug-customer-items__name mb-0'>Dr. Arjun Narula</h3>
                                    <h4 className='aug-customer-items__designation mb-0'>DNB Diagnostic & Interventional Radiologist, Narula Diagnostic Centres </h4>
                                </div>
                                <sub className='aug-customer-items__quote d-flex'>“</sub>
                                <div className='aug-customer-items__text'>
                                Being a Mac OS user, I faced numerous limitations with other legacy Voice Recognition softwares. Augnito proved to be a game-changer with its seamless compatibility and intuitive design, providing an exceptional experience on both interfaces.
                                </div>
                            </div>

                        </Slider>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default CustomerSayIN;