import React from 'react';
import './CareerAbout.scss'
import Conscientiousness from './Conscientiousness';
import Innovation from './Innovation';
import People from './People';
import Precision from './Precision';
import ConscientiousnessME from './ConscientiousnessME';
import BaseComponent from '../../../Common/BaseComponent';
import PeopleIN from './PeopleIN';
import InnovationIN from './InnovationIN';
import ConscientiousnessIN from './ConscientiousnessIN';

export default class CareerAbout extends BaseComponent {
    render() {

        var regionData = this.props.userRegionData;
        if (this.props.isGeoLoading) {
            return;
        }

        return (
        <section className='aug-career-about mb-60'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-12 offset-md-1 col-md-11 offset-lg-2 col-lg-9'>
                            <h2 className='aug-title mb-60'>What we’re about</h2>
                            <div className='row gx-40'>
                                <div className='col-12 col-md-6'>
                                    {
                                        regionData === "IN" ? <PeopleIN/> :
                                        <People/>
                                    } 
                                </div>
                                <div className='col-12 col-md-6'>
                                    {
                                        regionData === "IN" ? <InnovationIN/> :
                                        <Innovation/>
                                    } 
                                </div>
                                <div className='col-12 col-md-6'>
                                    {
                                        regionData === "ME" ? <ConscientiousnessME/> :
                                        regionData === "IN" ? <ConscientiousnessIN/> :
                                        <Conscientiousness/>
                                    } 
                                </div>
                                <div className='col-12 col-md-6'>
                                    <Precision/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </section>
        );
    };
};