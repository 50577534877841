import React from 'react';
import {ReactComponent as Microphone} from '../../../../Assets/images/icons/microphone.svg';

const AdditionalHardware = () => {
    return (
        <div className='mt-60'>
            <div className='aug-speech-models__icon d-flex align-items-center justify-content-center'>
                <Microphone/>
            </div>
            <h3 className='mb-3 aug-speech-models__heading'>No additional hardware required</h3>
            <p className='mb-0'>Effortless integration with any microphone, ensuring comprehensive capture of information even during examinations. </p>
        </div>
    );
};

export default AdditionalHardware;