import React from 'react';
import {ReactComponent as Card} from '../../../../Assets/images/icons/clock.svg';

const OutEaseME = () => {
    return (
        <div className='mt-60'>
            <div className='aug-speech-models__icon d-flex align-items-center justify-content-center'>
                <Card/>
            </div>
            <h3 className='mb-3 aug-speech-models__heading'>Opt out with ease</h3>
            <p>Cancel your Spectra subscription at any time.</p>
        </div>
    );
};

export default OutEaseME;