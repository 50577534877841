import React from 'react';
import CloudBased from '../../../Spectra/Features/VersatileDeployment/CloudBased';
import OnPremise from '../../../Spectra/Features/VersatileDeployment/OnPremise';
import './VersatileDeployment.scss';

const VersatileDeployment = () => {
   
    return (
      <section className='aug-voice-versatile-deployment aug-our-customer'>
      <div className='container-fluid'>
          <div className='row'>
            <div className='offset-lg-2 offset-md-1 col-md-11 col-lg-8'>
              <h2 className='aug-title mb-60'>Versatile deployment</h2>
            </div>
          </div>
          <div className='row'>
              <div className='offset-lg-2 offset-md-1 col-12 col-md-10 col-lg-10'>
                  <div className='row'>
                      <div className='col-12 col-md-12 col-lg-6'>
                          <CloudBased/>
                      </div>
                      <div className='col-12 col-md-12 col-lg-6'>
                        <OnPremise/>
                      </div>
                  </div>
              </div>
          </div>
      </div>
 </section>
    );
};

export default VersatileDeployment;