import React from 'react';
import Banner from './Banner/Banner';
import WhatWeOffer from './WhatWeOffer/WhatWeOffer';
import MakesUnique from './MakesUnique/MakesUnique';
import GetStarted from './GetStarted/GetStarted';
import PositiveStake from './PositiveStake/PositiveStake';
import AugnitoUSP from './AugnitoUSP/AugnitoUSP';
import OurExpertise from './OurExpertise/OurExpertise';
import OurPartner from './OurPartner/OurPartner';
import BookDemo from './BookDemo/BookDemo';
import CustomerSay from './CustomerSay/CustomerSay';
import DataSecurity from './DataSecurity/DataSecurity';
import SpectraTool from './SpectraTool/SpectraTool';
import TrySpectra from './TrySpectra/TrySpectra';
import HomeMeta from './Meta/HomeMeta';
import BaseComponent  from '../../Common/BaseComponent';
import BookDemoME from './BookDemo/BookDemoME';
import CustomerSayME from './CustomerSay/CustomerSayME';
import SpectraToolME from './SpectraTool/SpectraToolME';
import TrySpectraME from './TrySpectra/TrySpectraME';
import MadeIndia from './MadeIndia/MadeIndia';
import AugnitoUSPIN from './AugnitoUSP/AugnitoUSPIN';
import BookDemoIN from './BookDemo/BookDemoIN';
import CustomerSayIN from './CustomerSay/CustomerSayIN';
import SpectraToolIN from './SpectraTool/SpectraToolIN';
import TrySpectraIN from './TrySpectra/TrySpectraIN';

export default class Home extends BaseComponent {
    
    render() {
        // const { regionData } = this.state;
        var regionData = this.props.userRegionData;
        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <main>
                <HomeMeta />
                <Banner userRegionData={regionData}/>
                <WhatWeOffer userRegionData={regionData}/>
                <MakesUnique userRegionData={regionData}/>
                <GetStarted />
                <PositiveStake userRegionData={regionData}/>
                {
                    regionData === "IN" ? <MadeIndia/> : 
                    <> </>
                }
                {
                    regionData === "IN" ? <AugnitoUSPIN/> :
                    <AugnitoUSP />
                }
                <OurExpertise />
                <OurPartner userRegionData={regionData}/>
                {
                    regionData === "IN" ? <BookDemoIN/> :
                    regionData === "ME" ? <BookDemoME/> :
                    <BookDemo />
                }
                {
                    regionData === "IN" ? <CustomerSayIN/> :
                    regionData === "ME" ? <CustomerSayME/> :
                    <CustomerSay />
                }
                <DataSecurity />
                {
                    regionData === "IN" ? <SpectraToolIN/> :
                    regionData === "ME" ? <SpectraToolME/> :
                    <SpectraTool />
                }
                {
                    regionData === "IN" ? <TrySpectraIN/> :
                    regionData === "ME" ? <TrySpectraME/> :
                    <TrySpectra />
                }
            </main>
        );
    }
}
