import React from 'react';
import {ReactComponent as Call} from '../../../../Assets/images/icons/call.svg';

const TeleconsultationSupport = () => {
    return (
        <div className='mt-60'>
            <div className='aug-speech-models__icon d-flex align-items-center justify-content-center'>
                <Call/>
            </div>
            <h3 className='mb-3 aug-speech-models__heading'>Versatile in-patient and teleconsultation support</h3>
            <p className='mb-0'>Seamless adaptation to various consultation methods, empowering healthcare providers to generate comprehensive medical notes in any setting.  </p>
        </div>
    );
};

export default TeleconsultationSupport;