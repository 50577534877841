import React from 'react';
import WatchDemo from './WatchDemo';
import { useState } from 'react';
import { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import {ReactComponent as Video} from '../../../Assets/images/icons/play-circle.svg';
import { RedirectToSignUp } from '../../../Common/Utils';

const BannerContent = (props) => {

    const [inIndex, setInIndex] = useState();
    let location = useLocation();
    useEffect(() => {
        setInIndex(location.pathname !== "/");
    }, [location]);

    return (
        <div className='container-fluid'>
            <div className="row">
                <div className='offset-lg-1 offset-md-1 col-md-9 col-lg-8'>

                    <div className={` ${inIndex ? 'd-none' : ''}`}>
                        <h1 className='mb-3'>Clinical documentation made easy with Voice AI</h1>
                        <p>Augnito enables effortless medical workflows via Automatic Speech Recognition & Natural Language Processing.</p>
                        <button type='button' className='btn btn-white' onClick={RedirectToSignUp}>
                            Try Spectra
                        </button>
                        <button type='button' className='btn btn-outline-white' data-bs-toggle="modal" data-bs-target="#whatchDemo">
                            <div className='d-flex align-items-center justify-content-center'>
                                <Video className='me-2'/>Watch demo
                            </div>
                        </button>
                        <WatchDemo/>
                    </div>

                    <div className={` ${inIndex ? 'd-block' : ''}`}>
                        {props.children}
                    </div>

                </div>
            </div>
        </div>
    );
};

export default BannerContent;