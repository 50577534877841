import React from 'react';

const TrustedLogo = () => {
    return (
        <ul className='list-unstyled mb-0 d-flex align-items-center flex-wrap'>
            <li>
                <img src={require('../../../../Assets/images/PartnersLogo/physician-one.webp')} alt="physician one logo" className='img-fluid bg-white p-10'/>
            </li>
            <li>
                <img src={require('../../../../Assets/images/PartnersLogo/apollo.webp')} alt="apollo logo" className='img-fluid bg-white p-10'/>
            </li>
            <li>
                <img src={require('../../../../Assets/images/PartnersLogo/fujifilm.webp')} alt="fujifilm logo" className='img-fluid bg-white p-10'/>
            </li>
            <li>
                <img src={require('../../../../Assets/images/PartnersLogo/nhs.webp')} alt="nhs logo" className='img-fluid bg-white p-10'/>
            </li>            
            <li>
                <img src={require('../../../../Assets/images/PartnersLogo/chartnote.webp')} alt="chartnote logo" className='img-fluid bg-white p-10'/>
            </li>
        </ul>
    );
};

export default TrustedLogo;