import React from 'react';
import Img from '../../../../Assets/images/Spectra/TrySpectaBG.webp';
import './TryDays.scss';
import { GetTrialDaysByProduct, RedirectToSignUp } from '../../../../Common/Utils';
import * as Constants from '../../../../Common/Constants'

const TryDaysME = () => {
    var trailDays = GetTrialDaysByProduct(Constants.SPECTRA_LISTING_NAME);
    return (
       <section className='aug-try-days aug-spectra-overview-try-days position-relative'>
            <div className='aug-try-days__img h-100'>
                <img src={Img} alt="banner footer" className="h-100 img-fluid"/>
            </div>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='offset-lg-6 col-12 col-md-9 col-lg-6'>
                        <div className='aug-try-days-box position-absolute'>
                            <h2 className='mb-3'>Try Spectra. <br /> It’s free for {trailDays} days. </h2>
                            <p>No credit card required. <br />
                            No onboarding and training fees.</p>
                            <button type='button' className='btn btn-primary' onClick={RedirectToSignUp}>Get started</button>
                        </div>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default TryDaysME;