import React from 'react';
import Img from '../../../../Assets/images/Spectra/OurReach/ME/desktop-web-plugin.webp';
import { RedirectToSignUp } from '../../../../Common/Utils';

const DesktopWebPluginME = () => {
    return (
        <div className="row gx-40 mt-60 align-items-lg-center flex-column-reverse flex-md-row">
            <div className="offset-lg-2 offset-md-1 col-md-7 col-lg-6">
                <h3 className='mb-3 aug-our-reach__heading'>
                    Available on desktop, web, browser plugin & mobile
                </h3>
                <p className='mb-0'>Seamlessly switch to any platform. Roaming profile with your settings are synced and available on any workstation.</p>
                <div className='mt-40'>
                    <button type="button" className="btn btn-primary" onClick={RedirectToSignUp} >Try Spectra for free</button>
                </div>
            </div>
            <div className="col-12 col-md-4 col-lg-4">
            <div className='mr-n40 aug-our-reach__img'>
                    <img src={Img} alt="Showing the reach of the apps on desktop, web, and mobile" className='w-100 img-fluid'/>
                </div>
            </div>
            
        </div>         
    );
};

export default DesktopWebPluginME;