import React from 'react';
import './TrySpectraFree.scss';
import { RedirectToSignUp } from '../../../../Common/Utils';

const TrySpectraFree = () => {
    return (
        <div className='aug-spectra-overiew-try container-fluid mb-60 mt-60'>
            <div className='row'>
                <div className='col-12 col-lg-10'>
                    <section className='aug-get-started ml-n40'>
                        <h2 className='mb-0'>
                            Cost effective solution for healthcare providers of all specialties, experience levels and sizes.
                        </h2>
                        <div className='mt-40'>
                            <button type="button" className="btn btn-white" onClick={RedirectToSignUp}>Try Spectra for free</button>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default TrySpectraFree;