import React from 'react';
import './Footer.scss';
import {Link} from "react-router-dom";
import * as Constants from '../../../Common/Constants';
import { ScrollTop } from '../../../Common/Utils';

const FooterInfo = () => {
    return (
        <div className='row'>
            <div className='col-6 col-md-3'>
                <div className="mb-4 mb-md-0">
                    <h5 className='aug-list-heading mb-2'>Product</h5>
                    <ul className='list-unstyled mb-0'>
                        <li>
                            <Link to="/spectra" onClick={ScrollTop} >Augnito Spectra</Link>
                        </li>
                        <li>
                            <Link to="/voice-services" onClick={ScrollTop}>Augnito Voice Services</Link>
                        </li>
                        <li>
                            <Link to="/ambient" onClick={ScrollTop}>Augnito Ambient</Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='col-6 col-md-3'>
                <div className="mb-4 mb-md-0">
                    <h5 className='aug-list-heading mb-2'>Company</h5>
                    <ul className='list-unstyled mb-0'>
                        <li>
                            <Link to="/about-us" onClick={ScrollTop}>About us</Link>
                        </li>
                        <li>
                            <Link to="/careers" onClick={ScrollTop}>Careers</Link>
                        </li>
                        <li>
                            <Link to="/contact-us" onClick={ScrollTop}>Contact us</Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='col-6 col-md-3'>
                <div className="mb-4 mb-md-0">
                    <h5 className='aug-list-heading mb-2'>Resources</h5>
                    <ul className='list-unstyled mb-0'>
                        <li>
                            <a href={Constants.SITE_URL + "resources/blog" } target="_blank" rel="noreferrer" className="nav-link">Blog</a>                            
                        </li>
                        <li>
                            <a href={Constants.SITE_URL + "resources/case-studies"} target="_blank" rel="noreferrer" className="nav-link">Case study</a>   
                        </li>
                        <li>
                            <a href={Constants.SITE_URL + "resources/newsroom"} target="_blank" rel="noreferrer" className="nav-link">Newsroom</a> 
                        </li>
                        <li>
                            <a href={Constants.SITE_URL + "resources/faq"} target="_blank" rel="noreferrer" className="nav-link">FAQs</a> 
                        </li>
                        <li>
                            <a href={Constants.SITE_URL + "resources/docs"} target="_blank" rel="noreferrer" className="nav-link">Documentation</a>
                        </li>
                        <li>
                            <a href={Constants.SITE_URL + "resources/videos"} target="_blank" rel="noreferrer" className="nav-link">Videos</a>
                        </li>
                        <li>
                            <a href={Constants.SITE_URL + "resources/events"} target="_blank" rel="noreferrer" className="nav-link">Events</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='col-6 col-md-3'>
                <div className="mb-4 mb-md-0">
                    <ul className='list-unstyled mb-0 aug-list-without-heading'>
                        <li>
                            <Link to="legal/terms-of-use" onClick={ScrollTop}>Terms of use</Link>
                        </li>
                        <li>
                            <Link to="/legal/augnito-privacy-policy" onClick={ScrollTop}>Privacy policy</Link>
                        </li>
                        <li>
                            <a href={Constants.SITE_URL + "legal"} target="_blank" rel="noreferrer" className="nav-link">Legal</a>                            
                        </li> 
                        <li>
                            <Link to="/sitemap" onClick={ScrollTop}>Sitemap</Link>
                        </li>                      
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default FooterInfo;