import React from 'react';

const PeopleIN = () => {
    return (
       <div className='mb-60'>
            <h3 className='mb-3'>People</h3>
            <p className='mb-0'>We believe in adopting a people-first in approach all that we do. Whether that translates as a human-centric product or an employee-focused organisation. </p>
       </div>
    );
};

export default PeopleIN;