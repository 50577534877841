import React from 'react';
import TrustedLogo from '../Spectra/PlansPricing/PricingTrusted/TrustedLogo';
import TrustedLogoME from '../Spectra/PlansPricing/PricingTrusted/TrustedLogoME';
import TrustedLogoIN from '../Spectra/PlansPricing/PricingTrusted/TrustedLogoIN';
import BaseComponent  from '../../Common/BaseComponent';

export default class Trusted extends BaseComponent {

    render() {
        // const { regionData } = this.state;
        var regionData = this.props.userRegionData;
        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <div className='aug-trusted aug-pricing-trusted'>
                <h2 className="aug-title mb-60">Trusted by</h2>
                {
                    regionData === "ME" ? <TrustedLogoME/> :
                    regionData === "IN" ? <TrustedLogoIN/> :
                    <TrustedLogo/>
                }
            </div>
        )
    }
}