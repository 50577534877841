import React from 'react';
import {NavLink} from "react-router-dom";
import ImgAvailability from '../../../../Assets/images/VoiceServices/features/standout-features/IN/platform-availability.webp';

const PlatformAvailabilityIN = () => {
    return (
        <div className='row gx-0 mt-60 align-items-lg-center'>
            <div className='offset-md-1 offset-lg-2 col-12 col-md-6 col-lg-4 pr-40'>
                <h3 className='aug-features-glance__heading mb-3'>Platform availability</h3>
                <p className='mb-0'>
                Voice Services APIs & SDKs can be integrated into desktop, web, and mobile (iOS & Android) applications. 
                </p>
                <div className="mt-40">
                    <NavLink to="/contact-sales" className='btn btn-outline-primary'>
                    Contact sales  
                    </NavLink>
                </div>
            </div>
            <div className='col-12 col-md-5 col-lg-6'>
                <div className='mr-n40 aug-standout-features-img'>
                    <img src={ImgAvailability} alt="Augnito spectra displayed on desktop, laptop, and mobile, showcasing its wide range of availability" className='w-100 rounded-ltb-10 aug-voice-features-standout-img img-fluid'/>
                </div>
            </div>
        </div>
    );
};

export default PlatformAvailabilityIN;