import React from 'react';
import SubNavbarVoice from '../../Shared/SubNavbarVoice/SubNavbarVoice';
import VoiceServicesMeta from './Meta/VoiceServicesMeta';
import BaseComponent from '../../../Common/BaseComponent';
import VoiceServices from './VoiceServices/VoiceServices';
import EasyLaunch from './EasyLaunch/EasyLaunch';
import ComprehensiveVoice from './ComprehensiveVoice/ComprehensiveVoice';
import TailorMade from './TailorMade/TailorMade';
import SuperiorService from './SuperiorService/SuperiorService';
import VoiceOverviewDemo from './VoiceOverviewDemo/VoiceOverviewDemo';
import OverviewBanner from './OverviewBanner/OverviewBanner';
import StandoutFeatures from './StandoutFeatures/StandoutFeatures';
import VoiceTryNow from './VoiceTryNow/VoiceTryNow';
import SecureCompliant from './SecureCompliant/SecureCompliant';
import SuccessfullyIntegrated from './SuccessfullyIntegrated/SuccessfullyIntegrated';
import VoiceGetStarted from './VoiceGetStarted/VoiceGetStarted';
import WhyChooseUs from './WhyChooseUs/WhyChooseUs';
import CustomersSay from './CustomersSay/CustomersSay';
import VoiceTryNowME from './VoiceTryNow/VoiceTryNowME';
import SuccessfullyIntegratedME from './SuccessfullyIntegrated/SuccessfullyIntegratedME';
import CustomersSayME from './CustomersSay/CustomersSayME';
import VoiceGetStartedME from './VoiceGetStarted/VoiceGetStartedME';
import SecureCompliantME from './SecureCompliant/SecureCompliantME';
import OverviewBannerIN from './OverviewBanner/OverviewBannerIN';
import VoiceTryNowIN from './VoiceTryNow/VoiceTryNowIN';
import SecureCompliantIN from './SecureCompliant/SecureCompliantIN';
import SuccessfullyIntegratedIN from './SuccessfullyIntegrated/SuccessfullyIntegratedIN';
import CustomersSayIN from './CustomersSay/CustomersSayIN';
import VoiceGetStartedIN from './VoiceGetStarted/VoiceGetStartedIN';

export default class Overview extends BaseComponent {
    
    render() {
        // const { regionData } = this.state;
        var regionData = this.props.userRegionData;
        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <main>
                <SubNavbarVoice/>
                <VoiceServicesMeta/>
                {
                    regionData === "IN" ? <OverviewBannerIN/> :
                    <OverviewBanner/>
                }
                <VoiceServices/>
                <EasyLaunch/>
                {
                    regionData === "IN" ? <StandoutFeatures userRegionData={regionData}/> :
                    regionData === "ME" ? <StandoutFeatures userRegionData={regionData}/> :
                    <ComprehensiveVoice userRegionData={regionData}/>
                }
                {
                    regionData === "IN" ? <WhyChooseUs userRegionData={regionData}/>:
                    regionData === "ME" ? <WhyChooseUs userRegionData={regionData}/>:
                    <StandoutFeatures userRegionData={regionData}/>
                }
                {
                    regionData === "IN" ? <div className='pt-1'><ComprehensiveVoice userRegionData={regionData}/> </div> :
                    regionData === "ME" ? <div className='pt-1'><ComprehensiveVoice userRegionData={regionData}/> </div> :
                    <WhyChooseUs userRegionData={regionData}/>
                }
                {
                    regionData === "IN" ? <VoiceTryNowIN/> :
                    regionData === "ME" ? <VoiceTryNowME/> :
                    <VoiceTryNow/>
                }
                {
                    regionData === "IN" ? <TailorMade userRegionData={regionData}/> :
                    regionData === "ME" ? <TailorMade userRegionData={regionData}/> :
                    <SuperiorService userRegionData={regionData}/>
                }
                {
                    regionData === "IN" ? <SuperiorService userRegionData={regionData}/> :
                    regionData === "ME" ? <SuperiorService userRegionData={regionData}/> :
                    <TailorMade userRegionData={regionData}/>
                }
                <VoiceOverviewDemo/>
                {
                    regionData === "IN" ? <SecureCompliantIN/> :
                    regionData === "ME" ? <SecureCompliantME/> :
                    <SecureCompliant/>
                }
                {
                    regionData === "IN" ? <SuccessfullyIntegratedIN/> :
                    regionData === "ME" ? <SuccessfullyIntegratedME/> :
                    <SuccessfullyIntegrated/>
                }
                {
                    regionData === "ME" ? <CustomersSayME/> :
                    regionData === "IN" ? <CustomersSayIN/> :
                    <CustomersSay/>
                }
                {
                    regionData === "ME" ? <VoiceGetStartedME/> :
                    regionData === "IN" ? <VoiceGetStartedIN/> :
                    <VoiceGetStarted/>
                }
            </main>
        );
    }
}