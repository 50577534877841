import React from 'react';
import BaseComponent from '../../../Common/BaseComponent';
import BreachPolicyMeta from './BreachPolicyMeta';

export default class BreachPolicy extends BaseComponent {
    render() {

        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <>
                <BreachPolicyMeta/>
                 <section className='aug-legal-page mt-60'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-12 offset-md-1 col-md-10 offset-lg-2 col-lg-8'>
                                <div className='aug-legal-page-header'>
                                    <h1 className='aug-legal-page-title mb-3'>Data Breach Policy</h1>
                                    <p className='mb-0 text-secondary'>Last updated: 29th June, 2023</p>
                                </div>

                                <div className='mb-4'>
                                    <h3 className='aug-legal-page-subtitle'>Purpose</h3>
                                    <p className='mb-0'>Augnito India Private Limited (AIPL) recognizes that cybersecurity and data privacy are important considerations in today’s digital world. AIPL is committed to responding quickly and decisively to any suspected or confirmed cybersecurity or data privacy incident. AIPL has crafted this Data Breach Policy and Procedures (this “Policy”) and the corresponding plan and worksheets to help those responding to an inevitable Incident.</p>
                                </div>
                                <div className=''>
                                    <h3 className='aug-legal-page-subtitle'>Scope</h3>
                                    <p className='mb-3'>AIPL has implemented the following procedures to follow in the event of a data breach involving personally identifying information (PII) or other confidential information maintained on personal computers, agency networks, or internet programs used by staff and volunteers.</p>
                                    <div className='mb-3'>
                                        In an effort to prevent a breach of data and PII, AIPL has implemented the following measures to prevent the breach of data:
                                        <ul className='mb-0'>
                                            <li>Technical Support Service Provider</li>
                                            <li>Installed anti virus intrusion notification software.</li>
                                            <li>Agency procedures for personal access and use of agency computers</li>
                                            <li>Org Wide compliance training to make the employees aware</li>
                                            <li>Advisory’s on security related issues to help employee stay updated</li>
                                            <li>Law enforcement support to locate and apprehend perpetrators.</li>
                                        </ul>
                                    </div>
                                    <div className='mb-3'>
                                    In the event of a data breach or imminent breach of PII data, in order to contain the data breach and minimize the extent of the intrusion:
                                        <ul className='mb-0'>
                                            <li>Disconnect the affected and related systems or networks from Internet access.</li>
                                            <li>Contact the infosec team (<a target="_blank" rel="noreferrer" href="mailto:infosec@augntio.ai"><u>infosec@augntio.ai</u></a>) to notify them of the data breach or imminent breach of PII data.</li>
                                            <li>Inform Cert-In if required</li>
                                            <li>Document in incident security tracker with the following details- date and time the breach occurred, what files the user was accessing at the time of the breach, the authorized person contacted, and actions taken to secure data.</li>
                                            <li>Review virus/malware/other protective software to review system vulnerabilities and increase the level of protection for the system.</li>
                                            <li>If possible, reimage the system and restore from backup files</li>
                                            <li>Issue advisory to employees based on learning from the incidence to help employee respond better to such incidents.</li>
                                        </ul>
                                    </div>
                                    <p className='mb-3'>Following the incident, AIPL staff will review procedures to determine if any actions by the user or the team contributed to the data breach. If found so, disciplinary action will be initiated against the employee in fault. Employees will be updated on policies to protect against data breaches or imminent breaches of PII data.</p>
                                    <p className='mb-3'>IT team will review software, updates, and software/data protection programs to improve the security of the data and operating system to prevent further incidents. Information related to the data breach will be documented on the incident log, repairs or modifications implemented will be included on the log and kept in a secure location.</p>
                                    <p>A periodic review of reported incidents will be shared with the management by the information security team to align and improve the system based on their inputs.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}