import React from 'react';
import {ReactComponent as Setting} from '../../../../Assets/images/icons/setting.svg';

const FeatureUpgradesIN = () => {
    return (
        <div className='mt-60'>
            <div className='aug-speech-models__icon d-flex align-items-center justify-content-center'>
                <Setting/>
            </div>
            <h3 className='mb-3 aug-speech-models__heading'>Free feature upgrades</h3>
            <p className='mb-0'>Get access to avant-garde services and product upgrades at no additional cost.</p>
        </div>
    );
};

export default FeatureUpgradesIN