import React from 'react';
import HIPAACompliant from './HIPAACompliant';
import ImplementDeploy from './ImplementDeploy';
import CustomerSupport from './CustomerSupport';

const SuperiorService = () => {
    return (
        <section className='aug-superior-service aug-our-customer'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='offset-md-1 col-md-10'>
                        <h2 className="aug-title mb-60">A superior service</h2>
                    </div>
                    <div className='offset-md-1 col-12 col-md-10'>
                        <div className='row'>
                            <div className='col-12 col-md-12 col-lg-4'>
                               <HIPAACompliant/> 
                            </div>
                            <div className='col-12 col-md-12 col-lg-4'>
                                <ImplementDeploy/>
                            </div>
                            <div className='col-12 col-md-12 col-lg-4'>
                                <CustomerSupport/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SuperiorService;