import React from 'react';
import {ReactComponent as Clock} from '../../../../Assets/images/icons/clock.svg';

const RealTime = () => {
    return (
        <div className='mt-60'>
            <div className='aug-speech-models__icon d-flex align-items-center justify-content-center'>
                <Clock/>
            </div>
            <h3 className='mb-3 aug-speech-models__heading'>Real-time transcription</h3>
            <p className='mb-0'>An efficient and accurate, real-time clinical speech-to-text output.</p>
        </div>
    );
};

export default RealTime;