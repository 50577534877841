import React from 'react';

const TrustedLogoME = () => {
    return (
        <ul className='list-unstyled mb-0 d-flex align-items-center flex-wrap'>
            <li>
                <img src={require('../../../../Assets/images/PartnersLogo/ME/prime-hospital.webp')} alt="prime hospital logo" className='img-fluid bg-white p-10'/>
            </li>
            <li>
                <img src={require('../../../../Assets/images/PartnersLogo/ME/fujifilm.webp')} alt="fujifilm logo" className='img-fluid bg-white p-10'/>
            </li>
            <li>
                <img src={require('../../../../Assets/images/PartnersLogo/ME/paxera-health.webp')} alt="paxera health logo" className='img-fluid bg-white p-10'/>
            </li>
            <li>
                <img src={require('../../../../Assets/images/PartnersLogo/ME/kims-hospital.webp')} alt="kims hospital logo" className='img-fluid bg-white p-10'/>
            </li>            
            <li>
                <img src={require('../../../../Assets/images/PartnersLogo/ME/attieh.webp')} alt="attieh logo" className='img-fluid bg-white p-10'/>
            </li>
        </ul>
    );
};

export default TrustedLogoME;