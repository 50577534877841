import React from 'react';

const HealthcareIN = () => {
    return (
        <div className='row gx-40 flex-column-reverse flex-md-row'>
            <div className='offset-lg-1 offset-md-1 col-md-6 col-lg-5'>
                {/* <h3 className='aug-tab-content__heading mb-2'>Healthcare IT</h3> */}
                <h3 className='mb-4 aug-tab-content__subheading'>Deploy the latest technology with ease</h3>
                <p className='mb-0'>Secure, portable, virtual and remote desktop ready solutions that are easy to deploy and manage at any scale. </p>
            </div>
            <div className='offset-lg-1 col-md-5 col-lg-4'>
                <img src={require('../../../Assets/images/home/PositiveStake/IN/positive-stake-1.webp')} height="auto" width="100%" alt="Radiologist using Augnito on one screen and watching radiology report on other" className='img-fluid mb-4 mb-md-0 pb-2 pb-md-0'/>
            </div>
        </div>
    );
};

export default HealthcareIN;