import React from 'react';
import Img from '../../../../Assets/images/VoiceServices/overview/easy-launch.webp';
import { RedirectToSignUpVoiceServices } from '../../../../Common/Utils';
import './EasyLaunch.scss'

const EasyLaunch = () => {
    return (
       <section className='aug-data-security aug-voice-overview-easy-launch'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='offset-lg-2 offset-md-1 col-md-11 col-lg-8'>
                        <h2 className='aug-title mb-60'>An easy launch</h2>
                    </div>
                </div>
                <div className='row gx-0 flex-column-reverse flex-md-row'>
                    <div className='offset-lg-2 offset-md-1 col-12 col-md-7 col-lg-6 pr-40'>
                        <h3 className='mb-3 aug-data-security__heading'>Evaluate the power of Voice Services via our Developer Portal</h3>
                        <p className='mb-4'>Our streamlined service enables an easy integration of Augnito. Discover step-by-step guides, Sandbox environment, SDKs, documentation, and other development resources.</p>                        
                        <button type="button" className="btn btn-primary" onClick={RedirectToSignUpVoiceServices}>Try for free</button>
                    </div>
                    <div className='col-12 col-md-4 col-lg-4'>
                        <div className='aug-spectra-tool__img mr-n40'>
                            <img src={Img} alt="Augnito Medical Voice Recognition: SDKs displayed on a laptop screen" className="img-fluid rounded-ltb-10 w-100"/>
                        </div>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default EasyLaunch;