import React from 'react';
import Img from '../../../Assets/images/home/WhatWeOffer/voice-services.webp';
import { RedirectToSignUpVoiceServices, NavigateToInternalPage } from '../../../Common/Utils';

const VoiceServices = () => {
    return (
       <section className='aug-voice-services mb-60'>
            <div className='row gx-0 flex-lg-row flex-column-reverse'>
                <div className='offset-md-1 col-12 col-md-10 col-lg-4 pr-40 pe-md-0'>
                    <h3 className='aug-what-we-offer__heading'>Voice Services</h3>
                    <h4 className='mb-3 aug-our-expertise__subheading'>APIs & SDKs to voice-power your clinical software</h4>
                    <p className='mb-0'>Add medical voice recognition capabilities to your clinical software through API (Application Programming Interface) and SDK (Software Development Kit) integration, with minimal coding.</p>
                    <div className='mt-40'>
                        <button type="button" className="btn btn-primary me-md-3" onClick={RedirectToSignUpVoiceServices}>Try for free</button>
                        <button type="button" className="btn btn-outline-primary mt-3 mt-md-0" onClick={ NavigateToInternalPage("/voice-services") }>Discover more</button>
                    </div>
                </div>
                <div className='offset-lg-1 offset-md-1 col-12 col-md-10 col-lg-5'>
                    <div className='aug-what-we-offer__img mb-md-32'>
                        <img src={Img} height="auto" width="100%" alt="Augnito voice services loading on a laptop screen" className="rounded-10 img-fluid"/>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default VoiceServices;