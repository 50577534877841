import React from 'react';
import BaseComponent from '../../Common/BaseComponent'
import ContactBanner from './ContactBanner/ContactBanner';
import DownloadPress from './DownloadPress/DownloadPress';
import HelpYou from './HelpYou/HelpYou';
import MediaContact from './MediaContact/MediaContact';
import ContactMeta from './Meta/ContactMeta';
import OurOffices from './OurOffices/OurOffices';

export default class Contact extends BaseComponent {
    render() {
        // const { regionData } = this.state;
        var regionData = this.props.userRegionData;
        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <main>
                <ContactMeta />
                <ContactBanner/>
                <HelpYou userRegionData={regionData} />
                <OurOffices/>
                <DownloadPress/>
                <MediaContact/>
            </main>
        );
    }
}