import React from 'react';

const ConscientiousnessIN = () => {
    return (
       <div className='mb-60'>
            <h3 className='mb-3'>Conscientiousness</h3>
            <p className='mb-0'>We work with diligence, alacrity, purpose and power to manifest results. Revolutionising healthcare systems is an ambitious undertaking. </p>
       </div>
    );
};

export default ConscientiousnessIN;