import React from 'react';
import Slider from "react-slick";
import ArrowRight from '../../../Assets/images/icons/arrow-right.svg';
import ArrowLeft from '../../../Assets/images/icons/arrow-left.svg';
import './CustomerSay.scss';

function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        style={{ 
            backgroundImage: `url(${ArrowRight})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
         }}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        style={{
            backgroundImage: `url(${ArrowLeft})`, 
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        }}
        onClick={onClick}
      />
    );
  }

const CustomerSay = () => {
    const settings = {
        dots: true,
        autoplay:true,
        infinite: true,
        slidesToShow: 3,
        pauseOnHover: true,
        slidesToScroll: 1,
        arrows:true,
        className:'aug-customer-slider',
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 2,
                speed: 1000,
              }
            },
            {
              breakpoint: 575,
              settings: {
                slidesToShow: 1
              }
            }
        ]
    };
    return (
       <section className='aug-customer-say'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='offset-lg-1 offset-md-1 col-md-11 col-lg-6'>
                        <h2 className='aug-title mb-60'>What customers say</h2>
                    </div>
                </div>
                <div className='row'>
                    <div className='offset-lg-1 offset-md-1 col-12 col-md-11 col-lg-9'>
                        <Slider {...settings}>

                            <div className='aug-customer-items'>
                                <img src={require('../../../Assets/images/HeadShot/jeannie-kenkare.webp')} alt="Dr. Jeannie Kenkare" height={96} width={96} className='img-fluid rounded-circle' />
                                <div className='mb-4 mt-3'>
                                    <h3 className='aug-customer-items__name mb-0'>Dr. Jeannie Kenkare</h3>
                                    <h4 className='aug-customer-items__designation mb-0'>DO, Co-founder & Chief Medical Officer, PhyscianOne Urgent Care </h4>
                                </div>
                                <sub className='aug-customer-items__quote d-flex'>“</sub>
                                <div className='aug-customer-items__text'>
                                    We pride ourselves in partnering with innovative companies like Augnito that focus on saving a provider’s time and improving patient outcomes for all our stakeholders.
                                </div>
                            </div>

                            <div className='aug-customer-items'>
                                <img src={require('../../../Assets/images/HeadShot/david-peregrim.webp')} alt="David Peregrim" height={96} width={96} className='img-fluid rounded-circle' />
                                <div className='mb-4 mt-3'>
                                    <h3 className='aug-customer-items__name mb-0'>David Peregrim</h3>
                                    <h4 className='aug-customer-items__designation mb-0'>PA-C, Emergency Medicine</h4>
                                </div>
                                <sub className='aug-customer-items__quote d-flex'>“</sub>
                                <div className='aug-customer-items__text'>
                                    Its fast, efficient, comprehensive, and very user friendly. I have used other legacy VR solutions out there and Augnito sits high amongst them. It allowed me to document a lot faster and keep up with the high patient throughput at the clinic.
                                </div>
                            </div>

                            <div className='aug-customer-items'>
                                <img src={require('../../../Assets/images/HeadShot/gerardo-bonilla.webp')} alt="Dr. Gerardo Bonilla" height={96} width={96} className='img-fluid rounded-circle' />
                                <div className='mb-4 mt-3'>
                                    <h3 className='aug-customer-items__name mb-0'>Dr. Gerardo Bonilla</h3>
                                    <h4 className='aug-customer-items__designation mb-0'>MD, CEO and Founder, ChartNote</h4>
                                </div>
                                <sub className='aug-customer-items__quote d-flex'>“</sub>
                                <div className='aug-customer-items__text'>
                                    Since integrating voice- recognition powered by Augnito in ChartNote, physicians have since seen a reduction in the baggage of medical documentation. As a consequence, physician's ability to provide better health care has improved the new additions resulting from our partnership enable our medical software to provide next- generation artificial intelligence with the best in class accuracy.
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default CustomerSay;