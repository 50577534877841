import React from 'react';
import './OurPartner.scss'
import OurPartnerLogo from './OurPartnerLogo';
import OurPartnerLogoME from './OurPartnerLogoME';
import BaseComponent from '../../../Common/BaseComponent';
import OurPartnerLogoIN from './OurPartnerLogoIN';

export default class OurPartner extends BaseComponent {

    render() {
        // const { regionData } = this.state;
        var regionData = this.props.userRegionData;
        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <section className='aug-our-partner'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='offset-md-1 offset-lg-1 col-md-11 col-lg-10'>
                            <h2 className="aug-title mb-60">Trusted by</h2>
                            {
                                regionData === "IN" ? <OurPartnerLogoIN/> :
                                regionData === "ME" ? <OurPartnerLogoME/> :
                                <OurPartnerLogo/>
                            }
                        </div>
                    </div>
                </div>
            </section>
        );
    };
};
