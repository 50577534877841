import React from 'react';
import OfficeUSA from '../../../Assets/images/ContactUs/our-offices/usa.png';

const USA = () => {
    return (
        <div className='mb-60'>
            <div className='mb-3'>
                <img src={OfficeUSA} alt="USA" className='img-fluid'/>
            </div>
            <h3 className='mb-3 aug-our-offices__name'>USA</h3>
            <a href="tel:8447745585">+1 800-390-7163</a>
        </div>
    );
};

export default USA;