import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import IndividualImg from '../../../../Assets/images/features/versatiledeployment/on-premise.webp';

const OnPremise = () => {
    const [inIndex, setInIndex] = useState();
    let location = useLocation();
    useEffect(() => {
        setInIndex(location.pathname === "/spectra/features");
    }, [location]);

    return (
        <div className='aug-card'>
            <img src={IndividualImg} alt="Abstract earth with connectivity around it" className="w-100 img-fluid"/>
            <div className='aug-card-body p-4'>
                <div className={`${inIndex ? 'd-block' : 'd-none'}`}>
                    <h3 className='mb-3 aug-card-header'>On-premise networks</h3>
                    <p className='mb-0'>Spectra allows you to take complete control of your network environment. It can be easily be hosted in your data centres—whether they are self-hosted, on-premise servers or private clouds. Our high availability plan ensures that, in the unlikely event of a failure, the full workload is automatically shifted to another server—guaranteeing 24x7x365 availability & 99.99% uptime.</p>
                </div>
                <div className={`${inIndex ? 'd-none' : 'd-block'}`}>
                    <h3 className='mb-3 aug-card-header'>On-premise networks</h3>
                    <p className='mb-0'>Voice Services allows you to take complete control of your network environment. It can easily be hosted in users' data centres—whether they are self-hosted, on-premise servers or private clouds. Our high availability plan ensures that, in the unlikely event of a failure, the full workload is automatically shifted to another server—guaranteeing 24x7x365 availability & 99.99% uptime.</p>
                </div>
            </div>
        </div>
    );
};

export default OnPremise;