import React from 'react';
import SubNavbar from '../../Shared/SubNavbar/SubNavbar';
import PricingBanner from './PricingBanner/PricingBanner';
import PricingSubscription from './PricingSubscription/PricingSubscription';
import PricingDemo from './PricingDemo/PricingDemo';
import FAQ from './FAQ/FAQ';
import PricingTrusted from './PricingTrusted/PricingTrusted';
import PricingCustomer from './PricingCustomer/PricingCustomer';
import BaseComponent from '../../../Common/BaseComponent';
import SpectraPricingMeta from './Meta/SpectraPricingMeta';
import PricingBannerME from './PricingBanner/PricingBannerME';
import PricingBannerIN from './PricingBanner/PricingBannerIN';

export default class PlansPricing extends BaseComponent {

    render() {
        // const { regionData } = this.state;
        var regionData = this.props.userRegionData;
        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <div>
                <SubNavbar/>
                <SpectraPricingMeta />
                {
                    regionData === "ME" ? <PricingBannerME/> :
                    regionData === "IN" ? <PricingBannerIN/> :
                    <PricingBanner />
                }                
                <PricingSubscription isGeoLoading={this.props.isGeoLoading}
                            productPlanDetails={this.state.priceData}
                            innerProductListId={this.props.productListId}
                            isPriceLoading={this.state.isPriceLoading} />
                <PricingCustomer userRegionData={regionData}/>
                <PricingTrusted userRegionData={regionData}/>
                <FAQ />
                <PricingDemo />
            </div>
        );
    }
}