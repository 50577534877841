import React from 'react';
import {NavLink} from "react-router-dom";
import Img from '../../../../Assets/images/Spectra/FeaturesGlance/ME/macros.webp';

const MacrosME = () => {
    return (
        <div className='row gx-0 mt-60 align-items-lg-center flex-column-reverse flex-md-row'>
            <div className='col-12 col-md-5 col-lg-6'>
                <div className='ml-n40 aug-features-glance__img aug-features-glance__img-border'>
                    <img src={Img} alt="Screen showing macros window" className='w-100 img-fluid'/>
                </div>
            </div>
            <div className='col-12 col-md-6 col-lg-4 pl-40'>
                <h3 className='aug-features-glance__heading mb-3'>Macros</h3>
                <p className='mb-0'>
                Save frequently repeating paragraphs as a phrase and reduce dictation time even further.
                </p>
                <div className="mt-40">
                    <NavLink to="/schedule-demo" className='btn btn-outline-primary'>
                        Book a demo  
                    </NavLink>
                </div>
            </div>
            
        </div>
    );
};

export default MacrosME;