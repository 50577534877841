import React from 'react';
import {ReactComponent as Mic} from '../../../../Assets/images/icons/microphone.svg';

const TestFree = () => {
    return (
        <div className='mt-60'>
            <div className='aug-speech-models__icon d-flex align-items-center justify-content-center'>
                <Mic/>
            </div>
            <h3 className='mb-3 aug-speech-models__heading'>Test it out for free</h3>
            <p>Avail of a complimentary trial or demo to better understand the benefits of Spectra.</p>
        </div>
    );
};

export default TestFree;