import React from 'react';

const PatientME = () => {
    return (
        <div className='row gx-40 flex-column-reverse flex-md-row'>
            <div className='offset-lg-1 offset-md-1 col-md-6 col-lg-5'>
                <div>
                    {/* <h3 className='aug-tab-content__heading mb-2'>Patients</h3> */}
                    <h3 className='mb-4 aug-tab-content__subheading'>Drive patient engagement</h3>
                    <p className='mb-0'>Experience a more meaningful appointment with physicians equipped to take more detailed notes, leading to better care and treatment. </p>
                </div>
            </div>
            <div className='offset-lg-1 col-md-5 col-lg-4'>
                <img src={require('../../../Assets/images/home/PositiveStake/ME/positive-stake-4.webp')} height="auto" width="100%" alt="Physician typing on desktop using Augnito" className='img-fluid  mb-4 mb-md-0 pb-2 pb-md-0'/>
            </div>
        </div>
    );
};

export default PatientME;