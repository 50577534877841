import React from 'react';
import './UnrivalledExperience.scss'
import EffortlessDocumentation from './EffortlessDocumentation';
import EnhancedInteractions from './EnhancedInteractions';
import UserExperience from './UserExperience';
import ReductionEfficiency from './ReductionEfficiency';


const UnrivalledExperience = () => {

    return (
        <section className='aug-features-glance aug-unrivalled-experience mt-60'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='offset-md-1 col-12 col-md-10'>
                        <h2 className='aug-title mb-0'>An unrivalled experience</h2>
                    </div>
                </div>
                <EffortlessDocumentation/>
                <EnhancedInteractions/>
                <UserExperience/>
                <ReductionEfficiency/>
            </div> 
        </section>
    );
};

export default UnrivalledExperience;