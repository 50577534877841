import React from 'react';
import Healthcare from './Healthcare';
import OperationAdministratorME from './OperationAdministratorME';
import PhysicianME from './PhysicianME';
import PatientME from './PatientME';

const TabContentME = () => {
    return (
        <div className="tab-content" id="pills-tabContent">
             <div className="tab-pane fade show active" id="operation-administrator" role="tabpanel" aria-labelledby="operation-administrator-tab">
                <OperationAdministratorME/>
            </div>
            <div className="tab-pane fade" id="physicians" role="tabpanel" aria-labelledby="physicians-tab">
                <PhysicianME/>
            </div>
            <div className="tab-pane fade" id="Healthcare-IT" role="tabpanel" aria-labelledby="Healthcare-IT-tab">
                <Healthcare/>
            </div>
            <div className="tab-pane fade" id="patient" role="tabpanel" aria-labelledby="patient-tab">
                <PatientME/>
            </div>
        </div> 
    );
};

export default TabContentME;