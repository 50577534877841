import React from 'react';
import {ReactComponent as Microphone} from '../../../../Assets/images/icons/microphone.svg';

const VoiceNavigationME = () => {
    return (
        <div className='mt-60'>
            <div className='aug-speech-models__icon d-flex align-items-center justify-content-center'>
                <Microphone/>
            </div>
            <h3 className='mb-3 aug-speech-models__heading'>Voice navigation & control</h3>
            <p className='mb-0'>Intuitive voice commands for your preferred HIS/EMR/PACS/RIS software.</p>
        </div>
    );
};

export default VoiceNavigationME;