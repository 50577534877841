import React from 'react';
import Hero from '../../../../Assets/images/VoiceServices/features/banner.webp';
import { RedirectToSignUpVoiceServices } from '../../../../Common/Utils';

const FeaturesBanner = () => {
    return (
        <section className='aug-banner-features'>
            <div className='aug-banner-features-img position-relative'>
                <img src={Hero} alt="banner" className='img-fluid'/>
                <div className='aug-banner-features-content position-absolute d-flex align-items-center'>
                    <div className='container-fluid'>
                        <div className="row">
                            <div className='offset-lg-2 offset-md-1 col-md-10 col-lg-8'>
                                <h1 className='mb-3'>Optimize Clinical Workflow with advanced Voice AI APIs & SDKs</h1>
                                <p>Easy-to-Integrate Automatic Speech Recognition & Natural Language Processing services to enhance your clinical software.</p>
                                <button type='button' className='btn btn-white' onClick={RedirectToSignUpVoiceServices}>
                                Try for free
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
           </div>
        </section>
    );
};

export default FeaturesBanner;