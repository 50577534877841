import React from 'react';
import OfficeLondon from '../../../Assets/images/ContactUs/our-offices/london.png';

const London = () => {
    return (
        <div className='mb-60'>
            <div className='mb-3'>
                <img src={OfficeLondon} alt="London" className='img-fluid'/>
            </div>
            <h3 className='mb-3 aug-our-offices__name'>London, UK</h3>
            <p className='mb-3'>Penhurst House, 352-356 Battersea Park Road, London, SW11, 3BY, United Kingdom.</p>
            <a href="tel:02086590800">+44 020-865-90800</a>
        </div>
    );
};

export default London;