import React from 'react';
import APISpecification from './APISpecification';
import ComprehensiveDocumentation from './ComprehensiveDocumentation';
import Sandbox from './Sandbox';
import StreamlinedSDK from './StreamlinedSDK';
import ComprehensiveDocumentationME from './ComprehensiveDocumentationME';
import BaseComponent from '../../../../Common/BaseComponent';
import ComprehensiveDocumentationIN from './ComprehensiveDocumentationIN';

export default class DeveloperPortal extends BaseComponent {

    render() {
        // const { regionData } = this.state;
        var regionData = this.props.userRegionData;
        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <section className='aug-career-about mb-60'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-12 offset-md-1 col-md-11 offset-lg-2 col-lg-9'>
                                <h2 className='aug-title mb-60'>Explore our Developer Portal</h2>
                                <div className='row gx-40'>
                                    <div className='col-12 col-md-6'>
                                        <Sandbox/>
                                    </div>
                                    <div className='col-12 col-md-6'>
                                        {
                                            regionData === "ME" ? <ComprehensiveDocumentationME/> :
                                            regionData === "IN" ? <ComprehensiveDocumentationIN/> :
                                            <StreamlinedSDK/>
                                        }
                                    </div>
                                    <div className='col-12 col-md-6'>
                                        <APISpecification/>
                                    </div>
                                    <div className='col-12 col-md-6'>
                                        {
                                            regionData === "ME" ? <StreamlinedSDK/> :
                                            regionData === "IN" ? <StreamlinedSDK/> :
                                            <ComprehensiveDocumentation/>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </section>
        );
    };
};