import React from 'react';
import {ReactComponent as Chart} from '../../../../Assets/images/icons/book.svg';

const MedicalVocabularyIN = () => {
    return (
        <div className='mt-60'>
            <div className='aug-speech-models__icon d-flex align-items-center justify-content-center'>
                <Chart/>
            </div>
            <h3 className='mb-3 aug-speech-models__heading'>Updated medical vocabulary</h3>
            <p>Spectra’s speech recognition AI is self-learning from millions of dictations across the world. It is also regularly updated to recognise newly-approved drugs and procedures. </p>
        </div>
    );
};

export default MedicalVocabularyIN;