import React from 'react';
import {NavLink} from "react-router-dom";
import { FormatCurrencyCode } from '../../../../Common/Utils';
import { SetSignupURL } from '../../../../Common/Utils';

const SubscriptionPlanName = (props) => {
    if (props.planData === undefined || props.selectedBillingCycle === undefined) {
        return;
    }

    var planSelectedPrice = undefined
    var selectedMonthlyPlan = undefined;
    if (props.planData.Prices !== undefined && props.planData.Prices !== null && props.planData.Prices.length > 0) {

        var filterPlan = props.planData.Prices.filter(f => f.Key === props.selectedBillingCycle);
        if (filterPlan !== undefined && filterPlan.length > 0) {
            planSelectedPrice = filterPlan[0].Value;

            if (props.planData.Prices[0].Key !== planSelectedPrice.BillingCycle) {
                var monthlyPlan = undefined;
                monthlyPlan = props.planData.Prices.filter(f => f.Key !== props.selectedBillingCycle);
                if (monthlyPlan !== undefined && monthlyPlan.length > 0) {
                    monthlyPlan.sort(f => parseInt(f.PerMonthFinalAmount));
                    monthlyPlan.reverse();
                    selectedMonthlyPlan = monthlyPlan[0].Value;
                    if (selectedMonthlyPlan.BillingCycle === planSelectedPrice.BillingCycle) {
                        selectedMonthlyPlan = undefined;
                    }
                }
            }
        }
    }    

    return (
       <section className='aug-subscription-plan-name'>
            <div className='aug-subscription-plan-head text-center p-4'>
                <h3 className='mb-2 aug-title'>{props.planData.Header}</h3>
                <p className='mb-0 text-center'>{props.planData.SubHeader}</p>
            </div>

            <div className='aug-subscription-plan-body p-4'>
                <div className='pt-4 pt-md-2 pt-lg-4 pb-4 mb-md-2 mb-lg-0 text-center'>
                    {
                        props.planData.IsCustomPrice || planSelectedPrice === undefined ?
                            <span>
                                <h4 className='mb-2 aug-subscription-plan-price'>Custom</h4>
                                {
                                    (props.selectedBillingCycle !== "Monthly") ?
                                        <p className='mb-0 text-secondary aug-subscription-plan-billed'>tailor-made plans</p> : <p className='mb-0 text-secondary aug-subscription-plan-billed'></p>
                                }
                            </span>
                            :
                            <span>                                
                                <h4 className='mb-2 aug-subscription-plan-price'> {FormatCurrencyCode(props.currencyCode)}{planSelectedPrice.PerMonthFinalAmount}/m</h4>
                                {
                                    selectedMonthlyPlan !== undefined ?
                                        <p className='mb-0 text-secondary aug-subscription-plan-billed'>billed annually <del>{FormatCurrencyCode(props.currencyCode)}{parseInt(selectedMonthlyPlan.PerMonthFinalAmount) * 12}</del> {FormatCurrencyCode(props.currencyCode)}{parseInt(planSelectedPrice.PerMonthFinalAmount) * 12}</p>
                                        :
                                        <p className='mb-0 text-secondary aug-subscription-plan-billed'>&nbsp;</p>
                                }                                
                            </span>
                    }
                </div>
                <div className='d-grid'>
                    {
                        props.planData.IsCustomPrice || (planSelectedPrice === undefined || planSelectedPrice === null)?
                            <NavLink to="/contact-sales" className='btn btn-primary'>
                                Contact sales
                            </NavLink>
                            :
                            <a href={SetSignupURL(props.productPublicId, planSelectedPrice.PlanId, planSelectedPrice.PlanPriceId)} className='btn btn-primary'>
                                Start free trial
                            </a>
                    }
                    
                </div>
                <div className='pt-1 mt-4'>
                    <p className='mb-3 aug-subscription-plan-type'> {props.planData.PreviousPlanInOrder !== "" ? "Everything in " + props.planData.PreviousPlanInOrder + " plan, plus:" : <span className='mt-n3 d-block'></span>}</p>
                    <ul className='list-unstyled aug-subscription-plan-list mb-0'>
                        {
                            props.planData.MainFeature.map((item, index, data) => {
                                return (<li className='text-secondary position-relative' key={index}>
                                    {item.FeatureName}
                                </li>)
                            })
                        }                        
                    </ul>
                </div>
            </div>
       </section>
    );
};

export default SubscriptionPlanName;
