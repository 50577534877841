import React from 'react';
import Hero from '../../../../Assets/images/Ambient/overview/Hero-Image-Ambient-Page.webp';
import { NavigateToInternalPage } from '../../../../Common/Utils';

const AmbientBanner = () => {
    return (
        <section className='aug-spectra-banner aug-banner'>
            <div className='aug-banner-img position-relative'>
                <img src={Hero} alt="Ambient Banner" className='img-fluid' />
                <div className='aug-banner-content aug-spectra-banner-content position-absolute d-flex align-items-center'>
                    <div className='container-fluid'>
                        <div className="row">
                            <div className='offset-md-1 col-md-10'>
                                <h1 className='mb-3'>Generate structured medical notes from natural conversation</h1>
                                <p>AI-powered Ambient Clinical Intelligence that helps healthcare providers document patient encounters and automate data-structuring tasks. </p>
                                <button type='button' className='btn btn-white' onClick={NavigateToInternalPage("/schedule-demo-ambient")}>
                                    Request demo
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AmbientBanner;