import React from 'react';
import './AmbientIntelligence.scss'
import TangibleBenefits from './TangibleBenefits';
import ConvertDocumentation from './ConvertDocumentation';

const AmbientIntelligence = () => {

    return (
        <section className='aug-ambient-intelligence'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='offset-md-1 col-12 col-md-10'>
                        <h2 className='aug-title mb-0'>Meet Ambient Clinical Intelligence</h2>
                    </div>
                </div>
                <TangibleBenefits/>
                <div className='pt-40'>
                    <ConvertDocumentation/>
                </div>
            </div> 
        </section>
    );
};

export default AmbientIntelligence;