import React from 'react';
import CareerBanner from './CareerBanner/CareerBanner';
import CareerAbout from './CareerAbout/CareerAbout';
import CollageGallery from './CollageGallery/CollageGallery';
import KekaCareer from './KekaCareer/KekaCareer';
import CareerFlexibility from './CareerFlexibility/CareerFlexibility';
import CareerContact from './CareerContact/CareerContact';
import BaseComponent from '../../Common/BaseComponent'
import CareersMeta from './Meta/CareersMeta';
import CareerBannerME from './CareerBanner/CareerBannerME';
import CareerBannerIN from './CareerBanner/CareerBannerIN';

export default class Careers extends BaseComponent {
    render() {
        
        var regionData = this.props.userRegionData;
        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <main>
                <CareersMeta />
                {
                    regionData === "ME" ? <CareerBannerME/> :
                    regionData === "IN" ? <CareerBannerIN/> :
                    <CareerBanner />
                }
                <CollageGallery />
                <CareerAbout userRegionData={regionData} />
                <CareerFlexibility />
                <KekaCareer />
                <CareerContact />
            </main>
        );
    }
}
