import React from 'react';
import {ReactComponent as Chart} from '../../../../Assets/images/icons/chart-line.svg';

const ProviderSatisfaction = () => {
    return (
        <div className='mt-60'>
            <div className='aug-choose-us__icon d-flex align-items-center justify-content-center'>
                <Chart/>
            </div>
            <h3 className='mb-3 aug-choose-us__heading'>Increase provider satisfaction</h3>
            <p>Reduce administrative burden, physician burnout and stress from time spent doing paperwork. Enable physicians to spend more time with patients.</p>
        </div>
    );
};

export default ProviderSatisfaction;