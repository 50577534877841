import React from 'react';
import './processing.scss'
import {ReactComponent as ProcessingIcon} from '../../../Assets/images/icons/processing.svg';

const Processing = () => {

    return(
        <section className="aug-detecting-location aug-processing-element">
            <div className='aug-detecting-location-box'>
                <div className='aug-detecting-location-icon mt-0'>
                    <ProcessingIcon/>
                </div>
            </div>
            <p className='mb-0'>Processing
                <span className="dot-one">.</span>
                <span className="dot-two">.</span>
                <span className="dot-three">.</span>
            </p>
        </section>
    );
};

export default Processing;