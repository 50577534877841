import React from 'react';
import BaseComponent from '../../../Common/BaseComponent';
import RetentionPolicyMeta from './RetentionPolicyMeta';

export default class RetentionPolicy extends BaseComponent {
    render() {

        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <>
                <RetentionPolicyMeta/>
                <section className='aug-legal-page mt-60'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-12 offset-md-1 col-md-10 offset-lg-2 col-lg-8'>
                                <div className='aug-legal-page-header'>
                                    <h1 className='aug-legal-page-title mb-3'>Data Retention Policy</h1>
                                    <p className='mb-0 text-secondary'>Last updated: 29th June, 2023</p>
                                </div>
                                <div className='mb-4'>
                                    <h3 className='aug-legal-page-subtitle'>Purpose</h3>
                                    <p className='mb-0'>The Purpose of the policy is to set out the principles for retaining and destroying specified categories of data. In course of Providing Services, Augnito India pvt. Ltd. may receive, store, and manage sensitive data on the systems. Due to Contractual, legal and regulatory obligations, Augnito maintains a strict confidentiality of data all the times. This policy communicates expectations with respect to the transmittal, storage, processing, retention and disposal of data provided to Augnito in the course of business.</p>
                                </div>
                                <div className='mb-4'>
                                    <h3 className='aug-legal-page-subtitle'>Scope</h3>
                                    <p className='mb-0'>The scope of this data retention and destruction policy is over all information technology systems, software, databases, applications and network resources needed by the Company to conduct its business. The policy is applicable to all Company employees, contractors and other authorized third-party organizations.</p>
                                </div>
                                <div className='mb-4'>
                                    <h3 className='aug-legal-page-subtitle'>Statement of Compliance</h3>
                                    <p className='mb-0'>Data retention and destruction policy compliance is managed by the Information Technology (IT) department, with support from the leadership and subject matter experts. The Information Technology (IT) department is responsible for managing all data retention and destruction activities for the Company. Other departments, such as Finance and Accounting, Operations and Human Resources, are also responsible for providing the IT department with their requirements for data retention and destruction. The IT department is responsible for developing, executing and periodically testing data retention and destruction procedures. The IT department also acknowledges it will comply with appropriate industry standards for data retention and destruction in its activities.</p>
                                </div>
                                <div>
                                    <h3 className='aug-legal-page-subtitle'>Policy</h3>
                                    <div className='mb-3'>
                                        <p className='mb-0'><strong>1. Data Protection</strong></p>
                                        <p className='mb-0'>The use of data shall always be treated with utmost care as safeguarding the data is critical. The data will be:</p>
                                        <p className='mb-0'><strong>1.1. </strong> Used lawfully, fairly and in a transparent way.</p>
                                        <p className='mb-0'><strong>1.2. </strong> Collected only for valid purposes and not used in any way that is incompatible with those purposes.</p>
                                        <p className='mb-0'><strong>1.3. </strong> Accurate and kept up to date.</p>
                                        <p className='mb-0'><strong>1.4. </strong> Retained only for as long as necessary to fulfill contractual obligations associated with it, in consonance with applicable law</p>
                                        <p className='mb-0'><strong>1.5. </strong> All PHI (Personal Health Information) to be anonymized in compliance with provisions of Hipaa and other applicable Data Privacy regulations</p>
                                        <p className='mb-0'><strong>1.6. </strong> Kept securely and protected against unauthorized or unlawful processing and against loss or destruction using appropriate technical and organizational measures.</p>
                                        <p className='mb-0'><strong>1.7. </strong> Not disclosed to any third party without any prior written consent.</p>
                                    </div>
                                    <div className='mb-3'>
                                        <p className='mb-0'><strong>2. Data Processing</strong></p>
                                        <p className='mb-0'>While processing the data, following requirements are adhered to in order to maintain data confidentiality:</p>
                                        <p className='mb-0'><strong>2.1. </strong> The anonymity of the data is maintained while processing it.</p>
                                        <p className='mb-0'><strong>2.2. </strong> The data is only used for the purpose it has been acquired.</p>
                                        <p className='mb-0'><strong>2.3. </strong> Augnito ensures that it uses data only for the purpose of providing services & for training its AI. The data fed to AI is also kept anonymous.</p>
                                    </div>
                                    <div className='mb-3'>
                                        <p className='mb-0'><strong>3. Data Access</strong></p>
                                        <p className='mb-0'>To ensure the confidentiality of data processed by Augnito, access to the data will only be restricted and as per the following guidelines:</p>
                                        <p className='mb-0'><strong>3.1. </strong> Access to data will only be granted to the Augnito employees who have legitimate purpose for such data.</p>
                                        <p className='mb-0'><strong>3.2. </strong> Only a minimum access will be granted as to accomplish the assigned task.</p>
                                    </div>
                                    <div className='mb-3'>
                                        <p className='mb-0'><strong>4. Data Retention & Destruction</strong></p>
                                        <p className='mb-0'>The data acquired shall not be retained beyond completion of its purpose. Therefore, All copies of data will be securely deleted after the purpose is achieved. Please note that no user data is retained or stored beyond 90 (ninety ) days.</p>
                                    </div>
                                    <div className='mb-3'>
                                        <p className='mb-0'><strong>5. Data</strong></p>
                                        <p className='mb-0'>There are two types of Data which Augnito Processes:</p>
                                        <p className='mb-0'><strong>5.1. </strong> PHI – Personal Health Information</p>
                                        <p className='mb-0'><strong>5.2. </strong> PII – Personal Identity Information</p>
                                    </div>
                                    <div>
                                        <p className='mb-3'>This data is strictly kept anonymous while processing.</p>
                                        <p>
                                            <strong>Note: </strong>
                                            The user id and details required for initial sign up for our services are not anonymized as these need to be identifiable to provide services. The use of this data is limited to the purpose of providing services and sending updates &amp; promotional emails.  However, the user can request for deletion/rectification of the their personal data by mailing on <a target="_blank" rel="noreferrer" href="mailto: legal@augnito.ai"><u>legal@augnito.ai</u></a> or can contact the Data Protection Officer Aakanksha Yadav at <a target="_blank" rel="noreferrer" href="mailto: aakanksha.yadav@augnito.ai"><u>aakanksha.yadav@augnito.ai</u></a>. For any further information they need in this regard.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}