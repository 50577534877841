import React from 'react';
import ImgSecure from '../../../../Assets/images/VoiceServices/overview/secure-compliant.webp';
import {ReactComponent as Tick} from '../../../../Assets/images/icons/tick.svg';
import './SecureCompliant.scss'


const SecureCompliantME = () => {
    return (
        <section className='aug-voice-secure-compliant mt-60'>
           <div className='container-fluid'>
                <div className='row'>
                    <div className='offset-md-1 offset-lg-2 col-12 col-md-6 col-lg-4'>
                        <h2 className='aug-title mb-0'>Secure & compliant</h2>
                    </div>
                </div>
                <div className='row gx-40 mt-60 align-items-lg-center'>
                    <div className='col-12 col-md-5 col-lg-6'>
                        <div className='ml-n40'>
                            <img src={ImgSecure} alt="Hand with compliance logo resting vertically on top of it" className='w-100 img-fluid rounded-rtb-10 aug-bookdemo-secure-compliant__img'/>
                        </div>
                    </div>
                    <div className='col-12 col-md-7 col-lg-4'>
                        <ul className="list-unstyled aug-tick-list mb-0">
                            <li className="mt-3">
                                <Tick className='me-3' width={30} height={15}/>
                                HIPAA compliant
                            </li>
                            <li className="mt-3">
                                <Tick className='me-3' width={30} height={15}/>
                                ISO 27001 compliant
                            </li>
                            <li className="mt-3">
                                <Tick className='me-3' width={30} height={15}/>
                                SOC 2 compliant
                            </li>
                            <li className="mt-3">
                                <Tick className='me-3' width={30} height={15}/>
                                GDPR Compliant
                            </li>
                            <li className="mt-3">
                                <Tick className='me-3' width={30} height={15}/>
                                PHI anonymisation
                            </li>
                            <li className="mt-3">
                                <Tick className='me-3' width={30} height={15}/>
                                256-bit encrypted connection
                            </li>
                        </ul>
                    </div>
                </div>
           </div> 
        </section>
    );
};

export default SecureCompliantME;