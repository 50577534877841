import React from 'react';

const KeyboardShortcuts = () => {
    return (
        <div className='row gx-40 flex-column-reverse flex-md-row'>
            <div className='offset-lg-2 offset-md-1 col-md-6 col-lg-5'>
                <div>
                    <h3 className='mb-4 aug-tab-content__subheading'>More efficient workflows</h3>
                    <p className='mb-0'>Pre-configured keyboard shortcuts are available for frequently used features. They can also be modified as per your preferences.</p>
                </div>
            </div>
            <div className='col-md-5 col-lg-4 pb-2 pb-md-0'>
                <img src={require('../../../../Assets/images/features/HyperPersonalization/keyboard-shortcuts.webp')} alt="Augnito Spectra Medical Speech Recognition Software : Keyboard Shortcuts Editor Window" className='img-fluid mb-4 mb-md-0'/>
            </div>
        </div>
    );
};

export default KeyboardShortcuts;