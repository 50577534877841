import React from 'react';
import Img from '../../../Assets/images/home/data-security.webp';
import './DataSecurity.scss';
import { NavigateToInternalPage } from '../../../Common/Utils';

const DataSecurity = () => {
    return (
       <section className='aug-data-security'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='offset-lg-1 offset-md-1 col-md-11 col-lg-8'>
                        <h2 className='aug-title mb-60'>Secure & compliant</h2>
                    </div>
                </div>
                <div className='row gx-40 flex-column-reverse flex-md-row'>
                    <div className='offset-lg-1 offset-md-1 col-12 col-md-7 col-lg-6'>
                        <h3 className='mb-3 aug-data-security__heading'>Data security is embedded in our product development process</h3>
                        <p className='mb-4'>At Augnito, we are committed to the highest level of data security across all stages of our production cycle. Our commitment to safeguarding information is enabled through the incorporation of several industry-standard encryption technologies, as well as stringent, recurring audits of security features within our systems and policies.</p>
                        <ul className='list-unstyled align-items-center d-flex flex-wrap'>
                            <li>
                                <img src={require('../../../Assets/images/Compliance/Dark/hipaa.webp')} className="img-fluid" height={48} width={90} alt="hipaa logo"/>
                            </li>
                            <li>
                                <img src={require('../../../Assets/images/Compliance/Dark/aicpa.webp')} className="img-fluid" height={60} width={60} alt="aicpa soc2 logo"/>
                            </li>
                            <li>
                                <img src={require('../../../Assets/images/Compliance/Dark/iso-27001.webp')} className="img-fluid" height={60} width={60} alt="iso27001 logo"/>
                            </li>                            
                            {/* <li>
                                <img src={require('../../../Assets/images/Compliance/Dark/cyber.webp')} className="img-fluid" height={57} width={65} alt="iso logo"/>
                            </li>
                            <li>
                                <img src={require('../../../Assets/images/Compliance/Dark/iso-news.webp')} className="img-fluid" height={57} width={57} alt="iso logo"/>
                            </li>
                            <li>
                                <img src={require('../../../Assets/images/Compliance/Dark/iso.webp')} className="img-fluid" height={57} width={57} alt="iso logo"/>
                            </li> 
                            <li>
                                <img src={require('../../../Assets/images/Compliance/Dark/eugdpr.webp')} className="img-fluid" height={57} width={132} alt="eu gdpr logo"/>
                            </li> */}
                        </ul>
                        <button type="button" className="btn btn-primary" onClick={NavigateToInternalPage("/schedule-demo")}>Book demo for Spectra</button>
                    </div>
                    <div className='col-12 offset-lg-1 col-md-4 col-lg-4'>
                        <div className='aug-spectra-tool__img mr-n40'>
                            <img src={Img} height="auto" width="100%" alt="Abstract data security and deployment process" className="img-fluid rounded-ltb-10 w-100"/>
                        </div>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default DataSecurity;
