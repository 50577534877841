import React from 'react';
import HealthcareImg from '../../../Assets/images/AboutUs/TheVoice/healthcare-AI.webp';

const HealthcareAI = () => {
    return (
        <div className='aug-card'>
            <img src={HealthcareImg} alt="Rustom Lawyer" className="w-100 img-fluid"/>
            <div className='aug-card-body p-4'>
                <h3 className='mb-3 aug-card-header'>Healthcare AI: The New Oil Barons</h3>
                <p className='mb-0'>How 20 years of experience in clinical transcription led to the genesis of a virtual assistant for every physician</p>
            </div>
        </div>
    );
};

export default HealthcareAI;