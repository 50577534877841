import React from 'react';
import Img from '../../../../Assets/images/Spectra/OurReach/ME/mobile-mic.webp';
import { RedirectToSignUp } from '../../../../Common/Utils';

const MobileMicME = () => {
    return (
        <div className="row gx-40 mt-60 align-items-center flex-column-reverse flex-md-row">
            <div className="offset-lg-2 offset-md-1 col-md-7 col-lg-6">
                <h3 className='mb-3 aug-our-reach__heading'>
                Free mobile mic app
                </h3>
                <p className='mb-0'>There is no need to spend on expensive microphone hardware. The Augnito Mic app converts any smartphone into a wireless microphone that can pair with any workstation.</p>
                <div className='mt-40'>
                    <button type="button" className="btn btn-primary" onClick={RedirectToSignUp}>Try Spectra for free</button>
                </div>
            </div>
            <div className="col-12 col-md-4 col-lg-4">
            <div className='mr-n40 aug-our-reach__img pb-0 ps-0'>
                    <img src={Img} alt="Mobile screen with Augnito mic app opened" className='w-100 img-fluid'/>
                </div>
            </div>
            
        </div>         
    );
};

export default MobileMicME;