import React from 'react';
import './Header.scss'
import Navbar from '../Navbar/Navbar';
import BaseComponent from '../../../Common/BaseComponent'
import DetectingLocation from '../DetectingLocation/DetectingLocation';
import DetectingLocationError from '../DetectingLocation/DetectingLocationError';

export class Header extends BaseComponent {
    
    render() {
        var pathname = window.location.pathname;
        var className = "";
        if (pathname === "/spectra" || pathname === "/spectra/features" || pathname === "/spectra/pricing" || pathname === "/voice-services" || pathname === "/voice-services/features" || pathname === "/ambient" ) {
            className = "product-sticky";
        }
        if (!this.props.isGeoRequestSuccess) {
            return <div className="App">
                <DetectingLocationError />
            </div>;
        } else if (this.props.isGeoLoading) {
            return <div className="App">
                <DetectingLocation />
            </div>;
        }
        return (
            <header className={`aug-sticky-header sticky-top ${className}`} id="augnito-main-header">
                <Navbar />                
            </header>
        );
    }
}

export default Header;