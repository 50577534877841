import React from 'react';

const SuccessfullyIntegrated = () => {
    return (
        <section className='aug-secure-compliant mt-60'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='offset-md-1 offset-lg-2 col-md-11 col-lg-10'>
                        <h2 className="aug-title mb-60">Successfully integrated with</h2>
                        <ul className='list-unstyled mb-0 d-flex flex-wrap'>
                            <li>
                                <img src={require('../../../../Assets/images/PartnersLogo/chartnote.webp')} alt="chartnote logo" className="img-fluid p-10"/>
                            </li>
                            <li>
                                <img src={require('../../../../Assets/images/PartnersLogo/fujifilm.webp')} alt="fujifilm logo" className="img-fluid p-10"/>
                            </li>
                            <li>
                                <img src={require('../../../../Assets/images/PartnersLogo/carpl.webp')} alt="carpl logo" className="img-fluid p-10"/>
                            </li>
                            <li>
                                <img src={require('../../../../Assets/images/PartnersLogo/Intelerad.webp')} alt="intelerad logo" className="img-fluid p-10"/>
                            </li>
                            <li>
                                <img src={require('../../../../Assets/images/PartnersLogo/paxera-health.webp')} alt="paxera-health logo" className="img-fluid p-10"/>
                            </li>
                            <li>
                                <img src={require('../../../../Assets/images/PartnersLogo/wellbeing.webp')} alt="wellbeing logo" className="img-fluid p-10"/>
                            </li>
                            <li>
                                <img src={require('../../../../Assets/images/PartnersLogo/patient-source.webp')} alt="patient-source logo" className="img-fluid p-10"/>
                            </li>                            
                            <li>
                                <img src={require('../../../../Assets/images/PartnersLogo/hexarad.webp')} alt="hexarad logo" className="img-fluid p-10"/>
                            </li>                            
                            <li>
                                <img src={require('../../../../Assets/images/PartnersLogo/medweb.webp')} alt="medweb logo" className="img-fluid p-10"/>
                            </li>
                            <li>
                                <img src={require('../../../../Assets/images/PartnersLogo/open-rad.webp')} alt="open-rad logo" className="img-fluid p-10"/>
                            </li>
                        </ul>  
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SuccessfullyIntegrated;