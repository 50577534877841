import React from 'react';
import BaseComponent from '../../../Common/BaseComponent';
import TermsOfUseMeta from './TermsOfUseMeta';

export default class TermsOfUse extends BaseComponent {
    render() {

        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <>
                <TermsOfUseMeta/>
                <section className='aug-legal-page mt-60'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-12 offset-md-1 col-md-10 offset-lg-2 col-lg-8'>
                            <div className='aug-legal-page-header'>
                                <h1 className='aug-legal-page-title mb-3'>Augnito General Terms of Use</h1>
                                <p className='mb-0 text-secondary'>Effective as of 29th June 2023. These Terms replace and supersede all prior versions. These Terms will supersede any offline contracts or terms as mutually agreed by the parties.</p>
                            </div>
                            <div className='mb-4'>
                                <h3 className='aug-legal-page-subtitle'>1. Definitions</h3>
                                <p className='mb-3'>
                                In this Agreement, unless otherwise stated or unless the context otherwise requires, the words and expressions beginning with capital letters (other than clause headings) shall have the meaning set out below:
                                </p>
                                <p className='mb-3'><strong>1.1. </strong>
                                “Affiliate” means any entity that directly or indirectly Controls, is Controlled by, or is under common Control of the subject entity. “Control” for purposes hereof, means direct or indirect ownership or control of more than 50% (Fifty Percent) of the voting interests of the subject entity.
                                </p>
                                <p className='mb-3'><strong>1.2. </strong>
                                “Authorized Partners” means independent contractors including Users who integrate our Product and sell further.
                                </p>
                                <p className='mb-3'><strong>1.3. </strong>
                                “Augnito Technology” means Augnito proprietary software, technology, frameworks, platforms, methodologies, facilitation guides, techniques, general purpose consulting and related know-how, logic, coherence, and methods of operation of systems, user interfaces, screen designs, presentation materials, and best-practices documentation, including any enhancements, modifications, or derivatives thereof, which are provided to User.
                                </p>
                                <p className='mb-3'><strong>1.4. </strong>
                                “User” means an individual authorized by Augnito to use and or sell the Products including, Enterprise Clients, Authorized Partners, Distributors, Resellers and End Users.
                                </p>
                                <p className='mb-3'><strong>1.5. </strong>
                                “Enterprise Clients” mean Companies/Organization/Hospitals who purchase our Products.
                                </p>
                                <p className='mb-3'><strong>1.6. </strong>
                                “End User” means an Individual or Company who is the eventual User of the Product, which may be purchased directly with Augnito or through an Authorized Partner, Distributor or Reseller.
                                </p>
                                <p className='mb-3'><strong>1.7. </strong>
                                “User Data” means all the data, records, files, images, graphics, audio, video, photographs, reports, forms and other content and material, in any format, that are submitted, stored, posted, displayed, transmitted, or otherwise used with the Augnito Technology. It may interchangeably be used as Your Data.
                                </p>
                                <p className='mb-3'><strong>1.8. </strong>
                                “Documentation” means Augnito’ s product guides and other end user documentation for the Services made available to the User, as may be updated by Augnito from time to time to reflect the then-current Services.
                                </p>
                                <p className='mb-3'><strong>1.9. </strong>
                                “Administrators” mean the personnel designated by you who administer the Products to End Users on their behalf. Administrators include Authorized Contractors, Resellers and Distributors.
                                </p>
                                <p className='mb-3'><strong>1.10. </strong>
                                “Products” means any and all products offered by Augnito including Augnito Spectra and Augnito Voice Services.
                                </p>
                                <p className='mb-3'><strong>1.11. </strong>
                                “Subscription Term” mean the period for which the Product services are availed, which will depend upon the Subscription Plan availed by the User.
                                </p>
                                <p className='mb-3'><strong>1.12. </strong>
                                “Augnito Subscription Terms” mean additional terms which will apply depending upon the Subscription Term.
                                </p>
                                <p className='mb-3'><strong>1.13. </strong>
                                “Subscription Fee” mean fee paid by the User for availing Product services limited to a pre-defined Subscription Term.
                                </p>
                                <p className='mb-3'><strong>1.14. </strong>
                                “Feedback” means comments, questions, ideas, suggestions, or other feedback relating to the Products, Support or Additional Services.
                                </p>
                                <p className='mb-3'><strong>1.15. </strong>
                                “HIPAA” means the Health Insurance Portability and Accountability Act, as amended and supplemented.
                                </p>
                                <p className='mb-3'><strong>1.16. </strong>
                                “Notification Email Address” means the email address(es) you used to register for the Product account or otherwise sign up for a Product. It is the responsibility of the User to keep email address(es) valid and current so that Augnito can share notices, statements, and other information as required under the Terms of this Agreement.
                                </p>
                                <p className='mb-3'><strong>1.17. </strong>
                                “PO” means a purchase order
                                </p>
                                <p className='mb-3'><strong>1.18. </strong>
                                “Augnito Developer Terms” means terms which apply to access to any of our APIs, SDKs or other Augnito developer assets
                                </p>
                                <p className='mb-3'><strong>2. </strong>
                                All products on the Website, or Mobile application, owned and operated by Augnito India Private Limited, a company incorporated under the Companies Act, 2013 and having its registered office at 31B, Floor – 1, Plot – 15, Meher House, Cawasji Patel Road, Horniman Circle Fort Mumbai, Mumbai City, MH 400001. Augnito India Private Limited along with its Products is referred to as “Augnito”, which term shall be deemed to mean and include its successors and assigns.
                                </p>
                                <p className='mb-3'><strong>3. </strong>
                                Augnito General Terms of Use (these “Terms”) describe Users rights and responsibilities as a User of our Products. However, your rights under this Agreement may be subject to additional terms and conditions depending upon the Product and Subscription you may choose These are available at Augnito Subscription Terms. The additional terms and conditions supplements or supersede all or portions of this Agreement.
                                </p>
                                <p className='mb-3'><strong>4. </strong>
                                These Terms are between you and Augnito that owns or operates the Product/s you are using or accessing (“Augnito”, “we” or “us”). “You” means the entity you represent in accepting these Terms or if that does not apply to you individually. If you are accepting on behalf of your employer or another entity, you represent and warrant that: (i) you have full legal authority to bind your employer or such entity to these Terms; (ii) you have read and understand these Terms; and (iii) you agree to these Terms on behalf of the party that you represent. If you don’t have the legal authority to bind your employer or the applicable entity, please do not click “I agree” (or similar button or checkbox) that is presented to you.
                                </p>
                                <p className='mb-3'><strong>PLEASE NOTE THAT IF YOU SIGN UP FOR A PRODUCT USING AN EMAIL ADDRESS FROM YOUR EMPLOYER OR ANOTHER ENTITY, THEN (A) YOU WILL BE DEEMED TO REPRESENT SUCH PARTY, (B) YOUR CLICK TO ACCEPT WILL BIND YOUR EMPLOYER OR THAT ENTITY TO THESE TERMS, AND (C) THE WORD “YOU” IN THESE TERMS WILL REFER TO YOUR EMPLOYER OR THAT ENTITY.</strong>
                                </p>
                                <p className='mb-3'><strong>5. </strong>
                                These Terms of Use constitute a legally binding agreement, as amended, supplemented, varied, or replaced from time to time between Augnito and you (the “User”), i.e., any natural or legal person (including their heirs, administrators, successors, and assigns, as the case may be) who uses and/or registers itself/themselves on the Website/App or any other mode as the case maybe (the “Agreement”).
                                </p>
                                <p className='mb-3'><strong>6. </strong>
                                This Agreement is an electronic record under the provisions of the Information Technology Act, 2000 and rules framed there under, including the Information Technology (Intermediaries Guidelines) Rules, 2011 (as applicable and as amended from time to time). This electronic record is generated by a computer system.
                                </p>
                                <p className='mb-3'><strong>7. </strong>
                                The use of the Website/Application is available only to persons competent to contract as specified under the Indian Contract Act, 1872 and all person’s incompetent to contract, including minors, are ineligible to use the Software. The acceptance of this Agreement necessarily implies that the User is above the age of 18 years. Augnito reserves the right to terminate any registration and/or refuse access to the website if at any time it is discovered that a User is below the age of 18 years and/or otherwise not competent to contract as per the terms of the Indian Contract Act, 1872.
                                </p>
                                <p className='mb-3'><strong>8. </strong>
                                These Terms are effective as of the date you first click “I agree” (or similar button or checkbox) or use or access a Product, whichever is earlier (the “Effective Date”). These Terms do not have to be signed in order to be binding. You indicate your assent to these Terms by clicking “I agree” (or similar button or checkbox) at the time you register for a Product, create a Product account, or place an Order. The acceptance of the Terms is a pre-requisite to the usage of the Product/s and by using the Product/s in any manner whatsoever, the User confirms his/her/its acknowledgement and acceptance to the Agreement
                                </p>
                                <p className='mb-3'><strong>9. </strong>
                                Augnito Holds the right to save the acceptance/acknowledgement of this Agreement by the User.
                                </p>
                                <p className='mb-3'><strong>10. </strong>
                                Using Augnito developer assets. Access to any of our APIs, SDKs or other Augnito developer assets is subject to the Augnito Developer Terms, which is a separate agreement.
                                </p>
                            </div>
                            <div className='mb-4'>
                                <h3 className='aug-legal-page-subtitle'>11. What these Terms cover</h3>
                                <p className='mb-3'><strong>11.1. </strong>
                                These Terms govern our Products, related Support, and Additional Services. These Terms include Our Policies (including our Privacy Policy), the Product-Specific Terms, Subscription Terms, and your Orders.
                                </p>
                                <p className='mb-3'><strong>11.2. Augnito Subscription Terms: </strong>
                                The Subscription Term chosen for a Product may include additional terms specific to the Subscription Term. By accessing or using a Product covered by the Subscription Terms, you also agree to the Augnito Subscription Terms.
                                </p>
                            </div>
                            <div className='mb-4'>
                                <h3 className='aug-legal-page-subtitle'>12. How Products are administered</h3>
                                <p className='mb-3'><strong>12.1. The Administrators: </strong>
                                will include Distributors, Enterprise Clients, Enterprise IT Administrators, Authorized Partners, and Resellers. All above shall be bound by the Terms of these Terms of Use and Agreement executed between them and Augnito, without limiting Responsibility for End Users the Administrators shall be responsible for acquainting the end user (to whom they sell/provide the products) with the terms and conditions published on the website or expressly shared with them in their Agreement. Such Consent must be saved and shared with Augnito on reasonable request.
                                </p>
                                <p className='mb-3'><strong>12.2. End Users as Administrators: </strong>
                                For Products for which, you may be able to specify certain End Users as Administrators, who will have important rights and controls over your use of Products and End User Accounts. This may include making Orders for Products or enabling Apps (which may incur fees); creating, de-provisioning, monitoring, or modifying End User Accounts, and setting End User usage permissions; and managing access to User Data by End Users or others. Administrators may also take over management of accounts previously registered using an email address belonging to your domain (which become “managed accounts”, as described in our Documentation). Without limiting Responsibility for End Users, which fully applies to Administrators, you are responsible for whom you allow to become Administrators and any actions they take, including as described above. You agree that our responsibilities do not extend to the internal management or administration of the Products for you.
                                </p>
                                <p className='mb-3'><strong>12.3. Purchase through Distributor/Reseller: </strong>
                                If You order Products through a Distributor/Reseller, then you are responsible for determining whether the Distributor /Reseller may serve as an Administrator and for any related rights or obligations in your applicable agreement with the Reseller. As between you and Augnito, you are solely responsible for any access by Reseller to your accounts or your other End User Accounts.
                                </p>
                            </div>
                            <div className='mb-4'>
                                <h3 className='aug-legal-page-subtitle'>13. User Covenants and Obligations</h3>
                                <p className='mb-3'><strong>13.1. </strong>
                                User agrees and undertakes to: (a) access and use the Products in accordance with this Agreement, applicable laws, and government regulations; (b) use commercially reasonable efforts to prevent unauthorized access to Augnito Technology or use of the Products and notify Augnito promptly of any such unauthorized access or use.
                                </p>
                                <p className='mb-3'><strong>13.2. </strong>
                                The User shall not and shall ensure that the Products and Augnito Technology are not used for any acts that are illegal and/or unlawful and/or malicious and/or any other activities whatsoever that Augntio deems to be improper in its sole judgment, including but not limited to the following, and any defiance in compliance will entitle Augnito to take such technical and/or legal remedies as it may deem necessary or fit, including but not limited to termination of the Agreement and suspending usage of the Services
                                </p>
                                <p className='mb-3'><strong>13.2.1. </strong>
                                Impersonating any person and/or entity or misrepresenting himself/herself/itself.
                                </p>
                                <p className='mb-3'><strong>13.2.2. </strong>
                                Providing any information to Augnito that is inaccurate, misleading and/or fraudulent.
                                </p>
                                <p className='mb-3'><strong>13.2.3. </strong>
                                Preventing Augnito from, in any manner whatsoever, authenticating the information provided by the User.
                                </p>
                                <p className='mb-3'><strong>13.2.4. </strong>
                                Access and/or use (and/or attempt to access or use) the account and/or login credentials of any other User.
                                </p>
                                <p className='mb-3'><strong>13.2.5. </strong>
                                Transmit any software and/or any other media that contain any viruses, worms, trojan horses, defects, or other such destructive / malicious software.
                                </p>
                                <p className='mb-3'><strong>13.2.6. </strong>
                                Infringe upon the intellectual property in any manner whatsoever, including but not limited to modifying and/or adapting and/or decompiling any portion of the Augnito Technology.
                                </p>
                                <p className='mb-3'><strong>13.2.7. </strong>
                                Harvest and/or collect information of or from other Users of the Services.
                                </p>
                                <p className='mb-3'><strong>13.2.8. </strong>
                                Act in a manner that is fraudulent and/or otherwise illegal for any reason whatsoever.
                                </p>
                                <p className='mb-3'><strong>13.2.9. </strong>
                                Acting in collusion and/or in connivance with another User(s).
                                </p>
                                <p className='mb-3'><strong>13.2.10. </strong>
                                Act otherwise than with complete decorum in his/her/its interactions with Augnito and/or its employees and/or agents and/or representatives; and/or.
                                </p>
                                <p className='mb-3'><strong>13.2.11. </strong>
                                Facilitate and/or encourage any of the aforesaid violations and/or destructive acts.
                                </p>
                                <p className='mb-3'><strong>13.3. </strong>
                                Users are responsible for understanding the settings and controls for each Product they use. In case you are an Enterprise User/Enterprise IT Admin/Distributor/Reseller you are responsible for controlling whom you allow to become an End User. Please note that you are responsible for the activities of your account with the Website/Application in case you are an End User
                                </p>
                                <p className='mb-3'><strong>13.4. </strong>
                                Users must ensure to keep your user IDs and passwords for the Products strictly confidential and do not share such information with any unauthorized person. User IDs are granted to individual, named persons, and may not be shared. You are responsible for any and all actions taken using End User Accounts and passwords, and you agree to immediately notify us of any unauthorized use of which you become aware.
                                </p>
                                <p className='mb-3'><strong>14. Support: </strong>
                                During the Subscription Term, we will provide Support for the Products in accordance with Support Policy.
                                </p>
                            </div>
                            <div className='mb-4'>
                                <h3 className='aug-legal-page-subtitle'>15. User Restrictions</h3>
                                <p className='mb-3'><strong>15.1. </strong>
                                Except as otherwise expressly permitted in these Terms, You will not: (a) reproduce, modify, adapt or create derivative works of the Products; (b) rent, lease, distribute, sell, sublicense, transfer or provide access to our Products to a third party; (c) use the our Products for the benefit of any third party; (d) incorporate any Products into a product or service you provide to a third party without any prior Agreement; (e) interfere with or otherwise circumvent mechanisms in the Products intended to limit your use; (f) reverse engineer, disassemble, decompile, translate or otherwise seek to obtain or derive the source code, underlying ideas, algorithms, file formats or non-public APIs to any Products, except to the extent expressly permitted by applicable law (and then only upon advance notice to us); (g) remove or obscure any proprietary or other notices contained in any Product; (h) use the Products for competitive analysis or to build competitive products; (i) publicly disseminate information regarding the performance of the Products; or (j) encourage or assist any third party to do any of the foregoing.
                                </p>
                            </div>
                            <div className='mb-4'>
                                <h3 className='aug-legal-page-subtitle'>16. User Data Transmission</h3>
                                <p className='mb-3'><strong>16.1. </strong>
                                User has and shall maintain all rights as are required to allow Augnito to provide the Products to the User as set forth in this Agreement, including without limitation to send the User Data to Augnito pursuant to this Agreement and to allow Augnito to access, use, and store User Data to perform its intended function pursuant and limited only to this Agreement. It is hereinafter clarified that Augnito’ s access, use, and store of User Data is limited to the purposes of this Agreement and enhancing its technology only. Augnito does not store/retain any user data beyond 90 (ninety) days. Augnito collects and uses all such data and information in an anonymized form and in accordance with applicable laws together with our Privacy Policy, which you acknowledge. For any further information please refer to our Privacy Policy. For any further information, rectification or deletion of any information relating to the User, please contact the Data Protection officer:-
                                </p>
                                <div className='mb-3'>
                                    <p className='mb-0'><strong>Name: </strong> Aakanksha Yadav
                                    </p>
                                    <p className='mb-0'><strong>Email: </strong> <a rel="noreferrer" target="_blank" href="mailto: legal@augnito.ai">legal@augnito.ai</a>
                                    </p>
                                </div>
                                <p className='mb-3'><strong>16.2. Aggregated Statistics: </strong>
                                Notwithstanding anything to the contrary in this Agreement, Augnito may monitor End User’s and Administrator’s use of its Products and collect and compile Aggregated Statistics. As between the Parties, all right, title, and interest in Aggregated Statistics, and all intellectual property rights therein, belong to and are retained solely by Augnito. User acknowledges that Augnito may compile Aggregated Statistics to the extent and in the manner permitted under applicable law; provided that such Aggregated Statistics do not identify End User, Administrator’s, or End User’s Confidential Information.
                                </p>
                            </div>
                            <div className='mb-4'>
                                <h3 className='aug-legal-page-subtitle'>17. Data Security</h3>
                                <p className='mb-3'><strong>17.1. </strong>
                                We implement and maintain physical, technical, and administrative security measures designed to protect your data from unauthorized access, destruction, use, modification, or disclosure. We also maintain a compliance program that includes independent third-party audits and certifications including ISO27001 and HIPAA.
                                </p>
                            </div>
                            <div className='mb-4'>
                                <h3 className='aug-legal-page-subtitle'>18. Billing, Renewals, and Payments</h3>
                                <p className='mb-3'><strong>18.1. </strong>
                                All Products are offered on a subscription basis governed by Augnito Subscription Terms in addition to General Terms of Use.
                                </p>
                                <p className='mb-3'><strong>18.2. </strong>
                                If you register for a free trial of any of our Products Augnito will provide the applicable Products to you on a trial basis free of charge until the earlier of (i) the end of the free trial period of the applicable Product (unless terminated earlier by you), (ii) the start date of the paid subscription period for the applicable Product, or (iii) termination by Augnito in its sole discretion. Any data that you enter to use any of our Products, during the free trial will be permanently lost unless you (i) purchase the corresponding paid subscription plan for the account, (ii) purchase applicable Service upgrades, or (iii) export such data before the end of the trial period. Notwithstanding anything contained in this Section, Services are offered as-is during the free trial, without any warranty, covenant, support, or liability whatsoever, to the extent permitted by law.
                                </p>
                                <p className='mb-3'><strong>18.3. </strong>
                                Renewals will be governed by Augnito Subscription Terms.
                                </p>
                                <p className='mb-3'><strong>18.4. </strong>
                                Adding Users: You may add users, increase storage limits, or otherwise increase your use of Products by placing a new Order or modifying an existing Order. Unless otherwise specified in the applicable Order, we will charge you for any increased use at our then-current rates, prorated for the remainder of the then-current Subscription Term.
                                </p>
                                <p className='mb-3'><strong>18.5. </strong>
                                Payment: You will pay all fees in accordance with each Order placed by you on the Website/App store or directly with Augnito, by the due dates and in the currency specified in the Order. If a PO number is required in order for an invoice to be paid, then you must provide such PO number to Augnito by emailing the PO number to Augnito at <a rel="noreferrer" target="_blank" href="mailto: sales@augnito.ai"><u>sales@augnito.ai</u></a>. Other than as expressly set forth in all amounts are non-refundable, non-cancelable and non-creditable. You agree that we may bill your credit card or other payment method for renewals, additional users, overages to set limits or scopes of use, expenses, and unpaid fees, as applicable.
                                </p>
                                <p className='mb-3'><strong>18.6. </strong>
                                If undisputed Fees are more than thirty (30) days overdue, then, following written notification from Augnito, Augnito may suspend User’s access to Augnito Technology, including, without limitation, User’s Account, until such unpaid Fees are paid in full.
                                </p>
                                <p className='mb-3'><strong>18.7. </strong>
                                If you purchased through a Reseller/Distributor (Administrator) of Augnito instead of paying us, you will pay the applicable amounts to the Administrator, as agreed between you and the Administrator. We may suspend or terminate your rights to use Products if we do not receive the corresponding payment from the Administrator .(b) Your order details (e.g., the Products you are entitled to use, the number of End Users, the Subscription Term, etc.) will be as stated in the Order placed with us by the Administrator on your behalf, and Administrator is responsible for the accuracy of any such Order as communicated to us. (c) If you are entitled to a refund under these Terms, then unless we otherwise specified, we will refund any applicable fees to the Administrator and the Administrator will be solely responsible for refunding the appropriate amounts to you. (d) Administrators are not authorized to modify these Terms or make any promises or commitments on our behalf, and we are not bound by any obligations to you other than as set forth in these Terms. (e) The amount paid or payable by the Administrator to us for your use of the applicable Product under these Terms will be deemed the amount actually paid or payable by you to us under these Terms for purposes of calculating the liability cap.
                                </p>
                                <p className='mb-3'><strong>18.8. </strong>
                                You are responsible for accessing your account to determine that we have received payment and that your Order has been processed. All deliveries under these Terms will be electronic.
                                </p>
                                <p className='mb-3'><strong>19. Return policy:</strong>
                                Return Policy is subject to and governed by the Subscription Terms.
                                </p>
                                <p className='mb-3'><strong>20. Taxes:</strong>
                                Our Subscription Fee are tax exclusive or inclusive.
                                </p>
                                <p className='mb-3'><strong>20.1. </strong>
                                In case of tax exclusive subscription: The Fees do not include any taxes, levies, duties, or similar governmental assessments, including value-added, sales, use or withholding taxes assessable by any local, state, provincial or foreign jurisdiction (collectively “Taxes”). The User agrees to pay applicable direct or indirect Taxes associated with its purchases hereunder, which, to the extent Augnito is legally required to collect the same, will be itemized on the Augnito’ s invoice. If User has an obligation to withhold any amounts under any law or tax regime), User will gross up the payments so that the Augnito receives the amount actually quoted and invoiced. If Augnito has the legal obligation to pay or collect Taxes for which User is responsible under this section, the appropriate amount will be invoiced and paid by the User unless, prior to the invoice date, the User provides Augnito with a valid tax exemption certificate authorized by the appropriate taxing authority.
                                </p>
                                <p className='mb-3'><strong>20.2. </strong>
                                In case of Tax inclusive subscription: The Subscription Fee shall be subject to change in case of any revision in tax. The Users shall be notified of any such changes via email.
                                </p>
                                <p className='mb-3'><strong>21. </strong>
                                No contingencies on other products of future functionality/features: You agree that your purchases are not contingent on the delivery of any future functionality or features (including future availability of any Products beyond the current Subscription Term), or dependent on any oral or written public comments we make regarding future functionality or features.
                                </p>
                                <p className='mb-3'><strong>22. </strong>
                                Evaluations, trials, and betas: We may offer certain Products (including some Augnito Apps) to you at no charge, including free accounts, trial use and Beta Versions as defined below (collectively, “No-Charge Products”). Your use of No-Charge Products is subject to any additional terms that we specify and is only permitted during the Subscription Term we designate (or, if not designated, until terminated in accordance with these Terms). Except as otherwise set forth in the terms and conditions of these Terms governing Products, including Restrictions fully apply to No-Charge Products. We may modify or terminate your right to use No-Charge Products at any time and for any reason in our sole discretion, without liability to you. You understand that any pre-release and beta Products, and any pre-release and beta features within generally available Products, that we make available (collectively, “Beta Versions”) are still under development, may be inoperable or incomplete and are likely to contain more errors and bugs than generally available Products. We make no promises that any Beta Versions will ever be made generally available. In some circumstances, we may charge a fee in order to allow you to access Beta Versions, but the Beta Versions will still remain subject to this section. All information regarding the characteristics, features, or performance of any No-Charge Products (including Beta Versions) constitutes our Confidential Information. To the maximum extent permitted by applicable law, we disclaim all obligations or liabilities with respect to No-Charge Products, including any Support, warranty, and indemnity obligations.
                                </p>
                            </div>
                            <div className='mb-4'>
                                <h3 className='aug-legal-page-subtitle'>23. Proprietary Rights</h3>
                                <p className='mb-3'><strong>23.1. Ownership of Rights: </strong>
                                Except for the rights expressly granted under this Agreement, Augnito and its licensors retain all right, title, and interest in and to the Products and Augnito Technology and Documentation, including all related intellectual property rights therein. Augnito reserves all rights in and to the Services, Augnito Technology and Documentation not expressly granted to the User under this Agreement. User will not delete or in any manner alter the copyright, trademark, and other proprietary notices of Augnito.
                                </p>
                                <p className='mb-3'><strong>23.2. Ownership of User Data: </strong>
                                As between User and Augnito, User is and will remain the sole and exclusive owner of all right, title, and interest to all User Data, including any intellectual property rights therein. User hereby grants Augnito and its Affiliates and applicable Authorized Contractors all necessary rights to host, use, process, store, display and transmit User Data solely as necessary for Augnito to provide the Services in accordance with this Agreement and Augnito’s Privacy Policy. User represents that it has, and warrants that it shall maintain, all rights as required to allow Augnito to compile, use, store, and retain aggregated User Data, including without limitation in combination with other Augnito User’s data, for internal or marketing uses (provided that no such marketing use shall include any User Data that can identify User or its Users). Subject to the licenses granted herein, Augnito acquires no right, title or interest from User or User Licensors hereunder in or to User Data, including any intellectual property rights therein. User reserves all rights in and to the User Data that are not expressly granted to Augnito pursuant to this Agreement.
                                </p>
                                <p className='mb-3'><strong>23.3. Feedback: </strong>
                                From time to time, you may choose to submit Feedback to us. We may in connection with any of our Products freely use, copy, disclose, license, distribute and exploit any Feedback in any manner without any obligation, royalty or restriction based on intellectual property rights or otherwise. No Feedback will be considered your Confidential Information, and nothing in these Terms limits our right to independently use, develop, evaluate, or market products or services, whether incorporating Feedback or otherwise.
                                </p>
                                <p className='mb-3'><strong>24. Publicity Rights: </strong>
                                We may identify you as an Augnito’s User in our promotional materials. We will promptly stop doing so upon your request sent to <a rel="noreferrer" target="_blank" href="mailto: marketing@augnito.ai"><u>marketing@augnito.ai</u></a>
                                </p>
                            </div>
                            <div className='mb-4'>
                                <h3 className='aug-legal-page-subtitle'>25. Confidentiality</h3>
                                <p className='mb-3'><strong>25.1. Definition of Confidential Information </strong>
                                “Confidential Information” means all confidential or proprietary information of a party (“Disclosing Party”) disclosed to the other party (“Receiving Party”), whether orally or in writing, that is designated as confidential or reasonably should be understood to be confidential given the nature of information and the circumstances of disclosure. Without limiting the coverage of these confidentiality obligations, the parties acknowledge and agree that Confidential Information of each party shall include the terms and conditions of this Agreement (including pricing and other terms set forth in all Order Forms issued hereunder), related benchmark or similar test results, other technology and technical information, security information, security audit reports, and business and marketing plans, except that Augnito may reference and use User’s name, logos and the nature of the Services provided hereunder in Augnito’s business development and marketing efforts.
                                </p>
                                <p className='mb-3'><strong>25.2. ExceptionsConfidential Information shall not include information that: </strong>  (a) is or becomes publicly available without a breach of any obligation owed to the Disclosing Party, (b) is already known to the Receiving Party at the time of its disclosure by the Disclosing Party, without a breach of any obligation owed to the Disclosing Party, (c) following its disclosure to the Receiving Party, is received by the Receiving Party from a third party without breach of any obligation owed to Disclosing Party, or (d) is independently developed by Receiving Party without reference to or use of the Disclosing Party’s Confidential Information.
                                </p>
                                <p className='mb-3'><strong>25.3. Protection of Confidential Information: </strong>
                                The Receiving Party shall use the same degree of care used to protect the confidentiality of its own Confidential Information of like kind (but in no event less than reasonable care), and, except with Disclosing Party’s written consent, shall: (a) not use any Confidential Information of Disclosing Party for any purpose outside the scope of this Agreement and (b) limit access to Confidential Information of Disclosing Party to those of its, its Authorized Contractors, and Affiliates’ employees, contractors and agents who need such access for purposes consistent with this Agreement and who have a duty or obligation of confidentiality no less stringent than those set forth herein.
                                </p>
                                <p className='mb-3'><strong>25.4. Lawful Disclosure: </strong>
                                The Receiving Party may disclose Confidential Information of the Disclosing Party to the extent required by applicable law, regulation or legal process, provided that the Receiving Party: (a) provides prompt written notice to the extent legally permitted; (b) provides reasonable assistance, at Disclosing Party’s cost, in the event the Disclosing Party wishes to oppose the disclosure; and (c) limits disclosure to that required by law, regulation or legal process.
                                </p>
                            </div>
                            <div className='mb-4'>
                                <h3 className='aug-legal-page-subtitle'>26. Representations, Warranties and Disclaimers</h3>
                                <p className='mb-3'><strong>26.1. Augnito Representations & Warranties: </strong>
                                Augnito represents and warrants that: (a) Augnito has the legal authority to enter into this Agreement; (b) the Products conform with the relevant Documentation in all material respects, and (c) the functionality and security of the Products will not be materially decreased during a Subscription Term.
                                </p>
                                <p className='mb-3'><strong>26.2. Remedies: </strong>
                                For any failure, as applicable, to conform to their respective warranties, Augnito’s liability and User’s sole and exclusive remedy shall be to require Augnito to use commercially reasonable efforts to correct such failure. If the foregoing remedies are not commercially practicable, Augnito may, in its sole discretion, terminate the applicable Order upon providing User with written notice thereof, and, as User’s sole and exclusive remedy, will be to refund to User the fees paid for that abovementioned subscription/license by User with respect to the unexpired portion of the current Subscription Term.
                                </p>
                                <p className='mb-3'><strong>26.3. User Representations & Warranties: </strong>
                                User represents and warrants that: (a) it has the legal authority and capacity to enter into this Agreement, and (b) it will use the Services and Software (if applicable) in accordance with the terms and conditions set forth in this Agreement and in compliance with all applicable laws, rules and regulations.
                                </p>
                                <p className='mb-3'><strong>26.4. Disclaimer: </strong>
                                EXCEPT AS EXPRESSLY PROVIDED HEREIN, AUGNITO MAKES NO WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, ORAL OR WRITTEN, STATUTORY OR OTHERWISE, AND AUGNITO HEREBY DISCLAIMS ALL IMPLIED WARRANTIES AND CONDITIONS, INCLUDING, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ANY WARRANTIES OF MERCHANTABILITY OR FITNESS FOR ANY PARTICULAR PURPOSE, OR ANY WARRANTY WITH RESPECT TO THE QUALITY, PERFORMANCE, ACCURACY OR FUNCTIONALITY OF THE PRODUCTS OR THAT THE SAME ARE OR WILL BE ERROR FREE OR WILL ACCOMPLISH ANY PARTICULAR RESULT. YOU UNDERSTAND THAT USE OF THE PRODUCTS NECESSARILY INVOLVES TRANSMISSION OF YOUR DATA OVER NETWORKS THAT WE DO NOT OWN, OPERATE OR CONTROL, AND WE ARE NOT RESPONSIBLE FOR ANY OF YOUR DATA LOST, ALTERED, INTERCEPTED OR STORED ACROSS SUCH NETWORKS. WE CANNOT GUARANTEE THAT OUR SECURITY PROCEDURES WILL BE ERROR-FREE, THAT TRANSMISSIONS OF YOUR DATA WILL ALWAYS BE SECURE OR THAT UNAUTHORIZED THIRD PARTIES WILL NEVER BE ABLE TO DEFEAT OUR SECURITY MEASURES OR THOSE OF OUR THIRD-PARTY SERVICE PROVIDERS. WE WILL NOT BE LIABLE FOR DELAYS, INTERRUPTIONS, SERVICE FAILURES OR OTHER PROBLEMS INHERENT IN USE OF THE INTERNET AND ELECTRONIC COMMUNICATIONS OR OTHER SYSTEMS OUTSIDE OUR REASONABLE CONTROL. YOU MAY HAVE OTHER STATUTORY RIGHTS, BUT THE DURATION OF STATUTORILY REQUIRED WARRANTIES, IF ANY, WILL BE LIMITED TO THE SHORTEST PERIOD PERMITTED BY LAW.
                                </p>
                            </div>
                            <div className='mb-4'>
                                <h3 className='aug-legal-page-subtitle'>27. Indemnification</h3>
                                <p className='mb-3'><strong>27.1. Indemnification by Augnito: </strong>
                                Augnito shall defend User against any claim, demand, suit, or proceeding made or brought against User by a third party alleging that the use of Services infringes or misappropriates the intellectual property rights of a third party (a “Claim Against User”); provided that User: (a) promptly gives Augnito written notice of the Claim Against User; (b) gives Augnito sole control of the defense and settlement of the Claim Against User (provided that Augnito may not settle any Claim Against User unless the settlement unconditionally releases User of all liability); and (c) provides to Augnito all reasonable assistance, at Augnito’s expense. In the event of a Claim Against User, or if Augnito reasonably believes the Services may infringe or misappropriate the intellectual property rights of a third party, Augnito may in Augnito’s sole discretion and at no cost to User: (a) modify the Services so that they no longer infringe or misappropriate, without breaching Augnito’s warranties hereunder, (b) obtain a license for User’s continued use of Services in accordance with this Agreement, or (c) terminate User’s subscriptions for such Services and refund to User any prepaid fees covering the remainder of the Subscription Term after the effective date of termination. Notwithstanding the foregoing, Augnito shall have no obligation to indemnify, defend, or hold User harmless from any Claim Against User to the extent it arises from: (a) User Data, (b) use by User after notice by Augnito to discontinue use of all or a portion of the Services, (c) use of Services by User in combination with equipment or software not supplied by Augnito where the Service itself would not be infringing, or (d) User’s breach of this Agreement.
                                </p>
                                <p className='mb-3'><strong>27.2. Indemnification by User: </strong>
                                User shall indemnify, defend, and hold Augnito harmless from and against any judgments, settlements, costs, and fees reasonably incurred (including reasonable attorney’s fees) resulting from any claim, demand, suit or proceeding made or brought against Augnito by a third party alleging that User Data or User’s use of the Services is in violation of this Agreement, infringes or misappropriates the intellectual property rights of a third party or violates applicable law.
                                </p>
                                <p className='mb-3'><strong>27.3. Exclusive Remedy: </strong>
                                This Clause states the indemnifying party’s sole liability to, and the indemnified party’s exclusive remedy against, the other party for any type of claim described in this Clause. If User’s use of Products is, or in Augnito’s reasonable opinion is likely to become, enjoined or materially diminished as a result of a Claim Against User, then Augnito will, at its sole option, either: (a) procure the continuing right of User to use the Product/s; (b) replace or modify Product in a functionally equivalent manner so that it no longer infringes; or (c) terminate this Agreement and refund to User all unused subscription fees paid by User with respect to such Products. This Clause states Augnito’s sole and exclusive liability, and User’s sole and exclusive remedy, for the actual or alleged infringement or misappropriation of any third-party intellectual property right by any Services.
                                </p>
                            </div>
                            <div className='mb-4'>
                                <h3 className='aug-legal-page-subtitle'>28. Limitation Of Liability</h3>
                                <p className='mb-3'><strong>28.1. </strong>
                                Other than the obligations set forth in clause 18(indemnification), each party’s aggregate liability to the other arising out of or related to these terms will not exceed the amount actually paid or payable by the User to Augnito under these terms in the three (3) months immediately preceding the claim.</p>
                                <p className='mb-3'><strong>28.2. Exclusion of Consequential and Related Damages: </strong>
                                Neither party shall have any liability to the other party for any lost profits or revenues or goodwill or reputation, lost or inaccurate data, failure of security mechanisms, interruption of business, costs of delay or for any indirect, special, incidental, consequential, cover or punitive damages however caused, whether in contract, tort or under any other theory of liability, and whether or not the party has been advised of the possibility of such damages.</p>
                            </div>
                            <div className='mb-4'>
                                <h3 className='aug-legal-page-subtitle'>29. Other Limitations</h3>
                                <p className='mb-3'><strong>29.1. </strong>
                                In no event shall Augnito be liable to the User to the extent that the alleged infringement of a third party intellectual property is based on or connected with: (a) a modification of the Services or Documentation by anyone other than Augnito; (b) the User’s use of the Services or Documentation in a manner contrary to the instructions given to the User by Augnito; or (c) the User’s use of the Services or Documentation after notice of the alleged or actual infringement from Augnito or any appropriate authority. Notwithstanding anything contained in this Agreement, User assumes sole responsibility for results obtained from the use of the Services and the Documentation by User, and for conclusions drawn from such use and Augnito shall have no liability for any damage caused by errors or omissions in any information, instructions or scripts, User Data provided to Augnito by the User in connection with the utilization of the Services, or any actions taken by Augnito at the User’s direction.</p>
                                <p className='mb-3'><strong>29.2. </strong>
                                With respect to the above, Augnito (including its directors and/or employees and/or affiliates and/or agents and/or representatives and/or subcontractors) shall not be liable for any loss and/or liability, from this Agreement, delays or interruptions due to electronic or mechanical equipment failures and for reasons of force majeure, which are out of Augnito ‘s control, as may include but is not limited to health pandemics, weather conditions, earthquakes, floods, fire and other acts of God. Augnito shall have no responsibility to provide the User with access to the Services while such interruption is ongoing.</p>
                                <p className='mb-3'><strong>29.3. </strong>
                                The Services may include external and/or third-party links and it is the responsibility of the User to be acquainted itself with the privacy policies and terms and conditions of such third-party websites. Augnito does not in any manner whatsoever vouch for and/or cannot in any manner be held responsible for the information set out therein.</p>
                                <p className='mb-0'><strong>29.4. The User expressly agrees that Augnito is not a licensed health care provider and that the Products are not a substitute for independent medical decision making by a qualified health care practitioner. The User is solely responsible for its own conduct with respect to patient care and any reliance upon the Product shall not diminish User’s responsibility for patient care.</strong></p>
                            </div>
                            <div className='mb-4'>
                                <h3 className='aug-legal-page-subtitle'>30. Term and Termination</h3>
                                <p className='mb-3'><strong>30.1. Term:</strong>
                                These Terms are effective as of the Effective Date and expire on the date of expiration or termination of all Subscription Terms.</p>
                                <p className='mb-3'><strong>30.2. Termination for Cause:</strong> Either party may terminate these Terms (including all related Orders) if the other party (a) fails to cure any material breach of these Terms within thirty (30) days after notice; (b) ceases operation without a successor; or (c) seeks protection under any bankruptcy, receivership, trust deed, creditors’ arrangement, composition or comparable proceeding, or if any such proceeding is instituted against that party (and not dismissed within sixty (60) days thereafter).</p>
                                <p className='mb-3'><strong>30.3. Termination for Convenience: </strong>
                                You may choose to stop using the Products and terminate these Terms (including all Orders) at any time for any reason upon written notice to us at <a rel="noreferrer" target="_blank" href="mailto: sales@augnito.ai"><u>sales@augnito.ai</u></a> or intimate us through account settings but, unless you are exercising your right to terminate early (without notice), upon any such termination (i) you will not be entitled to a refund of any pre-paid fees and (ii) if you have not already paid all applicable fees for the then-current Subscription Term or related services period (as applicable), any such fees that are outstanding will become immediately due and payable. Further Augnito may terminate this Agreement, cancel any User registration, refuse access to the Product and/or take such other action (including recourse to legal remedies) as it may deem fit upon breach by the User of any terms or conditions of this Agreement.</p>
                                <p className='mb-3'><strong>30.4. Effects of Termination:</strong>
                                Upon any expiration or termination of these Terms, you must cease using all Products and delete (or at our request, return) all Confidential Information or other materials of ours in your possession, including on any third-party systems operated on your behalf. We will certify such deletion upon our request. You will not have access to Your Data and we will delete all of Your Data unless legally prohibited after expiration and/or termination of these Terms or its applicable Subscription Term, so you should make sure to export Your Data using the functionality of the Products during the applicable Subscription Term. We will communicate deletion of your account and Data via email.</p>
                                <p className='mb-3'><strong>30.5. </strong>
                                If you terminate these Terms in accordance with Termination for Cause, we will refund you any prepaid fees covering the remainder of the then-current Subscription Term after the effective date of termination. If we terminate these Terms in accordance Termination for Cause, you will pay any unpaid fees covering the remainder of the then-current Subscription Term after the effective date of termination. In no event will termination relieve you of your obligation to pay any fees payable to us for the period prior to the effective date of termination. Except where an exclusive remedy may be specified in these Terms, the exercise by either party of any remedy, including termination, will be without prejudice to any other remedies it may have under these Terms, by law or otherwise.</p>
                                <p className='mb-0'><strong>31. Survival: </strong>
                                The provisions pertaining to Clause 25(Confidentiality) Clause 23(Proprietary Rights) Clause 27(Indemnity), Clause 28(Limitations of Liability),Clause 32(Dispute Resolution) and Clause 33(Governing Law) will survive any termination or expiration of these Terms.</p>
                            </div>
                            <div className='mb-4'>
                                <h3 className='aug-legal-page-subtitle'>32. Dispute Resolution</h3>
                                <p className='mb-3'><strong>32.1. </strong>
                                If any dispute arises between Augnito and the User in connection with the validity, interpretation, implementation, or alleged material breach of any provision of this Agreement (including the validity, scope, and enforceability of this agreement) then both parties shall endeavor to settle the dispute amicably. The attempt to bring about an amicable settlement shall be considered to have failed if the parties after reasonable attempts for not less than 30 days, gives a notice of 30 days thereof to the other party in writing.</p>
                                <p className='mb-3'><strong>32.2. </strong>
                                In case of such failure, the dispute shall be solely and finally settled by reference to arbitration in accordance with the provisions of the Arbitration & Conciliation Act, 1996. The Arbitration shall be a fast-track arbitration, as per Section 29B of the Arbitration & Conciliation Act, 1996.</p>
                                <p className='mb-3'><strong>32.3. </strong>
                                The arbitration shall be conducted by a sole arbitrator appointed mutually by the parties. The place of arbitration shall be Mumbai and the language of arbitration shall be English.</p>
                                <p className='mb-3'><strong>32.4. </strong>
                                The prevailing party in any arbitration proceedings shall be entitled to recover its costs (including, without limitation, its legal costs). Judgment upon any award rendered by the arbitrator may be entered in any court having competent jurisdiction in relation thereto, or application may be made to such court for a judicial acceptance or recognition of the award and an order of enforcement (including specific performance), as the case may be.</p>
                                <p className='mb-3'><strong>32.5. </strong>
                                Notwithstanding anything contained in this Agreement, the User acknowledges and agrees that in the event of a material breach of this agreement Augnito shall be entitled to seek immediate injunctive relief, without limiting its other rights and remedies.</p>
                                <p className='mb-3'><strong>33. Governing Law:</strong>
                                This Agreement shall be governed by and construed in accordance with the laws of India and the courts in Mumbai shall have exclusive jurisdiction to deal with any matters or disputes arising between the parties under this Agreement.</p>
                                <p className='mb-3'><strong>34. Changes to these Terms:</strong>
                                Augnito reserves the right to unilaterally make changes or modify and/or change and/or update this Agreement at any time and from time to time and in any manner whatsoever, with notice or by posting the modified Terms on our website. Together with notice, we will specify the effective date of the modifications. The User hereby accepts that his/her/its continued access to and/or use of the Products post such modifications is deemed to be an acceptance of the same and will bind the User as such.</p>
                            </div>
                            <div className='mb-4'>
                                <h3 className='aug-legal-page-subtitle'>35. General Provisions</h3>
                                <p className='mb-3'><strong>35.1. Notices: </strong>
                                Any notice under these Terms must be given in writing. We may provide notice to you through your Notification Email Address, your account, or in-product notifications. You agree that any electronic communication will satisfy any applicable legal communication requirements, including that such communications be in writing. Any notice to you will be deemed given upon the first business day after we send it. You will provide notice to us via <a rel="noreferrer" target="_blank" href="mailto: legal@augnito.ai"><u>legal@augnito.ai</u></a></p>
                                <p className='mb-3'><strong>35.2. Assignment: </strong>
                                Augnito reserves the rights to assign this Agreement, as may be amended, together with all rights and obligations thereunder to any successor-in-interest that may acquire rights to that information because of the sale or merger of Augnito or otherwise.</p>
                                <p className='mb-3'><strong>35.3. Severability: </strong>
                                If any one or more provisions of this Agreement become illegal, invalid, or unenforceable, such illegality, invalidity or unenforceability shall not affect the validity or enforceability of the remaining provisions of this Agreement which shall be severable therefrom and shall remain in full force and effect.</p>
                                <p className='mb-3'><strong>35.4. Waiver: </strong>
                                No failure or delay by the injured party to these Terms in exercising any right, power or privilege hereunder will operate as a waiver thereof, nor will any single or partial exercise thereof preclude any other or further exercise thereof or the exercise of any right, power, or privilege hereunder at law or equity. Except as set forth in Changes to these Terms, any amendments or modifications to these Terms must be executed in writing by an authorized representative of each party.</p>
                                <p className='mb-3'><strong>35.5. Force Majeure: </strong>
                                Neither party will be liable to the other for any delay or failure to perform any obligation under these Terms (except for a failure to pay fees) if the delay or failure is due to events which are beyond the reasonable control of such party, such as a strike, blockade, war, act of terrorism, riot, natural disaster, failure or diminishment of power or telecommunications or data networks or services, or refusal of a license by a government agency.</p>
                                <p className='mb-3'><strong>35.6. Entire Agreement: </strong>
                                These Terms are the entire agreement between you and us relating to the Products and any other subject matter covered by these Terms and supersede all prior or contemporaneous oral or written communications, proposals and representations between you and us with respect to the Products or any other subject matter covered by these Terms.</p>
                                <p className='mb-3'><strong>35.7. Conflicts: </strong>
                                In event of any conflict between the main body of these Terms and either Our Policies or Product-Specific Terms, Our Policies or Product-Specific Terms (as applicable) will control with respect to their subject matter.</p>
                                <p className='mb-3'><strong>35.8. Interpretation: </strong>
                                As used herein, “including” (and its variants) means “including without limitation” (and its variants). Headings are for convenience only. If any provision of these Terms is held to be void, invalid, unenforceable, or illegal, the other provisions will continue in full force and effect.</p>
                                <p className='mb-0'><strong>35.9. Independent Contractors: </strong>
                                The parties are independent contractors. These Terms will not be construed as constituting either party as a partner of the other or to create any other form of legal association that would give either party the express or implied right, power, or authority to create any duty or obligation of the other party.</p>
                            </div>
                        </div>
                    </div>
                </div>
                </section>
            </>
        );
    }
}