import React from 'react';

const OurPartnerLogoIN = () => {
    return (
        <ul className='list-unstyled mb-0 d-flex flex-wrap'>
            <li>
                <img src={require('../../../Assets/images/PartnersLogo/IN/apollo.webp')} alt="apollo logo" className="img-fluid bg-white p-10"/>
            </li>
            <li>
                <img src={require('../../../Assets/images/PartnersLogo/IN/Medanta.webp')} alt="Medanta logo" className="img-fluid bg-white p-10"/>
            </li>
            <li>
                <img src={require('../../../Assets/images/PartnersLogo/IN/max-healthcare.webp')} alt="max healthcare logo" className="img-fluid bg-white p-10"/>
            </li>
            <li>
                <img src={require('../../../Assets/images/PartnersLogo/IN/fortis.webp')} alt="fortis logo" className="img-fluid bg-white p-10"/>
            </li>
            <li>
                <img src={require('../../../Assets/images/PartnersLogo/IN/AIIMs.webp')} alt="AIIMs logo" className="img-fluid bg-white p-10"/>
            </li>
            <li>
                <img src={require('../../../Assets/images/PartnersLogo/IN/Narayana-Health.webp')} alt="Narayana Health logo" className="img-fluid bg-white p-10"/>
            </li>
            <li>
                <img src={require('../../../Assets/images/PartnersLogo/IN/Manipal-Hospitals.webp')} alt="Manipal Hospitals logo" className="img-fluid bg-white p-10"/>
            </li>
            <li>
                <img src={require('../../../Assets/images/PartnersLogo/IN/Aster.webp')}  alt="Aster logo" className="img-fluid bg-white p-10"/>
            </li>
            <li>
                <img src={require('../../../Assets/images/PartnersLogo/IN/Teleradiology-Solutions.webp')} alt="Teleradiology Solutions logo" className="img-fluid bg-white p-10"/>
            </li>
            <li>
                <img src={require('../../../Assets/images/PartnersLogo/IN/Zydus.webp')} alt="Zydus logo" className="img-fluid bg-white p-10"/>
            </li>
        </ul>
    );
};

export default OurPartnerLogoIN;