import React from 'react';
import {ReactComponent as Database} from '../../../../Assets/images/icons/database.svg';

const DataNormalizationIN = () => {
    return (
        <div className='mt-60'>
            <div className='aug-speech-models__icon d-flex align-items-center justify-content-center'>
                <Database/>
            </div>
            <h3 className='mb-3 aug-speech-models__heading'>Clinical data normalisation</h3>
            <p className='mb-0'>In-built text formatting preferences customised for clinical documentation, including the capitalisation of words, numeric representations, and specialised formatting for medical terms. </p>
        </div>
    );
};

export default DataNormalizationIN;