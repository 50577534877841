import React from 'react';
import BaseComponent from '../../Common/BaseComponent'
import CustomerService from '../Spectra/Features/CustomerService/CustomerService';
import ContactSalesBanner from './ContactSalesBanner/ContactSalesBanner';
import ContactSalesMeta from './Meta/ContactSalesMeta';
import SuccessfullyIntegrated from './SuccessfullyIntegrated/SuccessfullyIntegrated';

export default class ContactSales extends BaseComponent {
    render() {
        // const {regionData } = this.state;
        var regionData = this.props.userRegionData;
        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <main>
                <ContactSalesMeta/>
                <ContactSalesBanner/>
                <SuccessfullyIntegrated userRegionData={regionData} />
                <CustomerService userRegionData={regionData}/>
            </main>
        );
    }
}