import React from 'react';
import BaseComponent from '../../Common/BaseComponent'
import AboutBanner from './AboutBanner/AboutBanner';
import AboutUSP from './AboutUSP/AboutUSP';
import LeadershipTeam from './LeadershipTeam/LeadershipTeam';
import AboutUsMeta from './Meta/AboutUsMeta';
import OurEthos from './OurEthos/OurEthos';
import OurPartners from './OurPartners/OurPartners';
import OurProducts from './OurProducts/OurProducts';
import OurStory from './OurStory/OurStory';
import TheVoice from './TheVoice/TheVoice';
import WorkWithUs from './WorkWithUs/WorkWithUs';
import OurStoryME from './OurStory/OurStoryME';
import AboutBannerIN from './AboutBanner/AboutBannerIN';
import OurStoryIN from './OurStory/OurStoryIN';
import OurEthosIN from './OurEthos/OurEthosIN';
import AboutUSPIN from './AboutUSP/AboutUSPIN';
import WorkWithUsIN from './WorkWithUs/WorkWithUsIN';

export default class AboutUs extends BaseComponent {
    render() {
        // const { regionData } = this.state;
        var regionData = this.props.userRegionData;
        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <main>
                <AboutUsMeta/>
                {
                    regionData === "IN" ? <AboutBannerIN/> :
                    <AboutBanner/>
                }
                {
                    regionData === "ME" ? <OurStoryME/> :
                    regionData === "IN" ? <OurStoryIN/> :
                    <OurStory/>
                }
                {
                    regionData === "IN" ? <OurEthosIN/> :
                    <OurEthos/>
                }
                {
                    regionData === "IN" ? <AboutUSPIN/> :
                    <AboutUSP/>
                }
                <OurProducts userRegionData={regionData}/>
                <LeadershipTeam/>
                <OurPartners userRegionData={regionData}/>
                <TheVoice/>
                {
                    regionData === "IN" ? <WorkWithUsIN/> :
                    <WorkWithUs/>
                }
            </main>
         );
    }
}