import React from 'react';
import Img from '../../../../Assets/images/VoiceServices/features/human-touch-ME.webp';
import { RedirectToSignUpVoiceServices } from '../../../../Common/Utils';

const HumanTouchME = () => {
    return (
        <div className="row gx-40 mt-60 align-items-center flex-column-reverse flex-md-row">
            <div className="offset-lg-2 offset-md-1 col-md-7 col-lg-6">
                <h3 className='mb-3 aug-customer-service__heading'>
                    A differentiated user experience with a human touch
                </h3>
                <p className='mb-0'>Voice Services’ dedicated Customer Success professionals are focused on training and onboarding new users, as well as helping existing ones make the most of our speech recognition technology—at no added cost!</p>
                <div className='mt-40'>
                    <button type="button" className="btn btn-primary" onClick={RedirectToSignUpVoiceServices}>Try for free</button>
                </div>
            </div>
            <div className="col-12 col-md-4 col-lg-4">
            <div className='mr-n40 aug-customer-service__img pb-0 ps-0'>
                    <img src={Img} alt="A physician talking to a customer service executive in an online meet using his laptop" className='w-100 rounded-ltb-10 img-fluid'/>
                </div>
            </div>
            
        </div>         
    );
};

export default HumanTouchME;