import React from 'react';

const Provider = () => {
    return (
        <div className='row gx-40 flex-column-reverse flex-md-row'>
            <div className='offset-lg-1 offset-md-1 col-md-6 col-lg-5'>
                <div>
                    {/* <h3 className='aug-tab-content__heading mb-2'>Providers</h3> */}
                    <h3 className='mb-4 aug-tab-content__subheading'>Mitigate physician burnout</h3>
                    <p className='mb-0'>Streamline clinical workflows and document into Electronic Health Records (EHRs) seamlessly. With Augnito users are more productive, experience less fatigue, and spend more time with patients (less on paperwork).</p>
                </div>
            </div>
            <div className='offset-lg-1 col-md-5 col-lg-4'>
                <img src={require('../../../Assets/images/home/PositiveStake/positive-stake-1.webp')} height="auto" width="100%" alt="Radiologist using Augnito medical speech recognition software while viewing a radiology report simultaneously" className='img-fluid mb-4 mb-md-0 pb-2 pb-md-0'/>
            </div>
        </div>
    );
};

export default Provider;