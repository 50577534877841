import React from 'react';
import './ChooseUs.scss';
import TranscriptionCost from './TranscriptionCost';
import SaveHours from './SaveHours';
import RevenueCycle from './RevenueCycle';
import ProviderSatisfaction from './ProviderSatisfaction';
import BaseComponent from '../../../../Common/BaseComponent';
import SaveHoursME from './SaveHoursME';
import AutomateTranscriptionME from './AutomateTranscriptionME';
import DriveEMRME from './DriveEMRME';
import RevenueCycleME from './RevenueCycleME';
import SaveHoursIN from './SaveHoursIN';
import AutomateTranscriptionIN from './AutomateTranscriptionIN';
import DriveEMRIN from './DriveEMRIN';
import RevenueCycleIN from './RevenueCycleIN';


export default class ChooseUs extends BaseComponent {

    render() {
        // const { regionData } = this.state;
        var regionData = this.props.userRegionData;
        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <section className='aug-choose-us mt-60 mb-60'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='offset-md-1 offset-lg-2 col-12 col-md-6 col-lg-4'>
                            <h2 className='aug-title mb-0 mt-60'>Why choose us</h2>
                        </div>
                    </div>
                    <div className="offset-lg-2 offset-md-1 col-md-11 col-lg-9">
                        <div className="row gx-40">
                            <div className="col-12 col-md-6">
                                {
                                    regionData === "ME" ? <SaveHoursME/> :
                                    regionData === "IN" ? <SaveHoursIN/> :
                                    <TranscriptionCost/>
                                }
                            </div>
                            <div className="col-12 col-md-6">
                                {
                                    regionData === "ME" ? <AutomateTranscriptionME/> :
                                    regionData === "IN" ? <AutomateTranscriptionIN/> :
                                    <SaveHours/>
                                }
                            </div>
                            <div className="col-12 col-md-6">
                                {
                                    regionData === "ME" ? <DriveEMRME/> :
                                    regionData === "IN" ? <DriveEMRIN/> :
                                    <RevenueCycle/>
                                }
                            </div>
                            <div className="col-12 col-md-6">
                                {
                                    regionData === "ME" ? <RevenueCycleME/> :
                                    regionData === "IN" ? <RevenueCycleIN/> :
                                    <ProviderSatisfaction/>
                                }
                            </div>
                        </div>                
                    </div>           
                </div> 
            </section>
        );
    };
};