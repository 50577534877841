import React from 'react';
import './AugnitoUSP.scss'

const AugnitoUSPIN = () => {
    return (
        <section className='aug-augnito-USP aug-augnito-USP-in'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='offset-md-1 offset-lg-1 col-md-11 col-lg-8'>
                        <div className='row gx-40'>
                            <div className='col-6 col-md-4'>
                                <div className='mb-60 pb-lg-3'>
                                    <div className='mb-3 aug-augnito-USP__digit'>
                                        24,000
                                    </div>
                                    <div className='aug-augnito-USP__text mb-lg-4'>
                                        Rules Medical Transcription Quality Control
                                    </div>
                                </div>
                            </div>
                            <div className='col-6 col-md-4'>
                                <div className='mb-60 pb-lg-3'>
                                    <div className='mb-3 aug-augnito-USP__digit'>
                                        99%
                                    </div>
                                    <div className='aug-augnito-USP__text mb-lg-4'>
                                        Speech Recognition Accuracy
                                    </div>
                                </div>
                            </div>
                            <div className='col-6 col-md-4'>
                                <div className='mb-60 pb-lg-3'>
                                    <div className='mb-3 aug-augnito-USP__digit'>
                                        75%
                                    </div>
                                    <div className='aug-augnito-USP__text mb-lg-4'>
                                        Reduction in Clinical Documentation Time
                                    </div>
                                </div>
                            </div>
                            <div className='col-6 col-md-4'>
                                <div className='mb-60'>
                                    <div className='mb-3 aug-augnito-USP__digit'>
                                        55+
                                    </div>
                                    <div className='aug-augnito-USP__text'>
                                        Medical Specialties & Sub-specialties
                                    </div>
                                </div>
                            </div>
                            <div className='col-6 col-md-4'>
                                <div className='mb-60'>
                                    <div className='mb-3 aug-augnito-USP__digit'>
                                        11x
                                    </div>
                                    <div className='aug-augnito-USP__text'>
                                        Return on Investment
                                    </div>
                                </div>
                            </div>
                            <div className='col-6 col-md-4'>
                                <div className='mb-60'>
                                    <div className='mb-3 aug-augnito-USP__digit'>
                                        62
                                    </div>
                                    <div className='aug-augnito-USP__text'>
                                        NPS Rating
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AugnitoUSPIN;