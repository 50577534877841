import React from 'react';
import Hero from '../../../Assets/images/ContactUs/banner.webp';
import './ContactBanner.scss';

const ContactBanner = () => {
    return (
        <section className='aug-contact-banner'>
            <div className='aug-contact-banner-img position-relative'>
                <img src={Hero} alt="banner" className='w-100 img-fluid'/>
                <div className='aug-contact-banner-content position-absolute'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="offset-lg-2 offset-md-1 col-md-10 col-lg-8">
                                <h1 className="mb-3">We would love to hear from you!</h1>
                                <p>Let us know what’s on your mind. Learn more about our products, get personalized support, inquire about partnerships—our team would be delighted to assist with anything you may require.</p>
                            </div>
                        </div>
                    </div>
                </div>
           </div>
        </section>
    );
};

export default ContactBanner;