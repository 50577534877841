import React from 'react';
import AutomatedStructuring from './AutomatedStructuring';
import SoapTemplates from './SoapTemplates';
import TeleconsultationSupport from './TeleconsultationSupport';
import APIsIntegration from './APIsIntegration';
import AdditionalHardware from './AdditionalHardware';
import EMRTransfer from './EMRTransfer';


const StandoutFeatures = () => {
    
    return (
        <section className='aug-superior-service aug-speech-models mt-60'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='offset-md-1 offset-lg-1 col-md-10 col-lg-9'>
                        <h2 className="aug-title mb-0">Standout Features</h2>
                    </div>
                </div>
                <div className="row gx-40">
                    <div className="offset-md-1 col-12 col-lg-4 col-md-5">
                        <AutomatedStructuring/>
                    </div>
                    <div className="offset-lg-1 col-12 col-lg-4 col-md-5">
                        <SoapTemplates/>
                    </div>
                    <div className="offset-md-1 col-12 col-lg-4 col-md-5">
                        <TeleconsultationSupport/>
                    </div>
                    <div className="offset-lg-1 col-12 col-lg-4 col-md-5">
                        <APIsIntegration/>
                    </div>
                    <div className="offset-md-1 col-12 col-lg-4 col-md-5">
                        <AdditionalHardware/>
                    </div>
                    <div className="offset-lg-1 col-12 col-lg-4 col-md-5">
                        <EMRTransfer/>
                    </div>
                </div> 
            </div>
        </section>
    );
};

export default StandoutFeatures;