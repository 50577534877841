import React from 'react';
import {ReactComponent as Dollar} from '../../../../Assets/images/icons/dollar-sign.svg';

const SaveHoursIN = () => {
    return (
        <div className='mt-60'>
            <div className='aug-choose-us__icon d-flex align-items-center justify-content-center'>
                <Dollar/>
            </div>
            <h3 className='mb-3 aug-choose-us__heading'>Save 3+ hours daily</h3>
            <p>Drive doctors’ productivity by eliminating cumbersome typing and clicks. Spectra enables you to record more detailed and accurate patient notes in less time. </p>
        </div>
    );
};

export default SaveHoursIN;