import React from 'react';
import './CareerContact.scss'

const CareerContact = () => {
    return (
       <section className='aug-career-contact'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12 offset-md-1 col-md-11 offset-lg-2 col-lg-9'>
                        <h3 className='mb-4'>Don’t see what you’re looking for, or think you might be better suited to another role?</h3>
                        <div>
                        Contact us at 
                        <a href="mailto:careers@augnito.ai"> careers@augnito.ai </a>
                        </div>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default CareerContact;