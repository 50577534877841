import React from 'react';

const AccessFeatures = () => {
    return (
        <div className="accordion-item">
            <h3 className="accordion-header" id="accessFeatures">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseAccessFeatures" aria-expanded="false" aria-controls="collapseAccessFeatures">
                Do I get access to all features during free trial?
                </button>
            </h3>
            <div id="collapseAccessFeatures" className="accordion-collapse collapse" aria-labelledby="accessFeatures" data-bs-parent="#faq">
                <div className="accordion-body">
                    Yes, all of Spectra’s features (available in the chosen plan) are accessible to you during the trial period.
                </div>
            </div>
        </div>
    );
};

export default AccessFeatures;