import React from 'react';
import DocumentationImg from '../../../../Assets/images/Ambient/overview/unrivalled-experience/Effortless-Documentation-Ambient-Feature.webp';

const EffortlessDocumentation = () => {
    return (
        <div className='row gx-0 mt-60 flex-column-reverse flex-md-row'>
            <div className='offset-md-1 col-12 col-md-5 pr-40'>
                <div className='aug-unrivalled-experience-img'>
                    <img src={DocumentationImg} alt="Physician referring to automatic documentation recorded using Augnito Ambient" className='w-100 aug-unrivalled-experience-img img-fluid'/>
                </div>
            </div>
            <div className='col-12 col-md-4'>
                <h3 className='aug-features-glance__heading mb-3'>Effortless documentation </h3>
                <p className='mb-0'>Ambient transcribes the entire doctor-patient conversation in real-time, with absolute accuracy and generates a structured SOAP note. This includes data for each field that a provider would otherwise have to manually fill into the EMR system—like chief complaints, medical history, diagnoses, recommended plan of care, prescriptions, and details of the follow-up appointment, among others. 
                </p>
            </div>
        </div>
    );
};

export default EffortlessDocumentation;