import React from 'react';

const HubSport = () => {
    // useEffect(() => {
    //     const script = document.createElement('script');
    //     script.src='//js.hsforms.net/forms/embed/v2.js';
    //     document.body.appendChild(script);

    //     script.addEventListener('load', () => {
    //         // @TS-ignore
    //         if (window.hbspt) {
    //             // @TS-ignore
    //             window.hbspt.forms.create({
    //                 region: "na1",
    //                 portalId: "9455047",
    //                 formId: "323cb3ab-0b78-413e-a635-a8b5590eafa3",
    //                 target: '#hubspotSalesForm'
    //             })
    //         }
    //     });
    // }, []);

    return (
        <div className='aug-hub-sport'>
            <div className='text-center'>
                <iframe title="zohoFrame" frameBorder="0" id="iframeContactSales" width="100%" height="600"  aria-label='Contact Sales' data-cookieyes="cookieyes-functional" src='https://forms.zohopublic.in/aayushkoshy/form/ContactSales/formperma/8TwTuXSHNncjdxbr5JcsSf40CkeFezl8U849dRFsdt4'></iframe>
            </div>
        </div>
    );
};

export default HubSport;