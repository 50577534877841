import React from 'react';
import Video from '../../../../Assets/images/VoiceServices/overview/VoiceService.webm';
import { useEffect, useRef } from "react";

const VoiceServices = () => {

    const videoRef = useRef(null);
    useEffect(() => {
        let options = {threshold: 0.9};
        let handlePlay = (entries, observer) => {
            entries.forEach((entry) => {
                videoRef.current.pause();
            });
        };
        let observer = new IntersectionObserver(handlePlay, options);
        observer.observe(videoRef.current);
    });

    var videoElement = document.getElementById("videoVoice");
    document.addEventListener("visibilitychange", function() {
        if(document.visibilityState === "hidden" && videoElement)
        {
            videoElement.pause();
        }     
    })

    return (
       <section className='aug-watch-spectra mt-60 mb-60'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='offset-lg-2 offset-md-1 col-md-11 col-lg-8'>
                        <h2 className='aug-title mb-60'>Augnito Voice Services in action</h2>
                    </div>
                </div>
                <div className='row'>
                    <div className='offset-lg-2 offset-md-1 col-12 col-md-10 col-lg-8'>
                        <div className="ratio ratio-16x9">
                            <video ref={videoRef} width="100%" id='videoVoice' className='aug-watch-video' controls>
                                <source src={Video} type="video/webm" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default VoiceServices;
