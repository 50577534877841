import React from 'react';
import VoiceCommands from './VoiceCommands';
import './FeaturesGlance.scss';
import Macros from './Macros';
import CustomVocabulary from './CustomVocabulary';
import VoiceCommandsME from './VoiceCommandsME';
import MacrosME from './MacrosME';
import CustomVocabularyME from './CustomVocabularyME';
import BaseComponent from '../../../../Common/BaseComponent';
import VoiceCommandsIN from './VoiceCommandsIN';
import MacrosIN from './MacrosIN';
import CustomVocabularyIN from './CustomVocabularyIN';

export default class FeaturesGlance extends BaseComponent {

    render() {

        var regionData = this.props.userRegionData;

        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <section className='aug-features-glance aug-spectra-overview-features-glance mt-60'>
            <div className='container-fluid'>
                    <div className='row'>
                        <div className='offset-md-1 offset-lg-2 col-12 col-md-6 col-lg-4'>
                            <h2 className='aug-title mb-0'>Features at a glance</h2>
                        </div>
                    </div>
                    {
                        regionData === "ME" ? <VoiceCommandsME/> :
                        regionData === "IN" ? <VoiceCommandsIN/> :
                        <VoiceCommands/>
                    }
                    {
                        regionData === "ME" ? <MacrosME/> :
                        regionData === "IN" ? <MacrosIN/> :
                        <Macros/>
                    }
                    {
                        regionData === "ME" ? <CustomVocabularyME/> :
                        regionData === "IN" ? <CustomVocabularyIN/> :
                        <CustomVocabulary/>
                    }
            </div> 
            </section>
        );
    }
};