import React from 'react';

const UserFriendlyIN = () => {
    return (
        <div className='mt-60'>
            <h3 className='mb-3 aug-choose-us__heading'>User-friendly software navigation</h3>
            <p>Intuitive, voice-powered controls ensures that clinical systems are easier and faster to operate. This reduces time spent on medical record creation and increases the time available for walk-in or follow-up appointments, research, and more. </p>
        </div>
    );
};

export default UserFriendlyIN;