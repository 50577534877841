import React from 'react';
import {NavLink} from "react-router-dom";
import Img from '../../../../Assets/images/VoiceServices/features/hyper-personalization/custom-formatting.webp';

const CustomFormattingIN = () => {
    return (
        <div className='aug-standout-features mt-60'>
            <div className='row'>
                <div className='col-12'>
                    <div className='aug-standout-features-img p-0'>
                        <img src={Img} alt="Augnito Medical Voice Services settings: Custom formatting window for rule addition open" className='w-100 rounded-10 img-fluid' />
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-11 col-12'>
                    <div className='mt-4 pt-1'>
                        <h3 className='mb-3 aug-standout-features__header'>Highly personalised documentation with custom formatting</h3>
                    </div>
                </div>
                <div className='col-lg-8 col-12'>
                    <p className='mb-0'>Users can set their own text formatting preferences as per preferred reporting style, including the capitalisation of words, numeric representations and more.</p>
                    <div className='mt-40'>
                    <NavLink to="/schedule-demo" className='btn btn-outline-primary'>
                        Book demo
                    </NavLink>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomFormattingIN ;