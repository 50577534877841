import React from 'react';
import ImgMobile from '../../../../Assets/images/VoiceServices/overview/standout-features/ME/free-mobile.webp';
import { NavigateToInternalPage } from '../../../../Common/Utils';

const FreeMobileME = () => {
    return (
        <div className='row gx-0 mt-60 align-items-lg-center'>
            <div className='offset-md-1 offset-lg-2 col-12 col-md-6 col-lg-4 pr-40'>
                <h3 className='aug-features-glance__heading mb-3'>Free mobile mic app & SDK</h3>
                <p className='mb-0'>
                A free-to-download mobile mic app (iOS & Android) and SDK for seamless dictation. No additional hardware required.  
                </p>
                <div className="mt-40">
                    <button onClick={NavigateToInternalPage("/voice-services/features")} className='btn btn-outline-primary'>
                        View all features   
                    </button>
                </div>
            </div>
            <div className='col-12 col-md-5 col-lg-6'>
                <div className='mr-n40 aug-voice-overview-standout-img'>
                    <img src={ImgMobile} alt="Mobile screen with mobile mic voice service open" className='w-100 rounded-ltb-10 img-fluid'/>
                </div>
            </div>
        </div>
    );
};

export default FreeMobileME;