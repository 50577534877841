import React from 'react';

const CustomerTextME = () => {
    return (
       <div className='aug-customer-text'>
           <h3>Dr Gaurang Raval</h3>
           <p>MD, Radiology</p>
           <p className='mb-0'>
            <span className='aug-quote'>“</span>
            <span>Augnito successfully registers my voice to text to 99.9% accuracy. I can vouch for my colleagues in the  hospital and know for a fact that their experiences have been exceptional. Augnito is an industry leader in voice to text software for medical reports, and I fully endorse Augnito for quick, effective, and flawless reports. </span>
            </p>
       </div>
    );
};

export default CustomerTextME;