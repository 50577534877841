import React from 'react';
import SubNavbarVoice from '../../Shared/SubNavbarVoice/SubNavbarVoice';
import FeaturesBanner from './FeaturesBanner/FeaturesBanner';
import StandoutFeatures from './StandoutFeatures/StandoutFeatures';
import HyperPersonalization from './HyperPersonalization/HyperPersonalization';
import ContactSales from './ContactSales/ContactSales';
import DeveloperPortal from './DeveloperPortal/DeveloperPortal';
import VersatileDeployment from './VersatileDeployment/VersatileDeployment';
import SuperiorPlatform from './SuperiorPlatform/SuperiorPlatform';
import BookDemo from './BookDemo/BookDemo';
import CustomerService from './CustomerService/CustomerService';
import GetStarted from './GetStarted/GetStarted';
import BaseComponent from '../../../Common/BaseComponent';
import VoiceServicesFeaturesMeta from './Meta/VoiceServicesFeaturesMeta';
import BookDemoME from './BookDemo/BookDemoME';
import FeaturesBannerIN from './FeaturesBanner/FeaturesBannerIN';
import BookDemoIN from './BookDemo/BookDemoIN';

export default class Features extends BaseComponent {

    render() {
        // const { regionData } = this.state;
        var regionData = this.props.userRegionData;
        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <main>
                <SubNavbarVoice/>
                <VoiceServicesFeaturesMeta/>
                {
                    regionData === "IN" ? <FeaturesBannerIN/> :
                    <FeaturesBanner/>
                }
                <StandoutFeatures userRegionData={regionData}/>
                <HyperPersonalization userRegionData={regionData}/>
                <ContactSales/>
                <DeveloperPortal userRegionData={regionData}/>
                <VersatileDeployment/>
                <SuperiorPlatform/>
                {
                    regionData === "ME" ? <BookDemoME/> :
                    regionData === "IN" ? <BookDemoIN/> :
                    <BookDemo/>
                }
                <CustomerService userRegionData={regionData}/>
                <GetStarted/>
            </main>
        );
    }
}