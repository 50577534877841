import React from 'react';
import './ChooseUs.scss';

const ChooseUs = () => {
    return (
       <section className='aug-ambient-chhose-us aug-what-offer'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='offset-lg-1 offset-md-1 col-md-10'>
                        <h2 className='aug-title mb-60'>Why choose us</h2>
                    </div>
                </div>
                <div className='row gx-40'>
                    <div className='offset-md-1 col-12 col-lg-4 col-md-5'>
                        <div className='mb-60'>
                            <h3 className='mb-3 aug-what-offer__header'>Ease of use </h3>
                            <p className='mb-0'>We understand the demanding nature of healthcare settings, so our product is designed to be intuitive and efficient. With quick adoption and no extensive training required, providers can experience benefits from day one.</p>
                        </div>
                    </div>
                    <div className='offset-lg-1 col-12 col-lg-4 col-md-5'>
                        <div className='mb-60'>
                            <h3 className='mb-3 aug-what-offer__header'>Compatible with popular EHRs </h3>
                            <p className='mb-0'>Works seamlessly with any EHR/PACS/RIS without the need for native integration. Compatible with eClinicalWorks, Epic, Cerner, Athena and many more. </p>
                        </div>
                    </div>
                    <div className='offset-md-1 col-12 col-lg-4 col-md-5'>
                        <div className='mb-60'>
                            <h3 className='mb-3 aug-what-offer__header'>Ability to understand multiple languages and accents</h3>
                            <p className='mb-0'>Ambient supports multiple global languages and accents, allowing providers to seamlessly interact with patients in their preferred dialect. The multi-lingual conversation is appropriately translated into English to generate the SOAP note.  </p>
                        </div>
                    </div>                            
                    <div className='offset-lg-1 col-12 col-lg-4 col-md-5'>
                        <div className='mb-60'>
                            <h3 className='mb-3 aug-what-offer__header'>Affordable healthcare technology accessible to all</h3>
                            <p className='mb-0'>We firmly believe in making advanced healthcare technology accessible to everyone without compromising quality. Our pricing model ensures that healthcare providers of all sizes can harness the transformative power of Ambient.</p>
                        </div>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default ChooseUs;