import React from 'react';
import { NavLink } from 'react-router-dom';
import './WorkWithUs.scss';
import ImgUs from '../../../Assets/images/AboutUs/work-with-us.webp';

const WorkWithUs = () => {
    return (
       <section className='aug-work-with-us'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12 offset-md-1 col-md-11 offset-lg-2 col-lg-9'>
                        <h3 className='aug-title mb-60'>Work with us</h3>
                        <div className=''>
                            <div className='mb-4 pb-1'>
                                <img src={ImgUs} alt="People at Augnito" className="rounded-10 img-fluid w-100"/>
                            </div>
                            <p className='mb-4'>At Augnito, we believe in adopting a people-first in approach all that we do. Whether that translates as a human-centric product or an employee-focused organization. Our ethos is to create a team of dedicated and ambitious individuals who are willing to push the boundaries at every step and dedicate themselves to reinventing the future of healthcare.</p>
                            <p className='mb-0'>Meaningful impact is only created through deep research, building advanced technologies, and nurturing extraordinary people who are in turn committed to building avant-garde products and services.</p>
                            <div className='mt-40'>
                                <NavLink to="/careers" className='btn btn-primary'>Search open jobs</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default WorkWithUs;