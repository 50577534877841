import React from 'react';
import AttentiveResponse from './AttentiveResponse';
import CreativeIntuitive from './CreativeIntuitive';
import InnovativeAccessible from './InnovativeAccessible';
import './OurEthos.scss';

const OurEthos = () => {
    return (
        <section className='aug-positive-stake aug-about-our-ethos'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='offset-lg-2 offset-md-1 col-md-11 col-lg-9'>
                        <h2 className='aug-title mb-60 mt-60'>Our ethos</h2>
                    </div>
                    <div className='offset-lg-2 offset-md-1 col-md-11 col-lg-10'>
                        <ul className="aug-nav-tab nav nav-pills" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link px-0 active" id="InnovativeAccessible-tab" data-bs-toggle="pill" data-bs-target="#InnovativeAccessible" type="button" role="tab" aria-controls="InnovativeAccessible" aria-selected="true">Innovative & Accessible</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link px-0" id="CreativeIntuitive-tab" data-bs-toggle="pill" data-bs-target="#CreativeIntuitive" type="button" role="tab" aria-controls="CreativeIntuitive" aria-selected="false">Creative & Intuitive</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link px-0" id="AttentiveResponse-tab" data-bs-toggle="pill" data-bs-target="#AttentiveResponse" type="button" role="tab" aria-controls="AttentiveResponse" aria-selected="false">Attentive & Responsive</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='aug-tab-content'>
                <div className='container-fluid'>
                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="InnovativeAccessible" role="tabpanel" aria-labelledby="InnovativeAccessible-tab">
                            <InnovativeAccessible/>
                        </div>
                        <div className="tab-pane fade" id="CreativeIntuitive" role="tabpanel" aria-labelledby="CreativeIntuitive-tab">
                            <CreativeIntuitive/>
                        </div>
                        <div className="tab-pane fade" id="AttentiveResponse" role="tabpanel" aria-labelledby="AttentiveResponse-tab">
                            <AttentiveResponse/>
                        </div>
                    </div>   
                </div>       
            </div> 
        </section>
    );
};

export default OurEthos;