import { Helmet } from "react-helmet";
import * as Constants from '../../../Common/Constants'

const RefundPolicyMeta = () => {

    return (
        <Helmet>
            <title> Refund & Cancelation Policy | Augnito </title>
            <link rel="shortcut icon" href={Constants.SITE_URL + "/favicon.ico"} />
            <link rel="icon" href={Constants.SITE_URL + "/favicon.ico"} />
            <link rel="profile" href="https://gmpg.org/xfn/11" />
            <meta name="description" content="Augnito’s refund & cancelation policy covers all aspects of subscription refund and cancelation terms for Augnito. Learn more about the billing process, subscription cancelation, and refund terms."/>
            <link rel="canonical" href={Constants.SITE_URL + "legal/refund-cancellation-policy"} />
            <meta property="og:url" content={Constants.SITE_URL + "legal/refund-cancellation-policy"} />
            <meta property="og:title" content="Refund & Cancelation Policy | Augnito" />
            <meta property="og:description" content="Augnito’s refund & cancelation policy covers all aspects of subscription refund and cancelation terms for Augnito. Learn more about the billing process, subscription cancelation, and refund terms." />
            <meta property="og:image" content={Constants.SITE_URL + "/logo.png"} />
            <meta property="og:type" content="website" />
            <meta property="og:locale" content="en_US" />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:url" content={Constants.SITE_URL + "legal/refund-cancellation-policy"}/>
            <meta name="twitter:site" content="@augnito" />
            <meta name="twitter:title" content="Refund & Cancelation Policy | Augnito" />
            <meta name="twitter:description" content="Augnito’s refund & cancelation policy covers all aspects of subscription refund and cancelation terms for Augnito. Learn more about the billing process, subscription cancelation, and refund terms." />
            <meta name="twitter:creator" content="@augnito" />
            <meta name="twitter:image" content={Constants.SITE_URL + "/logo.png"} />
            <meta name="copyright" content="Augnito" />
            <meta name="owner" content="Augnito" />
            <link rel="alternate" href={Constants.SITE_URL} hreflang="en-us" />
            <meta name="google-site-verification" content="TivMeqLjfaI6TIRsSF0Kqw0J8UnBPK2PToomuQRwVCs" />
        </Helmet>
    );
};

export default RefundPolicyMeta;