import React from 'react';
import IndividualImg from '../../../../Assets/images/VoiceServices/overview/superior-service/testing-support.webp';

const PostIntegration = () => {
    return (
        <div className='aug-card'>
            <img src={IndividualImg} alt="Inspect element of a page shown on Augnito Medical Voice Recognition platform" className="w-100 aug-superior-service-img img-fluid"/>
            <div className='aug-card-body p-4'>
                <h3 className='mb-3 aug-card-header'>Post-integration testing support</h3>
                <p className='mb-0'>At Augnito, we not only understand the various use-cases of voice integration, but also the significance of user experience. Our team will provide a thorough testing service and reports on request.</p>
            </div>
        </div>
    );
};

export default PostIntegration;