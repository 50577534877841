import React from 'react';
import './CollageGallery.scss'


const CollageGallery = () => {
    return (
       <section className='aug-collage-gallery mb-60 mt-60'>
            <div className='row g-8'>
                <div className='col-lg-2 col-md-4 col-6'>
                    <img src={require('../../../Assets/images/Careers/Collage/1.webp')} className="img-fluid" alt="Culture and events at Augnito"/>
                </div>
                <div className='col-lg-2 col-md-4 col-6'>
                    <img src={require('../../../Assets/images/Careers/Collage/2.webp')} className="img-fluid" alt="Culture and events at Augnito"/>
                </div>
                <div className='col-lg-2 col-md-4 col-6'>
                    <div className='aug-collage-box aug-collage-box-first'></div>
                </div>
                <div className='col-lg-2 col-md-4 col-6'>
                    <img src={require('../../../Assets/images/Careers/Collage/3.webp')} className="img-fluid" alt="Culture and events at Augnito"/>
                </div>
                <div className='col-lg-2 col-md-4 col-6'>
                    <img src={require('../../../Assets/images/Careers/Collage/4.webp')} className="img-fluid" alt="Culture and events at Augnito"/>
                </div>
                <div className='col-lg-2 col-md-4 col-6'>
                    <div className='aug-collage-box aug-collage-box-secound'></div>
                </div>
                <div className='col-lg-2 col-md-4 col-6 d-none d-md-block'>
                    <img src={require('../../../Assets/images/Careers/Collage/5.webp')} className="img-fluid" alt="Culture and events at Augnito"/>
                </div>
                <div className='col-lg-2 col-md-4 col-6 d-none d-md-block'>
                    <div className='aug-collage-box aug-collage-box-third'></div>
                </div>
                <div className='col-lg-2 col-md-4 col-6 d-none d-md-block'>
                    <img src={require('../../../Assets/images/Careers/Collage/6.webp')} className="img-fluid" alt="Culture and events at Augnito"/>
                </div>
                <div className='col-lg-2 col-md-4 col-6 d-none d-lg-block'>
                    <img src={require('../../../Assets/images/Careers/Collage/7.webp')} className="img-fluid" alt="Culture and events at Augnito"/>
                </div>
                <div className='col-lg-2 col-md-4 col-6 d-none d-lg-block'>
                    <div className='aug-collage-box aug-collage-box-first'></div>
                </div>
                <div className='col-lg-2 col-md-4 col-6 d-none d-lg-block'>
                    <img src={require('../../../Assets/images/Careers/Collage/8.webp')} className="img-fluid" alt="Culture and events at Augnito"/>
                </div>
                <div className='col-lg-2 col-md-4 col-6 d-none d-lg-block'>
                    <div className='aug-collage-box aug-collage-box-secound'></div>
                </div>
                <div className='col-lg-2 col-md-4 col-6 d-none d-lg-block'>
                    <img src={require('../../../Assets/images/Careers/Collage/9.webp')} className="img-fluid" alt="Culture and events at Augnito"/>
                </div>
                <div className='col-lg-2 col-md-4 col-6 d-none d-lg-block'>
                    <img src={require('../../../Assets/images/Careers/Collage/10.webp')} className="img-fluid" alt="Culture and events at Augnito"/>
                </div>
                <div className='col-lg-2 col-md-4 col-6 d-none d-lg-block'>
                    <img src={require('../../../Assets/images/Careers/Collage/11.webp')} className="img-fluid" alt="Culture and events at Augnito"/>
                </div>
                <div className='col-lg-2 col-md-4 col-6 d-none d-lg-block'>
                    <img src={require('../../../Assets/images/Careers/Collage/12.webp')} className="img-fluid" alt="Culture and events at Augnito"/>
                </div>
                <div className='col-lg-2 col-md-4 col-6 d-none d-lg-block'>
                    <div className='aug-collage-box aug-collage-box-third'></div>
                </div>
            </div>
       </section>
    );
};

export default CollageGallery;