import React from 'react';
import BaseComponent from '../../../../Common/BaseComponent';
import SpectraTrustedBy from '../../Overview/TrustedBy/SpectraTrustedBy';
import SpectraTrustedByME from '../../Overview/TrustedBy/SpectraTrustedByME';
import './SecureCompliant.scss';
import SpectraTrustedByIN from '../../Overview/TrustedBy/SpectraTrustedByIN';

export default class SecureCompliant extends BaseComponent {

    render() {
        // const { regionData } = this.state;
        var regionData = this.props.userRegionData;
        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <section className='aug-secure-compliant'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='offset-md-1 offset-lg-2 col-md-11 col-lg-10'>
                            <h2 className="aug-title mb-60">Secure & compliant</h2>
                            {
                                regionData === "ME" ? <SpectraTrustedByME/> :
                                regionData === "IN" ? <SpectraTrustedByIN/> :
                                <SpectraTrustedBy/>
                            }
                        </div>
                    </div>
                </div>
            </section>
        );
    };
};