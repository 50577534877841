import React from 'react';
import Spectra from './Spectra';
import VoiceServices from './VoiceServices';
import BaseComponent from '../../../Common/BaseComponent';
import SpectraIN from './SpectraIN';
import VoiceServicesIN from './VoiceServicesIN';
import SpectraME from './SpectraME';

export default class OurProducts extends BaseComponent {

    render() {
        // const { regionData } = this.state;
        var regionData = this.props.userRegionData;
        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <section className='aug-what-we-offer mt-60'>
                <div className='container-fluid'>
                    <div className="row">
                        <div className="offset-md-1 offset-lg-2 col-12 col-md-6 col-lg-4">
                            <h2 className="aug-title mb-60">Our products</h2>
                        </div>
                        {
                            regionData === "ME" ? <SpectraME/> :
                            regionData === "IN" ? <SpectraIN/> :
                            <Spectra/>
                        }
                        {
                            regionData === "IN" ? <VoiceServicesIN/> :
                            <VoiceServices/>
                        }
                    </div>
                </div>
            </section>
        );
    };
};