import React from 'react';
import HospitalImg from '../../../../Assets/images/Spectra/OurCustomer/IN/hospitals.webp';

const LargeHospitalsIN = () => {
    return (
        <div className='aug-card'>
            <img src={HospitalImg} alt="Group of doctor discussing in the hospital" className="w-100 img-fluid"/>
            <div className='aug-card-body p-4'>
                <h3 className='mb-3 aug-card-header'>Large hospitals</h3>
                <p className='mb-0'>Spectra is designed and developed for large scale roll outs. Available on both cloud & on-premises deployment models, it offers administrative controls that makes monitoring and managing licenses easy. We also provide dedicated Customer Success Managers to streamline onboarding and training of doctors across large hospitals. </p>
            </div>
        </div>
    );
};

export default LargeHospitalsIN;