import React from 'react';
import video from '../../../Assets/images/home/ASR_NLP_Technology.webm';
import {ReactComponent as Close} from '../../../Assets/images/icons/times.svg';

const WatchDemo = () => {

    const videoPause = () => {
        document.querySelector('.aug-watch-video').pause();
        // if (document.getElementById("videoHome"))
        //     {
        //     video = document.getElementById("videoHome")
        //     video.currentTime = 0;
        // }
    }

    var videoElement = document.getElementById("videoHome");
    document.addEventListener("visibilitychange", function() {
        // if(document.visibilityState === "visible")
        // {
        //     videoElement.play();
        // }
        // else if(document.visibilityState === "hidden")
        // {
        
        //     videoElement.pause();
        // } 
        if(document.visibilityState === "hidden" && videoElement)
        {
            videoElement.pause();
        }     
    })


    return (
        <div className="modal fade" id="whatchDemo" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="whatchDemoLabel" aria-hidden="true">
            <div className="aug-watch-demo-modal modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body p-0 rounded-0">
                        <div className='aug-modal-close' onClick={videoPause} data-bs-dismiss="modal" aria-label="Close">
                            <Close/>
                        </div>
                        <div className="ratio ratio-16x9">
                            <video width="100%" id='videoHome' className='aug-watch-video' controls>
                                <source src={video} type="video/webm" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WatchDemo;