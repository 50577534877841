import React from 'react';
import CustomSDK from '../../Overview/StandoutFeatures/CustomSDK';
import CommandControl from './CommandControl';
import FreeMobile from './FreeMobile';
import PlatformAvailability from './PlatformAvailability';
import PlugPlay from './PlugPlay';
import './StandoutFeatures.scss';
import CommandControlME from './CommandControlME';
import BaseComponent  from '../../../../Common/BaseComponent';
import FreeMobileME from './FreeMobileME';
import PlatformAvailabilityME from './PlatformAvailabilityME';
import FreeMobileIN from './FreeMobileIN';
import CommandControlIN from './CommandControlIN';
import PlatformAvailabilityIN from './PlatformAvailabilityIN';

export default class StandoutFeatures extends BaseComponent {

    render() {
        // const { regionData } = this.state;
        var regionData = this.props.userRegionData;
        if (this.props.isGeoLoading) {
            return;
        }
        return (
            <section className='aug-features-glance mt-60 mb-60 aug-voice-features-standout'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='offset-md-1 offset-lg-2 col-12 col-md-6 col-lg-4'>
                            <h2 className='aug-title mb-0'>Standout features</h2>
                        </div>
                    </div>
                    <PlugPlay/>
                    <CustomSDK/>
                    {
                        regionData === "ME" ? <FreeMobileME/> :
                        regionData === "IN" ? <FreeMobileIN/> :
                        <FreeMobile/>
                    }
                    {
                        regionData === "ME" ? <CommandControlME/> :
                        regionData === "IN" ? <CommandControlIN/> :
                        <CommandControl/>
                    }
                    {
                        regionData === "ME" ? <PlatformAvailabilityME/> :
                        regionData === "IN" ? <PlatformAvailabilityIN/> :
                        <PlatformAvailability/>
                    } 
                </div> 
            </section>
        );
    };
};