import React from 'react';

const SaveHoursME = () => {
    return (
        <div className='mt-60'>
            <h3 className='mb-3 aug-choose-us__heading'>Save 3+ hours daily</h3>
            <p>Drive physicians’ productivity by eliminating cumbersome typing and clicks. Spectra enables you to record more detailed and accurate patient notes in less time. </p>
        </div>
    );
};

export default SaveHoursME;