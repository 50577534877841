import React from 'react';
import {Link} from "react-router-dom";
import Logo from '../../../Assets/images/logo.svg';
import { ScrollTop } from '../../../Common/Utils';

const FooterLogo = () => {
    return (
        <div className='aug-footer-logo'>
            <div className='row'>
                <div className='col-12 col-md-4'>
                    <Link to="/" onClick={ScrollTop}>
                        <img src={Logo} alt="Augnito logo" className='aug-augnito-logo'/>  
                    </Link>
                </div>
                <div className='col-12 col-md-8'>
                    <div className='aug-brand-logo'>
                        <ul className='list-unstyled mb-0 d-flex align-items-center flex-wrap justify-content-md-end'>
                            <li>
                                <img src={require('../../../Assets/images/Compliance/hipaa.webp')} className="img-fluid" height={33} width={63} alt="hipaa logo"/>
                            </li>
                            <li>
                                <img src={require('../../../Assets/images/Compliance/aicpa.webp')} className="img-fluid" height={42} width={42} alt="aicpa soc2 logo"/>
                            </li>
                            <li>
                                <img src={require('../../../Assets/images/Compliance/iso-27001.webp')} className="img-fluid" height={42} width={42} alt="iso27001 logo"/>
                            </li>
                            
                            {/* <li>
                                <img src={require('../../../Assets/images/Compliance/cyber.webp')} className="img-fluid" height={42} width={48} alt="iso logo"/>
                            </li>
                            <li>
                                <img src={require('../../../Assets/images/Compliance/iso-news.webp')} className="img-fluid" height={42} width={42} alt="iso logo"/>
                            </li>
                            <li>
                                <img src={require('../../../Assets/images/Compliance/iso.webp')} className="img-fluid" height={42} width={42} alt="iso logo"/>
                            </li>
                            <li>
                                <img src={require('../../../Assets/images/Compliance/eugdpr.webp')} className="img-fluid" height={42} width={96} alt="eu gdpr logo"/>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FooterLogo;