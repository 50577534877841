import React from 'react';
import './CareerFlexibility.scss'
import CareerFlexibilityLeft from './CareerFlexibilityLeft';
import CareerFlexibilityRight from './CareerFlexibilityRight';

const CareerFlexibility = () => {
    return (
       <section className='aug-career-flexibility mb-60'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12 offset-md-1 col-md-11 offset-lg-2 col-lg-10'>
                        <h2 className='aug-title mb-60'>Flexibility is key</h2>
                    </div>
                </div>
                <div className='row gx-40 flex-column-reverse flex-md-row'>
                    <div className='offset-lg-2 offset-md-1 col-12 col-md-7 col-lg-6'>
                        <CareerFlexibilityLeft/>
                    </div>
                    <div className='col-12 col-md-4'>
                        <CareerFlexibilityRight/>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default CareerFlexibility;