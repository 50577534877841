import React from 'react';
import AccentsSpellings from './AccentsSpellings';
import DataNormalization from './DataNormalization';
import RealTime from './RealTime';
import VoiceNavigation from './VoiceNavigation';
import VoiceNavigationME from './VoiceNavigationME';
import BaseComponent from '../../../../Common/BaseComponent';
import AccentsSpellingsME from './AccentsSpellingsME';
import DataNormalizationME from './DataNormalizationME';
import VoiceNavigationIN from './VoiceNavigationIN';
import AccentsSpellingsIN from './AccentsSpellingsIN';
import DataNormalizationIN from './DataNormalizationIN';

export default class ComprehensiveVoice extends BaseComponent {

    render() {
        // const { regionData } = this.state;
        var regionData = this.props.userRegionData;
        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <section className='aug-superior-service aug-speech-models mt-60'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='offset-md-1 offset-lg-2 col-md-11 col-lg-10'>
                            <h2 className="aug-title">A comprehensive voice service</h2>
                        </div>
                        <div className="offset-lg-2 offset-md-1 col-md-11 col-lg-9">
                            <div className="row gx-40">
                                <div className="col-12 col-md-6">
                                    <RealTime/>
                                </div>
                                <div className="col-12 col-md-6">
                                    {
                                        regionData === "IN" ? <VoiceNavigationIN/> :
                                        regionData === "ME" ? <VoiceNavigationME/> :
                                        <VoiceNavigation/>
                                    }
                                </div>
                                <div className="col-12 col-md-6">
                                    {
                                        regionData === "IN" ? <AccentsSpellingsIN/> :
                                        regionData === "ME" ? <AccentsSpellingsME/> :
                                        <AccentsSpellings/>
                                    }
                                </div>
                                <div className="col-12 col-md-6">
                                    {
                                        regionData === "IN" ? <DataNormalizationIN/> :
                                        regionData === "ME" ? <DataNormalizationME/> :
                                        <DataNormalization/>
                                    }
                                </div>
                            </div>                
                        </div>
                    </div>
                </div>
            </section>
        );
    };
};