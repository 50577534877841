import React from 'react';

const APISpecification = () => {
    return (
       <div className='mb-60'>
            <h3 className='mb-3'>API specifications</h3>
            <p className='mb-0'>A list of available API end points, parameters, and responses.</p>
       </div>
    );
};

export default APISpecification;