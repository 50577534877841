import React from 'react';
import './StandoutFeatures.scss';
import IncreasedProductivity from './IncreasedProductivity'
import CustomVocabulary from './CustomVocabulary'
import CustomVocabularyME from './CustomVocabularyME';
import BaseComponent from '../../../../Common/BaseComponent';
import CustomVocabularyIN from './CustomVocabularyIN';

export default class StandoutFeatures extends BaseComponent {

    render() {
        // const { regionData } = this.state;
        var regionData = this.props.userRegionData;
        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <section>
                <div className='container-fluid'>
                    <div className="row">
                        <div className="offset-md-1 offset-lg-2 col-12 col-md-11 col-lg-10">
                            <h2 className="aug-title mb-60">Standout features</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="offset-md-1 offset-lg-2 col-12 col-md-11 col-lg-10">
                            <IncreasedProductivity />
                            {
                                regionData === "ME" ? <CustomVocabularyME/> :
                                regionData === "IN" ? <CustomVocabularyIN/> :
                                <CustomVocabulary />
                            }
                        </div>
                    </div>
                </div>
            </section>
        );
    };
};