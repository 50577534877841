import React from 'react';
import GetStarted from '../../../../Assets/images/VoiceServices/overview/get-started.webp';
import './VoiceGetStarted.scss';
import { GetTrialDaysByProduct, RedirectToSignUpVoiceServices } from '../../../../Common/Utils';
import * as Constants from '../../../../Common/Constants';

const VoiceGetStarted = () => {
    var trailDays = GetTrialDaysByProduct(Constants.VOICE_LISTING_NAME);
    return (
       <section className='aug-features-days position-relative'>
            <div className='aug-features-days__img h-100'>
                <img src={GetStarted} alt="banner footer" className="h-100 img-fluid"/>
            </div>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='offset-lg-2 offset-md-2 col-12 col-md-10 col-lg-6'>
                        <div className='aug-features-days-box position-absolute'>
                            <h2 className='mb-3'>Get free, unlimited access to Voice Services for {trailDays} Days</h2>
                            <p>No credit card required.<br/>No implementation and training fees.</p>
                            <button type='button' className='btn btn-primary' onClick={RedirectToSignUpVoiceServices}>Get started</button>
                        </div>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default VoiceGetStarted;