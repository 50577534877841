import React from 'react';
import IndividualImg from '../../../../Assets/images/Ambient/overview/superior-service/Compliance-Ambient.webp';

const HIPAACompliant = () => {
    return (
        <div className='aug-card'>
            <img src={IndividualImg} alt="Hands extended with compliance logo resting on top of it" className="w-100 aug-superior-service-img img-fluid"/>
            <div className='aug-card-body p-4'>
                <h3 className='mb-3 aug-card-header'>HIPAA compliant & secure </h3>
                <p className='mb-0'>Ambient is HIPAA, GDPR, SOC 2 and ISO 27001 compliant with strict information security policies. </p>
            </div>
        </div>
    );
};

export default HIPAACompliant;