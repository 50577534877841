import React from 'react';
import SubNavbar from '../../Shared/SubNavbar/SubNavbar';
import BookDemo from '../../Home/BookDemo/BookDemo';
import ChooseUs from './ChooseUs/ChooseUs';
import FeaturesGlance from './FeaturesGlance/FeaturesGlance';
import OurCustomer from './OurCustomer/OurCustomer';
import OurReach from './OurReach/OurReach';
import SpectraUSP from './SpectraUSP/SpectraUSP';
import TrustedBy from './TrustedBy/TrustedBy';
import TrySpectraFree from './TrySpectraFree/TrySpectraFree';
import WatchSpectra from './WatchSpectra/WatchSpectra';
import WhatOffer from './WhatOffer/WhatOffer';
import TryDays from './TryDays/TryDays';
import SubscriptionPlan from './SubscriptionPlan/SubscriptionPlan';
import SpectraMeta from './Meta/SpectraMeta';
import BaseComponent from '../../../Common/BaseComponent';
import SpectraBanner from './SpectraBanner/SpectraBanner';
import CustomersSay from './CustomersSay/CustomersSay';
import WhatOfferME from './WhatOffer/WhatOfferME';
import SpectraUSPME from './SpectraUSP/SpectraUSPME';
import CustomersSayME from './CustomersSay/CustomersSayME';
import TryDaysME from './TryDays/TryDaysME';
import SpectraBannerME from './SpectraBanner/SpectraBannerME';
import TrustedByME from './TrustedBy/TrustedByME';
import SpectraBannerIN from './SpectraBanner/SpectraBannerIN';
import WhatOfferIN from './WhatOffer/WhatOfferIN';
import TrustedByIN from './TrustedBy/TrustedByIN';
import SpectraUSPIN from './SpectraUSP/SpectraUSPIN';
import CustomersSayIN from './CustomersSay/CustomersSayIN';
import TryDaysIN from './TryDays/TryDaysIN';

export default class SpectraOverview extends BaseComponent {
    
    render() {
        // const { regionData } = this.state;
        var regionData = this.props.userRegionData;
        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <div>
                <SubNavbar/>
                <SpectraMeta />
                {
                    regionData === "ME" ? <SpectraBannerME/> :
                    regionData === "IN" ? <SpectraBannerIN/>  :
                    <SpectraBanner/>
                }
                <WatchSpectra />
                {
                    regionData === "ME" ? <WhatOfferME/> :
                    regionData === "IN" ? <WhatOfferIN/> :
                    <TrustedBy/>
                }
                {
                    regionData === "ME" ? <TrustedByME/> :
                    regionData === "IN" ? <TrustedByIN/> :
                    <WhatOffer />
                }
                {
                    regionData === "ME" ? <SpectraUSPME/> :
                    regionData === "IN" ? <SpectraUSPIN/> :
                    <SpectraUSP />
                }
                <FeaturesGlance userRegionData={regionData}/>
                <ChooseUs userRegionData={regionData}/>
                <OurReach userRegionData={regionData}/>
                <OurCustomer userRegionData={regionData}/>
                <TrySpectraFree />
                <SubscriptionPlan isGeoLoading={this.props.isGeoLoading}
                            productPlanDetails={this.state.priceData}
                            innerProductListId={this.props.productListId}
                            isPriceLoading={this.state.isPriceLoading} />    
                <BookDemo>
                    <div>
                        Robust medical vocabularies and 99% out-of-the-box accuracy for 50+ specialties
                    </div>
                </BookDemo>
                <div className='mt-60'>
                    {
                        regionData === "ME" ? <CustomersSayME/> :
                        regionData === "IN" ? <CustomersSayIN/> :
                        <CustomersSay/>
                    }
                </div>
                    {
                        regionData === "ME" ? <TryDaysME/> :
                        regionData === "IN" ? <TryDaysIN/> :
                        <TryDays/>
                    }                
            </div>
        );
    }
}