import React from 'react';
import CustomFormatting from './CustomFormatting';
import CustomVocabulary from './CustomVocabulary';
import IncreasedProductivity from './IncreasedProductivity';
import BaseComponent from '../../../../Common/BaseComponent';
import CustomFormattingME from './CustomFormattingME';
import CustomVocabularyME from './CustomVocabularyME';
import CustomFormattingIN from './CustomFormattingIN';
import CustomVocabularyIN from './CustomVocabularyIN';

export default class HyperPersonalization extends BaseComponent {
    render() {
        // const { regionData } = this.state;
        var regionData = this.props.userRegionData;
        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <section className='mb-60'>
                <div className='container-fluid'>
                    <div className="row">
                        <div className="offset-md-1 offset-lg-2 col-12 col-md-11 col-lg-10">
                            {
                                regionData === "ME" ? <h2 className="aug-title mb-60 mt-60">Hyper personalisation
                                </h2> :
                                regionData === "IN" ? <h2 className="aug-title mb-60 mt-60">Hyper personalisation 
                                </h2> :
                                <h2 className="aug-title mb-60 mt-60">Hyper personalization</h2>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="offset-md-1 offset-lg-2 col-12 col-md-11 col-lg-10">
                            <IncreasedProductivity/>
                            {
                                regionData === "ME" ? <CustomFormattingME/> :
                                regionData === "IN" ? <CustomFormattingIN/> :
                                <CustomFormatting/>
                            }
                            {
                                regionData === "ME" ? <CustomVocabularyME/> :
                                regionData === "IN" ? <CustomVocabularyIN/> :
                                <CustomVocabulary/>
                            }
                        </div>
                    </div>
                </div>
            </section>
        );
    };
};