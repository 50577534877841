import React from 'react';
import RecognitionImg from '../../../Assets/images/AboutUs/TheVoice/voice-recognition.webp';

const VoiceRecognition = () => {
    return (
        <div className='aug-card'>
            <img src={RecognitionImg} alt="Doctor using Augnitos' mobile mic app to dictate reports on Augnito Spectra leveraging voice AI" className="w-100 img-fluid"/>
            <div className='aug-card-body p-4'>
                <h3 className='mb-3 aug-card-header'>Voice Recognition: The AI That’s Transforming Urgent Care</h3>
                <p className='mb-0'>Clinical voice-recognition technology boosts ROI and improves treatment by speeding up record keeping and reducing physician burnout</p>
            </div>
        </div>
    );
};

export default VoiceRecognition;