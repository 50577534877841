import React from 'react';
import HealthcareAI from './HealthcareAI';
import ImperativesHealthcare from './ImperativesHealthcare';
import './TheVoice.scss';
import VoiceRecognition from './VoiceRecognition';
import * as Constants from '../../../Common/Constants';

const TheVoice = () => {
   
    return (
      <section className='aug-the-voice aug-our-customer'>
        <div className='container-fluid'>
            <div className='row'>
                <div className='offset-lg-2 offset-md-1 col-md-11 col-lg-8'>
                <h2 className='aug-title mb-60'>The Voice</h2>
                </div>
            </div>
            <div className='row'>
                <div className='offset-lg-2 offset-md-1 col-12 col-md-10 col-lg-10'>
                    <div className='row'>
                        <a href={Constants.SITE_URL + "resources/blog/the-ai-thats-transforming-urgent-care"} target="_blank" rel="noreferrer" className='col-12 col-md-12 col-lg-4'>
                         <VoiceRecognition/>
                        </a>
                        <a href={Constants.SITE_URL + "resources/blog/healthcare-ai-the-new-oil-barons"} target="_blank" rel="noreferrer" className='col-12 col-md-12 col-lg-4'>
                            <HealthcareAI/>
                        </a>
                        <a href={Constants.SITE_URL + "resources/blog/how-voice-recognition-supports-the-big-3-imperatives-of-healthcare-better-patient-outcomes-less-provider-burnout-and-stronger-revenue"} target="_blank" rel="noreferrer" className='col-12 col-md-12 col-lg-4'>
                            <ImperativesHealthcare/>
                        </a>
                    </div>
                    <div className='mt-40'>
                        <a href={Constants.SITE_URL + "resources/blog"} target="_blank" rel="noreferrer" className='btn btn-outline-primary'>Discover more</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
};

export default TheVoice;