import React from 'react';
import HospitalImg from '../../../../Assets/images/Spectra/OurCustomer/hospitals.webp';

const LargeHospitals = () => {
    return (
        <div className='aug-card'>
            <img src={HospitalImg} alt="Medical professionals discussing speech recognition technology" className="w-100 img-fluid"/>
            <div className='aug-card-body p-4'>
                <h3 className='mb-3 aug-card-header'>Large hospitals</h3>
                <p className='mb-0'>Spectra is designed and developed for large scale roll outs. Available on both cloud & on premise deployment models, it offers administrative controls that makes monitoring and managing licenses easy. We also provide dedicated Customer Success Managers to streamline onboarding and training of providers across large hospitals.</p>
            </div>
        </div>
    );
};

export default LargeHospitals;