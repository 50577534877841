import React from 'react';
import BaseComponent from '../../Common/BaseComponent';
import LegalBanner from './LegalBanner/LegalBanner';
import LegalMeta from './Meta/LegalMeta';
import PageCard from './PageCard/PageCard';
import './LegalPage.scss';

export default class Legal extends BaseComponent {
    render() {

        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <main>
                <LegalMeta/>
                <LegalBanner/>
                <PageCard/>
            </main>
        );
    }
}
