import React from 'react';
import BaseComponent from '../../Common/BaseComponent';
import SecureCompliant from '../VoiceServices/Overview/SecureCompliant/SecureCompliant';
import BookDemoBanner from './BookDemoBanner/BookDemoBanner';
import BookDemoSpectra from './BookDemoSpectra/BookDemoSpectra';
import BookDemoMeta from './Meta/BookDemoMeta';
import TrustedByDemo from './TrustedByDemo/TrustedByDemo';
import './BookDemo.scss';
import SecureCompliantME from '../VoiceServices/Overview/SecureCompliant/SecureCompliantME';
import SecureCompliantIN from '../VoiceServices/Overview/SecureCompliant/SecureCompliantIN';

export default class BookDemo extends BaseComponent {
    render() {
        var regionData = this.props.userRegionData;
        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <main>
                <BookDemoMeta/>
                <BookDemoBanner/>
                <div className='mt-120 pt-1'>
                   <div className='py-60'>
                        <div className='pt-lg-5 mt-5'></div>
                    </div> 
                </div>
                <div className='aug-book-demo-secure-compliant'>
                    {
                        regionData === "ME" ? <SecureCompliantME/> :
                        regionData === "IN" ? <SecureCompliantIN/> :
                        <SecureCompliant/>
                    }
                </div>
                <BookDemoSpectra/>
                <TrustedByDemo userRegionData={regionData}/>
            </main>
        );
    }
}