import Header from './component/Shared/Header/Header';
import Footer from './component/Shared/Footer/Footer';
import Home from './component/Home/Home';
import SpectraOverview from './component/Spectra/Overview/Overview';
import SpectraFeatures from './component/Spectra/Features/Features';
import PlansPricing from './component/Spectra/PlansPricing/PlansPricing';
import Contact from './component/Contact/Contact';
import Sitemap from './component/Sitemap/Sitemap';
import ThankYou from './component/ThankYou/ThankYou';
import PageNotFound from './component/PageNotFound/PageNotFound';
//import AlertDialog from './component/Shared/AlertDialog/AlertDialog';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/scss/bootstrap.scss';
import 'bootstrap/dist/js/bootstrap.min.js';
import './App.scss';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ScrollToTop from './component/Shared/ScrollTop/ScrollToTop';
import Careers from './component/Careers/Careers';
import AboutUs from './component/AboutUs/AboutUs';
import Overview from './component/VoiceServices/Overview/Overview';
import Features from './component/VoiceServices/Features/Features';
import ContactSales from './component/ContactSales/ContactSales';
import BaseComponent from './Common/BaseComponent'
import * as Constants from './Common/Constants';
import BookDemo from './component/BookDemo/BookDemo';
import Legal from './component/Legal/Legal';
import BreachPolicy from './component/Legal/BreachPolicy/BreachPolicy';
import TermsOfUse from './component/Legal/TermsOfUse/TermsOfUse';
import ReferralPolicy from './component/Legal/ReferralPolicy/ReferralPolicy';
import RefundPolicy from './component/Legal/RefundPolicy/RefundPolicy';
import RetentionPolicy from './component/Legal/RetentionPolicy/RetentionPolicy';
import SubscriptionTerms from './component/Legal/SubscriptionTerms/SubscriptionTerms';
import CookiePolicy from './component/Legal/CookiePolicy/CookiePolicy';
import PrivacyPolicy from './component/Legal/PrivacyPolicy/PrivacyPolicy';
import VoiceAgreement from './component/Legal/VoiceAgreement/VoiceAgreement';
import AmbientBookDemo from './component/AmbientBookDemo/AmbientBookDemo';
import AmbientOverview from './component/Ambient/Overview/Overview';
import DataProcessing from './component/Legal/DataProcessing/DataProcessing';

export default class App extends BaseComponent {
    render() {
        return (
            <div>
                <BrowserRouter>
                    {/* <div className='d-none d-md-block'>
                      <AlertDialog />
                    </div> */}
                    <ScrollToTop />
                    <Header isGeoLoading={this.state.isLoading} isGeoRequestSuccess={this.state.isGeoRequestSuccess}/>
                    <Routes>
                        <Route path="/" element={<Home isGeoLoading={this.state.isLoading} userRegionData={this.state.regionData}/>} />
                        <Route path="/spectra" element={<SpectraOverview isGeoLoading={this.state.isLoading} userRegionData={this.state.regionData} productListId={Constants.SPECTRA_LISTING_NAME}/>} />
                        <Route path="/spectra/features" element={<SpectraFeatures isGeoLoading={this.state.isLoading} userRegionData={this.state.regionData}/>} />
                        <Route path="/spectra/pricing" element={<PlansPricing isGeoLoading={this.state.isLoading} userRegionData={this.state.regionData} productListId={Constants.SPECTRA_LISTING_NAME}/>} />
                        <Route path="/voice-services" element={<Overview isGeoLoading={this.state.isLoading} userRegionData={this.state.regionData}/>} />
                        <Route path="/voice-services/features" element={<Features isGeoLoading={this.state.isLoading} userRegionData={this.state.regionData}/>} />
                        <Route path="/ambient" element={ <AmbientOverview/>} />
                        <Route path="/about-us" element={<AboutUs isGeoLoading={this.state.isLoading} userRegionData={this.state.regionData}/>} />
                        <Route path="/careers" element={<Careers isGeoLoading={this.state.isLoading} userRegionData={this.state.regionData}/>} />
                        <Route path="/contact-sales" element={<ContactSales isGeoLoading={this.state.isLoading} userRegionData={this.state.regionData}/>} />
                        <Route path="/schedule-demo" element={<BookDemo isGeoLoading={this.state.isLoading} userRegionData={this.state.regionData}/>} />
                        <Route path="/schedule-demo-ambient" element={ <AmbientBookDemo isGeoLoading={this.state.isLoading} userRegionData={this.state.regionData}/>} />
                        <Route path="/contact-us" element={<Contact isGeoLoading={this.state.isLoading} userRegionData={this.state.regionData}/>} />
                        <Route path="/sitemap" element={<Sitemap isGeoLoading={this.state.isLoading}/>} />
                        <Route path="/thank-you" element={<ThankYou isGeoLoading={this.state.isLoading} userRegionData={this.state.regionData}/>} />
                        <Route path="/legal" element={<Legal isGeoLoading={this.state.isLoading} />} />
                        <Route path="/legal/terms-of-use" element={<TermsOfUse isGeoLoading={this.state.isLoading} />} />
                        <Route path="/legal/augnito-privacy-policy" element={<PrivacyPolicy isGeoLoading={this.state.isLoading} />} />
                        <Route path="/legal/augnito-cookie-policy" element={<CookiePolicy isGeoLoading={this.state.isLoading} />} />
                        <Route path="/legal/subscription-terms" element={<SubscriptionTerms isGeoLoading={this.state.isLoading} />} />
                        <Route path="/legal/refund-cancellation-policy" element={<RefundPolicy isGeoLoading={this.state.isLoading} />} />
                        <Route path="/legal/api-sdk-agreement" element={<VoiceAgreement isGeoLoading={this.state.isLoading} />} />
                        <Route path="/legal/augnito-data-breach-policy" element={<BreachPolicy isGeoLoading={this.state.isLoading} />} />
                        <Route path="/legal/augnito-data-retention-policy" element={<RetentionPolicy isGeoLoading={this.state.isLoading} />} />
                        <Route path="/legal/referral-policy" element={<ReferralPolicy isGeoLoading={this.state.isLoading} />} />
                        <Route path="/legal/augnito-data-processing-terms" element={<DataProcessing isGeoLoading={this.state.isLoading} />} />
                        <Route path="*" element={<PageNotFound isGeoLoading={this.state.isLoading} />} />
                    </Routes>
                    <Footer isGeoLoading={this.state.isLoading} />
                    {/* <div className="d-block d-md-none">
                      <AlertDialog />
                    </div> */}
                </BrowserRouter>
            </div>
        );
    }
}
