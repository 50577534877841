import React from 'react';

const TrustedLogoIN = () => {
    return (
        <ul className='list-unstyled mb-0 d-flex align-items-center flex-wrap'>
            <li>
                <img src={require('../../../../Assets/images/PartnersLogo/IN/apollo.webp')} alt="Apollo logo" className='img-fluid bg-white p-10'/>
            </li>
            <li>
                <img src={require('../../../../Assets/images/PartnersLogo/IN/Medanta.webp')} alt="Medanta logo" className='img-fluid bg-white p-10'/>
            </li>
            
            <li>
                <img src={require('../../../../Assets/images/PartnersLogo/IN/max-healthcare.webp')} alt="Max healthcare logo" className='img-fluid bg-white p-10'/>
            </li>
            <li>
                <img src={require('../../../../Assets/images/PartnersLogo/IN/AIIMs.webp')} alt="AIIMs logo" className='img-fluid bg-white p-10'/>
            </li>            
            <li>
                <img src={require('../../../../Assets/images/PartnersLogo/IN/fortis.webp')} alt="Fortis logo" className='img-fluid bg-white p-10'/>
            </li>
        </ul>
    );
};

export default TrustedLogoIN;