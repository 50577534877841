import { Helmet } from "react-helmet";
import * as Constants from '../../../../Common/Constants';

const SpectraFeaturesMeta = () => {

    return (
        <Helmet>
            <title>Augnito Spectra: Advanced Medical Speech Recognition Features</title>
            <link rel="shortcut icon" href={Constants.SITE_URL + "/favicon.ico"} />
            <link rel="icon" href={Constants.SITE_URL + "/favicon.ico"} />
            <link rel="profile" href="https://gmpg.org/xfn/11" />
            <meta name="description" content="Discover Effortless Medical Speech Recognition with Augnito Spectra's Advanced Features. Simplify report creation for physicians using secure, cloud-based solutions."/>
            <link rel="canonical" href={Constants.SITE_URL + "spectra/features"} />
            <meta property="og:url" content={Constants.SITE_URL + "spectra/features"} />
            <meta property="og:title" content="Augnito Spectra: Advanced Medical Speech Recognition Features " />
            <meta property="og:description" content="Discover Effortless Medical Speech Recognition with Augnito Spectra's Advanced Features. Simplify report creation for physicians using secure, cloud-based solutions." />
            <meta property="og:image" content={Constants.SITE_URL + "/logo.png"} />
            <meta property="og:type" content="website" />
            <meta property="og:locale" content="en_US" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:url" content={Constants.SITE_URL + "spectra/features"}/>
            <meta name="twitter:site" content="@augnito" />
            <meta name="twitter:title" content="Augnito Spectra: Advanced Medical Speech Recognition Features" />
            <meta name="twitter:description" content="Discover Effortless Medical Speech Recognition with Augnito Spectra's Advanced Features. Simplify report creation for physicians using secure, cloud-based solutions." />
            <meta name="twitter:creator" content="@augnito" />
            <meta name="twitter:image" content={Constants.SITE_URL + "/logo.png"} />
            <meta name="copyright" content="Augnito" />
            <meta name="owner" content="Augnito" />
            <link rel="alternate" href={Constants.SITE_URL} hreflang="en-us" />
            <meta name="google-site-verification" content="TivMeqLjfaI6TIRsSF0Kqw0J8UnBPK2PToomuQRwVCs" />
        </Helmet>
    );
};

export default SpectraFeaturesMeta;
