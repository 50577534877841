import React from 'react';
import ImgPlay from '../../../../Assets/images/VoiceServices/overview/standout-features/plug-play.webp';
import { NavigateToInternalPage } from '../../../../Common/Utils';

const PlugPlay = () => {
    return (
        <div className='row gx-0 mt-60 align-items-lg-center'>
            <div className='offset-md-1 offset-lg-2 col-12 col-md-6 col-lg-4 pr-40'>
                <h3 className='aug-features-glance__heading mb-4'>Plug & play SDKs</h3>
                <p className='mb-0'>
                Easy integration through SDKs (C#, JavaScript, Flutter, React & more) that cater to a wide range of web, desktop and mobile applications. Minimal coding required.
                </p>
                <div className="mt-40">
                    <button onClick={NavigateToInternalPage("/voice-services/features")} className='btn btn-outline-primary'>
                        View all features   
                    </button>
                </div>
            </div>
            <div className='col-12 col-md-5 col-lg-6'>
                <div className='mr-n40 aug-voice-overview-standout-img'>
                    <img src={ImgPlay} alt="Augnito Medical Voice Recognition: Person pointing on a laptop screen showing plug & play SDKs" className='w-100 rounded-ltb-10 img-fluid'/>
                </div>
            </div>
        </div>
    );
};

export default PlugPlay;