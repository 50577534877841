import React from 'react';
import BaseComponent from '../../../Common/BaseComponent';
import SubscriptionTermsMeta from './SubscriptionTermsMeta';

export default class SubscriptionTerms extends BaseComponent {
    render() {

        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <>
                <SubscriptionTermsMeta/>
                <section className='aug-legal-page mt-60'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-12 offset-md-1 col-md-10 offset-lg-2 col-lg-8'>
                                <div className='aug-legal-page-header'>
                                    <h1 className='aug-legal-page-title mb-3'>Augnito Subscription Terms</h1>
                                    <p className='mb-0 text-secondary'>Effective as of 29th June 2023. These Terms replace and supersede all prior versions.</p>
                                </div>
                                <div className='mb-60'>
                                    This Agreement contains additional terms to the General Terms of Use which will apply depending upon the Subscription Term and the plan availed by the User.
                                </div>
                                <div className='mb-4'>
                                    <h3 className='aug-legal-page-subtitle'>Defintions</h3>
                                    <p className='mb-3'>In this Agreement, unless otherwise stated or unless the context otherwise requires, the words and expressions beginning with capital letters (other than clause headings) shall have the meaning set out below:</p>
                                    <ul className='mb-0 list-unstyled ps-0'>
                                        <li className='mb-3'>
                                            1. <strong>"User"</strong> means an individual authorized by Augnito to use and or sell the Products including, Enterprise Clients, Authorized Partners, Distributors, Resellers and End Users.
                                        </li>
                                        <li className='mb-3'>
                                            2. <strong>"End Users"</strong> means an Individual or Company who is the eventual User of the Product ,which may be purchased directly with Augnito or through a Authorized Partner, Distributor or Reseller.
                                        </li>
                                        <li className='mb-3'>
                                            3. <strong>"Authorized Partners"</strong> means independent contractors including Users who integrate our Product and sell further.
                                        </li>
                                        <li className='mb-3'>
                                            4. <strong>"Enterprise Clients"</strong> means Companies/Organization/Hospitals who purchase our Products.
                                        </li>
                                        <li className='mb-3'>
                                            5. <strong>"Products"</strong> means all products offered by Augnito including Augnito Spectra and Augnito Voice Services.
                                        </li>
                                        <li className='mb-3'>
                                            6. <strong>"Subscription Term"</strong> means the period for which the Product services are availed, which will in turn depend upon the Subscription Plan availed by the User.
                                        </li>
                                        <li className='mb-3'>
                                            7. <strong>"Subscription Terms"</strong> means additional terms which will apply depending upon the Subscription Term.
                                        </li>
                                        <li className='mb-0'>
                                            8. <strong>"Subscription Plan"</strong> also referred to as “Plan” means the plan available with the Product including but not limited to Subscription Term, refund, renewal and cancellation.
                                        </li>
                                    </ul>
                                </div>

                                <div className='mb-4'>
                                    <h3 className='aug-legal-page-subtitle'>The available plans and their Subscription Terms are as below:</h3>
                                    <p className='mb-3'><strong>1. Augnito Spectra is available with the following plans:</strong></p>
                                    <div>
                                        <div className='mb-3'>
                                            <p className='mb-0'><strong>1.1. Professional Plan</strong></p>
                                            <p className='mb-0'><strong>1.1.1.</strong>
                                                Your subscription shall be effective /start on the date the payment made by you is processed and realized. It shall continue for the period for which payment is made, which maybe monthly/quarterly/annually.
                                            </p>
                                        </div>
                                        <div className='mb-3'>
                                            <p className='mb-0'><strong>1.1.2.</strong>
                                                Your subscription can be cancelled at the end of your billing cycle. Please ensure to cancel your Subscription at the end of the billing cycle if you don’t want to renew for the next billing cycle.
                                            </p>
                                        </div>
                                        <div className='mb-3'>
                                            <p className='mb-0'><strong>1.1.3.</strong>
                                                Your Subscription/s will not be automatically renewed. Plan rates are subject to change at Augnito India Private Ltd.’s discretion. In case of such a change, you will be duly notified with an option to either continue or cancel.
                                            </p>
                                        </div>
                                        <div className='mb-3'>
                                            <p className='mb-0'><strong>1.1.4.</strong>
                                                In case of tax inclusive plans, if the applicable VAT or GST rate (or other included tax or duty) changes during your billing term, we will accordingly adjust the tax-inclusive price for your plan on your next billing date.
                                            </p>
                                        </div>
                                        <div className='mb-3'>
                                            <p className='mb-0'><strong>1.1.5.</strong>
                                            You can cancel your subscription anytime through the product. Please note that Subscription fee for the remaining billing period upon cancellation request being raised is non-refundable. Subscription of the Product availed by you will continue until the end of that billing period.
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='mb-3'>
                                            <p className='mb-0'><strong>1.2. Long Term Membership Plan previously known as LifetimeSaas:</strong></p>
                                            <p className='mb-0'><strong>1.2.1.</strong>
                                                Your subscription shall be effective /start on the date the upfront payment made by you in favor of Augnito is processed and realized. In addition to the upfront Subscription Fee, the User is required to pay Annual Service Fee (ASF).
                                            </p>
                                        </div>
                                        <div className='mb-3'>
                                            <p className='mb-0'><strong>1.2.2.</strong>
                                                Subject to the payment of Annual Service Fee (ASF) by the User, the Subscription shall continue for a maximum period of 7 years. Augnito holds the right to suspend/cancel subscription in case of non-realisation of ASF.
                                            </p>
                                        </div>
                                        <div className='mb-3'>
                                            <p className='mb-0'><strong>1.2.3.</strong>
                                                Augnito holds the right to continue or revise the Subscription Plan on the expiry of 7 years, depending upon the then available plans. It is hereinafter clarified continuance or revision of Subscription shall be aligned based on Augnito’s sole discretion.
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='mb-3'>
                                            <p className='mb-0'><strong>1.3. Augnito Spectra Enterprise Plan:</strong></p>
                                            <p className='mb-0'><strong>1.3.1.</strong>
                                                Subscription Terms for the Enterprise including Subscription Term, Effective date, refund, cancellation etc. will be defined and governed by the Agreement concluded between Augnito and the Enterprise Client.
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='mb-3'>
                                            <p className='mb-0'><strong>1.4. Augnito Spectra (Only App)</strong></p>
                                            <p className='mb-0'><strong>1.4.1.</strong>
                                                Your subscription is governed by In App Purchase policy of Google PlayStore or iOS Appstore.
                                            </p>
                                        </div>
                                        <div className='mb-3'>
                                            <p className='mb-0'><strong>1.4.2.</strong>
                                                Subscription purchased on one mobile platform cannot be used on any other mobile platform. Subscription will be auto-renewed Google PlayStore or iOS Appstore, unless cancelled. Please note that the Augnito Spectra App is subject to the in-app purchase policy of Google PlayStore and iOS Appstore. Accordingly like every other app hosted on these platforms are subject to auto renewals. Augnito is not liable to refund any amount debited from the User on account of auto renewal, Users are requested to cancel their subscription at the end of the billing cycle if they do not intend to renew.
                                            </p>
                                        </div>
                                        <div className='mb-3'>
                                            <p className='mb-0'><strong>1.4.3.</strong>
                                                Please note your subscription fee/payment made is non-refundable. You can cancel your subscription anytime through the product. Please note that Subscription fee for the remaining billing period upon cancellation request being raised is non-refundable. Product Subscription availed by you will continue until the end of that billing period.
                                            </p>
                                        </div>
                                        <div className='mb-3'>
                                            <p className='mb-0'><strong>1.4.4.</strong>
                                                Plan rates are subject to change at Augnito India Private Ltd.’s discretion, in case of such a change, you will be duly notified with an option to either continue or cancel.
                                            </p>
                                        </div>
                                        <div className='mb-3'>
                                            <p className='mb-0'><strong>1.4.5.</strong>
                                                In case of tax inclusive plans if the applicable VAT or GST rate (or other included tax or duty) changes during your billing term, we will accordingly adjust the tax-inclusive price for your plan on your next billing date.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='mb-4'>
                                    <p className='mb-3'><strong>2. Augnito Voice Services</strong></p>
                                    <p className='mb-3'><strong>2.1.</strong> Augnito Subscription Terms including but not limited to Subscription Term, Subscription Terms, Effective date, refund, cancellation will be defined and governed by the Agreement concluded between Augnito and the User.</p>
                                </div>
                                <div>
                                    <p className='mb-0'><strong>Resellers / Distributors</strong></p>
                                    <p className='mb-3'>Customers who purchase any of the Products indirectly through resellers/distributors are liable to check the above Subscription Terms which apply to the Plan availed by them with the Reseller/Distributor.</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}