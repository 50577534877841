import React from 'react';
import EnterpriseAvailability from './EnterpriseAvailability';
import TimelyNotifications from './TimelyNotifications';

const SuperiorPlatform = () => {
    return (
        <section className='aug-superior-service aug-speech-models mt-60'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='offset-md-1 offset-lg-2 col-md-11 col-lg-10'>
                        <h2 className="aug-title">A superior platform</h2>
                    </div>
                    <div className="offset-lg-2 offset-md-1 col-md-11 col-lg-9">
                        <div className="row gx-40">
                            <div className="col-12 col-md-6">
                                <EnterpriseAvailability/>
                            </div>
                            <div className="col-12 col-md-6">
                                <TimelyNotifications/>
                            </div>
                        </div>                
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SuperiorPlatform;