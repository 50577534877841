import React from 'react';
import AutoDebits from './AutoDebits';
import OutEase from './OutEase';
import PaymentPlans from './PaymentPlans';
import './StreamlinedSubscriptions.scss'
import TestFree from './TestFree';
import BaseComponent from '../../../../Common/BaseComponent';
import AutoDebitsME from './AutoDebitsME';
import OutEaseME from './OutEaseME';

export default class StreamlinedSubscriptions extends BaseComponent {

    render() {
        var regionData = this.props.userRegionData;

        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <section className='aug-streamlined-subscriptions aug-speech-models mt-60'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='offset-md-1 offset-lg-2 col-md-11 col-lg-10'>
                            <h2 className="aug-title">Streamlined subscriptions</h2>
                        </div>
                        <div className="offset-lg-2 offset-md-1 col-md-11 col-lg-9">
                            <div className="row gx-40">
                                <div className="col-12 col-md-6">
                                    <TestFree/>
                                </div>
                                <div className="col-12 col-md-6">
                                    {
                                        regionData === "ME" ? <AutoDebitsME/> :
                                        regionData === "IN" ?  <AutoDebits/> :
                                        <OutEase/>
                                    } 
                                </div>
                                <div className="col-12 col-md-6">
                                    <PaymentPlans/>
                                </div>
                                <div className="col-12 col-md-6">
                                    {
                                        regionData === "ME" ? <OutEaseME/> :
                                        regionData === "IN" ? <OutEase/> :
                                        <AutoDebits/>
                                    } 
                                </div>
                            </div>                
                        </div>
                    </div>
                </div>
            </section>
        );
    };
};