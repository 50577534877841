import React from 'react';
import {Link} from "react-router-dom";

const ExpressInterest = () => {
    return (

        <div className='container-fluid'>
            <div className="row gx-0 mb-60">
                <div className="offset-lg-1 offset-md-1 col-12 col-md-7 col-lg-6 pr-40">
                    <h1 className="aug-thankyou-page__heading mb-2">Thank you for expressing your interest!</h1>
                    <p className='mb-0'>One of our representatives will be in touch with you shortly.</p>
                    <div className="mt-4 pt-2">
                        <Link to="/" className="btn btn-primary">Back to home</Link>
                    </div>
                </div>
                <div className="offset-lg-1 col-12 col-md-4 col-lg-4">
                    <div className="aug-thankyou-page__img mr-n40">
                        <img src={require('../../../Assets/images/ThankYou/thankyou.webp')} className="img-fluid rounded-ltb-10 w-100" alt="Customer service executive sitting in front of a desktop thanking the customer virtually" />
                    </div>
                </div>
            </div>
        </div>

        
    )
}

export default ExpressInterest;