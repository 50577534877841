import React from 'react';

const Sandbox = () => {
    return (
       <div className='mb-60'>
            <h3 className='mb-3'>Sandbox</h3>
            <p className='mb-0'>Instantly test the APIs and their responses through sample integrations.</p>
       </div>
    );
};

export default Sandbox;