import React from 'react';
import {ReactComponent as Cloud} from '../../../../Assets/images/icons/cloud.svg';

const EnterpriseAvailability = () => {
    return (
        <div className='mt-60'>
            <div className='aug-speech-models__icon d-flex align-items-center justify-content-center'>
                <Cloud/>
            </div>
            <h3 className='mb-3 aug-speech-models__heading'>Enterprise class availability</h3>
            <p>Spectra guarantees 99.99% cloud platform availability.</p>
        </div>
    );
};

export default EnterpriseAvailability;