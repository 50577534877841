import React from 'react';

const NoiseSuppressionIN = () => {
    return (
        <div className='row gx-40 flex-column-reverse flex-md-row'>
            <div className='offset-lg-2 offset-md-1 col-md-6 col-lg-5'>
                <div>
                    <h3 className='mb-4 aug-tab-content__subheading'>Seamless dictation in loud surroundings</h3>
                    <p className='mb-0'>You can configure Spectra for high accuracy even in noisy environments via our patented background noise suppression feature. </p>
                </div>
            </div>
            <div className='col-md-5 col-lg-4 pb-2 pb-md-0'>
                <img src={require('../../../../Assets/images/features/HyperPersonalization/IN/noise-suppression.webp')} alt="Augnito Spectra Medical Speech Recognition Software : Noise Suppression Settings" className='img-fluid mb-4 mb-md-0'/>
            </div>
        </div>
    );
};

export default NoiseSuppressionIN;