import React from 'react';
import Doctors from './Doctors';
import Financiers from './Financiers';
import PatientIN from './PatientIN';
import OperationAdministratorIN from './OperationAdministratorIN';
import HealthcareIN from './HealthcareIN';

const TabContentIN = () => {
    return (
        <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade show active" id="Healthcare-IT" role="tabpanel" aria-labelledby="Healthcare-IT-tab">
                <HealthcareIN/>
            </div>
            <div className="tab-pane fade" id="operation-administrator" role="tabpanel" aria-labelledby="operation-administrator-tab">
                <OperationAdministratorIN/>
            </div>
            <div className="tab-pane fade" id="doctors" role="tabpanel" aria-labelledby="doctors-tab">
                <Doctors/>
            </div>
            <div className="tab-pane fade" id="patient" role="tabpanel" aria-labelledby="patient-tab">
                <PatientIN/>
            </div>
            <div className="tab-pane fade" id="financiers" role="tabpanel" aria-labelledby="financiers-tab">
                <Financiers/>
            </div>
            
        </div> 
    );
};

export default TabContentIN;