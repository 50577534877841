import React from 'react';
import DoctorPatient from '../../../../Assets/images/Ambient/overview/unrivalled-experience/Enhanced-Interactions-Ambient-Feature.webp';

const EnhancedInteractions = () => {
    return (
        <div className='row gx-0 mt-60'>
            <div className='offset-md-1 col-12 col-md-4 col-lg-4'>
                <h3 className='aug-features-glance__heading mb-3'>Enhanced doctor-patient interactions</h3>
                <p className='mb-0'>Ambient enables healthcare providers to focus exclusively on their patients. It allows more time for addressing patient concerns and treatments, which in turn enhances trust and patient satisfaction. Unlike traditional tools, the AI seamlessly records visits and generates output in the background, preserving the natural flow of patient interactions without distractions. </p>
            </div>
            <div className='offset-md-1 col-12 col-md-4'>
                <div className='mr-n40 aug-unrivalled-experience-img'>
                    <img src={DoctorPatient} alt="Physician discusses with patient with digital tablet on desk" className='w-100 img-fluid'/>
                </div>
            </div>
        </div>
    );
};

export default EnhancedInteractions;