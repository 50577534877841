import React from 'react';
import {ReactComponent as Clock} from '../../../../Assets/images/icons/clock.svg';

const SaveHours = () => {
    return (
        <div className='mt-60'>
            <div className='aug-choose-us__icon d-flex align-items-center justify-content-center'>
                <Clock/>
            </div>
            <h3 className='mb-3 aug-choose-us__heading'>Save 3+ hours daily</h3>
            <p>Drive provider productivity by eliminating cumbersome typing and clicks. Spectra enables you to record more detailed and accurate patient notes in less time.</p>
        </div>
    );
};

export default SaveHours;