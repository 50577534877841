import React from 'react';
import OfficeBengaluru from '../../../Assets/images/ContactUs/our-offices/bengaluru.png';

const Bengaluru = () => {
    return (
        <div className='mb-60'>
            <div className='mb-3'>
                <img src={OfficeBengaluru} alt="Bengaluru" className='img-fluid'/>
            </div>
            <h3 className='mb-3 aug-our-offices__name'>Bengaluru, India</h3>
            <p className='mb-3'>AJ Towers, #40/509, 1st Floor, 8th Cross Road, 7th Block, Jayanagar (W), Bengaluru, 560082.</p>
            <a href="tel:8447745585">+91 8447-74-5585</a>
        </div>
    );
};

export default Bengaluru;



