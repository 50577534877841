import React from 'react';
import './PricingDemo.scss';
import { NavigateToInternalPage } from '../../../../Common/Utils';

const PricingDemo = () => {
    return (
       <section className='aug-pricing-demo'>
            <div className='container-fluid'>
                <div className='row align-items-center'>
                    <div className='col-12 offset-lg-1 col-lg-7'>
                        <p className='mb-0'>Want more details on how Spectra will improve your medical reporting?</p>
                    </div>
                    <div className='col-12 col-lg-3'>
                        <div className='text-lg-end'>
                            <button type='button' className='btn btn-white' onClick={NavigateToInternalPage("/schedule-demo")}>Request demo</button>
                        </div>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default PricingDemo;