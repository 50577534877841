import React from 'react';
import Img from '../../../Assets/images/home/banner/footer-banner.webp';
import './TrySpectra.scss';
import { RedirectToSignUp, GetTrialDaysByProduct } from '../../../Common/Utils';
import * as Constants from '../../../Common/Constants'

const TrySpectra = () => {

    var trailDays = GetTrialDaysByProduct(Constants.SPECTRA_LISTING_NAME);
    return (
       <section className='aug-try-spectra position-relative'>
            <div className='aug-try-spectra__img h-100'>
                <img src={Img} alt="banner footer" className="h-100 w-100 img-fluid"/>
                <div className='aug-try-spectra-gradient'></div>
            </div>
            <div className=''>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-12 col-md-9 col-lg-6'>
                            <div className='aug-try-spectra-box position-absolute'>
                                <h2 className='mb-3'>Try Spectra. <br /> It’s free for {trailDays} days. </h2>
                                <p>No credit card required. <br />
                                No implementation and training fees.</p>
                                <button type='button' className='btn btn-primary' onClick={RedirectToSignUp}>Get started</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default TrySpectra;