import React from 'react';
import BaseComponent from '../../../Common/BaseComponent';
import CookiePolicyMeta from './CookiePolicyMeta';

export default class CookiePolicy extends BaseComponent {
    render() {

        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <>
                <CookiePolicyMeta />
                <section className='aug-legal-page mt-60'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-12 offset-md-1 col-md-10 offset-lg-2 col-lg-8'>
                                <div className='aug-legal-page-header'>
                                    <h1 className='aug-legal-page-title mb-3'>Cookie Policy</h1>
                                    <p className='mb-0 text-secondary'>Last updated: 29th June, 2023</p>
                                </div>

                                <div className='mb-4'>
                                    <h3 className='aug-legal-page-subtitle'>Background</h3>
                                    <p className='mb-0'>This website / (“Our Site”) uses Cookies and similar technologies in order to distinguish you from other users. By using Cookies, We are able to provide you with a better experience and to improve Our Site by better understanding how you use it. Please read this Cookie Policy carefully and ensure that you understand it. Your acceptance of Our Cookie Policy is deemed to occur if you continue using Our Site <strong>OR</strong> when you press the OK button on Our Cookie notifier at the <strong>top of the page</strong>. If you do not agree to Our Cookie Policy, please stop using Our Site immediately.</p>
                                </div>
                                <div className='mb-4'>
                                    <h3 className='aug-legal-page-subtitle'>1. Definitions and Interpretation</h3>
                                    <p className='mb-3'>In this Agreement, unless otherwise stated or unless the context otherwise requires, the words and expressions beginning with capital letters (other than clause headings) shall have the meaning set out below:</p>
                                    <ul className='mb-0'>
                                        <li>In this Cookie Policy, unless the context otherwise requires, the following expressions have the following meanings:</li>
                                        <li>“Cookie” means a small file placed on your computer or device by Our Site when you visit certain parts of Our Site and/or when you use certain features of Our Site;</li>
                                        <li>“personal data” means any and all data that relates to an identifiable person who can be directly or indirectly identified from that data, and</li>
                                        <li>“We/Us/Our” means Augnito India Private Limited, a limited company registered in India under company number U72900MH2022PTC377129, whose registered address is 31B, Floor – 1, Plot – 15, Meher House, Cawasji Patel Road, Horniman Circle Fort Mumbai, Mumbai City, MH 400001.</li>
                                    </ul>
                                </div>
                                <div className='mb-4'>
                                    <h3 className='aug-legal-page-subtitle'>2. Information About Us</h3>
                                    <ul className='mb-0'>
                                        <li>Our Site is owned and operated by Augnito India Private Limited, a limited company registered in India under company number U72900MH2022PTC377129, whose registered address is 31B, Floor – 1, Plot – 15, Meher House, Cawasji Patel Road, Horniman Circle Fort Mumbai, Mumbai City, MH 400001</li>
                                        <li>Our Data Protection Officer is Aakanksha Yadav, <a href="mailto: aakanksha.yadav@augnito.ai"><u>aakanksha.yadav@augnito.ai</u></a></li>
                                    </ul>
                                </div>
                                <div className='mb-4'>
                                    <h3 className='aug-legal-page-subtitle'>3. How Does Our Site Use Cookies?</h3>
                                    <ul className='mb-3'>
                                        <li>Our Site may place and access certain first party Cookies on your computer or device. First party Cookies are those placed directly by Us and are used only by Us. We use Cookies to facilitate and improve your experience of Our Site and to provide and improve Our products and We have carefully chosen these Cookies and have taken steps to ensure that your privacy and personal data is protected and respected at all times.</li>
                                        <li>By using Our Site, you may also receive certain third party Cookies on your computer or device. Third party Cookies are those placed by websites, services, and/or parties other than Us. Third party Cookies are used on Our Site for helping us improve your experience of our site. For more details, please refer to section 4 below.</li>
                                        <li>By using Our Site, you may also receive certain third party Cookies on your computer or device. Third party Cookies are those placed by websites, services, and/or parties other than Us. Third party Cookies are used on Our Site for helping us improve your experience of our site. For more details, please refer to section 4 below.</li>
                                    </ul>
                                    <div className='mb-3 ms-4'>
                                        <div class="cky-audit-table-element"></div>
                                    </div>
                                </div>
                                <div className='mb-4'>
                                    <h3 className='aug-legal-page-subtitle'>4. Consent and Control</h3>
                                    <p className='mb-3'>Before Cookies are placed on your computer or device, you will be shown a pop-up requesting your consent to set those Cookies. By giving your consent to the placing of Cookies you are enabling Us to provide the best possible experience and service to you. You may, if you wish, deny consent to the placing of Cookies unless those Cookies are strictly necessary; however certain features of Our Site may not function fully or as intended. You can return to your Cookie preferences to review and/or change them at any time by updating your internet browser.</p>
                                    <p className='mb-3'>In addition to the controls that We provide, you can choose to enable or disable Cookies in your internet browser. Most internet browsers also enable you to choose whether you wish to disable all Cookies or only third party Cookies. By default, most internet browsers accept Cookies but this can be changed. For further details, please consult the help menu in your internet browser or the documentation that came with your device.</p>
                                    <div className='mb-3'>
                                        <p className='mb-0'>The links below provide instructions on how to control Cookies in all mainstream browsers:</p>
                                        <p className='mb-0'><strong>Google Chrome:</strong><a target="_blank" rel="noreferrer" href="https://support.google.com/chrome/answer/95647?hl=en-GB"><u>https://support.google.com/chrome/answer/95647?hl=en-GB</u></a></p>
                                    </div>
                                    <p className='mb-3'><strong>Microsoft Internet Explorer:</strong> <a target="_blank" rel="noreferrer" href="https://support.microsoft.com/en-us/kb/278835"><u>https://support.microsoft.com/en-us/kb/278835</u></a></p>
                                    <p className='mb-3'><strong>Microsoft Edge: </strong><a target="_blank" rel="noreferrer" href="https://support.microsoft.com/en-gb/products/microsoft-edge"><u>https://support.microsoft.com/en-gb/products/microsoft-edge</u></a> (Please note that there are no specific instructions at this time, but Microsoft support will be able to assist)</p>
                                    <p className='mb-3'><strong>Safari (macOS):</strong> <a target="_blank" rel="noreferrer" href="https://support.apple.com/kb/PH21411?viewlocale=en_GB&amp;locale=en_GB"><u>https://support.apple.com/kb/PH21411?viewlocale=en_GB&amp;locale=en_GB</u></a></p>
                                    <p className='mb-3'><strong>Safari (iOS):</strong> <a target="_blank" rel="noreferrer" href="https://support.apple.com/en-gb/HT201265"><u>https://support.apple.com/en-gb/HT201265</u></a></p>
                                    <p className='mb-3'><strong>Mozilla Firefox:</strong> <a rel="noreferrer" target="_blank" href="https://support.mozilla.org/en-US/kb/enable-and-disable-Cookies-website-preferences"><u>https://support.mozilla.org/en-US/kb/enable-and-disable-Cookies-website-preferences</u></a></p>
                                    <p className='mb-0'>Android: <a rel="noreferrer" target="_blank" href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&amp;hl=en"><u>https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&amp;hl=en</u></a> (Please refer to your device’s documentation for manufacturers’ own browsers)</p>
                                </div>
                                <div className='mb-4'>
                                    <h3 className='aug-legal-page-subtitle'>5. Changes to this Cookie Policy</h3>
                                    <p className='mb-3'>We may alter this Cookie Policy at any time. Any such changes will become binding on you on your first use of Our Site after the changes have been made. You are therefore advised to check this page from time to time.</p>
                                    <p className='mb-0'>In the event of any conflict between the current version of this Cookie Policy and any previous version(s), the provisions current and in effect shall prevail unless it is expressly stated otherwise.</p>
                                </div>
                                <div className=''>
                                    <h3 className='aug-legal-page-subtitle'>6. Further Information</h3>
                                    <p className='mb-3'>If you would like to know more about how We use Cookies, please contact Us at <a rel="noreferrer" target="_blank" href="mailto: support@augnito.ai"><u>support@augnito.ai</u></a> or by post at Augnito India Private Limited, 31B, Floor – 1, Plot – 15, Meher House, Cawasji Patel Road, Horniman Circle Fort Mumbai, Mumbai City, MH 400001</p>
                                    <p className='mb-0'>For more information about privacy and data protection you may write to <a rel="noreferrer" target="_blank" href="mailto: legal@augnito.ai"><u>legal@augnito.ai</u></a> or reach out to our Data Protection officer (details above).</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}