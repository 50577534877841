import React from 'react';
import {ReactComponent as Chart} from '../../../../Assets/images/icons/chart-line.svg';

const RevenueCycleME = () => {
    return (
        <div className='mt-60'>
            <div className='aug-choose-us__icon d-flex align-items-center justify-content-center'>
                <Chart/>
            </div>
            <h3 className='mb-3 aug-choose-us__heading'>Improve revenue cycle management</h3>
            <p>With more detailed and complete patient narratives, reduce claim rejections and improve reimbursements for more than 90% of your patients.</p>
        </div>
    );
};

export default RevenueCycleME;