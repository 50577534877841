import React from 'react';

const OurStoryME = () => {
    return (
       <section className='mb-60 mt-60'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12 offset-md-1 col-md-10 offset-lg-2 col-lg-8'>
                        <h2 className='aug-title mb-60'>Our story</h2>
                        <div className=''>
                            <p className='mb-4'>With over two decades of experience and having pioneered clinical documentation in the UK, with the NHS, our co-founders—Rustom Lawyer and Shiraz Austin—know what it takes to transform a medical data management suite. They began developing Augnito as a cloud-based voice intelligence nine years ago. The technology was, and continues to be, worked on by some of the brightest minds from the Massachusetts Institute of Technology (MIT), Indian Institute of Technology Bombay (IIT), and Sheffield University.</p>

                            <p className='mb-0'>Augnito is an intuitive and advanced Voice AI solution that is revolutionising clinical documentation in the global healthcare market. It helps streamline clinical workflows, makes healthcare intelligence securely accessible, and ensures that physicians have more time to concentrate on their primary concern: patient care. Co-designed by physicians & AI scientists, Augnito aims to democratise the use of real-time speech-to-text software in healthcare. By combining human-centric design and cutting-edge technology, the overarching vision at Augnito is to help institutions establish a strong backbone for a centralised healthcare infrastructure—one that benefits both providers and patients.</p>
                        </div>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default OurStoryME;