import React from 'react';
import CompatibleAccents from './CompatibleAccents';
import MedicalVocabulary from './MedicalVocabulary';
import RangeSpecialties from './RangeSpecialties';
import './SpeechModels.scss';
import SpellingPreferences from './SpellingPreferences';
import RangeSpecialtiesME from './RangeSpecialtiesME';
import BaseComponent  from '../../../../Common/BaseComponent';
import CompatibleAccentsME from './CompatibleAccentsME';
import MedicalVocabularyME from './MedicalVocabularyME';
import RangeSpecialtiesIN from './RangeSpecialtiesIN';
import MedicalVocabularyIN from './MedicalVocabularyIN';
import CompatibleAccentsIN from './CompatibleAccentsIN';

export default class SpeechModels extends BaseComponent {

    render() {
        // const { regionData } = this.state;
        var regionData = this.props.userRegionData;
        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <section className='aug-speech-models mt-60'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='offset-md-1 offset-lg-2 col-md-11 col-lg-10'>
                            <h2 className="aug-title">Comprehensive speech models</h2>
                        </div>
                        <div className="offset-lg-2 offset-md-1 col-md-11 col-lg-9">
                            <div className="row gx-40">
                                <div className="col-12 col-md-6">
                                    {
                                        regionData === "ME" ? <RangeSpecialtiesME/> :
                                        regionData === "IN" ? <RangeSpecialtiesIN/> :
                                        <RangeSpecialties/>
                                    }
                                </div>
                                <div className="col-12 col-md-6">
                                    {
                                        regionData === "ME" ? <CompatibleAccentsME/> :
                                        regionData === "IN" ? <CompatibleAccentsIN/> :
                                        <MedicalVocabulary/>
                                    }
                                </div>
                                <div className="col-12 col-md-6">
                                    {
                                        regionData === "ME" ? <SpellingPreferences/> :
                                        regionData === "IN" ? <SpellingPreferences/> :
                                        <SpellingPreferences/>
                                    }
                                </div>
                                <div className="col-12 col-md-6">
                                    {
                                        regionData === "ME" ? <MedicalVocabularyME/> :
                                        regionData === "IN" ? <MedicalVocabularyIN/> :
                                        <CompatibleAccents/>
                                    }
                                </div>
                            </div>                
                        </div>
                    </div>
                </div>
            </section>
        );
    };
};