import React from 'react';
import Slider from "react-slick";
import ArrowRight from '../../../../Assets/images/icons/arrow-right.svg';
import ArrowLeft from '../../../../Assets/images/icons/arrow-left.svg';

function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        style={{ 
            backgroundImage: `url(${ArrowRight})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
         }}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        style={{
            backgroundImage: `url(${ArrowLeft})`, 
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        }}
        onClick={onClick}
      />
    );
  }

const CustomersSayME = () => {
    const settings = {
        dots: true,
        autoplay:true,
        infinite: true,
        slidesToShow: 3,
        pauseOnHover: true,
        slidesToScroll: 1,
        arrows:true,
        className:'aug-customer-slider',
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 2,
                speed: 1000,
              }
            },
            {
              breakpoint: 575,
              settings: {
                slidesToShow: 1
              }
            }
        ]
    };
    return (
       <section className='aug-customer-say'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='offset-lg-2 offset-md-1 col-md-11 col-lg-6'>
                        <h2 className='aug-title mb-60'>What customers say</h2>
                    </div>
                </div>
                <div className='row'>
                    <div className='offset-lg-2 offset-md-1 col-12 col-md-11 col-lg-9'>
                        <Slider {...settings}>

                            <div className='aug-customer-items'>
                                <img src={require('../../../../Assets/images/HeadShot/ME/Anirudha-Kohli.webp')} alt="Dr. Anirudh Kohli" height={96} width={96} className='img-fluid rounded-circle' />
                                <div className='mb-4 mt-3'>
                                    <h3 className='aug-customer-items__name mb-0'>Dr. Anirudh Kohli</h3>
                                    <h4 className='aug-customer-items__designation mb-0'>MD,  Radiology</h4>
                                </div>
                                <sub className='aug-customer-items__quote d-flex'>“</sub>
                                <div className='aug-customer-items__text'>
                                It is making huge difference, it has improved our turnaround time tremendously, it’s changed my life and it will change every physicians life, believe me!
                                </div>
                            </div>

                            <div className='aug-customer-items'>
                                <img src={require('../../../../Assets/images/HeadShot/ME/David-Peregrim.webp')} alt="David Peregrim" height={96} width={96} className='img-fluid rounded-circle' />
                                <div className='mb-4 mt-3'>
                                    <h3 className='aug-customer-items__name text-truncate mb-0'>David Peregrim</h3>
                                    <h4 className='aug-customer-items__designation mb-0'>PA-C, Emergency Medicine </h4>
                                </div>
                                <sub className='aug-customer-items__quote d-flex'>“</sub>
                                <div className='aug-customer-items__text'>
                                    Its fast, efficient, comprehensive, and very user friendly. I have used other legacy VR solutions out there and Augnito sits high amongst them. It allowed me to document a lot faster and keep up with the high patient throughput at the clinic.
                                </div>
                            </div>

                            <div className='aug-customer-items'>
                                <img src={require('../../../../Assets/images/HeadShot/ME/Ravikanti-Prasad.webp')} alt="Dr Ravikanti Prasad" height={96} width={96} className='img-fluid rounded-circle' />
                                <div className='mb-4 mt-3'>
                                    <h3 className='aug-customer-items__name text-truncate mb-0'>Dr Ravikanti Prasad</h3>
                                    <h4 className='aug-customer-items__designation mb-0'>HOD, Radiology Apollo Hospitals- Jubilee Hills</h4>
                                </div>
                                <sub className='aug-customer-items__quote d-flex'>“</sub>
                                <div className='aug-customer-items__text'>
                                Augnito is very user friendly. We got started in no time and it has tremendously improved our quality of reports. We are now able to put all our thoughts into the report. I am able to discuss the case in the report. Your team’s technical support is really excellent. We used to struggle for technical support from other speech recognition services. With Augnito, it was so seamless to get started and any questions we had, were answered quickly by your team. 
                                </div>
                            </div>
                            
                        </Slider>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default CustomersSayME;