import React from 'react';
import './KekaCareer.scss';

const KekaCareer = () => {
    return (
        <section className='aug-career-keka mb-60'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12 offset-md-1 col-md-11 offset-lg-2 col-lg-10'>
                        <h2 className='aug-title mb-60 mt-60'>Kick-start your career at Augnito</h2>
                        <iframe title="kekaFrame" src="https://augnito.kekahire.com/api/embedjobs/37be0e0a-5863-4fdc-a569-1738d6669d42" id="kekaCareerRMS" onLoad={function () { }}></iframe>
                    </div>
                </div>
            </div>
        </section>
    );
};


var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
var eventer = window[eventMethod];
var messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";
var HeightSet = false;
eventer(messageEvent, function (e) {
    // If the message is a resize frame request
    
    if (e.data && typeof e.data === "string" && e.origin && !HeightSet) {
        if (e.origin.indexOf("augnito.keka.com") !== -1 && e.data.indexOf('kekaHireCareerPortalHeight') !== -1) {
            var kekaData = JSON.parse(e.data);
            var kekaCareerRMS = document.getElementById("kekaCareerRMS");
            kekaCareerRMS.style.height = (kekaData.kekaHireCareerPortalHeight - 120) + 'px';
            if (kekaData.kekaHireCareerPortalHeight > 500) {
                HeightSet = true;
            }
        }
    }
}, false);

export default KekaCareer;
