import React from 'react';
import CustomerImg from './CustomerImg';
import CustomerText from './CustomerText';
import './PricingCustomer.scss';
import CustomerTextME from './CustomerTextME';
import BaseComponent from '../../../../Common/BaseComponent';
import CustomerTextIN from './CustomerTextIN';

export default class PricingCustomer extends BaseComponent {

    render() {
        // const { regionData } = this.state;
        var regionData = this.props.userRegionData;
        if (this.props.isGeoLoading) {
            return;
        }

        return (
        <section className='aug-pricing-customer'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='offset-lg-1 col-12 col-lg-9'>
                            <h2 className='aug-title mb-60 mt-60'>What our customers say</h2>
                        </div>
                    </div>
                    <div className='row gx-40 align-lg-items-center'>
                        <div className='offset-lg-1 col-12 col-md-5 col-lg-5'>
                            <CustomerImg/>
                        </div>
                        <div className='col-12 col-md-6 col-lg-4'>
                            {
                                regionData === "ME" ? <CustomerTextME/> :
                                regionData === "IN" ? <CustomerTextIN/>  :
                                <CustomerText/>
                            }
                        </div>
                    </div>
                </div>
        </section>
        );
    };
};