import React from 'react';
import SubscriptionPlanName from '../../Overview/SubscriptionPlan/SubscriptionPlanName';
import SubscriptionPlanPopular from '../../Overview/SubscriptionPlan/SubscriptionPlanPopular';
import BaseComponent from '../../../../Common/BaseComponent'
import './PricingSubscription.scss'
import FeaturesCompare from '../FeaturesCompare/FeaturesCompare';
import Processing from '../../../Shared/Processing/Processing'
import { NavLink } from 'react-router-dom';

export default class PricingSubscription extends BaseComponent {
    constructor(props) {
        super(props);

        this.ReloadPlans = this.ReloadPlans.bind(this);

        if (this.IsValidPlanData()) {
            var billingCycle = this.props.productPlanDetails.Data.Clouds.BillingCycle;

            this.state = {
                selectedBillingCycle: billingCycle[billingCycle.length - 1]
            };
        }
    }

    ReloadPlans(){       
        this.LoadPlaPriceInformation(this.props.innerProductListId);
    }

    IsValidPlanData() {
        if (this.props.productPlanDetails === undefined || this.props.productPlanDetails === ""
            || this.props.productPlanDetails.Status !== 0 ||
            this.props.productPlanDetails.Data.Clouds === null) {
            return false;
        }
        else {
            return true;
        }
    }

    IsValidPlanDataFromState(){
        if(this.state.priceData !== undefined && this.state.priceData !== "" && this.state.priceData.Status === 0 
        && this.state.priceData.Data.Clouds !== null){            
            return true;
        }
        else{
            return false;
        }
    }

    render() {

        if (this.props.isPriceLoading) {
            return (
                <Processing />
            );
        }
        if (!this.IsValidPlanData() && !this.IsValidPlanDataFromState()) {
            return(
                <section className="aug-pricing-load">
                    <div className="container-fluid">
                        <div className='text-center'>
                            <h3 className="aug-title mb-0">Oops! Failed to load latest pricing.</h3>
                            <div className="mt-40">
                                <button type='button' className='btn btn-primary me-md-3' onClick={this.ReloadPlans}>Try again</button>
                                <NavLink to="/spectra" className="btn btn-outline-primary active">Learn more about Spectra</NavLink>
                            </div>
                        </div>                        
                    </div>
                </section>
            );
        }

        var PlanPriceDeatils = this.props.productPlanDetails;
        if(!this.IsValidPlanData()){
            PlanPriceDeatils = this.state.priceData;
        }
        var CloudsData = PlanPriceDeatils.Data.Clouds;
        var CurrencyCode = PlanPriceDeatils.Data.CurrencyCode;
        var billingCycle = CloudsData.BillingCycle;
        var plans = CloudsData.Plans;

        const handleBillingCycleChange = (data) => {
            if (data.target.checked) {
                this.setState({ selectedBillingCycle: data.target.value });
            }
        };

        var selectedBillingCycle = this.state.selectedBillingCycle;
        if (selectedBillingCycle === undefined) {
            selectedBillingCycle = billingCycle[billingCycle.length - 1]
        }
        return (
            <>
                <section className='aug-pricing-subscription aug-subscription-plan'>
                    <div className='container-fluid'>
                        <div className='text-center mt-60 mb-60'>
                            <div className='aug-pricing-subscription-badge'>
                                {billingCycle.map((item, index, data) => {
                                    return (
                                        <span key={index}>
                                            <input type="radio" className="btn-check"
                                                onChange={handleBillingCycleChange}
                                                name="billing-cycle-options-outlined" id={item}
                                                autoComplete="off" value={item} defaultChecked={data.length - 1 === index ? true : false} />
                                            <label className="btn" htmlFor={item}>{item}</label>
                                        </span>
                                    )
                                })}
                            </div>
                        </div>

                        <div className='row'>
                            <div className='offset-lg-1 col-12 col-md-12 col-lg-10'>
                                <div className='row gx-6 justify-content-center'>
                                    {
                                        plans.map((item, index, data) => {
                                            return (
                                                <div className='col-12 col-md-3 col-lg-3' key={index}>
                                                    {item.IsMostPopular ? <SubscriptionPlanPopular /> : ""}
                                                    <SubscriptionPlanName planData={item} selectedBillingCycle={selectedBillingCycle} currencyCode={CurrencyCode} productPublicId={this.props.innerProductListId}/>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <FeaturesCompare productPlanDetails={PlanPriceDeatils} selectedBillingCycle={selectedBillingCycle} productPublicId={this.props.innerProductListId}/>
            </>
        );
    }
}