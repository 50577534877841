import React from 'react';
import './SpectraUSP.scss'

const SpectraUSP = () => {
    return (
        <section className='aug-spectra-USP'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='offset-md-1 offset-lg-2 col-md-11 col-lg-8'>
                        <div className='row gx-40'>
                            <div className='col-6 col-md-4'>
                                <div className='mb-60'>
                                    <div className='mb-3 aug-spectra-USP__digit'>
                                        99%
                                    </div>
                                    <div className='aug-spectra-USP__text'>
                                        Out-of-the-box accuracy
                                    </div>
                                </div>
                            </div>
                            <div className='col-6 col-md-4'>
                                <div className='mb-60'>
                                    <div className='mb-3 aug-spectra-USP__digit'>
                                        4x
                                    </div>
                                    <div className='aug-spectra-USP__text'>
                                        Faster than typing
                                    </div>
                                </div>
                            </div>
                            <div className='col-6 col-md-4'>
                                <div className='mb-60'>
                                    <div className='mb-3 aug-spectra-USP__digit'>
                                        3+
                                    </div>
                                    <div className='aug-spectra-USP__text'>
                                        Hours saved for providers every day
                                    </div>
                                </div>
                            </div>
                            <div className='col-6 col-md-4'>
                                <div className='mb-60'>
                                    <div className='mb-3 aug-spectra-USP__digit'>
                                        9/10
                                    </div>
                                    <div className='aug-spectra-USP__text'>
                                        Providers believe Augnito Spectra improves quality of documentation
                                    </div>
                                </div>
                            </div>
                            <div className='col-6 col-md-4'>
                                <div className='mb-60'>
                                    <div className='mb-3 aug-spectra-USP__digit'>
                                        11x
                                    </div>
                                    <div className='aug-spectra-USP__text'>
                                        ROI achieved in just 2 months
                                    </div>
                                </div>
                            </div>
                            <div className='col-6 col-md-4'>
                                <div className='mb-60'>
                                    <div className='mb-3 aug-spectra-USP__digit'>
                                        62
                                    </div>
                                    <div className='aug-spectra-USP__text'>
                                        NPS rating
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SpectraUSP;