import React from 'react';
import Hero from '../../../Assets/images/home/banner/banner.webp';
import BannerContent from './BannerContent';
// import BannerTrusted from './BannerTrusted';
import './Banner.scss';
import BannerContentIN from './BannerContentIN';
import BaseComponent from '../../../Common/BaseComponent';

export default class Banner extends BaseComponent {

    render() {
        var regionData = this.props.userRegionData;
        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <section className='aug-banner aug-home-banner'>
                <div className='aug-banner-img position-relative'>
                    <img src={Hero} loading='lazy' rel="preload" alt="banner" className='img-fluid'/>
                    <div className='aug-banner-gradient'></div>
                </div>
                <div className='aug-banner-content position-absolute d-flex align-items-center'>
                    {
                        regionData === "IN" ? <BannerContentIN/> :
                        <BannerContent/>
                    }
                </div>
                {/* <div className='position-absolute aug-banner-trusted'>
                    <BannerTrusted/>
                </div> */}
            </section>
        );
    }
}