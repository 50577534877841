import React from 'react';
import {ReactComponent as Chart} from '../../../../Assets/images/icons/card.svg';

const AutoDebitsME = () => {
    return (
        <div className='mt-60'>
            <div className='aug-speech-models__icon d-flex align-items-center justify-content-center'>
                <Chart/>
            </div>
            <h3 className='mb-3 aug-speech-models__heading'>Eliminate cards & auto-debits</h3>
            <p>There’s no need to share any payment information when you first subscribe to Spectra.</p>
        </div>
    );
};

export default AutoDebitsME;