import React from 'react';
import Img from '../../../Assets/images/Ambient/overview/Meet-Ambient-Feature.webp';
import { NavigateToInternalPage } from '../../../Common/Utils';

const Ambient = () => {
    return (
       <section className='aug-voice-services mt-60'>
            <div className='row gx-0'>
            <div className='offset-lg-1 offset-md-1 col-12 col-md-10 col-lg-5'>
                    <div className='aug-what-we-offer__img'>
                        <img src={Img} height="auto" width="100%" alt="Augnito voice services loading on a laptop screen" className="rounded-10 img-fluid"/>
                    </div>
                </div>
                <div className='col-12 offset-lg-0 offset-md-1 col-md-10 col-lg-4 pl-40 pl-md-0 mt-md-32'>
                    <h3 className='aug-what-we-offer__heading'>Ambient</h3>
                    <h4 className='mb-3 aug-our-expertise__subheading'>Create structured medical documentation from natural, free-flowing conversation</h4>
                    <p className='mb-0'>Proprietary Multi-lingual Speech Recognition and Generative AI technology that enables providers to interact with patients without the burden of manual data capture and input.</p>
                    <div className='mt-40'>
                        <button type="button" className="btn btn-primary me-md-3" onClick={ NavigateToInternalPage("/schedule-demo-ambient") }>Try for free</button>
                        <button type="button" className="btn btn-outline-primary mt-3 mt-md-0" onClick={ NavigateToInternalPage("/ambient") }>Discover more</button>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default Ambient;