import React from 'react';
import SubNavbar from '../../Shared/SubNavbar/SubNavbar';
import UnmatchedCompatibility from './UnmatchedCompatibility/UnmatchedCompatibility';
import StandoutFeatures from './StandoutFeatures/StandoutFeatures'
import SecureCompliant from './SecureCompliant/SecureCompliant'
import VersatileDeployment from './VersatileDeployment/VersatileDeployment'
import BannerFeatures from './BannerFeatures/BannerFeatures';
import SpeechModels from './SpeechModels/SpeechModels';
import FeaturesDay from './FeaturesDay/FeaturesDay';
import CustomerService from './CustomerService/CustomerService';
import FeaturesDemo from './FeaturesDemo/FeaturesDemo';
import StreamlinedSubscriptions from './StreamlinedSubscriptions/StreamlinedSubscriptions';
import FeaturesTrySpectra from './FeaturesTrySpectra/FeaturesTrySpectra';
import SuperiorService from './SuperiorService/SuperiorService';
import HyperPersonalization from './HyperPersonalization/HyperPersonalization';
import SpectraFeaturesMeta from './Meta/SpectraFeaturesMeta';
import BaseComponent from '../../../Common/BaseComponent';
import BannerFeaturesME from './BannerFeatures/BannerFeaturesME';
import HyperPersonalizationME from './HyperPersonalization/HyperPersonalizationME';
import UnmatchedCompatibilityME from './UnmatchedCompatibility/UnmatchedCompatibilityME';
import BannerFeaturesIN from './BannerFeatures/BannerFeaturesIN';
import UnmatchedCompatibilityIN from './UnmatchedCompatibility/UnmatchedCompatibilityIN';
import HyperPersonalizationIN from './HyperPersonalization/HyperPersonalizationIN';

export default class SpectraFeatures extends BaseComponent {
    render() {
        // const { regionData } = this.state;
        var regionData = this.props.userRegionData;
        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <main>
                <SubNavbar/>
                <SpectraFeaturesMeta />
                {
                    regionData === "ME" ? <BannerFeaturesME/> :
                    regionData === "IN" ? <BannerFeaturesIN/> :
                    <BannerFeatures/>
                }
                {
                    regionData === "ME" ? <UnmatchedCompatibilityME/> :
                    regionData === "IN" ? <UnmatchedCompatibilityIN/> :
                    <UnmatchedCompatibility />
                }
                <StandoutFeatures userRegionData={regionData}/>
                <SpeechModels userRegionData={regionData}/>
                {
                    regionData === "ME" ? <HyperPersonalizationME/> :
                    regionData === "IN" ? <HyperPersonalizationIN/> :
                    <HyperPersonalization/>
                }                
                <SuperiorService/>
                <FeaturesTrySpectra/>
                <VersatileDeployment userRegionData={regionData}/>
                <SecureCompliant userRegionData={regionData}/>
                <StreamlinedSubscriptions userRegionData={regionData}/>
                <FeaturesDemo/>
                <CustomerService userRegionData={regionData}/>
                <FeaturesDay/>
            </main>
        );
    }
}