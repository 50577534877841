import React from 'react';
import Img from '../../../Assets/images/home/our-expertise.webp';
import './OurExpertise.scss';
import { RedirectToSignUp } from '../../../Common/Utils';

const OurExpertise = () => {
    return (
       <section className='aug-our-expertise'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='offset-lg-1 offset-md-1 col-md-11 col-lg-8'>
                        <h2 className='aug-title mb-60'>Industry expertise</h2>
                    </div>
                </div>
                <div className='row gx-40 flex-column-reverse flex-md-row'>
                    <div className='offset-lg-1 offset-md-1 col-12 col-md-7 col-lg-6'>
                        <h3 className='mb-4 aug-our-expertise__heading'>20 years of clinical transcription proficiency converted into Clinical AI</h3>
                        <ul className='list-unstyled mb-0 list-disc'>
                            <li className='mb-2'>
                                Built by pioneers of Medical Transcription Services in the NHS (UK)
                            </li>
                            <li className='mb-2'>
                                8 Years of R&D in Speech Science & Deep Learning
                            </li>
                            <li className='mb-2'>
                                Built with leading professors from MIT (USA), IIT Bombay (India) and Sheffield University (London)
                            </li>
                            <li>
                                Proprietary Automatic Speech Recognition (ASR) and Natural Language Processing (NLP) technology
                            </li>
                        </ul>
                        <div className='mt-40'>
                            <button type="button" className="btn btn-primary" onClick={RedirectToSignUp}>Try Spectra for free</button>
                        </div>
                    </div>
                    <div className='col-12 col-md-4 col-lg-4'>
                        <div className='aug-our-expertise__img mr-n40'>
                            <img src={Img} height="auto" width="100%" alt="Voice modulation. Sound wave" className="w-100 rounded-10 img-fluid"/>
                        </div>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default OurExpertise;