import React from 'react';
import BaseComponent from '../../../Common/BaseComponent';
import ReferralPolicyMeta from './ReferralPolicyMeta';

export default class ReferralPolicy extends BaseComponent {
    render() {

        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <>
                <ReferralPolicyMeta/>
                <section className='aug-legal-page mt-60'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-12 offset-md-1 col-md-10 offset-lg-2 col-lg-8'>
                                <div className='aug-legal-page-header'>
                                    <h1 className='aug-legal-page-title mb-3'>Augnito Referral Policy</h1>
                                    <p className='mb-0 text-secondary'>Last updated: 29th June, 2023</p>
                                </div>
                                <div>
                                    <ul className='mb-0 ps-3'>
                                        <li><strong>This offer is valid for Augnito Spectra in India and USA.</strong> </li>
                                        <li>This offer is not valid for Lifetime SaaS, Student Plans, Enterprise subscription plans.</li>
                                        <li>Your referral code is active once you have purchased a paid subscription plan.</li>
                                        <li>Free days get added to your Subscription Term only if you have an active paid subscription plan at the time of your referral code getting applied.</li>
                                        <li>Your subscription term extends by 7 days for every unique sign up for free trial from your referral code.</li>
                                        <li>You can earn a maximum of 14 free days in a calendar month from sign ups for free trials from your referral code.</li>
                                        <li>Your subscription term extends by 30 days for every first time purchase of a paid subscription using your referral code.</li>
                                        <li>You can earn unlimited free subscription days from first time purchases of paid subscriptions from your referral code.</li>
                                        <li>Your subscription term is extended by 30 days if a referral code is applied the first time you purchase a paid subscription plan.</li>
                                        <li>Referral code once applied to your subscription plan cannot be changed.</li>
                                        <li>Referral code can be applied only at the time of sign up for free trial or the first time purchase of paid subscription. It is not applicable for subsequent renewals.</li>
                                        <li>This offer may not be valid after a certain duration as determined by Augnito.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}