import React from 'react';
import Slider from "react-slick";
import ArrowRight from '../../../../Assets/images/icons/arrow-right.svg';
import ArrowLeft from '../../../../Assets/images/icons/arrow-left.svg';

function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        style={{ 
            backgroundImage: `url(${ArrowRight})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
         }}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        style={{
            backgroundImage: `url(${ArrowLeft})`, 
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        }}
        onClick={onClick}
      />
    );
  }

const CustomersSayME = () => {
    const settings = {
        dots: true,
        autoplay:true,
        infinite: true,
        slidesToShow: 3,
        pauseOnHover: true,
        slidesToScroll: 1,
        arrows:true,
        className:'aug-customer-slider',
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 2,
                speed: 1000,
              }
            },
            {
              breakpoint: 575,
              settings: {
                slidesToShow: 1
              }
            }
        ]
    };
    return (
       <section className='aug-customer-say'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='offset-lg-2 offset-md-1 col-md-11 col-lg-6'>
                        <h2 className='aug-title mb-60'>What customers say</h2>
                    </div>
                </div>
                <div className='row'>
                    <div className='offset-lg-2 offset-md-1 col-12 col-md-11 col-lg-9'>
                        <Slider {...settings}>

                            <div className='aug-customer-items'>

                                <img src={require('../../../../Assets/images/HeadShot/ME/Jamil-Ahmed.webp')} alt="Dr Jamil Ahmed" height={96} width={96} className='img-fluid rounded-circle' />
                                <div className='mb-4 mt-3'>
                                    <h3 className='aug-customer-items__name mb-0'>Dr Jamil Ahmed</h3>
                                    <h4 className='aug-customer-items__designation mb-0'>Founder and Managing Director, PRIME Healthcare Group</h4>
                                </div>
                                <sub className='aug-customer-items__quote d-flex'>“</sub>
                                <div className='aug-customer-items__text'>
                                “It is a great honour to have Augnito onboard as our Voice AI partner as we continue to work towards delivering world-class services . After reviewing several products, we found the quality of Augnito to be exceptional. Another core differentiator was Augnito’s SDK (Software Development Kit) that enables a direct integration within our HIS (Hospital Information System) for seamless clinician experience.” 
                                </div>
                            </div>

                            <div className='aug-customer-items'>
                                <img src={require('../../../../Assets/images/HeadShot/ME/Gerardo-Bonilla.webp')} alt="Dr Gerardo Bonilla" height={96} width={96} className='img-fluid rounded-circle' />
                                <div className='mb-4 mt-3'>
                                    <h3 className='aug-customer-items__name text-truncate mb-0'>Dr Gerardo Bonilla</h3>
                                    <h4 className='aug-customer-items__designation mb-0'>CEO and Founder, ChartNote</h4>
                                </div>
                                <sub className='aug-customer-items__quote d-flex'>“</sub>
                                <div className='aug-customer-items__text'>
                                Since integrating voice- recognition powered by Augnito in ChartNote, physicians have since seen a reduction in the baggage of medical documentation. As a consequence, physician's ability to provide better health care has improved the new additions resulting from our partnership enable our medical software to provide next- generation artificial intelligence with the best in class accuracy” 
                                </div>
                            </div>

                            <div className='aug-customer-items'>
                                <img src={require('../../../../Assets/images/HeadShot/ME/Jaymin-Patel.webp')} alt="Dr Jaymin Patel" height={96} width={96} className='img-fluid rounded-circle' />
                                <div className='mb-4 mt-3'>
                                    <h3 className='aug-customer-items__name text-truncate mb-0'>Dr Jaymin Patel</h3>
                                    <h4 className='aug-customer-items__designation mb-0'>Chief Operating Officer, Hexarad (UK)</h4>
                                </div>
                                <sub className='aug-customer-items__quote d-flex'>“</sub>
                                <div className='aug-customer-items__text'>
                                Hexarad is excited to be working with Augnito. Its responsive and agile.The solution itself has received excellent feedback from our radiologists and has provided us with a truly integrated product that works straight 'out of the box’.”
                                </div>
                            </div>
                            
                        </Slider>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default CustomersSayME;