import React from 'react';

const DriveEMRME = () => {
    return (
        <div className='mt-60'>
            <h3 className='mb-3 aug-choose-us__heading'>Drive EMR adoption</h3>
            <p className='mb-0'>Simplify the navigation of and input of data by physicians into complex clinical systems, enabling them to create quality and source and reduce time spent on creating medical records. Generate access to more detailed data with less effort. </p>
        </div>
    );
};

export default DriveEMRME;