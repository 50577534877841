import React from 'react';

const PositiveStakeTabME = () => {
    return (
        <ul className="aug-nav-tab nav nav-pills" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
                <button className="nav-link px-0 active" id="operation-administrator-tab" data-bs-toggle="pill" data-bs-target="#operation-administrator" type="button" role="tab" aria-controls="operation-administrator" aria-selected="false">Operatives & Administrators</button>
            </li>
            <li className="nav-item" role="presentation">
                <button className="nav-link px-0" id="physicians-tab" data-bs-toggle="pill" data-bs-target="#physicians" type="button" role="tab" aria-controls="physicians" aria-selected="true">Physicians</button>
            </li>
            <li className="nav-item" role="presentation">
                <button className="nav-link px-0" id="Healthcare-IT-tab" data-bs-toggle="pill" data-bs-target="#Healthcare-IT" type="button" role="tab" aria-controls="Healthcare-IT" aria-selected="false">Healthcare IT</button>
            </li>
            <li className="nav-item" role="presentation">
                <button className="nav-link px-0" id="patient-tab" data-bs-toggle="pill" data-bs-target="#patient" type="button" role="tab" aria-controls="patient" aria-selected="false">Patients</button>
            </li>
        </ul>
    );
};

export default PositiveStakeTabME;