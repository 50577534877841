import React from 'react';
import ImgME from '../../../../Assets/images/features/CustomerService/ME/human-touch.webp';
import { RedirectToSignUp } from '../../../../Common/Utils';

const HumanTouchME = () => {

    return (
        <div className="row gx-40 mt-60 align-items-center flex-column-reverse flex-md-row">
            <div className="offset-lg-2 offset-md-1 col-md-7 col-lg-6">
                <h3 className='mb-3 aug-customer-service__heading'>
                    A differentiated user experience with a human touch
                </h3>
                <p className='mb-0'>Spectra’s dedicated Customer Success professionals are focused on training and onboarding new users, helping them make the most of our speech recognition technology — at no added cost!</p>
                <div className='mt-40'>
                    <button type="button" className="btn btn-primary" onClick={RedirectToSignUp}>Try Spectra for free</button>
                </div>
            </div>
            <div className="col-12 col-md-4 col-lg-4">
                <div className='mr-n40 aug-customer-service__img pb-0 ps-0'>
                    <img src={ImgME} alt="Customer service executive using Augnito to instruct a doctor" className='w-100 rounded-ltb-10 aug-human-touch-imh-height img-fluid'/>
                </div>
            </div>
        </div>         
    );
};

export default HumanTouchME;