import React from 'react';
import Img from '../../../Assets/images/home/makesUnique/flexible-subscriptions.webp';

const  FlexibleSubscription= () => {
    return (
        <div className='row gx-0 mt-60 align-items-lg-center'>
            <div className='offset-md-1 offset-lg-1 col-12 col-md-6 col-lg-4 pr-40'>
                <h3 className='aug-makes-unique-heading mb-4'>Affordable, flexible subscriptions </h3>
                <ul className='list-unstyled mb-0 list-disc'>
                    <li className='mb-2'>No set-up, installation or training fees</li>
                    <li className='mb-2'>No credit card required</li>
                    <li className='mb-2'>No long-term commitment</li>
                    <li>Unlimited downloads on multiple devices, without any extra fees</li>
                </ul>
            </div>
            <div className='col-12 offset-lg-1 col-md-5 col-lg-6'>
                <div className='mr-n40'>
                    <img src={Img} height="auto" width="100%" alt="Physician conducting an online meeting" className='w-100 rounded-ltb-10 img-fluid'/>
                </div>
            </div>
        </div>
    );
};

export default FlexibleSubscription;