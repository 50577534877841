import React from 'react';
import Video from '../../../../Assets/images/home/ASR_NLP_Technology.webm';
import {NavLink} from "react-router-dom";
import './WatchSpectra.scss';
import { RedirectToSignUp, NavigateToInternalPage } from '../../../../Common/Utils';
import { useEffect, useRef } from "react";

const WatchSpectra = () => {

    const videoRef = useRef(null);
    useEffect(() => {
        let options = {threshold: 0.9};
        let handlePlay = (entries, observer) => {
            entries.forEach((entry) => {
                videoRef.current.pause();
            });
        };
        let observer = new IntersectionObserver(handlePlay, options);
        observer.observe(videoRef.current);
    });

    var videoElement = document.getElementById("videoSpectra");
    document.addEventListener("visibilitychange", function() {
        if(document.visibilityState === "hidden" && videoElement)
        {
            videoElement.pause();
        }     
    })
 
    return (
       <section className='aug-watch-spectra mt-60 mb-60'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='offset-lg-2 offset-md-1 col-md-11 col-lg-8'>
                        <h2 className='aug-title'>Watch Spectra in action</h2>
                        <div className='mt-40 mb-60'>
                            <NavLink className='btn btn-primary me-3 mb-3 mb-sm-0' onClick={RedirectToSignUp}>
                                Try for free   
                            </NavLink>
                            <button className='btn btn-outline-primary mb-3 mb-sm-0' onClick={NavigateToInternalPage("/spectra/pricing")}>
                                View plans and pricing  
                            </button>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='offset-lg-2 offset-md-1 col-12 col-md-10 col-lg-8'>
                        <div className="ratio ratio-16x9">
                            <video ref={videoRef} width="100%" id='videoSpectra' className='aug-watch-video' controls>
                                <source src={Video} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default WatchSpectra;