import React from 'react';
import CustomSDK from './CustomSDK';
import FreeMobile from './FreeMobile';
import PlugPlay from './PlugPlay';
import './StandoutFeatures.scss'
import FreeMobileME from './FreeMobileME';
import BaseComponent from '../../../../Common/BaseComponent';
import FreeMobileIN from './FreeMobileIN';

export default class StandoutFeatures extends BaseComponent {

    render() {
        var regionData = this.props.userRegionData;

        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <section className='aug-features-glance aug-voice-overview-standout mt-60'>
            <div className='container-fluid'>
                    <div className='row'>
                        <div className='offset-md-1 offset-lg-2 col-12 col-md-6 col-lg-4'>
                            <h2 className='aug-title mb-0'>Standout features</h2>
                        </div>
                    </div>
                    <PlugPlay/>
                    <CustomSDK/>
                    {
                        regionData === "IN" ? <FreeMobileIN/> :
                        regionData === "ME" ? <FreeMobileME/> :
                        <FreeMobile/>
                    }
            </div> 
            </section>
        );
    };
};