import React from 'react';
import DesktopWebPlugin from './DesktopWebPlugin';
import MobileMic from './MobileMic';
import './OurReach.scss';
import DesktopWebPluginME from './DesktopWebPluginME';
import MobileMicME from './MobileMicME';
import BaseComponent from '../../../../Common/BaseComponent';
import DesktopWebPluginIN from './DesktopWebPluginIN';
import MobileMicIN from './MobileMicIN';

export default class OurReach extends BaseComponent {

    render() {
        // const { regionData } = this.state;
        var regionData = this.props.userRegionData;
        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <section className='aug-our-reach'>
            <div className='container-fluid'>
                    <div className='row'>
                        <div className='offset-md-1 offset-lg-2 col-12 col-md-6 col-lg-4'>
                            <h2 className='aug-title mb-0'>Our reach</h2>
                        </div>
                    </div>
                    {
                        regionData === "ME" ? <DesktopWebPluginME/> :
                        regionData === "IN" ? <DesktopWebPluginIN/> :
                        <DesktopWebPlugin/>
                    }
                    {
                        regionData === "ME" ? <MobileMicME/> :
                        regionData === "IN" ? <MobileMicIN/> :
                        <MobileMic/>
                    }                   
            </div> 
            </section>
        );
    };
};