import React from 'react';
import './VoiceOverviewDemo.scss';
import { NavigateToInternalPage } from '../../../../Common/Utils';

const VoiceOverviewDemo = () => {
   
    return (
       <section className='mb-60 aug-voice-overview-demo'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-lg-11'>
                        <div className='aug-book-demo ml-n40 d-lg-flex justify-content-lg-between align-items-lg-center mb-60'>
                            <div>
                            Proprietary Speech Recognition & Natural Language Understanding technology with 99% out-of-the-box accuracy
                            </div>
                            <div className='aug-book-demo-btn'>
                                <button type='button' className='btn btn-white' onClick={NavigateToInternalPage("/schedule-demo")}>
                                    Book a demo
                                </button>
                            </div>
                        </div>        
                    </div>
                </div>
            </div>
       </section>
    );
};

export default VoiceOverviewDemo;