import React from 'react';

const UserFriendly = () => {
    return (
        <div className='mt-60'>
            <h3 className='mb-3 aug-choose-us__heading'>User-friendly software navigation</h3>
            <p>Intuitive, voice-powered controls ensures that clinical systems are easier and faster to operate. This reduces time spent on medical record creation and also increases report accuracy, resulting in fewer claim rejections.</p>
        </div>
    );
};

export default UserFriendly;