import React from 'react';
import SpeechRecognition from './SpeechRecognition';
import AlwaysAvailable from './AlwaysAvailable';
import FlexibleSubscription from './FlexibleSubscription';
import CustomerSupport from './CustomerSupport';
import BaseComponent from '../../../Common/BaseComponent';
import './MakesUnique.scss';
import SpeechRecognitionME from './SpeechRecognitionME';
import FlexibleSubscriptionME from './FlexibleSubscriptionME';
import SpeechRecognitionIN from './SpeechRecognitionIN';
import FlexibleSubscriptionIN from './FlexibleSubscriptionIN';
import CustomerSupportIN from './CustomerSupportIN';
import CustomerSupportME from './CustomerSupportME';

export default class MakesUnique extends BaseComponent {

    render() {
        // const { regionData } = this.state;
        var regionData = this.props.userRegionData;
        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <section className='aug-makes-unique aug-home-makes-unique'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='offset-md-1 col-12 col-md-6 col-lg-4'>
                            <h2 className='aug-title mb-0'>An unrivalled experience</h2>
                        </div>
                    </div>
                    {
                        regionData === "IN" ? <SpeechRecognitionIN/> :
                        regionData === "ME" ? <SpeechRecognitionME/> :
                        <SpeechRecognition/>
                    }
                    <AlwaysAvailable/>
                    {
                        regionData === "IN" ? <FlexibleSubscriptionIN/> :
                        regionData === "ME" ? <FlexibleSubscriptionME/> :
                        <FlexibleSubscription/>
                    }  
                    {
                        regionData === "IN" ? <CustomerSupportIN/> :
                        regionData === "ME" ? <CustomerSupportME/> :
                        <CustomerSupport/>
                    }                  
                </div> 
            </section>
        );
    }
}