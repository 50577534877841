import React from 'react';
import TrustedLogo from '../../Spectra/PlansPricing/PricingTrusted/TrustedLogo';
import './SuccessfullyIntegrated.scss';
import TrustedLogoME from '../../Spectra/PlansPricing/PricingTrusted/TrustedLogoME';
import BaseComponent from '../../../Common/BaseComponent';
import TrustedLogoIN from '../../Spectra/PlansPricing/PricingTrusted/TrustedLogoIN';

export default class SuccessfullyIntegrated extends BaseComponent {

    render() {
        // const { regionData } = this.state;
        var regionData = this.props.userRegionData;
        if (this.props.isGeoLoading) {
            return;
        }

        return (
            <section className='aug-contact-sales-partner aug-secure-compliant mb-60'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='offset-md-1 offset-lg-2 col-md-11 col-lg-10'>
                            <h2 className="aug-title mb-60">Successfully partnered with</h2>
                            {
                                regionData === "ME" ? <TrustedLogoME/> :
                                regionData === "IN" ? <TrustedLogoIN/> :
                                <TrustedLogo/>
                            }
                        </div>
                    </div>
                </div>
            </section>
        );
    };
};