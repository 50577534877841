import { Helmet } from "react-helmet";
import * as Constants from '../../../Common/Constants'

const BookDemoMeta = () => {

    return (
        <Helmet>
            <title> Schedule a Demo | Augnito </title>
            <link rel="shortcut icon" href={Constants.SITE_URL + "/favicon.ico"} />
            <link rel="icon" href={Constants.SITE_URL + "/favicon.ico"} />
            <link rel="profile" href="https://gmpg.org/xfn/11" />
            <meta name="description" content="Ready to see how Augnito can revolutionize your clinical documentation process? Sign up for a demo today and experience the power of voice AI firsthand!"/>
            <link rel="canonical" href={Constants.SITE_URL + "schedule-demo"} />
            <meta property="og:url" content={Constants.SITE_URL + "schedule-demo"} />
            <meta property="og:title" content="Schedule a Demo | Augnito" />
            <meta property="og:description" content="Ready to see how Augnito can revolutionize your clinical documentation process? Sign up for a demo today and experience the power of voice AI firsthand!" />
            <meta property="og:image" content={Constants.SITE_URL + "/logo.png"} />
            <meta property="og:type" content="website" />
            <meta property="og:locale" content="en_US" />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:url" content={Constants.SITE_URL + "schedule-demo"}/>
            <meta name="twitter:site" content="@augnito" />
            <meta name="twitter:title" content="Schedule a Demo | Augnito" />
            <meta name="twitter:description" content="Ready to see how Augnito can revolutionize your clinical documentation process? Sign up for a demo today and experience the power of voice AI firsthand!" />
            <meta name="twitter:creator" content="@augnito" />
            <meta name="twitter:image" content={Constants.SITE_URL + "/logo.png"} />
            <meta name="copyright" content="Augnito" />
            <meta name="owner" content="Augnito" />
            <link rel="alternate" href={Constants.SITE_URL} hreflang="en-us" />
            <meta name="google-site-verification" content="TivMeqLjfaI6TIRsSF0Kqw0J8UnBPK2PToomuQRwVCs" />
        </Helmet>
    );
};

export default BookDemoMeta;