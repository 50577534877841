import React from 'react';

const SpectraTrustedBy = () => {
    return (
        <ul className='list-unstyled mb-0 d-flex flex-wrap'>
            <li>
                <img src={require('../../../../Assets/images/PartnersLogo/physician-one.webp')} alt="physician one logo" className="img-fluid p-10"/>
            </li>
            <li>
                <img src={require('../../../../Assets/images/PartnersLogo/nhs.webp')} alt="nhs logo" className="img-fluid p-10"/>
            </li>
            <li>
                <img src={require('../../../../Assets/images/PartnersLogo/apollo.webp')} alt="apollo logo" className="img-fluid p-10"/>
            </li>            
            <li>
                <img src={require('../../../../Assets/images/PartnersLogo/aster.webp')} alt="aster logo" className="img-fluid p-10"/>
            </li>
            <li>
                <img src={require('../../../../Assets/images/PartnersLogo/prime-hospital.webp')} alt="prime hospital logo" className="img-fluid p-10"/>
            </li>
            <li>
                <img src={require('../../../../Assets/images/PartnersLogo/barwon-health.webp')} alt="barwon health logo" className="img-fluid p-10"/>
            </li>
            <li>
                <img src={require('../../../../Assets/images/PartnersLogo/kims-hospital.webp')} alt="kims hospital logo" className="img-fluid p-10"/>
            </li>
            <li>
                <img src={require('../../../../Assets/images/PartnersLogo/max-healthcare.webp')} alt="max healthcare logo" className="img-fluid p-10"/>
            </li>
            <li>
                <img src={require('../../../../Assets/images/PartnersLogo/gleneasgles-hospital.webp')} alt="gleneasgles hospital logo" className="img-fluid p-10"/>
            </li>
            <li>
                <img src={require('../../../../Assets/images/PartnersLogo/fortis-logo.webp')} alt="fortis logo logo" className="img-fluid p-10"/>
            </li>
        </ul>           
    );
};

export default SpectraTrustedBy;