import { Helmet } from "react-helmet";
import * as Constants from '../../../Common/Constants'

const AmbientBookDemoMeta = () => {

    return (
        <Helmet>
            <title> Schedule a Demo for Augnito Ambient </title>
            <link rel="shortcut icon" href={Constants.SITE_URL + "/favicon.ico"} />
            <link rel="icon" href={Constants.SITE_URL + "/favicon.ico"} />
            <link rel="profile" href="https://gmpg.org/xfn/11" />
            <meta name="description" content="Experience the innovation of Augnito Ambient in clinical documentation. Register for a demo today and witness the capabilities of voice AI with NLP."/>
            <link rel="canonical" href={Constants.SITE_URL + "schedule-demo-ambient"} />
            <meta property="og:url" content={Constants.SITE_URL + "schedule-demo-ambient"} />
            <meta property="og:title" content="Schedule a Demo for Augnito Ambient" />
            <meta property="og:description" content="Experience the innovation of Augnito Ambient in clinical documentation. Register for a demo today and witness the capabilities of voice AI with NLP." />
            <meta property="og:image" content={Constants.SITE_URL + "/logo.png"} />
            <meta property="og:type" content="website" />
            <meta property="og:locale" content="en_US" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:url" content={Constants.SITE_URL + "schedule-demo-ambient"}/>
            <meta name="twitter:site" content="@augnito" />
            <meta name="twitter:title" content="Schedule a Demo for Augnito Ambient" />
            <meta name="twitter:description" content="Experience the innovation of Augnito Ambient in clinical documentation. Register for a demo today and witness the capabilities of voice AI with NLP." />
            <meta name="twitter:creator" content="@augnito" />
            <meta name="twitter:image" content={Constants.SITE_URL + "/logo.png"} />
            <meta name="copyright" content="Augnito" />
            <meta name="owner" content="Augnito" />
            <link rel="alternate" href={Constants.SITE_URL} hreflang="en-us" />
            <meta name="google-site-verification" content="TivMeqLjfaI6TIRsSF0Kqw0J8UnBPK2PToomuQRwVCs" />
        </Helmet>
    );
};

export default AmbientBookDemoMeta;