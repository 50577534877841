import React from 'react';

const OurPartnerLogo = () => {
    return (
        <ul className='list-unstyled mb-0 d-flex flex-wrap'>
            <li>
                <img src={require('../../../Assets/images/PartnersLogo/physician-one.webp')} alt="physicianone logo" className="img-fluid bg-white p-10"/>
            </li>
            <li>
                <img src={require('../../../Assets/images/PartnersLogo/fujifilm.webp')} alt="fujifilm logo" className="img-fluid bg-white p-10"/>
            </li>
            <li>
                <img src={require('../../../Assets/images/PartnersLogo/Intelerad.webp')} alt="intelerad logo" className="img-fluid bg-white p-10"/>
            </li>
            <li>
                <img src={require('../../../Assets/images/PartnersLogo/medweb.webp')} alt="medweb logo" className="img-fluid bg-white p-10"/>
            </li>
            <li>
                <img src={require('../../../Assets/images/PartnersLogo/open-rad.webp')} alt="openrad logo" className="img-fluid bg-white p-10"/>
            </li>
            <li>
                <img src={require('../../../Assets/images/PartnersLogo/nhs.webp')} alt="nhs logo" className="img-fluid bg-white p-10"/>
            </li>
            <li>
                <img src={require('../../../Assets/images/PartnersLogo/paxera-health.webp')} alt="paxera health logo" className="img-fluid bg-white p-10"/>
            </li>
            <li>
                <img src={require('../../../Assets/images/PartnersLogo/apollo.webp')}  alt="apollo logo" className="img-fluid bg-white p-10"/>
            </li>
            <li>
                <img src={require('../../../Assets/images/PartnersLogo/teleradiology-solutions.webp')} alt="teleradiology solutions logo" className="img-fluid bg-white p-10"/>
            </li>
            <li>
                <img src={require('../../../Assets/images/PartnersLogo/chartnote.webp')} alt="chartnote logo" className="img-fluid bg-white p-10"/>
            </li>
        </ul>
    );
};

export default OurPartnerLogo;