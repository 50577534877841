import React from 'react';
import Customer from '../../../../Assets/images/pricing/customer.webp';

const CustomerImg = () => {
    return (
       <div className='aug-customer-img'>
            <img src={Customer} alt='Customer sharing their positive testimonial with Augnito' className="img-fluid rounded-10 w-100" />
       </div>
    );
};

export default CustomerImg;