import React from 'react';
import Slider from "react-slick";
import ArrowRight from '../../../../Assets/images/icons/arrow-right.svg';
import ArrowLeft from '../../../../Assets/images/icons/arrow-left.svg';

function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        style={{ 
            backgroundImage: `url(${ArrowRight})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
         }}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        style={{
            backgroundImage: `url(${ArrowLeft})`, 
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        }}
        onClick={onClick}
      />
    );
  }

const CustomersSayIN = () => {
    const settings = {
        dots: true,
        autoplay:true,
        infinite: true,
        slidesToShow: 3,
        pauseOnHover: true,
        slidesToScroll: 1,
        arrows:true,
        className:'aug-customer-slider',
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 2,
                speed: 1000,
              }
            },
            {
              breakpoint: 575,
              settings: {
                slidesToShow: 1
              }
            }
        ]
    };
    return (
       <section className='aug-customer-say'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='offset-lg-2 offset-md-1 col-md-11 col-lg-6'>
                        <h2 className='aug-title mb-60'>What customers say</h2>
                    </div>
                </div>
                <div className='row'>
                    <div className='offset-lg-2 offset-md-1 col-12 col-md-11 col-lg-9'>
                        <Slider {...settings}>

                            <div className='aug-customer-items'>
                                <img src={require('../../../../Assets/images/HeadShot/IN/Ravikanti-Prasad.webp')} alt="Dr Ravikanti Prasad" height={96} width={96} className='img-fluid rounded-circle' />
                                <div className='mb-4 mt-3'>
                                    <h3 className='aug-customer-items__name mb-0'>Dr Ravikanti Prasad</h3>
                                    <h4 className='aug-customer-items__designation mb-0'>HOD, Radiology, Apollo Hospitals- Jubilee Hills</h4>
                                </div>
                                <sub className='aug-customer-items__quote d-flex'>“</sub>
                                <div className='aug-customer-items__text'>
                                My experience with Augnito has been excellent for the past few years. We have been using another speech to text service for the past seven years before switching to Augnito. With Augnito we have seen the turnaround time for our reports come down to less than one hour on most occasions.Augnito is very user friendly. We got started in no time and it has tremendously improved our quality of reports. We are now able to put all our thoughts into the report. I am able to discuss the case in the report. Many physicians are appreciating this detail. All thanks to Augnito. You team’s technical support is really excellent. We used to struggle for technical support from other speech recognition services. With Augnito, it was so seamless to get started and any questions we had, were answered quickly by your team.
                                </div>
                            </div>

                            <div className='aug-customer-items'>
                                <img src={require('../../../../Assets/images/HeadShot/IN/Aditya-Kulkarni.webp')} alt="Dr Aditya Kulkarni" height={96} width={96} className='img-fluid rounded-circle' />
                                <div className='mb-4 mt-3'>
                                    <h3 className='aug-customer-items__name text-truncate mb-0'>Dr Aditya Kulkarni</h3>
                                    <h4 className='aug-customer-items__designation mb-0'>Consultant Pathologist, Dept of Histopathology, Apollo Hospitals- Jubilee Hills</h4>
                                </div>
                                <sub className='aug-customer-items__quote d-flex'>“</sub>
                                <div className='aug-customer-items__text'>
                                With Augnito, I don’t have to wait for the printed report to come or write the report again as I can verify my reports right away. Augnito is really good at recognising medical vocabulary, picking up sentences and you can dictate in real time, as you speak.  I am saving time thanks to Augnito.
                                </div>
                            </div>

                            <div className='aug-customer-items'>
                                <img src={require('../../../../Assets/images/HeadShot/IN/Sapna-Nangia.webp')} alt="Dr Sapna Nangia" height={96} width={96} className='img-fluid rounded-circle' />
                                <div className='mb-4 mt-3'>
                                    <h3 className='aug-customer-items__name text-truncate mb-0'>Dr Sapna Nangia</h3>
                                    <h4 className='aug-customer-items__designation mb-0'>Sr Consultant, Radiation Oncology, Apollo Proton Center, Chennai </h4>
                                </div>
                                <sub className='aug-customer-items__quote d-flex'>“</sub>
                                <div className='aug-customer-items__text'>
                                I have been using Augnito for a few months now and I am very happy with the efficiency it has increased in terms of being able to document patient details.
                                </div>
                            </div>

                            <div className='aug-customer-items'>
                                <img src={require('../../../../Assets/images/HeadShot/IN/Shreesha-Maiya.webp')} alt="Dr Shreesha Maiya" height={96} width={96} className='img-fluid rounded-circle' />
                                <div className='mb-4 mt-3'>
                                    <h3 className='aug-customer-items__name text-truncate mb-0'>Dr Shreesha Maiya</h3>
                                    <h4 className='aug-customer-items__designation mb-0'>Pediatric Cardiologist, Narayana Institute of Cardiac Sciences</h4>
                                </div>
                                <sub className='aug-customer-items__quote d-flex'>“</sub>
                                <div className='aug-customer-items__text'>
                                I am very pleased to say that Augnito is extremely efficient and very helpful especially for busy doctors like us. I would highly recommend it to everyone.  
                                </div>
                            </div>

                            <div className='aug-customer-items'>
                                <img src={require('../../../../Assets/images/HeadShot/IN/Murali-Mohan.webp')} alt="Dr Murali Mohan" height={96} width={96} className='img-fluid rounded-circle' />
                                <div className='mb-4 mt-3'>
                                    <h3 className='aug-customer-items__name text-truncate mb-0'>Dr Murali Mohan</h3>
                                    <h4 className='aug-customer-items__designation mb-0'>Sr Consultant, Pulmonology and Internal Medicine, Narayana Health, Mazumdar Shaw Medical Center</h4>
                                </div>
                                <sub className='aug-customer-items__quote d-flex'>“</sub>
                                <div className='aug-customer-items__text'>
                                    Augnito has really saved me a lot of time, especially saved me tiresome key-ing in of all the words. It understands medical language very well and adapts to all kinds of accents. I would strongly recommend everyone to use it as it is a real boon to the practicing doctor.
                                </div>
                            </div>
                            
                        </Slider>
                    </div>
                </div>
            </div>
       </section>
    );
};

export default CustomersSayIN;