import React from 'react';

const SuccessfullyIntegratedIN = () => {
    return (
        <section className='aug-secure-compliant mt-60'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='offset-md-1 offset-lg-2 col-md-11 col-lg-10'>
                        <h2 className="aug-title mb-60">Successfully integrated with</h2>
                        <ul className='list-unstyled mb-0 d-flex flex-wrap'>
                            <li>
                                <img src={require('../../../../Assets/images/PartnersLogo/IN/fujifilm.webp')} alt="FujiFilm logo" className="img-fluid p-10"/>
                            </li>
                            <li>
                                <img src={require('../../../../Assets/images/PartnersLogo/IN/5C-Network.webp')} alt="5C Network logo" className="img-fluid p-10"/>
                            </li>
                            <li>
                                <img src={require('../../../../Assets/images/PartnersLogo/IN/CloudPhysician.webp')} alt="Cloud Physician logo" className="img-fluid p-10"/>
                            </li>
                            <li>
                                <img src={require('../../../../Assets/images/PartnersLogo/IN/Med-Synapse.webp')} alt="Medsynapse logo" className="img-fluid p-10"/>
                            </li>
                            <li>
                                <img src={require('../../../../Assets/images/PartnersLogo/IN/Kranium-Health.webp')} alt="Kranium Health logo" className="img-fluid p-10"/>
                            </li>
                            <li>
                                <img src={require('../../../../Assets/images/PartnersLogo/IN/Plus-91.webp')} alt="Plus91 logo" className="img-fluid p-10"/>
                            </li>
                            <li>
                                <img src={require('../../../../Assets/images/PartnersLogo/IN/Akhil-Systems.webp')} alt="Akhil Systems logo" className="img-fluid p-10"/>
                            </li>                            
                            <li>
                                <img src={require('../../../../Assets/images/PartnersLogo/IN/Tata-Elxsi.webp')} alt="Tata Elxsi logo" className="img-fluid p-10"/>
                            </li>                            
                            <li>
                                <img src={require('../../../../Assets/images/PartnersLogo/IN/Neos-Healthtech.webp')} alt="Neos Healthtech logo" className="img-fluid p-10"/>
                            </li>
                            {/* <li>
                                <img src={require('../../../../Assets/images/PartnersLogo/IN/DocPlix.webp')} alt="DocPlix logo" className="img-fluid p-10"/>
                            </li> */}
                        </ul>  
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SuccessfullyIntegratedIN;